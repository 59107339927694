// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box } from '@graphite/uneon';
import { getBoxSx } from '@graphite/selectors';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import type { TSx } from '@graphite/types';

import type { TConnectProps } from './constants/types';

import imgCaptcha from './captcha.inline.svg';

const captchaSx = {
	boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
	height: '76px',
	width: '302px',
	backgroundImage: `url(${imgCaptcha})`,
};
const Captcha = (props: TConnectProps, ref) => {
	const { dragContainer, dragFlip, data, position, gridspec } = props;

	const boxSx = gridspec ? getBoxSx({ data, position, gridspec }) : emptyObject;
	const dynamicStyle: ?TSx = useDynamicStyle(data.style);
	const otherProps = _.assign(dragContainer, dragFlip);

	const sx = React.useMemo(
		() => ({
			...boxSx,
			'[data-netlify-recaptcha="true"]': {
				...captchaSx,
			},
		}),
		[boxSx],
	);

	return (
		<Box
			ref={ref}
			style={dynamicStyle}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...otherProps}
			sx={sx}
		>
			<div data-netlify-recaptcha="true" />
		</Box>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Captcha));
