// @flow
import firebase from '../firebase';

export const auth = firebase.auth();
if (window.location.hostname === 'localhost') {
	auth.useEmulator('http://localhost:9099', { disableWarnings: true });
}

export const signInWithPopup = auth.signInWithPopup.bind(auth);
export const createUserWithEmailAndPassword =
	auth.createUserWithEmailAndPassword.bind(auth);
export const signInWithEmailAndPassword = auth.signInWithEmailAndPassword.bind(auth);
export const signInWithCustomToken = auth.signInWithCustomToken.bind(auth);

// FixMe: поменять на язык браузера
auth.languageCode = 'en';

export const { EmailAuthProvider } = firebase.auth;
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export default auth;
