// @flow
import _ from 'lodash/fp';
import {
	getCurrentPageId,
	getOrderIndex,
	getWidgetsByKind,
	getImageLibraryListUploads,
	getCurrentSiteId,
	closestDeviceWithKey,
} from '@graphite/selectors';
import { dirDefaults } from '@graphite/constants';
import { composeCached } from 'Widget/selectors';
import type {
	TId,
	TStateEditor,
	TWidgets,
	TWidgetOwnProps,
	TCurrentWidget,
	TWidgetChain,
} from '@graphite/types';

import mapStateToPropsPublished from './mapStateToProps';

type TGetWidgetModeArgs = $ReadOnly<{|
	currentWidget: ?TCurrentWidget,
	widgetChain: TWidgetChain,
	widgets: TWidgets,
	myId: TId,
|}>;
type TGetWidgetMode = TGetWidgetModeArgs => 'none' | 'hidden';

const getWidgetMode: TGetWidgetMode = _.cond([
	/* Если редактируется кто-то из моих потомков - скрываем */
	[
		({ currentWidget, myId }) => {
			// widgetChain from currentWidget
			const widgetChain = _.getOr([], 'widgetChain', currentWidget);
			return widgetChain.includes(myId);
		},
		_.constant<'hidden'>('hidden'),
	],
	/* Если стек в стеке и редактируется кто-то потомков и это не я - показываем */
	[
		({ currentWidget, widgetChain, widgets, myId }) => {
			// widgetChain from ownProps
			const parent = widgetChain[0];
			const currentWidgetId = _.getOr('', 'id', currentWidget);
			return (
				widgets[parent].kind === 'stack' &&
				myId !== currentWidget?.id &&
				widgets[parent].children &&
				widgets[parent].children[currentWidgetId]
			);
		},
		_.constant<'none'>('none'),
	],
	/* Если редактиуюсь я - показываем */
	[
		({ currentWidget, myId }) =>
			currentWidget?.id === myId && currentWidget?.controls,
		({ currentWidget }) => currentWidget.controls,
	],
	/* Если стек в стеке и родительский не редактируется - скрываем */
	[
		({ currentWidget, widgetChain, widgets }) => {
			const parent = widgetChain[0];
			return widgets[parent].kind === 'stack' && parent !== currentWidget?.id;
		},
		_.constant<'hidden'>('hidden'),
	],
	[_.stubTrue, _.constant<'none'>('none')],
]);

const getHoverAvailablity = (currentWidget, widgets, widgetChainInitial, myId) => {
	const widgetChain = widgetChainInitial || [];
	const isParentEdited = widgetChain[0] === currentWidget?.id;
	const parentKind = widgets[widgetChain[0]].kind || 'block';
	const excludesParentTypes = ['col', 'block'];
	const isSiblingsEditing =
		currentWidget &&
		currentWidget.widgetChain.includes(widgetChain[0]) &&
		!currentWidget.widgetChain.includes(myId);
	/* Мой ховер активен в случаях:
		1. если редактируется кто-то из других детей нашего общего родителя
		2. если я нахожусь непосредственно в блоке/коле или редактируется мой прямой родитель
		И НЕ редактируется никто из моих детей
		*/
	if (isSiblingsEditing) {
		return true;
	}
	return !!(
		isParentEdited ||
		(excludesParentTypes.includes(parentKind) &&
			!(currentWidget && currentWidget.widgetChain.includes(myId)))
	);
};

const getDragAvailable = (currentWidget, widgets, widgetChain) => {
	const isParentEdited: boolean = !!widgetChain && widgetChain[0] === currentWidget?.id;
	const parentKind = widgetChain[0] && widgets[widgetChain[0]].kind;
	const excludesParentTypes = ['col', 'block'];
	/*
		Драгать можно, если:
		- виджет лежит в колонке или блоке
		- если виджет лежит в стеке, который сейчас редактируется
	*/
	return !!(excludesParentTypes.includes(parentKind) || isParentEdited);
};

const mapStateToProps = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const {
		editor: { currentWidget, currentInstance, currentDevice },
		widgets,
	} = state;
	const currentPage = getCurrentPageId(state);
	const siteId = getCurrentSiteId(state);

	// Если нет текущей страницы, то лучше не едлать вид, что мы сможем это отрендерить.
	// Пусть уже падает с ошибкой, ибо произошла какая-то дичь
	// FixMe: Подумать как обработать эту ошибку
	if (!currentPage) throw new Error('originId not found');

	const { widgetChain: widgetChainInitial, containerId } = ownProps;
	const widgetChain = widgetChainInitial || [];

	const mapStateToPropsFromPublished = mapStateToPropsPublished(state, ownProps);

	const { data } = mapStateToPropsFromPublished;

	const hoverAvailable = getHoverAvailablity(
		currentWidget,
		widgets,
		widgetChain,
		data._id,
	);

	const widgetOrderIndex = getOrderIndex({
		id: data._id,
		currentPage,
		widgets,
		currentDevice,
	});

	const widgetMode = getWidgetMode({
		currentWidget,
		widgetChain,
		widgets,
		myId: data._id,
	});

	const parent = composeCached(widgets, widgets[containerId || 'none']);
	const { alignItems = 'stretch', flexDirection = 'row' } = closestDeviceWithKey(
		parent.box,
		{
			currentDevice,
			key: `box-${parent._id}`,
		},
	);

	const direction = dirDefaults[flexDirection];

	return {
		...mapStateToPropsFromPublished,
		currentDevice,
		widgetMode,
		isInstanceEdited: !!currentInstance && data._id === currentInstance,
		hoverAvailable,
		clickAvailable: hoverAvailable,
		dragAvailable: getDragAvailable(currentWidget, widgets, widgetChain),
		widgetOrderIndex,
		images: getWidgetsByKind(state, {
			kind: 'file',
			scopeId: siteId,
			removedAt: null,
		}),
		uploads: getImageLibraryListUploads(state),
		alignItems,
		direction,
	};
};

export default mapStateToProps;
