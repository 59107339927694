// @flow

import React from 'react';
import emptyObject from 'empty/object';
import _ from 'lodash/fp';
import { Text } from '@graphite/uneon';
import { minHeight, minWidth } from '@graphite/constants';

import { closestDeviceWithKey } from '@graphite/selectors';
import Poper from 'Widget/Poper';
import type { TMarginDevice } from '@graphite/types';

import type { TPropsResize } from './types';
import Controls from './Controls';
import { useResize } from '../libs';

const dynamicSide = ['top', 'right', 'left'];
const sidesHorizontal = ['left', 'right'];
const sidesVertical = ['top', 'bottom'];
const sides = [...sidesHorizontal, ...sidesVertical];

const round = num => Math.ceil(num * 100) / 100;

const getTitle = (resizeInfo, propName, dir) => {
	if (!dir) {
		return null;
	}

	if (propName === 'margin' && resizeInfo.margin) {
		const { top = 0, left = 0, right = 0, bottom = 0 } = resizeInfo.margin;

		// явно указаны стороны, ибо флоу не ругает если не указать только нужные
		if (dir === 'top') return `${round(top)} px`;
		if (dir === 'left') return `${round(left)} px`;
		if (dir === 'right') return `${round(right)} px`;
		if (dir === 'bottom') return `${round(bottom)} px`;
	}

	if (propName === 'sizes' && (resizeInfo.height || resizeInfo.width)) {
		const {
			width = minWidth,
			height = minHeight,
			heightUnit = 'px',
			widthUnit = 'px',
		} = resizeInfo;
		if (sides.includes(dir)) {
			if (sidesHorizontal.includes(dir)) {
				return `${round(width)} ${widthUnit}`;
			}
			return `${round(height)} ${heightUnit}`;
		}

		return `${round(height)} ${heightUnit} / ${round(width)} ${widthUnit}`;
	}

	if (propName === 'offset' && resizeInfo.offset) {
		const { width = minWidth, height = minHeight } = resizeInfo.offset;
		if (sides.includes(dir)) {
			if (sidesHorizontal.includes(dir)) {
				return `${round(width)} px`;
			}
			return `${round(height)} px`;
		}
		return `${round(height)} x ${round(width)} px`;
	}
};

function Resize(props: TPropsResize): React$Node {
	const {
		isShown,
		currentDevice,
		requiredControls,
		direction,
		alignItems,
		justifyContent,
		isAbsolute,
	} = props;

	const refWrap = React.useRef(null);

	const { actions, resizeData } = useResize({
		refWrap,
		currentDevice,
		requiredControls,
		direction,
	});
	const { initialData, data, isResize, resizeInfo = {}, dir, propName } = resizeData;

	const { modified } = data;
	const isInstance = _.isArray(modified);

	const initialMargin: TMarginDevice = React.useMemo(() => {
		const initialBox = closestDeviceWithKey(initialData.box, {
			currentDevice,
			key: `initialBox-${data._id}`,
		});
		return initialBox.margin || (emptyObject: TMarginDevice);
	}, [currentDevice, data._id, initialData.box]);

	const margin = React.useMemo(() => {
		if (!resizeInfo.margin) {
			return initialMargin;
		}

		const { left = 0, right = 0, top = 0, bottom = 0 } = resizeInfo.margin;

		return {
			left,
			right,
			top,
			bottom,
		};
	}, [resizeInfo, initialMargin]);

	return (
		<>
			<Controls
				isShown={isShown}
				isResize={isResize}
				isInstance={isInstance}
				margin={margin}
				dynamicSide={dynamicSide}
				actions={actions}
				requiredControls={requiredControls}
				ref={refWrap}
				direction={direction}
				justifyContent={justifyContent}
				alignItems={alignItems}
				isAbsolute={isAbsolute}
			/>
			{isResize && (
				<Poper>
					<Text variant="captionlg" color="spec.lightblue10">
						{getTitle(resizeInfo, propName, dir)}
					</Text>
				</Poper>
			)}
		</>
	);
}

export default React.memo<TPropsResize>(Resize);
