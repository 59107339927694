// @flow
import React from 'react';

import withDrag from 'Widget/libs/dnd/drag';

import type { TWidgetGridProps, TWithGreedDragProps } from '../constants/types';

const withGridDrag = (Component: React$ComponentType<TWidgetGridProps>) => {
	const DragWidget = withDrag<TWidgetGridProps>(Component);

	const WidgetWrapper = (props: TWithGreedDragProps) => {
		const { protoId, addWidgetOnClick, ...otherProps } = props;
		const handleMouseDown = React.useCallback((event: MouseEvent) => {
			event.stopPropagation();
		}, []);

		const handleMouseUp = React.useCallback(
			event => {
				event.stopPropagation();
				if (addWidgetOnClick) addWidgetOnClick(protoId, 'grid');
			},
			[addWidgetOnClick, protoId],
		);

		return (
			<div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
				<DragWidget
					rowId={null}
					instanceId={null}
					originId={null}
					id={props.protoId}
					containerId="panel"
					protoId={protoId}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...otherProps}
				/>
			</div>
		);
	};

	return React.memo<TWithGreedDragProps>(WidgetWrapper);
};

export default withGridDrag;
