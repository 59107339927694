// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash/fp';
import emptyFunction from 'empty/function';
import { Box, ButtonGroup } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';
import {
	gridBreakpointsNames,
	defaultBreakpoint,
	deviceTabsSm,
} from '@graphite/constants';
import { updateEditor } from 'Editor/ducks/editor';
import { useDispatch } from '@graphite/use-redux';
import { getCurrentDevice } from 'Editor/selectors/editor';
import useDefaultDevice from 'Editor/libs/use-default-device';
import type {
	TSpecs,
	TSpecsGrid,
	TUnit,
	TActiveButtons,
	TParamSource,
	TParams,
	TListParamsOnClick,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	gridspec: TSpecsGrid,
	updateSpecs?: TSpecs => void,
|}>;

const groupStyle = {
	justifyContent: 'space-around',
	margin: '27px -24px 22px -24px',
};

const listStyle = {
	marginBottom: '12px',
};

const paramList: TParams = [
	{
		title: 'Columns',
		key: 'columns',
		kind: 'unit',
		info: {
			showUnits: false,
			domain: 'nonnegative',
			unitKey: null,
			isRequired: true,
			isInteger: true,
			max: 24,
			min: 1,
		},
	},
	{
		title: 'Gutter',
		key: 'gutter',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'gutterUnit',
			domain: 'nonnegative',
			units: ['unit', 'px'],
		},
	},
	{
		title: 'Container',
		key: 'container',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'containerUnit',
			domain: 'positive',
			units: ['px', 'unit', '%'],
		},
	},
	{
		title: 'Margin',
		key: 'padding',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'paddingUnit',
			domain: 'nonnegative',
			units: ['unit', 'px'],
		},
	},
];

function Columns({ gridspec, updateSpecs = emptyFunction }: TProps) {
	const currentDevice = useSelector(getCurrentDevice);
	const dispatch = useDispatch();
	const defaultDevice = useDefaultDevice();
	const paramListEnd = React.useMemo(
		() =>
			currentDevice === defaultDevice
				? paramList
				: [
						{
							title: 'Breakpoint',
							key: 'breakpoint',
							kind: 'unit',
							info: {
								showUnits: true,
								unitKey: null,
								signKey: 'sign',
							},
						},
						...paramList,
				  ],
		[currentDevice, defaultDevice],
	);

	const availableDevices = React.useMemo(
		() =>
			deviceTabsSm.filter(
				({ name }) => !!_.get(`breakpoints.${name}.active`, gridspec),
			),
		[gridspec],
	);

	const changeDevice = React.useCallback(
		(e, nextDevice) => {
			const currentDevice =
				gridBreakpointsNames.find(name => name === nextDevice) ||
				gridBreakpointsNames[0];
			dispatch(updateEditor({ currentDevice }));
		},
		[dispatch],
	);

	const breakpoint: TParamSource = React.useMemo(() => _.set(
			'sign',
			'≤',
			_.mapValues(v => {
				if (typeof v === 'string') {
					return v;
				}
				if (typeof v === 'number') {
					return `${v}`;
				}
				return '';
			}, gridspec.breakpoints[currentDevice] || defaultBreakpoint),
		), [currentDevice, gridspec.breakpoints]);

	const changeParam = React.useCallback(
		(key, value) =>
			updateSpecs({
				[gridspec._id]: _.set(
					['breakpoints', currentDevice, key],
					+value,
					gridspec,
				),
			}),
		[updateSpecs, gridspec, currentDevice],
	);

	const clickBound = React.useCallback(
		(e, name) => {
			if (typeof name === 'string') {
				changeDevice(e, name);
			}
		},
		[changeDevice],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key: string, name: ?TActiveButtons) => {
			if (name !== '%' && name !== 'px' && name !== 'unit') {
				return;
			}
			const unitNext: TUnit = name;
			const param = paramListEnd.find(p => p.key === key);
			if (!param || param.kind !== 'unit' || !param.info.unitKey) {
				return;
			}

			const unitPath = ['breakpoints', currentDevice, param.info.unitKey];
			const unitPrev: TUnit = _.get(unitPath, gridspec);
			if (unitPrev === unitNext) {
				return;
			}

			let spec = _.set(unitPath, unitNext, gridspec);

			const valuePath = ['breakpoints', currentDevice, param.key];

			// Только поле 'container' может содержать проценты
			if (param.key === 'container' && (unitPrev === '%' || unitNext === '%')) {
				// Convert (% <---> px/unit), apply defaults
				if (unitPrev === '%') {
					const defaultPx = Math.round(
						_.get(['breakpoints', currentDevice, 'breakpoint'], gridspec) *
							_.get(valuePath, gridspec) *
							0.01,
					);
					if (unitNext === 'px') {
						spec = _.set(valuePath, defaultPx, spec);
					} else if (unitNext === 'unit') {
						spec = _.set(valuePath, Math.round(defaultPx / spec.unit), spec);
					}
				} else if (unitNext === '%') {
					const defaultPc = Math.round(
						((_.get(valuePath, gridspec) *
							(unitPrev === 'px' ? 1 : spec.unit)) /
							_.get(
								['breakpoints', currentDevice, 'breakpoint'],
								gridspec,
							)) *
							100,
					);
					spec = _.set(valuePath, defaultPc, spec);
				}
			} else if (unitPrev !== '%' && unitNext !== '%') {
				const valuePrev = _.get(valuePath, spec);
				if (unitNext === 'px') {
					spec = _.set(valuePath, valuePrev * spec.unit, spec);
				} else if (unitNext === 'unit') {
					spec = _.set(valuePath, Math.round(valuePrev / spec.unit), spec);
				}
			} else {
				return;
			}

			updateSpecs({ [spec._id]: spec });
		},
		[paramListEnd, currentDevice, gridspec, updateSpecs],
	);

	return (
		<>
			<ButtonGroup
				behavior="radio"
				active={currentDevice}
				buttons={availableDevices}
				shape="underlined"
				variant="flat"
				size="sm"
				colors="secondaryflat"
				activeColors="accentflat"
				onClick={clickBound}
				sx={groupStyle}
			/>
			<Box sx={listStyle}>
				<ListParams
					key={currentDevice}
					listName={currentDevice}
					paramSource={breakpoint}
					paramList={paramListEnd}
					unit={gridspec.unit}
					onChange={changeParam}
					onClick={clickUnit}
				/>
			</Box>
		</>
	);
}

Columns.defaultProps = {
	updateSpecs: emptyFunction,
};

export default React.memo<TProps>(Columns);
