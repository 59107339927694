// @flow
import React from 'react';
import _ from 'lodash/fp';
import { getTrueIds, getWidget, getPresets } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import { defaultDevice } from '@graphite/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import faker from 'faker';

import {
	removeWidget,
	placeWidget,
	editWidget,
	createPreset,
	syncScopedWidgets,
	unsyncScopedWidgets,
} from 'Editor/ducks/widgets';
import { syncScopedSpecs, unsyncScopedSpecs } from 'Editor/ducks/specs';
import type { TWidgetOwnProps } from '@graphite/types';

import Project from './Project';

const ProjectEdit = ({ id, instanceId, containerId, originId }: TWidgetOwnProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const project = useSelector(state => getWidget(state, { id }));
	const widgetPresets = useSelector(getPresets);
	const presets = _.pickBy(
		({ kind, scope }) => kind === 'site' && scope === 'market',
		widgetPresets,
	);

	React.useEffect(() => {
		if (project) {
			dispatch(
				syncScopedWidgets('project', project._id, project.userId, 'dashboard'),
			);
			dispatch(
				syncScopedSpecs('project', project._id, project.userId, 'dashboard'),
			);
			dispatch(syncScopedWidgets('market', null, null, 'dashboard'));
		}

		return () => {
			if (project) {
				dispatch(
					unsyncScopedWidgets(
						'project',
						project._id,
						project.userId,
						'dashboard',
					),
				);
				dispatch(
					unsyncScopedSpecs(
						'project',
						project._id,
						project.userId,
						'dashboard',
					),
				);
				dispatch(unsyncScopedSpecs('market', null, null, 'dashboard'));
			}
		};
	}, [dispatch, project]);

	const sites = useSelector(state =>
		_.reduce(
			(res, id) => {
				const widget = getWidget(state, { id });
				if (!widget) return res;
				return { ...res, [id]: widget };
			},
			{},
			getTrueIds({
				...project,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
			}),
		),
	);

	const onCreateSite = React.useCallback(
		presetId => {
			dispatch(
				placeWidget(
					presetId,
					id,
					null,
					originId,
					{
						kind: 'grid',
						prevId: null,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					{ name: faker.address.streetName() },
				),
			);
		},
		[dispatch, id, originId],
	);

	const onRemove = React.useCallback(
		(targetId, containerId) => {
			if (!originId) return;

			dispatch(removeWidget(targetId, containerId, instanceId, originId));
		},
		[dispatch, instanceId, originId],
	);

	const onUpdate = React.useCallback(
		(id, diff) => {
			if (!originId) return;
			dispatch(editWidget(id, instanceId, originId, diff));
		},
		[dispatch, instanceId, originId],
	);

	const onSelect = React.useCallback(
		siteId => {
			if (!containerId) return;
			const pageId = getTrueIds({
				...sites[siteId],
				currentDevice: defaultDevice,
			})[0];
			history.push(`/project/${id}/site/${siteId}/page/${pageId}`);
		},
		[containerId, history, id, sites],
	);

	const onCreatePreset = React.useCallback(
		id => {
			dispatch(createPreset(id));
		},
		[dispatch],
	);

	if (!project || !containerId) return null;

	return (
		<Project
			id={id}
			containerId={containerId}
			project={project}
			sites={sites}
			presets={presets}
			t={t}
			onRemove={onRemove}
			onUpdate={onUpdate}
			onCreateSite={onCreateSite}
			onSelect={onSelect}
			onCreatePreset={onCreatePreset}
		/>
	);
};

export default React.memo<TWidgetOwnProps>(ProjectEdit);
