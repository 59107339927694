// @flow
import { takeEvery, put, fork, select } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import { type Saga } from 'redux-saga';
import _ from 'lodash/fp';

import logger from '@graphite/logger';
import { collection } from 'libs/firebase';
import { getCurrentSite } from '@graphite/selectors';
import type { TAction, TWidget } from '@graphite/types';

type TStatusProcessing =
	| 'connected'
	| 'connecting'
	| 'disconnecting'
	| 'disconnected'
	| 'reset'
	| 'fail';

type TState = $ReadOnly<{|
	status: TStatusProcessing,
|}>;

const CONNECT = 'DOMAIN/CONNECT';
const DISCONNECT = 'DOMAIN/DISCONNECT';
const FAIL = 'DOMAIN/FAIL';
const RESET = 'DOMAIN/RESET';
const REQUEST = 'DOMAIN/REQUEST';

export const connect = (domain: string): TAction => ({
	type: CONNECT,
	payload: { domain },
});

export const disconnect = (domain: string): TAction => ({
	type: DISCONNECT,
	payload: { domain },
});
export const request = (): TAction => ({
	type: REQUEST,
	payload: {},
});
export const fail = (): TAction => ({
	type: FAIL,
	payload: {},
});
export const reset = (): TAction => ({
	type: RESET,
	payload: {},
});

export function* disconnectSaga(): Saga<void> {
	yield takeEvery(DISCONNECT, function*({
		payload: { domain },
	}: {
		payload: { domain: string },
	}): Saga<void> {
		try {
			yield put(request());

			const site: ?TWidget = yield select(getCurrentSite);
			if (!site) {
				throw new Error('Site should all be specified.');
			}

			yield collection('domains')
				.doc(site._id)
				.set({
					status: 'disconnecting',
					updateAt: new Date().toISOString(),
					doneAt: null,
					userId: site.userId,
					siteId: site._id,
					domain,
				});
			logger.info('disconnectDomain');
		} catch (e) {
			yield put(fail());
			logger.error(e);
		}
	});
}

export function* connectSaga(): Saga<void> {
	yield takeEvery(CONNECT, function*({
		payload: { domain },
	}: {
		payload: { domain: string },
	}): Saga<void> {
		try {
			yield put(request());

			const site: ?TWidget = yield select(getCurrentSite);
			if (!site) {
				throw new Error('Site should all be specified.');
			}
			yield collection('domains')
				.doc(site._id)
				.set({
					status: 'connecting',
					updateAt: new Date().toISOString(),
					doneAt: null,
					userId: site.userId,
					siteId: site._id,
					domain,
				});
			logger.info('connectDomain');
		} catch (e) {
			yield put(fail());
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(connectSaga);
	yield fork(disconnectSaga);
}

const initialState: TState = {
	status: 'connecting',
};

export default handleActions<TState, TAction>(
	{
		[RESET](state: TState): TState {
			return _.set('status', 'reset', state);
		},
		[REQUEST](state: TState): TState {
			return _.set('status', 'request', state);
		},
		[FAIL](state: TState): TState {
			return _.set('status', 'error', state);
		},
	},
	initialState,
);
