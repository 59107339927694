// @flow
import _ from 'lodash/fp';
import reSelect from 're-reselect';
import { listMatchWeight } from '@graphite/constants';
import type { TId, TSpecsWidget, TFonts, TDesigns } from '@graphite/types';
import type { RawDraftContentState } from 'draft-js/lib/RawDraftContentState';

const getFont = (designId: TId, designs: TDesigns, widgetspec: TSpecsWidget): TFonts => {
	const design = widgetspec.text.find((t) => t._id === designId) || designs?.[designId];

	return _.map((device) => {
		const font = {};

		if (typeof device.family === 'string') {
			const { family, weight, variants = [] } = device;
			font.family = family;
			font.id = family.replace(/\s+/g, '-').toLowerCase();

			if (typeof weight === 'string') {
				const avaibleVariants = variants.filter((item) => {
					const currentWeight = listMatchWeight.find(
						({ name }) => name === weight,
					);

					if (currentWeight) {
						if (currentWeight.value === 'regular' && item === 'italic')
							return true;

						return item.includes(currentWeight.value);
					}

					return false;
				});

				font.variants = avaibleVariants;
			}
		}

		return font;
	}, design?.breakpoints ?? {});
};

const getFonts = reSelect<
	$ReadOnly<{
		id: TId,
		designs: TDesigns,
		raw: RawDraftContentState,
		widgetspec: TSpecsWidget,
	}>,
	TDesigns,
	RawDraftContentState,
	TSpecsWidget,
	TFonts,
>(
	({ designs }) => designs,
	({ raw }) => raw,
	({ widgetspec }) => widgetspec,
	(designs, raw, widgetspec) => {
		const { blocks } = raw;
		return blocks.reduce((acc, { type }) => {
			const designId: TId = type.slice(0, type.lastIndexOf('-'));
			const fonts: TFonts = getFont(designId, designs, widgetspec);
			return [...acc, ...fonts];
		}, []);
	},
)(({ id }) => `widget@getFonts-${id}`);

export default getFonts;
