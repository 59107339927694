// @flow
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import emptyObject from 'empty/object';
import reSelect from 're-reselect';
import {
	closestDeviceWithKey,
	getActiveBreakpointNames,
	maxCombineSx,
} from '@graphite/selectors';
import type {
	TSx,
	TId,
	TSpecsGrid,
	TWidget,
	TWidgetBox,
	TWidgetBoxBreakpoint,
} from '@graphite/types';

export type TGetImagePositionBreakpointSxParams = $ReadOnly<{|
	breakpoint: TWidgetBoxBreakpoint,
|}>;

export type TGetImagePositionBreakpointSxFn = TGetImagePositionBreakpointSxParams => TSx;

export type TGetImagePositionSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetImagePositionSxFn = TGetImagePositionSxParams => TSx;

// Для стилизации текста по спеке
export const getImagePositionBreakpointSx: TGetImagePositionBreakpointSxFn = reSelect<
	TGetImagePositionBreakpointSxParams,
	TWidgetBoxBreakpoint,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	breakpoint => {
		const {
			objectPositionX,
			objectPositionXUnit,
			objectPositionY,
			objectPositionYUnit,
		} = breakpoint;

		const positionX = `${
			typeof objectPositionX === 'number' ? objectPositionX : 50
		}${objectPositionXUnit || '%'}`;
		const positionY = `${
			typeof objectPositionY === 'number' ? objectPositionY : 50
		}${objectPositionYUnit || '%'}`;

		const customSx = {
			objectPosition: `${positionX} ${positionY}`,
		};

		return customSx;
	},
)(({ breakpoint }) => ['widget@getContainerBreakpointSx', size(breakpoint)].join('-'));

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getImagePositionSx: TGetImagePositionSxFn = reSelect<
	TGetImagePositionSxParams,
	?TWidgetBox,
	TId,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ gridspec }) => gridspec,
	(box, _id, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: map(currentDevice => {
					const breakpoint = closestDeviceWithKey(box, {
						currentDevice,
						key: `box-${_id}`,
					});
					const breakpointSx = getImagePositionBreakpointSx({
						breakpoint,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			return (emptyObject: TSx);
		}
	},
)(({ data, gridspec }) =>
	['widget@getImagePositionSx', data._id, gridspec._id].join('-'),
);
