// @flow

import { createApi } from 'unsplash-js';
import { config } from '@graphite/constants';
import logger from '@graphite/logger';

const unsplash = createApi({ accessKey: config.apiUnsplashKey });

export type TImgsUnsplash = $ReadOnlyArray<{
	urls: { regular: string, thumb: string },
	id: string,
	height: number,
	width: number,
	description: ?string,
	alt_description: ?string,
	user: { name: string, username: string, links: { html: string, self: string } },
	links: { download_location: string },
}>;

/**
 * Loading latest imgs from unsplash
 *
 * @param {*} imgsCount
 * @param {*} page
 */
const latestUnsplash = async (
	imgsCount: number,
	page: number = 1,
): Promise<TImgsUnsplash> => {
	const result = await unsplash.photos.list({ page, perPage: imgsCount });

	if (result.type === 'error') {
		logger.error('unsplash error', await result?.errors[0]);
	}

	if (result.type === 'success') {
		return result.response.results;
	}

	return [];
};

/**
 * Loking for key in unsplash
 *
 * @param {*} key
 * @param {*} imgsCount
 * @param {*} page
 */
const searchUnsplash = async (
	key: string,
	imgsCount,
	page = 1,
): Promise<TImgsUnsplash> => {
	const result = await unsplash.search.getPhotos({
		query: key,
		page,
		perPage: imgsCount,
	});

	if (result.type === 'error') {
		logger.error('unsplash error', await result?.errors[0]);
	}

	if (result.type === 'success') {
		return result.response.results;
	}

	return [];
};

/**
 * Get list of imgs
 *
 * @param {*} imgsCount
 * @param {*} key
 * @param {*} page
 */
export const getUnsplash = async (
	imgsCount: number,
	key?: ?string = null,
	page?: number = 1,
): Promise<TImgsUnsplash> => {
	// if more than 30 imgs then doing several request
	if (key) return searchUnsplash(key, imgsCount, page);

	return latestUnsplash(imgsCount, page);
};

/**
 * Trigger a download event
 *
 * @param {*} downloadLocation
 */
export const triggeringADownload = (downloadLocation: ?string): void => {
	if (downloadLocation) {
		try {
			unsplash.photos.trackDownload({
				downloadLocation,
			});
		} catch (e) {
			logger.error(e);
		}
	}
};
