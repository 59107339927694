// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { useTranslation } from 'react-i18next';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';
import { closestDeviceWithKey } from '@graphite/selectors';
import { editWidget } from 'Editor/ducks/widgets';
import type {
	TUnit,
	TWidgetBoxBreakpoint,
	TPaddingDevice,
	TActiveButtons,
	TParams,
	TListParamsOnClick,
} from '@graphite/types';

import type { TPropsLayout } from './constants/types';

const DEFAULT_PX_HEIGHT = 400;

const checkProp = prop => {
	if (prop === undefined || prop === null) {
		return '';
	}

	// Должна быть строка, т.к далее свойство пройдёт через slice
	return `${prop}`;
};

const Layout = ({
	dispatch,
	gridspec,
	currentDevice,
	data,
	instanceId,
	originId,
}: TPropsLayout) => {
	const { t } = useTranslation();
	const { _id } = data;
	const { unit } = gridspec;

	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		key: `box-${data._id}`,
	});
	const { height, heightUnit = 'auto', padding = (emptyObject: TPaddingDevice) } = box;

	/**
		Height
	 */
	const paramSourceHeight = React.useMemo(() => {
		if (heightUnit === 'px') {
			return {
				heightUnit: 'px',
				height: `${height || DEFAULT_PX_HEIGHT}`,
			};
		}
		if (heightUnit === 'unit') {
			return {
				heightUnit: 'unit',
				height: `${height || DEFAULT_PX_HEIGHT / unit}`,
			};
		}
		return {
			heightUnit: 'auto',
			height: '',
		};
	}, [height, heightUnit, unit]);

	const paramListHeight: TParams = React.useMemo(
		() => [
			{
				title: t('Height'),
				key: 'height',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'heightUnit',
					domain: 'positive',
					units: ['auto', 'px', 'unit'],
				},
			},
		],
		[t],
	);

	const saveHeight = React.useCallback(
		(key, value) => {
			if (!originId || typeof value !== 'number') {
				return;
			}
			dispatch(
				editWidget(_id, instanceId, originId, {
					box: _.set(currentDevice, { ...box, height: value }, data.box),
				}),
			);
		},
		[originId, dispatch, _id, instanceId, currentDevice, box, data.box],
	);

	const clickHeightUnit: TListParamsOnClick = React.useCallback(
		(e, key: string, name: ?TActiveButtons) => {
			if (!originId) {
				return;
			}
			if (name !== 'vh' && name !== 'px' && name !== 'unit' && name !== 'auto') {
				return;
			}
			const unitNext: TUnit = name;
			const unitPrev: TUnit = box.heightUnit || 'auto';

			if (unitPrev === unitNext) {
				return;
			}

			const valuePrev = box.height;
			let valueNext = box.height;
			if (unitNext === 'px' && unitPrev === 'unit') {
				valueNext = valuePrev ? valuePrev * unit : DEFAULT_PX_HEIGHT;
			} else if (unitNext === 'unit' && unitPrev === 'px') {
				valueNext = Math.round((valuePrev || DEFAULT_PX_HEIGHT) / unit);
			} else if (unitNext === 'px') {
				valueNext = DEFAULT_PX_HEIGHT;
			} else if (unitNext === 'px') {
				valueNext = DEFAULT_PX_HEIGHT / unit;
			}

			dispatch(
				editWidget(_id, instanceId, originId, {
					box: _.set(
						currentDevice,
						{
							...box,
							height: valueNext,
							heightUnit: name,
						},
						data.box,
					),
				}),
			);
		},
		[box, dispatch, _id, instanceId, originId, currentDevice, data.box, unit],
	);

	/**
		Grid
	 */
	const breakpoint =
		(data.gridspec &&
			closestDeviceWithKey(data.gridspec.breakpoints, {
				currentDevice,
				key: `gridspec-${currentDevice}`,
			})) ||
		null;

	const paramSourceGrid = React.useMemo(
		() => ({
			fluid: box.fluid ? 'true' : '',
			gridEnabled: data.gridspec ? 'true' : '',
			gridspec: breakpoint
				? {
						columns: checkProp(breakpoint.columns),
						gutter: checkProp(breakpoint.gutter),
						container: checkProp(breakpoint.container),
						padding: checkProp(breakpoint.padding),
						gutterUnit: checkProp(breakpoint.gutterUnit),
						containerUnit: checkProp(breakpoint.containerUnit),
						paddingUnit: checkProp(breakpoint.paddingUnit),
				  }
				: emptyObject,
		}),
		[box.fluid, data.gridspec, breakpoint],
	);

	const paramListGrid: TParams = React.useMemo(() => {
		let params = [
			{
				title: t('Fluid Container'),
				key: 'fluid',
				kind: 'switch',
				info: {},
			},
			{
				title: t('Override Grid'),
				key: 'gridEnabled',
				kind: 'switch',
				info: {},
			},
		];

		if (data.gridspec) {
			params = [
				...params,
				{
					title: t('Columns'),
					key: 'gridspec.columns',
					kind: 'unit',

					info: {
						showUnits: false,
						domain: 'nonnegative',
						unitKey: null,
						isRequired: true,
						isInteger: true,
						max: 24,
						min: 1,
					},
				},
				{
					title: t('Gutter'),
					key: 'gridspec.gutter',
					kind: 'unit',
					info: {
						showUnits: true,
						domain: 'nonnegative',
						unitKey: 'gridspec.gutterUnit',
					},
				},
				{
					title: t('Container'),
					key: 'gridspec.container',
					kind: 'unit',
					info: {
						showUnits: true,
						domain: 'positive',
						unitKey: 'gridspec.containerUnit',
					},
				},
				{
					title: t('Margin'),
					key: 'gridspec.padding',
					kind: 'unit',
					info: {
						showUnits: true,
						domain: 'nonnegative',
						unitKey: 'gridspec.paddingUnit',
					},
				},
			];
		}

		return params;
	}, [t, data.gridspec]);

	const saveGrid = React.useCallback(
		(key, value) => {
			if (!originId) return;
			if (key === 'fluid' && typeof value === 'boolean') {
				dispatch(
					editWidget(_id, instanceId, originId, {
						box: _.set(`${currentDevice}.fluid`, value, data.box),
					}),
				);
				return;
			}

			if (key === 'gridEnabled') {
				dispatch(
					editWidget(_id, instanceId, originId, {
						gridspec: (value && gridspec) || null,
					}),
				);
				return;
			}

			if (data.gridspec && key.startsWith('gridspec.')) {
				dispatch(
					editWidget(_id, instanceId, originId, {
						gridspec: _.set(
							`breakpoints.${currentDevice}.${key.slice(
								'gridspec.'.length,
							)}`,
							value,
							data.gridspec,
						),
					}),
				);
			}
		},
		[
			_id,
			currentDevice,
			data.box,
			data.gridspec,
			dispatch,
			gridspec,
			instanceId,
			originId,
		],
	);

	/**
		Padding
	 */
	const paramSourcePadding = React.useMemo(
		() => ({
			top: `${padding.top || 0}`,
			bottom: `${padding.bottom || 0}`,
		}),
		[padding],
	);

	const paramListPadding: TParams = React.useMemo(() => {
		const params = [
			{
				title: t('Top'),
				key: 'top',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: null,
					domain: 'nonnegative',
				},
			},
			{
				title: t('Bottom'),
				key: 'bottom',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: null,
					domain: 'nonnegative',
				},
			},
		];

		return params;
	}, [t]);

	const savePadding = React.useCallback(
		(key, value) => {
			if (!originId || typeof value !== 'number') {
				return;
			}
			dispatch(
				editWidget(_id, instanceId, originId, {
					box: _.set(`${currentDevice}.padding.${key}`, value, data.box),
				}),
			);
		},
		[_id, currentDevice, data.box, dispatch, instanceId, originId],
	);

	return (
		<>
			<Section label={t('Height')}>
				<ListParams
					listName="height"
					paramSource={paramSourceHeight}
					paramList={paramListHeight}
					unit={unit}
					onChange={saveHeight}
					onClick={clickHeightUnit}
				/>
			</Section>
			<Section label={t('Grid')}>
				<ListParams
					listName="grid"
					paramSource={paramSourceGrid}
					paramList={paramListGrid}
					unit={unit}
					onChange={saveGrid}
				/>
			</Section>
			<Section label={t('Padding')}>
				<ListParams
					listName="padding"
					paramSource={paramSourcePadding}
					paramList={paramListPadding}
					unit={unit}
					onChange={savePadding}
				/>
			</Section>
		</>
	);
};

export default React.memo<TPropsLayout>(Layout);
