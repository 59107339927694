// @flow
import React from 'react';
import { Box, Checkers, Overlay } from '@graphite/uneon';
import emptyFunction from 'empty/function';
import chroma from 'chroma-js';

type TProps = $ReadOnly<{|
	alpha: number,
	color: string,
	onChange?: number => void,
	onPreview?: number => void,
|}>;

const containerStyle = {
	position: 'relative',
	userSelect: 'none',
	height: '18px',
	cursor: 'pointer',
};

const handleWrapperStyle = {
	position: 'relative',
	width: '0',
	height: '0',
};

const boundStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
};

const handleStyle = {
	position: 'absolute',
	left: '-7px',
	top: '-7px',
	width: '14px',
	height: '14px',
	cursor: 'pointer',
	borderWidth: '3px',
	borderStyle: 'solid',
	borderColor: 'spec.lightblue10',
	borderRadius: 'rounded.all',
	boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
};

const roundedStyle = {
	...boundStyle,
	borderRadius: 'rounded.all',
};

const checkersStyle = {
	height: '18px',
	borderRadius: 'rounded.all',
	boxShadow: '0 0 0  1px #222',
};

const cursorSx = {
	cursor: 'pointer',
};

const getLeftRightStyle = color => {
	const [r, g, b] = chroma(color).rgb();
	const rgb = `${r},${g},${b}`;
	return {
		background: [
			'linear-gradient(90deg, rgba(',
			rgb,
			', 0) 0%, rgba(',
			rgb,
			', 1) 100%)',
		].join(''),
	};
};

function SliderColorAlpha({
	alpha,
	color,
	onChange = emptyFunction,
	onPreview = emptyFunction,
}: TProps) {
	const [width, setWidth] = React.useState(0);
	const [ownAlpha, setOwnAlpha] = React.useState(alpha);
	const [isGrab, setGrab] = React.useState(false);

	React.useEffect(() => setOwnAlpha(alpha), [alpha]);

	const x = ownAlpha * width;

	const containerRef = React.useRef();

	const pickColor = React.useCallback(
		clientX => {
			if (!containerRef.current) {
				return null;
			}
			const rect = containerRef.current.getBoundingClientRect();
			// eslint-disable-next-line no-shadow
			const x = Math.max(0, Math.min(width, clientX - rect.x));
			const newAlpha = x / width;
			setOwnAlpha(newAlpha);
			return newAlpha;
		},
		[width, setOwnAlpha],
	);

	const onMouseMove = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				const alphaColor = pickColor(clientX);
				if (alphaColor !== null) {
					onPreview(alphaColor);
				}
			});
		},
		[pickColor, onPreview],
	);

	const onMouseUp = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const alpha = pickColor(clientX);
				if (alpha !== null) {
					onChange(alpha);
				}
			});

			setGrab(false);
		},
		[pickColor, onChange],
	);

	const onMouseDown = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const alpha = pickColor(clientX);
				if (alpha !== null) {
					onPreview(alpha);
				}
			});

			setGrab(true);
		},
		[pickColor, onPreview],
	);

	React.useEffect(() => {
		window.requestAnimationFrame(() => {
			if (containerRef.current) {
				setWidth(containerRef.current.getBoundingClientRect().width);
			}
		});
	}, []);

	const positionStyle = React.useMemo(
		() => ({
			position: 'absolute',
			left: `${x}px`,
			top: '50%',
		}),
		[x],
	);

	const leftRightStyle = React.useMemo(() => getLeftRightStyle(color), [color]);

	return (
		<>
			<Box sx={containerStyle} ref={containerRef}>
				<Checkers color="#222" size={4.5} sx={checkersStyle} />
				<Box sx={roundedStyle} style={leftRightStyle} />
				<Box style={positionStyle}>
					<Box sx={handleWrapperStyle} onMouseDown={onMouseDown}>
						<Box sx={handleStyle} />
					</Box>
				</Box>
				<Box style={boundStyle} onMouseDown={onMouseDown} />
			</Box>
			{isGrab && (
				<Box sx={cursorSx} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
					<Overlay isActive={isGrab} />
				</Box>
			)}
		</>
	);
}

SliderColorAlpha.defaultProps = {
	onChange: emptyFunction,
	onPreview: emptyFunction,
};

export default React.memo<TProps>(SliderColorAlpha);
