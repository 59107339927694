// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { Toolbar, ToolbarBtn, Popper, Tooltip, Text } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';
import type { TGridBreakpointName, TWidgetDiff } from '@graphite/types';
import type { TWidgetStack } from '../constants/types';
import ToolbarBtnAlign from './ToolbarBtnAlign';

type TProps = $ReadOnly<{|
	device: TGridBreakpointName,
	data: TWidgetStack,
	save: TWidgetDiff => void,
	openedPanel: ?string,
	setOpenedPanel: (?string) => void,
|}>;

const ICONS_ROW = {
	'flex-start': 'align-top-18',
	center: 'align-x-center-18',
	baseline: 'align-x-baseline-18',
	'flex-end': 'align-bottom-18',
	stretch: 'align-x-center-full-18',
};

const ICONS_COLUMN = {
	'flex-start': 'align-left-18',
	center: 'align-y-center-18',
	baseline: 'align-x-baseline-18',
	'flex-end': 'align-right-18',
	stretch: 'align-y-center-full-18',
};

const AlignItems = ({ device, save, data, openedPanel, setOpenedPanel }: TProps) => {
	const { t } = useTranslation();

	const { alignItems = 'stretch', flexDirection = 'row' } = closestDeviceWithKey(
		data.box,
		{
			currentDevice: device,
			key: `box-${data._id}`,
		},
	);

	const alignlist = React.useMemo(
		() =>
			[
				{
					align: 'flex-start',
					title: flexDirection === 'row' ? t('Top') : t('Left'),
				},
				{ align: 'center', title: t('Center') },
				{
					align: 'flex-end',
					title: flexDirection === 'row' ? t('Bottom') : t('Right'),
				},
			]
				.concat(
					flexDirection !== 'row'
						? []
						: [{ align: 'baseline', title: t('Baseline') }],
				)
				.concat([{ align: 'stretch', title: t('Stretch') }]),
		[flexDirection, t],
	);

	const alignBtnEl: React$ElementRef<any> = React.useRef(null);

	const handleAlignItems = React.useCallback(
		e => {
			e.preventDefault();
			setOpenedPanel(openedPanel !== 'vertical' ? 'vertical' : null);
		},
		[openedPanel, setOpenedPanel],
	);

	const saveAlign = React.useCallback(
		value => save(_.set(`box.${device}.alignItems`, value, data)),
		[device, save, data],
	);

	const isOpenedPanel = openedPanel === 'vertical';

	return (
		<>
			<ToolbarBtn
				onClick={handleAlignItems}
				iconName={
					flexDirection === 'row'
						? ICONS_ROW[alignItems]
						: ICONS_COLUMN[alignItems]
				}
				ref={alignBtnEl}
				isActive={isOpenedPanel}
			/>
			<Tooltip elRef={alignBtnEl} placement={isOpenedPanel ? 'bottom' : 'top'}>
				{flexDirection !== 'row'
					? t('Horizontal alignment')
					: t('Vertical alignment')}
				&nbsp;&nbsp;
				<Text as="span" color="spec.lightblue80">
					{alignlist.find(item => item.align === alignItems)?.title}
				</Text>
			</Tooltip>

			<Popper
				isOpen={isOpenedPanel}
				anchorEl={alignBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					{alignlist.map(({ align, title }) => (
						<ToolbarBtnAlign
							key={align}
							onClick={saveAlign}
							icon={
								flexDirection === 'row'
									? ICONS_ROW[align]
									: ICONS_COLUMN[align]
							}
							isActive={align === alignItems}
							align={align}
							title={title}
						/>
					))}
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(AlignItems);
