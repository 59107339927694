// @flow
import React, { useMemo } from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import FontsLib from '@graphite/fonts-lib';
import { Params as ListParams } from '@graphite/lists';
import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import useBreakpoint from '@graphite/use-breakpoint';
import { listForFontsLib, listMatchWeight } from '@graphite/constants';
import type {
	TUnit,
	TActiveButtons,
	TDesignStyleProps,
	TParams,
	TListParamsOnClick,
	TFonts,
} from '@graphite/types';

const DEFAULT_TEXT_SIZE = 16; // px

const spacerSx = {
	height: '18px',
};

const anchorBoxOuterStyle = {
	position: 'relative',
	margin: '0 -24px',
	height: 0,
};

const anchorBoxInnerStyle = {
	position: 'absolute',
	width: 0,
	height: 0,
	right: 0,
	top: 0,
};

type TProps = $ReadOnly<{|
	...$Exact<TDesignStyleProps>,
|}>;

function Style({
	design,
	widgetspec,
	gridspec: { unit },
	device,
	onChange = null,
	t,
}: TProps) {
	const { paramSource, changeParam, changeBreakpoint } = useBreakpoint(
		onChange,
		design,
		device,
	);

	const [isShowFontLib, showFontLib] = React.useState(false);

	const listWeight = useMemo(
		() =>
			listMatchWeight
				.filter(
					(item) =>
						Array.isArray(paramSource.variants) &&
						paramSource.variants.includes(item.value),
				)
				.map(({ name, label }) => ({ name, label: t(label) })),
		[paramSource.variants, t],
	);
	const targetRef = React.useRef();

	const activeFontFamily =
		typeof paramSource.family === 'string'
			? paramSource.family
			: listForFontsLib[0].family;

	const paramList1: TParams = useMemo(
		() => [
			{
				title: t('Font'),
				key: 'family',
				kind: 'button',
				info: {
					label: activeFontFamily,
					active: isShowFontLib,
				},
			},
			{
				title: t('Weight'),
				key: 'weight',
				kind: 'select',
				info: {
					list: {
						items: listWeight,
					},
				},
			},
			{
				title: t('Size'),
				key: 'fontSize',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: null,
				},
			},
			{
				title: t('Line Height'),
				key: 'textHeight',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'textHeightUnit',
					units: ['px', 'unit', '%'],
				},
			},
			{
				title: t('Letter Spacing'),
				key: 'spacing',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: null,
				},
			},
		],
		[listWeight, t, isShowFontLib, activeFontFamily],
	);

	const paramList2: TParams = useMemo(
		() => [
			{
				title: t('Margin Bottom'),
				key: 'textMargin',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'textMarginUnit',
					units: ['unit', 'px'],
				},
			},
			{
				title: t('Letter Case'),
				key: 'lettercase',
				kind: 'select',
				info: {
					list: {
						items: [
							{ name: 'none', label: t('As Typed') },
							{ name: 'capitalize', label: t('Capitalize') },
							{ name: 'uppercase', label: t('Uppercase') },
							{ name: 'lowercase', label: t('Lowercase') },
						],
					},
				},
			},
		],
		[t],
	);

	const paramListAll = useMemo(
		() => [...paramList1, ...paramList2],
		[paramList1, paramList2],
	);

	const changeShownFontLib = React.useCallback(() => {
		showFontLib(!isShowFontLib);
	}, [isShowFontLib]);

	const changeFont = React.useCallback(
		(font) => {
			// Значение нужно брать из хука useBreakpoint
			// Нельзя просто так взять его из Breakpoint дизайна
			// тип тут ебанутый, нужно проверить на строку
			const weight =
				typeof paramSource.weight === 'string' ? paramSource.weight : '400';

			const checkVariant = (virant: number): ?number =>
				font.variants?.includes(
					listMatchWeight.find((item) => parseInt(item.name, 10) === virant)
						?.value,
				)
					? virant
					: null;

			// Алгоритм выбора начертания такой:
			// 1) пытаемся найти такое же начертание, если нет, то идем далее
			// 2) делаем первый шаг поиска – ищем начертание ‘текущее начертание’ - 100 * ‘номер шага’ (учесть что более 900 никогда нет)
			// – если не нацден, ищем начертание ‘текущее начертание’ + 100 * ‘номер шага’ (учесть что менее 100 никогда нет)
			// 3) если все еще не найден, делаем еще один шаг
			const listFindedWeight = listMatchWeight.map((el, index) => {
				const findedWeight: ?number = checkVariant(
					parseInt(weight, 10) - 100 * index,
				);
				if (!findedWeight) {
					return checkVariant(parseInt(weight, 10) + 100 * index);
				}
				return findedWeight;
			});

			changeBreakpoint({
				...design.breakpoints[device],
				family: font.family,
				variants: font.variants,
				weight:
					(font.variants?.includes(
						listMatchWeight.find((item) => item.name === weight)?.value,
					)
						? weight
						: listFindedWeight.find((first) => first)?.toString()) || '400',
			});
		},
		[changeBreakpoint, paramSource.weight, design.breakpoints, device],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key: string, name: ?TActiveButtons) => {
			if (key === 'family') {
				changeShownFontLib();
				return;
			}

			if (!onChange || (name !== '%' && name !== 'px' && name !== 'unit')) {
				return;
			}
			const unitNext: TUnit = name;
			const param = paramListAll.find((p) => p.key === key);

			if (!param || param.kind !== 'unit' || !param.info.unitKey) {
				return;
			}

			const unitPath = ['breakpoints', device, param.info.unitKey];
			const unitPrev: TUnit = get(unitPath, design);
			if (unitPrev === unitNext) {
				return;
			}

			let next = set(unitPath, unitNext, design);

			const valuePath = ['breakpoints', device, param.key];

			// Только поле 'textHeight' может содержать проценты
			if (param.key === 'textHeight' && (unitPrev === '%' || unitNext === '%')) {
				// Convert (% <---> px/unit), apply defaults
				const currentSize =
					get(['breakpoints', device, 'fontSize'], design) || DEFAULT_TEXT_SIZE;
				if (unitPrev === '%') {
					const currentFactor = (get(valuePath, design) || 100) * 0.01;
					const defaultPx = Math.round(currentSize * currentFactor);
					if (unitNext === 'px') {
						next = set(valuePath, defaultPx, next);
					} else if (unitNext === 'unit') {
						next = set(valuePath, Math.round(defaultPx / unit), next);
					}
				} else if (unitNext === '%') {
					const currentHeight = get(valuePath, design);
					const currentPx = currentHeight
						? currentHeight * (unitPrev === 'px' ? 1 : unit)
						: DEFAULT_TEXT_SIZE;
					const defaultPc = Math.round((currentPx / currentSize) * 100);
					next = set(valuePath, defaultPc, next);
				}
			} else if (unitPrev !== '%' && unitNext !== '%') {
				const valuePrev = get(valuePath, next);
				if (unitNext === 'px') {
					next = set(valuePath, valuePrev * unit, next);
				} else if (unitNext === 'unit') {
					next = set(valuePath, Math.round(valuePrev / unit), next);
				}
			} else {
				return;
			}

			onChange(next);
		},
		[onChange, paramListAll, device, design, unit, changeShownFontLib],
	);

	const recentlyFonts: TFonts = React.useMemo(() => {
		let fonts = [];

		_.forEach(({ breakpoints }) => {
			_.forEach((deviceDesign) => {
				const { family, variants } = deviceDesign;

				const webSafeFont = listForFontsLib.find(
					({ name, family: fontFamily }) =>
						family === fontFamily || name === family,
				);

				if (webSafeFont) return;

				if (
					typeof family === 'string' &&
					!fonts.find((font) => font.family === family)
				) {
					fonts = [
						...fonts,
						{
							family,
							id: family.replace(/\s+/g, '-').toLowerCase(),
							variants: Array.isArray(variants) ? variants : [],
						},
					];
				}
			}, breakpoints);
		}, widgetspec.text);

		return _.sortBy((font) => font.family, fonts);
	}, [widgetspec]);

	return (
		<Box>
			<Box sx={anchorBoxOuterStyle}>
				<Box ref={targetRef} sx={anchorBoxInnerStyle} />
			</Box>
			<ListParams
				listName={`${device}1`}
				paramSource={paramSource}
				paramList={paramList1}
				unit={unit}
				onChange={changeParam}
				onClick={clickUnit}
			/>
			<Box sx={spacerSx} />
			<ListParams
				listName={`${device}2`}
				paramSource={paramSource}
				paramList={paramList2}
				unit={unit}
				onChange={changeParam}
				onClick={clickUnit}
			/>
			<Box sx={spacerSx} />
			{isShowFontLib && (
				<FontsLib
					t={t}
					onClose={changeShownFontLib}
					onChange={changeFont}
					recentlyFonts={recentlyFonts}
					activeFontFamily={activeFontFamily}
				/>
			)}
		</Box>
	);
}

export default React.memo<TProps>(Style);
