// @flow
import { connect } from 'react-redux';
import { withAbsolute } from '@graphite/abs-grid';
import Rectangle from '@graphite/widget-rectangle';
import type { TWidgetOwnProps } from '@graphite/types';

import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import type { TConnectProps } from './constants/types';
import withHydrate from '../../libs/with-hydrate';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(getMapStateToProps)(
	withAbsolute<TConnectProps>()(withHydrate(Rectangle)),
);
