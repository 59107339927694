// @flow
import React from 'react';
import { EditorState, Editor, type EditorState as TEditorState } from 'draft-js';
import type {
	TWidget,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
	TGridBreakpointName,
	TWidgetMode,
} from '@graphite/types';

import { selectAll } from './draft-utils';
import { customStyleMap, getCustomStyleFn } from './style-map';
import { getBlockRenderMap } from './blockRenderMap';
import blockRendererFn from './blockRendererFn';

import fromRaw, { createWithContent } from './from-raw';

type TMinimalEditableProps = $ReadOnly<{
	data: TWidget,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
	currentDevice: TGridBreakpointName,
	editorState: TEditorState,
	setEditorState: ((TEditorState => TEditorState) | TEditorState) => void,
	widgetMode: TWidgetMode,
}>;

const withEditable = <T: TMinimalEditableProps>(
	Component: React$ComponentType<
		$ReadOnly<{| ...$Exact<T>, children: React$Node, onClick: MouseEvent => void |}>,
	>,
): React$ComponentType<$Exact<T>> => {
	const WithEditable = (props: $Exact<T>, ref) => {
		const {
			data,
			widgetspec,
			colorspec,
			gridspec,
			effectspec,
			currentDevice,
			editorState,
			setEditorState,
			widgetMode,
		} = props;

		const isEdit = widgetMode === 'widget-edit';

		const editorRef = React.useRef(null);

		const focus = React.useCallback(() => {
			if (editorRef.current) editorRef.current.focus();
		}, [editorRef]);

		/**
			issues 37
		 */
		const [prevWidgetMode, setPrevWidgetMode] = React.useState(widgetMode);
		React.useEffect(() => {
			if (widgetMode !== prevWidgetMode) {
				setPrevWidgetMode(widgetMode);

				const changeSelection = selectAll(editorState);

				switch (widgetMode) {
					case 'widget-edit':
						setEditorState(changeSelection());
						// FixMe: временное решение для виджета текст
						// при входе в редактирвоание виджета теряется фокус
						if (editorRef.current) {
							editorRef.current.focus();
						}
						break;
					case 'widget-resize':
						setEditorState(changeSelection(true));
						break;
					default:
						break;
				}
			}
		}, [
			editorState,
			setEditorState,
			widgetMode,
			prevWidgetMode,
			setPrevWidgetMode,
			editorRef,
			data.raw,
		]);

		const blockRenderMap = React.useMemo(
			() =>
				widgetspec &&
				colorspec &&
				gridspec &&
				effectspec &&
				getBlockRenderMap({
					device: currentDevice,
					designs: data.designs,
					widgetspec,
					colorspec,
					gridspec,
					effectspec,
					unit: gridspec.unit,
				}),
			[colorspec, currentDevice, data.designs, gridspec, widgetspec, effectspec],
		);

		React.useEffect(() => {
			setEditorState(newEditorState => {
				if (!data?.raw) return newEditorState;
				const convertEditorState = fromRaw(data.raw);

				const contentState = convertEditorState.getCurrentContent();
				const newEditorStateInstance = createWithContent(contentState);

				return EditorState.set(newEditorStateInstance, {
					selection: newEditorState.getSelection(),
					undoStack: newEditorState.getUndoStack(),
					redoStack: newEditorState.getRedoStack(),
					lastChangeType: newEditorState.getLastChangeType(),
				});
			});
		}, [setEditorState, data.raw]);

		React.useEffect(() => {
			setEditorState(newEditorState => {
				// Хуй я кому расскажу что это такое!
				const contentState = newEditorState.getCurrentContent();
				const newEditorStateInstance = createWithContent(contentState);

				return EditorState.set(newEditorStateInstance, {
					selection: newEditorState.getSelection(),
					undoStack: newEditorState.getUndoStack(),
					redoStack: newEditorState.getRedoStack(),
					lastChangeType: newEditorState.getLastChangeType(),
				});
			});
		}, [blockRenderMap, setEditorState]);

		if (!widgetspec || !colorspec || !gridspec || !effectspec) return null;

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Component {...props} onClick={focus} ref={ref}>
				<Editor
					readOnly={!isEdit}
					customStyleFn={getCustomStyleFn(colorspec)}
					customStyleMap={customStyleMap}
					editorState={editorState}
					blockRenderMap={blockRenderMap}
					blockRendererFn={blockRendererFn}
					onChange={setEditorState}
					ref={editorRef}
				/>
			</Component>
		);
	};

	WithEditable.displayName = 'withTextEditable(Text)';

	return React.memo(React.forwardRef(WithEditable));
};

export default withEditable;
