// @flow

import _ from 'lodash/fp';
import type { TPropsCss, TAnimations } from '@graphite/types';
import { effects } from '../constants';

const validateProp = (prop, isPerspective) =>
	effects[isPerspective ? '3d' : '2d'].includes(prop) ||
	prop === 'transformPerspective';

const validateValue = (prop, props) => props[`${prop}`] === 0 || props?.[`${prop}`];

export const filterBox = (props: TAnimations, isAnimateProp: ?boolean, is3d: ?boolean) =>
	_.reduce(
		(acc, prop) => {
			if (typeof props[`${prop}`] === 'object') {
				if (!props?.[`${prop}`]) return acc;

				if (!_.isEmpty(props[`${prop}`])) {
					const isAnimate = prop === 'from' || prop === 'to';
					const isPerspective = !!props[`${prop}`].transformPerspective;
					return {
						...acc,
						[`${prop}`]: filterBox(
							props[`${prop}`],
							isAnimate,
							isPerspective,
						),
					};
				}
			}

			if (isAnimateProp) {
				if (validateProp(prop, is3d) && validateValue(prop, props)) {
					return { ...acc, [`${prop}`]: props[`${prop}`] };
				}

				return acc;
			}

			return { ...acc, [`${prop}`]: props[`${prop}`] };
		},
		{},
		_.keys(props),
	);

export const filterProp = (props: TPropsCss) => {
	const isPerspective = !!props.transformPerspective;

	return _.reduce(
		(acc, prop) => {
			if (validateProp(prop, isPerspective) && validateValue(prop, props)) {
				return { ...acc, [`${prop}`]: props[`${prop}`] };
			}

			return acc;
		},
		{},
		_.keys(props),
	);
};

export const convertUnit = (value: string): string | null => {
	if (!value) return null;
	return value.replace(/\d+|-+/g, '') || null;
};

export default {};
