// @flow
import { moveWidget, placeWidget } from 'Editor/ducks/widgets';
import type { TDropSide } from '@graphite/types';
import { getRects } from './rect';
import type {
	TDNDMethodDropWidget,
	TDNDMethodMoveDraggedWidget,
	TDNDMethodPlaceDraggedWidget,
} from '../constants/types';

const moveDraggedWidget: TDNDMethodMoveDraggedWidget = ({
	rootDispatch,
	srcId,
	srcContainerId,
	destContainerId,
	destInstanceId,
	destOriginId,
	position,
	currentDevice,
}) =>
	rootDispatch(
		moveWidget(
			srcId,
			srcContainerId,
			destContainerId,
			destInstanceId,
			destOriginId,
			position,
			currentDevice,
		),
	);

const placeDraggedWidget: TDNDMethodPlaceDraggedWidget = ({
	rootDispatch,
	protoId,
	destContainerId,
	destInstanceId,
	destOriginId,
	position,
}) =>
	rootDispatch(
		placeWidget(protoId, destContainerId, destInstanceId, destOriginId, position),
	);

export const dropWidget: TDNDMethodDropWidget = (state, rootDispatch, currentDevice) => {
	const { dragId, srcContainerId, dragPlace, dropPlace, stackPlace, widgets } = state;

	const isFromPanel = srcContainerId === 'panel';
	const dstComposeId =
		dropPlace?.composeId || stackPlace?.composeId || dragPlace?.composeId || null;
	const dropPosition = dropPlace?.position || dragPlace?.position || null;
	const dropContainerId = dropPlace?.containerId || null;
	const dropOriginId = dropPlace?.originId || null;

	if (!dstComposeId || !srcContainerId) return;

	const srcWidget = dragId ? widgets[dragId] : null;
	const dstWidget = widgets[dstComposeId];
	const dstWidgetId = dstWidget?.widgetId || null;
	const isNewStack = !!stackPlace && dstWidget?.kind !== 'stack';
	const isAddToStack = !!stackPlace && !isNewStack;

	if (!srcWidget) return;

	const dragRect = getRects(srcWidget)?.rect;
	const prevId = stackPlace || dropPosition === 'after' ? dstWidgetId : null;
	const nextId = dropPosition === 'before' ? dstWidgetId : null;
	const row = dropPlace?.direction === 'horizontal' ? 'new' : null;

	const srcId = srcWidget.widgetId;
	const protoId = srcId;
	const destContainerId = isAddToStack
		? dstWidget?.widgetId
		: dstWidget?.containerId || dropContainerId;

	const destOriginId = dstWidget?.originId || dropOriginId;
	const destInstanceId = null;
	const side: TDropSide = isNewStack ? 'stack' : null;
	const position = {
		kind: 'grid',
		breakpoints: null,
		destRect: null,
		dragRect,
		side,
		prevId,
		nextId,
		row,
	};

	if (!destContainerId || !destOriginId) return;

	const action = isFromPanel ? placeDraggedWidget : moveDraggedWidget;

	return action({
		rootDispatch,
		protoId,
		srcId,
		srcContainerId,
		destContainerId,
		destInstanceId,
		destOriginId,
		position,
		currentDevice,
	});
};

export default {};
