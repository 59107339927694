// @flow
import React from 'react';
import assign from 'lodash/fp/assign';
import emptyObject from 'empty/object';
import { Flex } from '@graphite/uneon/lib/Box';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDesignSx } from '@graphite/selectors';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import type { TSx, TDesign } from '@graphite/types';

import type { TProps } from './constants/types';

const baseButtonSx: TSx = {
	borderWidth: 0,
	borderRadius: 0,
	borderColor: 'transparent',
	outline: 'none',
	background: 'none',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	textDecoration: 'none',
	padding: 0,
	flexGrow: 1,
};

const iconsWithBrand = { ...icons, ...fab };

const Button = (props: TProps, ref) => {
	const {
		data,
		colorspec,
		effectspec,
		gridspec,
		widgetspec,
		children,
		onClick,
		widgetMode,
	} = props;

	const buttonSx: TSx = React.useMemo(() => {
		if (!gridspec || !widgetspec || !colorspec || !effectspec) return emptyObject;
		if (!data.designId) return baseButtonSx;

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.button.find((d) => d._id === data.designId);

		if (!design) return baseButtonSx;

		const designSx = getDesignSx({
			design,
			widgetspec,
			colorspec,
			effectspec,
			gridspec,
		});

		return assign(baseButtonSx, designSx);
	}, [data, colorspec, widgetspec, effectspec, gridspec]);

	const {
		link,
		text = { isShown: false, label: '' },
		icon = { isShown: false },
	} = data;

	const boxProps = React.useMemo(() => {
		if (widgetMode || !(link && link.href)) {
			return { as: 'button', sx: buttonSx };
		}
		return {
			as: 'a',
			href: link.href,
			target: link.isNewTab ? '_blank' : '_self',
			sx: buttonSx,
		};
	}, [buttonSx, link, widgetMode]);

	const [dangerouslySetInnerHTML] = React.useState({ __html: text?.label });

	return (
		<Flex
			id={data.id}
			className={data.className}
			onClick={onClick}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...boxProps}
			ref={ref}
		>
			{icon && icon.isShown && icon.position === 'left' && (
				<FontAwesomeIcon icon={iconsWithBrand[icon.name]} />
			)}
			{children ||
				(text && text.isShown ? (
					<span dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
				) : null)}
			{icon && icon.isShown && icon.position === 'right' && (
				<FontAwesomeIcon icon={iconsWithBrand[icon.name]} />
			)}
		</Flex>
	);
};

export default React.memo<TProps>(React.forwardRef(Button));
