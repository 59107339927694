// @flow
import React, { type Node, useCallback, memo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import _ from 'lodash/fp';
import { Portal, Box } from '@graphite/uneon';
import { zIndices } from '@graphite/constants';

import type { TProps } from './types';

const cssAll = css`
	position: absolute;
	user-select: none;
	transition: opacity 0.2s;
	pointer-events: auto;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const cssTopBottom = css`
	${cssAll}
	cursor: ns-resize;
	height: 10px;
	left: 0;
	right: 0;
	user-select: none;
`;

const cssTop = css`
	${cssTopBottom}
	top: -5px;
`;

const cssBottom = css`
	${cssTopBottom}
	bottom: -5px;
`;

const cssLeftRight = css`
	${cssAll}
	bottom: 0;
	cursor: ew-resize;
	top: 0;
	width: 10px;
`;

const cssLeft = css`
	${cssLeftRight}
	left: -5px;
`;

const cssRight = css`
	${cssLeftRight}
	right: -5px;
`;

const cssCorners = css`
	${cssAll}
	height: 20px;
	width: 20px;
`;

const cssTopLeft = css`
	${cssCorners}
	top: -10px;
	left: -10px;
	cursor: nwse-resize;
`;

const cssTopRight = css`
	${cssCorners}
	top: -10px;
	right: -10px;
	cursor: nesw-resize;
`;

const cssBottomLeft = css`
	${cssCorners}
	bottom: -10px;
	left: -10px;
	cursor: nesw-resize;
`;

const cssBottomRight = css`
	${cssCorners}
	bottom: -10px;
	right: -10px;
	cursor: nwse-resize;
`;

const Wrapper = styled.div`
	${({ isDrag }) =>
		isDrag &&
		css`
			&:before {
				opacity: 1 !important;
			}
		`}

	${({ dir }) =>
		({
			top: cssTop,
			bottom: cssBottom,
			left: cssLeft,
			right: cssRight,
			'top-left': cssTopLeft,
			'top-right': cssTopRight,
			'bottom-left': cssBottomLeft,
			'bottom-right': cssBottomRight,
		}[dir])}
`;

const overlayOpenSx = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: zIndices.pageOverlay,
	pointerEvents: 'auto',
};

const overlayClosedSx = {
	display: 'none',
};

const getAxisData = {
	top: {
		disable: 'x',
	},
	bottom: {
		disable: 'x',
	},
	left: {
		disable: 'y',
	},
	right: {
		disable: 'y',
	},
	'bottom-left': {
		disable: false,
	},
	'bottom-right': {
		disable: false,
	},
	'top-left': {
		disable: false,
	},
	'top-right': {
		disable: false,
	},
};

const getDataCoords = ({ dir, element, initialCoords }) => {
	const { pageX: x, pageY: y } = element;
	const { x: initialX, y: initialY } = initialCoords;
	const data = {
		x: initialX - x,
		y: initialY - y,
	};

	if (dir && getAxisData[dir].disable === 'y') {
		data.y = 0;
	}

	if (dir && getAxisData[dir].disable === 'x') {
		data.x = 0;
	}

	return data;
};

// Вынес throttle из хука ресайза

// Каждый update будет изменять layout страницы
// с учетом того, что у нас обновление симбиота на любой чих
// НЕ рекомендуется делать много update, ибо будут фризы
const FPS = 33.3; // 60fps = 16.6, 30fps = 33.3, 24fps = 41.6
const updateThrottled = _.throttle(FPS, (resizeUpdate, dragData) => {
	resizeUpdate(dragData);
});

// Специальный хук, для того, что бы тач скролл не мешал ресайзу
function usePreventScroll(preventScroll) {
	const preventScrolling = React.useCallback(
		(e: TouchEvent) => {
			if (preventScroll) {
				e.preventDefault();
			}
		},
		[preventScroll],
	);

	React.useEffect(() => {
		document.addEventListener('touchmove', preventScrolling, {
			passive: false,
		});
		return () => document.removeEventListener('touchmove', preventScrolling);
	}, [preventScrolling]);
}

function DragSide(props: TProps): Node {
	const {
		dir,
		resizeUpdate,
		resizeStart,
		resizeStop,
		className,
		style,
		control,
	} = props;

	const [isDrag, setDrag] = React.useState(false);
	// Для тача своё состояние, что бы использовать без оверлея и в связке с хуком
	const [isTouchDrag, setTouchDrag] = React.useState(false);
	usePreventScroll(isTouchDrag);

	const initialCoords = React.useRef({ x: 0, y: 0 });
	const swipeCoords = React.useRef({ x: 0, y: 0 });

	const onStart = useCallback(
		({ pageX, pageY }) => {
			initialCoords.current = {
				x: pageX,
				y: pageY,
			};

			swipeCoords.current = { x: 0, y: 0 };

			if (dir) resizeStart({ dir, propName: control });
		},
		[control, dir, resizeStart],
	);

	const onResize = useCallback(
		dragData => {
			if (!dir) return;

			updateThrottled(resizeUpdate, dragData);
		},
		[dir, resizeUpdate],
	);

	const onStop = useCallback(
		dragData => {
			if (!dir) return;

			resizeStop(dragData);
		},
		[dir, resizeStop],
	);

	const onMouseUpdate = useCallback(
		(e: MouseEvent) => {
			const data = getDataCoords({
				element: e,
				initialCoords: initialCoords.current,
				dir,
			});
			swipeCoords.current = data;
			onResize(data);
		},
		[onResize, dir],
	);

	const onTouchUpdate = useCallback(
		(e: TouchEvent) => {
			const touch = e.touches ? e.touches[0] : {};
			const data = getDataCoords({
				element: touch,
				initialCoords: initialCoords.current,
				dir,
			});
			swipeCoords.current = data;
			onResize(data);
		},
		[onResize, dir],
	);

	const onMouseStop = useCallback(() => {
		onStop(swipeCoords.current);
		setDrag(false);
	}, [onStop]);

	const onTouchStop = useCallback(() => {
		onStop(swipeCoords.current);
		setTouchDrag(false);
	}, [onStop]);

	const onMouseStart = useCallback(
		(e: MouseEvent) => {
			onStart(e);
			setDrag(true);
		},
		[onStart],
	);

	const onTouchStart = useCallback(
		(e: TouchEvent) => {
			const touch = e.touches ? e.touches[0] : {};
			onStart(touch);
			setTouchDrag(true);
		},
		[onStart],
	);

	const preventMenu = React.useCallback((e: SyntheticMouseEvent<EventTarget>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	// для Тача не нужен оверлей
	const overlayStyle = React.useMemo(
		() => ({
			...(isDrag ? overlayOpenSx : overlayClosedSx),
			cursor: _.cond([
				[_.includes(_, ['left', 'right']), _.constant('ew-resize')],
				[_.includes(_, ['top', 'bottom']), _.constant('ns-resize')],
				[_.includes(_, ['bottom-left', 'top-right']), _.constant('nesw-resize')],
				[_.includes(_, ['bottom-right', 'top-left']), _.constant('nwse-resize')],
				[_.stubTrue, _.constant('move')],
			])(dir),
			zIndex: zIndices.resizeOverlay,
		}),
		[dir, isDrag],
	);

	return (
		<>
			<Portal>
				<Box
					sx={overlayStyle}
					style={style}
					onContextMenu={preventMenu}
					onMouseMove={onMouseUpdate}
					onMouseUp={onMouseStop}
				/>
			</Portal>
			<Wrapper
				dir={dir}
				isDrag={isDrag || isTouchDrag}
				className={className}
				style={style}
				onMouseDown={onMouseStart}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchUpdate}
				onTouchEnd={onTouchStop}
			/>
		</>
	);
}

export default memo<TProps>(DragSide);
