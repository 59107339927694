// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Button, IconClear } from '@graphite/uneon';
import type { TAnimation } from '@graphite/types';
import { effects, useAnimatePlay, AnimatePlayContext } from '@graphite/animations';

type TProps = $ReadOnly<{|
	t: (string) => string,
	params: TAnimation,
|}>;

const playWrapSx = {
	flexGrow: '1',
	marginBottom: '12px',
};

const PlayButtonComponent = ({ t, params }: TProps) => {
	const is3d = React.useMemo(() => {
		const { from, to } = params;
		return !!from?.transformPerspective && !!to?.transformPerspective;
	}, [params]);

	const currentEffects = React.useMemo(() => effects[is3d ? '3d' : '2d'], [is3d]);

	const paramKeys = React.useMemo(() => {
		const { from, to } = params;
		return _.filter(
			(prop) =>
				from?.[prop] || from?.[prop] === 0 || to?.[prop] || to?.[prop] === 0,
			currentEffects,
		);
	}, [params, currentEffects]);

	const [{ isPlay }] = React.useContext(AnimatePlayContext);
	const action = useAnimatePlay();
	const onPlay = React.useCallback(() => {
		if (!isPlay) {
			action.onPlay();
			return;
		}
		action.onStop();
	}, [action, isPlay]);

	const noEffect = _.isEmpty(paramKeys);

	React.useEffect(() => {
		action.setAnimation(params);

		return () => {
			action.setAnimation({ from: {}, to: {}, transition: {} });
		};
	}, [action, params]);

	return (
		!noEffect && (
			<Button variant="primaryalt.rounded.sm" onClick={onPlay} sx={playWrapSx}>
				<IconClear name={`${!isPlay ? 'play' : 'stop'}-stroke-18`} />
				&nbsp;&nbsp;
				{!isPlay ? t('Play') : t('Stop')}
			</Button>
		)
	);
};

export default React.memo<TProps>(PlayButtonComponent);
