// @flow
import React from 'react';
import { Flex, IconClear } from '@graphite/uneon';
import type { TWidgetMode } from '@graphite/types';

type TMinimalProps = $ReadOnly<{
	data: $ReadOnly<{
		code: string,
	}>,
	widgetMode?: TWidgetMode,
}>;

const placeholderSx = {
	backgroundColor: 'bg.primaryalt',
	padding: '18px 0',
	flexGrow: 1,
	justifyContent: 'center',

	'& > svg': {
		fill: 'text.primary',
	},
};

const withCodeEditor = <TProps: TMinimalProps>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const WidgetWrapper = (props: TProps) => {
		const {
			data: { code },
			widgetMode,
		} = props;

		const [isShowPlaceholder, setShowPlaceholder] = React.useState(false);

		const ref = React.useRef<mixed>(null);

		React.useEffect(() => {
			setShowPlaceholder(
				!!(
					widgetMode &&
					ref.current &&
					typeof ref.current === 'object' &&
					(ref.current.offsetHeight === 0 || ref.current.offsetWidth === 0)
				),
			);
		}, [code, widgetMode]);

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} ref={ref} />
				{isShowPlaceholder && (
					<Flex sx={placeholderSx}>
						<IconClear name="code-24" />
					</Flex>
				)}
			</>
		);
	};

	return React.memo<TProps>(WidgetWrapper);
};

export default withCodeEditor;
