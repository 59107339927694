// @flow
import React from 'react';
import set from 'lodash/fp/set';
import type { TDesign } from '@graphite/types';

type TSseNameParamProps = (
	TDesign,
	?(TDesign) => void,
	(string) => string,
) => $ReadOnly<{|
	onChange: (string, ?(string | number | $ReadOnlyArray<number> | boolean)) => void,
	param: $ReadOnly<{|
		hidden?: boolean,
		info: $ReadOnly<{|
			maxLength: ?number,
			placeholder?: ?string,
		|}>,
		key: string,
		kind: 'string',
		title: string,
	|}>,
	paramSource: $ReadOnly<{| name: string |}>,
	unit: number,
|}>;

export const useNameParamProps: TSseNameParamProps = (design, onChange, t) => {
	const nameParamProps = React.useMemo(() => ({
			paramSource: { name: design.name || '' },
			unit: 1,
			param: {
				title: t('Name'),
				key: 'name',
				kind: 'string',
				info: {
					maxLength: 0,
					placeholder: t('Design name'),
				},
			},
			onChange: (key, newName) => {
				if (onChange) {
					onChange(set('name', newName, design));
				}
			},
		}), [design, onChange, t]);

	return nameParamProps;
};

export default useNameParamProps;
