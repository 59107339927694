// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Section } from '@graphite/uneon';
import { Params as List } from '@graphite/lists';
import type { TParams, TAnimation } from '@graphite/types';
import { defaultValue } from '@graphite/animations';

type TProps = $ReadOnly<{|
	t: (string) => string,
	params: TAnimation,
	changeEffect: (TAnimation) => void,
|}>;

const sectionSx = {
	marginTop: '12px',
};

const SensitivityComponent = ({ t, params, changeEffect }: TProps) => {
	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Threshold'),
				key: 'threshold',
				kind: 'unit',
				info: {
					domain: 'onehundred',
					showUnits: true,
					unitKey: 'thresholdUnit',
					units: ['%'],
				},
			},
		],
		[t],
	);

	const paramSource = React.useMemo(() => {
		const { threshold } = _.assign(
			defaultValue.transition.sensitivity,
			params.transition,
		);

		return {
			threshold: `${parseFloat(threshold)}`,
			thresholdUnit: '%',
		};
	}, [params]);

	const changeParam = React.useCallback(
		(key, value) => {
			if (typeof value === 'number') {
				const concatValue = `${value}${paramSource[`${key}Unit`] || '%'}`;
				changeEffect(_.set(`transition.${key}`, concatValue, params));
			}
		},
		[changeEffect, params, paramSource],
	);

	const changeUnit = React.useCallback(
		(e, key, value) => {
			if (typeof value === 'string') {
				const concatValue = `${paramSource[key]}${value}`;
				changeEffect(_.set(`transition.${key}`, concatValue, params));
			}
		},
		[changeEffect, params, paramSource],
	);

	return (
		<Section size="sm" label={t('Sensitivity')} sx={sectionSx}>
			<List
				listName="animation-transition-sensitivity"
				paramSource={paramSource}
				paramList={paramList}
				unit={0}
				onChange={changeParam}
				onClick={changeUnit}
			/>
		</Section>
	);
};

export default React.memo<TProps>(SensitivityComponent);
