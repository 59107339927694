// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type EditorState as TEditorState } from 'draft-js';
import { ToolbarBtn, PopupWhite, Tooltip } from '@graphite/uneon';
import Library from '@graphite/library';
import { getLink, toggleLink } from '@graphite/widget-text';
import type {
	TLibLinkValue,
	TLibraryValue,
	TSpecsColor,
	TSpecsGrid,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	genId: string => string,
|}>;

const libLinkConf = { link: {} };

const Link = ({
	editorState,
	setEditorState,
	setOpenedPanel,
	openedPanel,
	colorspec,
	gridspec,
	genId,
}: TProps) => {
	const { t } = useTranslation();
	const linkBtnEl: React$ElementRef<any> = React.useRef(null);
	const handleLink = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel => (openedPanel === 'link' ? null : 'link'));
		},
		[setOpenedPanel],
	);

	const disableActive = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setOpenedPanel(null);
		},
		[setOpenedPanel],
	);

	const changeLink = React.useMemo(() => toggleLink(editorState), [editorState]);
	const handleChangeLink = React.useCallback(
		(value: TLibraryValue) => {
			if (value.kind !== 'link') {
				return;
			}
			setOpenedPanel(null);
			setEditorState(
				changeLink({
					value: value.value.href,
					isNewTab: value.value.isNewTab,
				}),
			);
		},
		[setEditorState, changeLink, setOpenedPanel],
	);

	const linkValue: TLibLinkValue = React.useMemo(() => {
		const data = getLink(editorState)();
		return {
			kind: 'link',
			value: {
				href: data.url ? data.url : '',
				isNewTab: data.isNewTab || true,
			},
		};
	}, [editorState]);

	const isActive = openedPanel === 'link';

	return (
		<>
			<ToolbarBtn
				onClick={handleLink}
				iconName="link-18"
				ref={linkBtnEl}
				isActive={isActive}
			/>
			<Tooltip elRef={linkBtnEl} placement="top">
				{t('Link')}
			</Tooltip>
			<PopupWhite
				isOpen={isActive}
				onClose={disableActive}
				anchorEl={linkBtnEl}
				offsetTop={10}
				isFixed
				mutex="settings"
			>
				{isActive && (
					<Library
						t={t}
						config={libLinkConf}
						onChange={handleChangeLink}
						value={linkValue}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(Link);
