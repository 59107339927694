// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';

type TPropsMinimal = $ReadOnly<{}>;

/**
 * Прокидывает реф компонента виджета
 */
const withRef = <TProps: TPropsMinimal>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const Hoc = (props: TProps, ref) => {
		const currentRef = React.useRef(null);
		React.useImperativeHandle(ref, () => currentRef.current);

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} ref={currentRef} />;
	};
	Hoc.displayName = `withRef(${getDisplayName(Component)})`;
	return React.memo<TProps>(React.forwardRef(Hoc));
};

export default withRef;
