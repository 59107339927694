// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex } from '@graphite/uneon';
import { Global, css } from '@emotion/core';
import awesomeStyles from '@fortawesome/fontawesome-svg-core/styles.css';
import emotionReset from 'emotion-reset';

import Widget from 'Widget';

import type { TConnectProps } from './constants/types';

const boxSx = {
	flexDirection: 'column',
	position: 'relative',
	overflow: 'hidden',
};

// Это нужно чтобы иконки правильно отображались на опубликованной странице
const globalStyle = css`
	${awesomeStyles}
	${emotionReset}

	html {
		box-sizing: border-box;
		scroll-behavior: smooth;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
`;

const Page = (props: TConnectProps, ref: React$Ref<*>) => {
	const {
		children,
		data,
		instanceId,
		widgetChain,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
	} = props;

	return (
		<>
			<Global styles={globalStyle} />
			<Flex sx={boxSx} ref={ref} data-kind="page">
				{children ||
					_.map(
						id => (
							<Widget
								id={id}
								containerId={data._id}
								instanceId={data.modified ? data._id : instanceId}
								originId={data._id}
								key={id}
								widgetChain={widgetChain}
								widgetspec={widgetspec}
								colorspec={colorspec}
								gridspec={gridspec}
								effectspec={effectspec}
							/>
						),
						props.babies,
					)}
			</Flex>
		</>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Page));
