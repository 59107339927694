// @flow
import { connect } from 'react-redux';
import { withAbsolute } from '@graphite/abs-grid';
import Button from '@graphite/widget-button';
import type { TWidgetOwnProps } from '@graphite/types';
import type { TConnectProps } from './constants/types';
import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import withHydrate from '../../libs/with-hydrate';

const WidgetComponent = connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(
	getMapStateToProps,
)(withAbsolute<TConnectProps>()(withHydrate<TConnectProps>(Button)));

export default WidgetComponent;
