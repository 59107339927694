// @flow
import React from 'react';
import { compose } from 'redux';
import emptyArray from 'empty/array';
import emptyObject from 'empty/object';
import { withAbsolute, withAbsoluteDrag } from '@graphite/abs-grid';
import Text, { withEditable, withEditorState } from '@graphite/widget-text';
import { withPlay } from '@graphite/animation-edit';
import withRef from '@graphite/with-ref';
import {
	getWidgets,
	getWidgetsByKind,
	getImageLibraryListUploads,
	getCurrentSiteId,
	getWidget,
	closestDeviceWithKey,
	getTrueIds,
	getOrderIndex,
} from '@graphite/selectors';

import { useSelector, useDispatch } from '@graphite/use-redux';
import withSymbiote from 'Editor/libs/with-symbiote';
import withControls from 'Editor/libs/with-controls';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import {
	insertImages as insertImagesAction,
	removeImage as removeImageAction,
	reset as resetAction,
} from 'Editor/ducks/imageLibrary';
import {
	repositionWidget as repositionWidgetAction,
	editWidget as editWidgetAction,
} from 'Editor/ducks/widgets';
import { startEdit as startEditAction } from 'Editor/ducks/editor';
import withDrag from 'Widget/libs/dnd/drag';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';

import withWidgetEdit from 'Widget/libs/with-widget-edit';
import type { TId, TWidgetOwnProps } from '@graphite/types';

import { getCurrentWidget, getCurrentDevice } from '../../../Editor/selectors/editor';
import Controls from './Controls';
import type {
	TPropsEdit,
	TPropsEditWithDrag,
	TWithSymbiote,
	TPropsEditWithEditorState,
	TPropsEditWithHydrate,
} from './types';

import withHydrate from '../../libs/with-hydrate';

import getWidgetMode from '../../libs/get-widget-mode';
import getDragAvailable from '../../libs/get-drag-available';
import getHoverAvailablity from '../../libs/get-hover-availablity';

const TextWithDecorators = compose(
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	(Component) => withRef<TPropsEdit>(Component),
	(Component) => withDrag<TPropsEdit>(Component),
	(Component) => withWidgetResizer<TPropsEditWithDrag>(Component),
	withSymbiote(
		compose(
			(Component) =>
				withWidgetEdit<
					$ReadOnly<{|
						...$Exact<TPropsEditWithDrag>,
						...$Exact<TWithSymbiote>,
					|}>,
				>(Component),
			(Component) =>
				withWidgetControls({ margin: true })<
					$ReadOnly<{|
						...$Exact<TPropsEditWithDrag>,
						...$Exact<TWithSymbiote>,
					|}>,
				>(Component),
		),
	),
	// ...
	withSymbiote(
		// ...
		(Component) =>
			withAbsoluteDrag<
				$ReadOnly<{|
					...$Exact<TPropsEditWithDrag>,
					...$Exact<TWithSymbiote>,
				|}>,
			>(Component),
		{
			level: 'abs-drag-place',
		},
	),
	withAbsolute<TPropsEditWithDrag>(),
	(Component) => withEditorState<TPropsEditWithDrag>(Component),
	withPlay,
	withSymbiote((Component) =>
		withControls<
			$ReadOnly<{|
				...$Exact<TPropsEditWithEditorState>,
				...$Exact<TWithSymbiote>,
			|}>,
		>(checkActiveWidget, Controls, Component, {
			isAnimateAvailable: true,
		}),
	),
	(Component) => withEditable<TPropsEditWithEditorState>(Component),
	(WidgetComponent) =>
		withHydrate<TPropsEditWithHydrate>(WidgetComponent, {
			baseSx: {
				flexDirection: 'column',
			},
		}),
)(Text);

const TextWithProps = ({
	id,
	containerId,
	rowId,
	instanceId,
	originId,
	widgetChain = emptyArray,
	widgetspec,
	colorspec,
	gridspec,
	effectspec,
}: TWidgetOwnProps) => {
	const dispatch = useDispatch();
	// FixMe: это ломает всю оптимизацию
	const widgets = useSelector(getWidgets);
	// Тут раньше были селекторы спек,
	// но в текущей реализации спек нам этот вариант не подходит
	// блок может переопределять спеки
	const currentDevice = useSelector(getCurrentDevice);
	const currentWidget = useSelector(getCurrentWidget);
	const siteId = useSelector(getCurrentSiteId);

	const repositionWidget = React.useCallback(
		(targetId, position, offset) => {
			if (!containerId) return;
			dispatch(
				repositionWidgetAction(targetId, originId, containerId, position, offset),
			);
		},
		[containerId, dispatch, originId],
	);

	const insertImage = React.useCallback(
		(files: FileList) => {
			dispatch(insertImagesAction(files));
		},
		[dispatch],
	);

	const removeImage = React.useCallback(
		(imageId: TId) => {
			dispatch(removeImageAction(imageId));
		},
		[dispatch],
	);

	const resetImage = React.useCallback(() => {
		dispatch(resetAction());
	}, [dispatch]);

	const editWidget = React.useCallback(
		(diff) => {
			dispatch(editWidgetAction(id, instanceId, originId, diff));
		},
		[dispatch, id, instanceId, originId],
	);

	const startEdit = React.useCallback(() => {
		dispatch(startEditAction(id, widgetChain, instanceId));
	}, [dispatch, id, instanceId, widgetChain]);

	const images = useSelector((state) =>
		getWidgetsByKind(state, {
			kind: 'file',
			scopeId: siteId,
			removedAt: null,
		}),
	);
	const uploads = useSelector(getImageLibraryListUploads);

	const widget = useSelector((state) => getWidget(state, { id }));
	const parent =
		useSelector((state) => getWidget(state, { id: containerId || '' })) ||
		emptyObject;

	const firstWidgetInChain = useSelector((state) =>
		getWidget(state, { id: widgetChain[0] || '' }),
	);
	// ToDo: тут composeCached не хватает, но он пока херово работает и херово написан, поэтому без него

	const data = React.useMemo(
		() =>
			widget?.kind === 'text' && widget?.raw
				? { ...widget, kind: widget?.kind, raw: widget.raw }
				: null,
		[widget],
	);

	const positions = React.useMemo(() => {
		if (!data?._id) return emptyObject;
		return parent ? parent.positions : { [data._id]: 'absolute' };
	}, [data, parent]);

	const position = data?._id ? positions?.[data._id] : null;

	if (!data) return null;
	if (!containerId) return null;
	if (!gridspec) return null;
	if (!widgetspec) return null;
	if (!colorspec) return null;
	if (!effectspec) return null;

	const parentBox = parent
		? closestDeviceWithKey(parent.box, {
				currentDevice,
				key: `box-${parent._id || 'null'}`,
		  })
		: null;

	const direction =
		parentBox?.flexDirection === 'row'
			? 'horizontal'
			: (parent && parent?.kind === 'stack' && 'horizontal') || 'vertical';

	const widgetMode = getWidgetMode(currentWidget, {
		_id: data._id,
		kind: data.kind,
	});

	const { justifyContent = 'space-evenly', alignItems = 'stretch' } =
		closestDeviceWithKey(parent.box, {
			currentDevice,
			key: `box-${parent._id}`,
		});

	const dragAvailable = getDragAvailable(
		id,
		currentWidget,
		firstWidgetInChain,
		widgetChain,
		widgetMode,
	);

	let editId = id;
	let editChain = widgetChain;
	let tmpChain = editChain;
	for (const wId of editChain) {
		if (id === currentWidget?.id) break;
		if (wId === currentWidget?.id || !wId) break;

		if (containerId) {
			// Если сейчас редактируется сосед, то текущий виджет можно выбрать с первого раза
			const siblings = getTrueIds({ ...parent, currentDevice });
			if (siblings.includes(currentWidget?.id)) break;
		}

		tmpChain = (tmpChain.slice(1): $ReadOnlyArray<TId>);
		// FixMe тут пиздец
		if (widgets[wId].kind === 'stack') {
			editId = wId;
			editChain = tmpChain;
		}
	}

	const hoverAvailable = getHoverAvailablity(currentWidget, widgets, widgetChain, id);

	const widgetOrderIndex = getOrderIndex({
		id,
		currentPage: originId,
		widgets,
		currentDevice,
	});

	return (
		<TextWithDecorators
			id={id}
			containerId={containerId}
			instanceId={instanceId}
			originId={originId}
			rowId={rowId}
			currentDevice={currentDevice}
			data={data}
			widgetspec={widgetspec}
			colorspec={colorspec}
			gridspec={gridspec}
			effectspec={effectspec}
			position={position} // абс обертки
			widgetMode={widgetMode}
			repositionWidget={repositionWidget}
			// для панельки картинок
			insertImage={insertImage}
			removeImage={removeImage}
			resetImage={resetImage}
			direction={direction}
			images={images}
			uploads={uploads}
			editWidget={editWidget}
			widgetChain={widgetChain}
			startEdit={startEdit}
			dispatch={dispatch} // Нужно для ресайза колонок ToDo Надо выпилить
			clickAvailable // Серьёзно?
			justifyContent={justifyContent}
			alignItems={alignItems}
			dragAvailable={dragAvailable}
			editId={editId}
			editChain={editChain}
			hoverAvailable={hoverAvailable}
			widgetOrderIndex={widgetOrderIndex}
		/>
	);
};

export default TextWithProps;
