// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Text, Textarea, useInput } from '@graphite/uneon';

const StyledTextarea = styled(Textarea)`
	resize: none;
`;

type TProps = $ReadOnly<{|
	label: string,
	value: string,
	name: string,
	onChange: (name: string, value: string) => void,
|}>;

const TextareaComponent = ({ value, name, onChange, label }: TProps) => {
	const handleSave = React.useCallback(
		val => {
			onChange(name, val);
		},
		[name, onChange],
	);

	const [currentValue, changeValue] = useInput(value, handleSave, 500);

	const onHandleChange = React.useCallback(e => changeValue(e.target.value), [
		changeValue,
	]);

	const handleBlur = React.useCallback(e => changeValue(e.target.value), [changeValue]);

	return (
		<>
			{/* ToDo: htmlFor */}
			<Text variant="bodysm" mb="12px" mt="24px" color="text.primary">
				{label}
			</Text>
			<StyledTextarea
				variant="textarea.sm"
				name={name}
				mb="30px"
				value={currentValue}
				onChange={onHandleChange}
				onBlur={handleBlur}
			/>
		</>
	);
};

export default React.memo<TProps>(TextareaComponent);
