// @flow
import React from 'react';
import { ListItemUnitParams } from '@graphite/lists';
import { cssUnitToUnit, availableUnits, domain } from '@graphite/animations';

type TProps = $ReadOnly<{|
	prop: string,
	value: number,
	point: 'from' | 'to',
	unit: ?string,
	changeUnit: (string) => void,
	changeValue: (number, point: 'from' | 'to', ?string) => void,
|}>;

const UnitInputComponent = ({
	prop,
	value,
	unit,
	point,
	changeUnit,
	changeValue,
}: TProps) => {
	const changeParam = React.useCallback(
		(inputValue) => {
			changeValue(inputValue, point, unit);
		},
		[changeValue, point, unit],
	);

	const clickSelect = React.useCallback(
		(e, unitSelect) => {
			if (typeof unitSelect !== 'string' || unitSelect === null) {
				return;
			}

			changeUnit(unitSelect);
		},
		[changeUnit],
	);

	const paramsUnit = React.useMemo(
		() => ({
			title: 'animate',
			key: 'animate',
			kind: 'unit',
			info: {
				units: availableUnits[prop],
				domain: domain[prop],
				unitKey: 'valueUnit',
				showUnits: true,
			},
		}),
		[prop],
	);

	return (
		<ListItemUnitParams
			value={`${value}`}
			valueUnit={cssUnitToUnit[unit || 'px']}
			param={paramsUnit}
			unit={0}
			onChange={changeParam}
			onClick={clickSelect}
		/>
	);
};

export default React.memo<TProps>(UnitInputComponent);
