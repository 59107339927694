// @flow
import React from 'react';
import Box from '@graphite/uneon/lib/Box';
import type { TSx, TSpecsGrid } from '@graphite/types';

import type { TWidgetImage } from './types';
import { getImagePositionSx } from './libs/getImagePositionSx';

const getObjectFit = ({ cropMode }) => {
	if (cropMode === 'fill') return 'cover';
	if (cropMode === 'fit') return 'contain';
	return 'inherit';
};

const getTransform = ({ cropMode, crop: { x, y } = {}, zoom, rotation }) => {
	if (cropMode === 'fill' || cropMode === 'fit') return `rotate(${rotation || 0}deg)`;

	return `translate(${x || 0}px, ${y || 0}px) rotate(${rotation || 0}deg) scale(${
		zoom || 1
	})`;
};

const getFilter = ({ brightness, contrast, saturation, grayscale, hue, blur }) =>
	[
		`brightness(${typeof brightness === 'number' ? brightness : 100}%)`,
		`contrast(${typeof contrast === 'number' ? contrast : 100}%)`,
		`saturate(${typeof saturation === 'number' ? saturation : 100}%)`,
		`grayscale(${grayscale || 0}%)`,
		`hue-rotate(${hue || 0}deg)`,
		`blur(${blur || 0}px)`,
	].join(' ');

type TProps = $ReadOnly<{|
	data: TWidgetImage,
	gridspec: TSpecsGrid,
|}>;

const Element = (props: TProps) => {
	const { data, gridspec } = props;
	const { src = '', alt = '', title = '' } = data;

	const objectPositionSx = getImagePositionSx({ data, gridspec });
	const baseSx: TSx = React.useMemo(
		() => ({
			...objectPositionSx,
			height: '100%',
			width: '100%',
			flexGrow: 0,

			// неадаптивые свойства
			objectFit: getObjectFit(data),
			filter: getFilter(data),
			transform: getTransform(data),
		}),
		[data, objectPositionSx],
	);

	return <Box as="img" sx={baseSx} src={src} alt={alt} title={title} />;
};

export default React.memo<TProps>(Element);
