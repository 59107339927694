// @flow

import React from 'react';
import { Flex } from 'rebass';
import Box from '../Box';
import Portal from '../Portal';
import Icon from '../Icon';
import Button from '../Button';
import Text from '../Text';

type TFakeMouseEvent = (MouseEvent) => void;

type TProps = $ReadOnly<{|
	children?: ?React$Node,
	onClose?: TFakeMouseEvent,
	headingText?: string,
	actionBtnText: string,
	onActionHandler: TFakeMouseEvent,
	withActionBtn: boolean,
	withCancelBtn: boolean,
	actionBtnKind: string,
|}>;

const dialogWrapSx = {
	position: 'fixed',
	top: 0,
	right: 0,
	left: 0,
	bottom: 0,
	zIndex: 1,
	height: '100%',
	backgroundColor: '#0202037a',
};
const dialogSx = {
	position: 'fixed',
	top: 'calc(50% - 100px)',
	left: 'calc(50% - 175px)',
	width: '350px',
	height: 'auto',
	zIndex: 2,
	borderRadius: '12px',
	backgroundColor: '#FAFCFD',
};
const actionBtnSx = {
	padding: '6px 18px',
};
const crossIconSx = {
	position: 'absolute',
	top: '19px',
	right: '12px',
};
const btnWrappSx = {
	marginTop: '30px',
	alignItems: 'center',
	justifyContent: 'flex-end',
};

const ModalDialog = (props: TProps) => {
	const {
		headingText,
		actionBtnText,
		actionBtnKind,

		children,

		withActionBtn,
		withCancelBtn,
		onActionHandler,
		onClose,
	} = props;

	return (
		<Portal>
			<Box as="div" sx={dialogWrapSx} onClick={onClose} />
			<Box as="div" p="24px" sx={dialogSx}>
				<Button
					data-kind="preview-preset-gallery-close"
					variant="primaryflat.icon.lg"
					onClick={onClose}
					sx={crossIconSx}
				>
					<Icon name="cross" iconSize={24} colors="primaryflat" />
				</Button>
				<Text variant="title4" pb="20px" color="text.primaryalt">
					{headingText}
				</Text>
				{children}
				{withActionBtn && (
					<Flex as="div" sx={btnWrappSx}>
						{withCancelBtn && (
							<Button
								sx={actionBtnSx}
								variant="primaryalt"
								onClick={onClose}
								mr="10px"
								color="text.primaryalt"
								data-kind="modalDialog-btn-cancel"
							>
								<Text color="text.secondary">Cancel</Text>
							</Button>
						)}
						<Button
							sx={actionBtnSx}
							variant={actionBtnKind === 'positive' ? 'accent' : 'error'}
							onClick={onActionHandler}
							data-kind={`modalDialog-btn-${actionBtnText}`}
						>
							{actionBtnText}
						</Button>
					</Flex>
				)}
			</Box>
		</Portal>
	);
};

export default React.memo<TProps>(ModalDialog);
