// @flow
import React from 'react';
import _ from 'lodash/fp';
import { type EditorState as TEditorState, convertToRaw } from 'draft-js';
import getDisplayName from '@graphite/get-display-name';
import type { RawDraftContentState } from 'draft-js/lib/RawDraftContentState';
import type { TId, TWidgetDiff } from '@graphite/types';
import fromRaw from './from-raw';

type TMinimal = $ReadOnly<{
	instanceId: ?TId,
	originId: TId,
	data: $ReadOnly<{
		_id: TId,
		raw: RawDraftContentState,
	}>,
	editWidget: TWidgetDiff => void,
}>;

const DELAY_SAVE = 1e3;

const withEditorState = <T: TMinimal>(
	Component: React$ComponentType<
		$ReadOnly<{|
			...$Exact<T>,
			editorState: TEditorState,
			setEditorState: ((TEditorState => TEditorState) | TEditorState) => void,
		|}>,
	>,
): React$ComponentType<$Exact<T>> => {
	const WithEditorState = (props: $Exact<T>, ref) => {
		const {
			data: { raw },
			editWidget,
		} = props;

		const [editorState, setEditorState] = React.useState(() => fromRaw(raw));

		const prevRaw = React.useRef(null);
		React.useEffect(() => {
			prevRaw.current = raw;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const save = React.useMemo(
			() =>
				_.debounce(DELAY_SAVE, editEditorState => {
					const contentState = editEditorState.getCurrentContent();
					// eslint-disable-next-line no-shadow
					const raw = convertToRaw(contentState);

					if (!_.isEqual(raw, prevRaw.current)) {
						prevRaw.current = raw;
						editWidget({ raw });
					}
				}),
			[editWidget],
		);

		const onChange = React.useCallback(
			// eslint-disable-next-line consistent-return, no-shadow
			editorState => {
				if (typeof editorState === 'function') {
					setEditorState(editorState);
				} else {
					save(editorState);
					setEditorState(editorState);
				}
			},
			[save],
		);

		return (
			<Component
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				ref={ref}
				editorState={editorState}
				setEditorState={onChange}
			/>
		);
	};

	WithEditorState.displayName = `withEditorState(${getDisplayName(Component)})`;

	return React.memo<$Exact<T>>(React.forwardRef(WithEditorState));
};

export default withEditorState;
