// @flow
import _ from 'lodash/fp';
import { widgetKinds } from '@graphite/constants';
import type { TId, TSpec, TSpecs, TWidgetKind, TDesign } from '@graphite/types';

type TGetSpecsFromPreset = {|
	sitespec: ?TSpecs,
	dictionaryIds: $ReadOnly<{ [TId]: TId }>,
	userId: ?TId,
	display: string,
	scope: string,
	scopeId: TId,
|};

/**
 * Создаем спеки для виджета
 * или из пресета или из переденых sitespec(шаблоны и т.д.)
 * @param {
 *  sitespec - спеки которые используем как пресет.
 *  replaceIdPreset - список id, которые будут сохранятся в виджета
 *  siteId
 *  userId
 * }
 */
export const getSpecsFromPreset = ({
	sitespec = null,
	dictionaryIds,
	userId,
	display,
	scope,
	scopeId,
}: TGetSpecsFromPreset): TSpecs => _.reduce(
		(
			result: $ReadOnly<{ [TId]: TSpec }> | {},
			item: $ReadOnly<{ [TId]: TSpec }>,
		): TSpecs => _.assign(result, item),
		{},
		// проходимся по всем спекам и меняем id, site and userID
		_.map((key: string | TId): $ReadOnly<{ [TId]: TSpec }> => {
			// подменяем поля для пресетов и шаблонлов
			const specId = dictionaryIds[key];

			return {
				[specId]: _.flow(
					_.set('_id', specId),
					_.set('display', display),
					_.set('scope', scope),
					_.set('scopeId', scopeId),
					_.set('userId', userId),
					(spec: TSpec): TSpec => {
						// if widgetspec than deep seek
						if (spec.kind === 'widgetspec') {
							// проходимся по всем дизайнам виджетов в спеках и меняем им тоже
							return _.assign(
								spec,
								_.fromPairs(
									_.map.convert({ cap: false })(
										(
											widget: $ReadOnlyArray<TDesign>,
											key: TWidgetKind,
										): $ReadOnlyArray<
											TWidgetKind | $ReadOnlyArray<TDesign>,
										> => [
											key,
											_.map(
												(val: TDesign): TDesign => ({
													...val,
													userId,
													display: 'preset',
													scope: 'premarket',
													scopeId,
												}),
												widget,
											),
										],
										_.pick(widgetKinds, spec),
									),
								),
							);
						}
						return spec;
					},
					// если sitespec есть значит мы создаем из шаблона
				)(sitespec?.[key]),
			};
		}, _.keys(sitespec)),
	);

export default getSpecsFromPreset;
