// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex, Box } from '@graphite/uneon';
import Widget from 'Widget';
import { Global, css } from '@emotion/core';
import type { TId } from '@graphite/types';

import Header from './Header';
import Footer from './Footer';
import EmptyProject from './EmptyProject';

type TProps = $ReadOnly<{|
	id: TId,
	name: string,
	photoURL: ?string,
	babies: $ReadOnlyArray<TId>,
	onInsert: () => void,
	logOut: () => void,
|}>;

// push footer to bottom

const bodySx = {
	minHeight: '100vh',
	flexDirection: 'column',
	backgroundColor: 'bg.primary',
};

// main layout

const contentSx = {
	marginTop: '30px',
	marginBottom: '60px',
};

const User = ({ id, name, photoURL, babies, onInsert, logOut }: TProps) => (
	<Flex sx={bodySx}>
		<Global
			styles={css`
				body {
					overflow-y: scroll;
				}
			`}
		/>
		<Header
			logOut={logOut}
			insertProject={onInsert}
			name={name}
			photoURL={photoURL}
		/>
		<Box data-kind="user-projects" sx={contentSx} variant="container.fluid">
			{_.isEmpty(babies) ? (
				<EmptyProject insertProject={onInsert} />
			) : (
				babies.map((babyId) => (
					<Widget
						key={babyId}
						containerId={id}
						instanceId={null}
						originId={id}
						id={babyId}
					/>
				))
			)}
		</Box>

		<Box mt="auto">
			<Footer />
		</Box>
	</Flex>
);

export default React.memo<TProps>(User);
