// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Section, PopupSelect, List, Box } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';
import type { TGridBreakpointName, TWidget, TWidgetDiff } from '@graphite/types';
import { triggers, defaultValue } from '@graphite/animations';
import AnimateItem from './AnimateItem';

import Empty from './Empty';

type TProps = $ReadOnly<{|
	t: (string) => string,
	data: TWidget,
	onSave: (TWidgetDiff) => void,
	currentDevice: TGridBreakpointName,
|}>;

const sectionSx = {
	paddingBottom: 0,
};

const separatorSx = {
	margin: '0 -24px',
	height: '1px',
	backgroundColor: 'bg.primaryalt',
};

const AnimateItemsComponent = ({ t, data, onSave, currentDevice }: TProps) => {
	const [editedTrigger, setEditedTrigger] = React.useState(null);
	const [isOpenTriggers, setOpenTriggers] = React.useState(false);
	const anchorRef = React.useRef();

	const toggleOpenTriggers = React.useCallback(() => {
		setOpenTriggers((status) => !status);
	}, []);

	const animate = closestDeviceWithKey(data.animation, {
		isDeep: true,
		currentDevice,
		key: `animation-${data._id}`,
	});

	const insertTrigger = React.useCallback(
		(e, value) => {
			if (typeof value !== 'string' || value === null) {
				return;
			}

			onSave({
				animation: _.set(
					`${currentDevice}.${value}`,
					{
						from: {},
						to: {},
						transition: {
							...defaultValue.transition.repeat,
							...defaultValue.transition.sensitivity,
							...defaultValue.transition.tween,
						},
					},
					data.animation,
				),
			});

			toggleOpenTriggers();
		},
		[toggleOpenTriggers, onSave, data.animation, currentDevice],
	);

	const btnInsertTrigger = React.useMemo(
		() => ({
			buttons: [
				{
					name: 'insert',
					icons: [
						{
							title: t('Insert trigger'),
							name: 'plus',
							iconSize: 18,
						},
					],
					colors: 'primary',
					ref: anchorRef,
				},
			],
		}),
		[t, anchorRef],
	);

	const itemsTrigger = React.useMemo(
		() =>
			triggers.map((name) => ({
				name,
				label: t(name),
				isDisabled: !!animate[`${name}`],
			})),
		[t, animate],
	);
	const keysAnimate = React.useMemo(
		() => _.filter((trigger) => !!animate[`${trigger}`], triggers),
		[animate],
	);
	const noTrigger = _.flow(
		_.keys,
		_.filter((trigger) => !!animate[trigger]),
		_.isEmpty,
	)(animate);

	return (
		<>
			<Section
				size="sm"
				label={t('Trigger')}
				buttonGroup={btnInsertTrigger}
				onClick={toggleOpenTriggers}
				sx={sectionSx}
			>
				{keysAnimate.map((trigger, i) => (
					<Box key={`animate-item-${trigger}`}>
						<AnimateItem
							key={`animate-item-${trigger}`}
							t={t}
							data={data}
							onSave={onSave}
							currentDevice={currentDevice}
							trigger={trigger}
							editedTrigger={editedTrigger}
							setEditedTrigger={setEditedTrigger}
						/>
						{i < keysAnimate.length - 1 && <Box sx={separatorSx} />}
					</Box>
				))}
				{noTrigger && <Empty t={t} title={t('Add trigger')} />}
			</Section>
			<PopupSelect
				isOpen={isOpenTriggers}
				anchorEl={anchorRef}
				offsetTop={0}
				offsetLeft={0}
				onClose={toggleOpenTriggers}
			>
				<List
					items={itemsTrigger}
					colors="primaryflat"
					activeColors="primaryflat"
					behavior="button"
					onClick={insertTrigger}
				/>
			</PopupSelect>
		</>
	);
};

export default React.memo<TProps>(AnimateItemsComponent);
