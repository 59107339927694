// @flow

import React from 'react';
import { DraggableCore, type DraggableData } from 'react-draggable';
import _ from 'lodash/fp';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Portal, Text, Box, Overlay } from '@graphite/uneon';

import Poper from 'Widget/Poper';
import ResizeCols, { type TContext } from 'Widget/libs/resize-cols';
import reSelect from 'libs/re-select';
import type { TId } from '@graphite/types';

const OFFSET = 1;
const PERMISSIBLE_INACCURACY_COL_TITLE = 0.2;
const TROTTLING = 20;

export type TProps = $ReadOnly<{|
	id: TId,
|}>;

const getStyle = reSelect<
	TContext,
	boolean,
	number,
	'left' | 'right' | null,
	{ [string]: string | false },
>(
	({ isResize }) => isResize,
	({ dragWidgetOriginalsize }) => dragWidgetOriginalsize,
	({ side }) => side,
	(isResize, width, side) => ({
		position: 'absolute',
		width: !isResize ? '100%' : `${width}px`,
		top: '0',
		height: '100%',
		left: side === 'left' ? 'auto' : '0',
		right: side === 'right' ? 'auto' : '0',
	}),
)(
	({ isResize, dragId, side }) =>
		`resize@getStyle-${isResize ? 'true' : 'false'}-${dragId || ''}-${side || ''}`,
);

// 11px чисто для успокоения души, ибо там бордер ещё есть
// визуально получается что как будто одного пикселя не хватает
const Backing = styled(Box)`
	position: absolute;
	top: 0;
	bottom: 0;
	width: 10px;
	cursor: ew-resize;
	pointer-events: auto;
	touch-action: none;
	user-select: none;
	z-index: 2;

	${({ isActive, side }) =>
		isActive
			? css`
					width: 1000%;
					left: -450%;
			  `
			: css`
					width: 11px;
					${side}: -6px;
			  `}
`;

const updateThrottled = _.throttle(TROTTLING, (dispatch, payload) => {
	dispatch({
		type: 'update',
		payload,
	});
});

const stopDebounce = _.debounce(TROTTLING + 10, dispatch => {
	dispatch({
		type: 'stop',
		payload: {},
	});
});

const overlayStyle = {
	cursor: 'ew-resize',
};

function Resize(props: TProps): React$Node {
	const { id } = props;

	const deltaPrev = React.useRef(0);
	const [resizeData, dispatch] = React.useContext(ResizeCols);
	const { colSize, colAmount, side, dragId, setGridHighlight } = resizeData;
	const isResize = dragId === id;

	// ToDo Не трогайте этот комментарий
	// const neighborsCount = _.find(size => size[id], colSizeMap)?.orderList?.length;

	const onDragStartLeft = React.useCallback(() => {
		setGridHighlight(true);
		dispatch({
			type: 'start',
			payload: { dir: 'left', dragId: id },
		});
	}, [setGridHighlight, dispatch, id]);

	const onDragStartRight = React.useCallback(() => {
		setGridHighlight(true);
		dispatch({
			type: 'start',
			payload: { dir: 'right', dragId: id },
		});
	}, [setGridHighlight, dispatch, id]);

	const onDragUpdate = React.useCallback(
		(e, dragData: DraggableData) => {
			const { deltaX } = dragData;

			const deltaOld = deltaPrev.current;
			deltaPrev.current += deltaX;

			if (Math.abs(deltaPrev.current) < OFFSET) return;
			if (deltaPrev.current === deltaOld) return;

			updateThrottled(dispatch, {
				delta: deltaPrev.current,
			});
		},
		[dispatch],
	);

	const onDragStop = React.useCallback(
		(e: SyntheticEvent<HTMLDivElement>) => {
			e.stopPropagation();
			deltaPrev.current = 0;

			setGridHighlight(false);
			stopDebounce(dispatch);
		},
		[setGridHighlight, dispatch],
	);

	const colSizeRounded = Math.round(colSize * 100) / 100;

	const title = React.useMemo(() => {
		if (colSizeRounded % 1 < PERMISSIBLE_INACCURACY_COL_TITLE) {
			return `${parseInt(colSizeRounded, 10)} col`;
		}
		return `${((colSize / colAmount) * 100).toFixed(0)} %`;
	}, [colSizeRounded, colSize, colAmount]);

	return (
		<>
			<Portal>
				<Overlay isActive={isResize} style={overlayStyle} />
			</Portal>
			<div style={getStyle(resizeData)}>
				<DraggableCore
					axis="y"
					onStart={onDragStartLeft}
					onDrag={onDragUpdate}
					onStop={onDragStop}
				>
					<Backing isActive={side === 'left'} side="left" />
				</DraggableCore>
				<DraggableCore
					axis="y"
					onStart={onDragStartRight}
					onDrag={onDragUpdate}
					onStop={onDragStop}
				>
					<Backing isActive={side === 'right'} side="right" />
				</DraggableCore>
				{isResize && (
					<Poper>
						<Text variant="captionlg" color="spec.lightblue10">
							{title}
						</Text>
					</Poper>
				)}
			</div>
		</>
	);
}

export default React.memo<TProps>(Resize);
