// @flow

import mapValues from 'lodash/fp/mapValues';
import sum from 'lodash/fp/sum';
import map from 'lodash/fp/map';
import floor from 'lodash/floor';
import { sizeRules } from '@graphite/constants';
import type {
	TId,
	TSizes,
	TSize,
	TSizeRule,
	TSizesBreakpoint,
	TGridBreakpointName,
} from '@graphite/types';


const scaleCol = (row: TSizes, factor: number): TSizes =>
	(mapValues(
		(size: TSize): TSize =>
			(mapValues.convert({ cap: false })(
				({ isScaling }: TSizeRule, name: TGridBreakpointName) => {
					if (isScaling) {
						return {
							width: floor(size[name].width * factor, 6),
							margin: {
								left: floor(size[name].margin.left * factor, 6),
								right: floor(size[name].margin.right * factor, 6),
							},
						};
					}
					return size[name];
				},
				sizeRules,
			): TSize),
		row,
	): TSizes);

/**
 * Adjust col-sizes within 1 row, by scaling.
 *
 * Can upscale/downscale the cols.
 * If a new col is inserted, it becomes 1/N size,
 * and the others downscale proportionally to compensate.
 * Otherwise, if the widget is removed, smartAdjustCol
 * upscales other cols, if any, to fill 100% of the row.
 */
const smartAdjustCol = (row: TSizes, newId: ?TId): TSizes => {
	// If newId is empty - no new widget is added to the row
	// Then, the row must reFILL itself to 100% with what is left
	if (!newId) {
		const rowSum: number = (sum(
			map(v => v.desktop.width + v.desktop.margin.left + v.desktop.margin.right, {
				...row,
			}),
		): number);
		// If rowSum is broken - do nothing
		if (rowSum <= 0) {
			return row;
		}
		const factor: number = 1 / rowSum;
		return scaleCol(row, factor);
	}

	// "ELSE" - add a new widget to the row
	const resultNum: number = Object.keys(row).length + 1;

	return {
		...scaleCol(row, 1 - 1 / resultNum),
		[newId]: (mapValues(({ isScaling }: TSizeRule): TSizesBreakpoint => {
			if (isScaling) {
				return {
					width: floor(1 / resultNum, 6),
					margin: { left: 0, right: 0 },
				};
			}
			return { width: 1, margin: { left: 0, right: 0 } };
		}, sizeRules): TSize),
	};
};

export default smartAdjustCol;
