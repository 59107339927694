// @flow
import _ from 'lodash/fp';
import type { TId, TCurrentWidget, TWidgetKind } from '@graphite/types';

const resizableWidgets = ['text', 'code', 'image', 'button'];

type TGetWidgetMode = (
	?TCurrentWidget,
	$ReadOnly<{ _id: TId, kind: TWidgetKind }>,
) => 'none' | 'widget-edit' | 'widget-resize';

const getWidgetMode: TGetWidgetMode = _.cond([
	[
		(currentWidget, propsPublised) =>
			!currentWidget || currentWidget.widgetChain.includes(propsPublised._id),
		_.constant<'none'>('none'),
	],
	[
		({ id, controls }, propsPublised) => id !== propsPublised._id || !controls,
		_.constant<'none'>('none'),
	],
	[
		({ controls }, propsPublised) =>
			!resizableWidgets.includes(propsPublised.kind) && controls === 'widget-edit',
		_.constant<'widget-resize'>('widget-resize'),
	],
	[_.stubTrue, ({ controls }) => controls],
]);

export default getWidgetMode;
