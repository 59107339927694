// @flow
import React from 'react';
import { Flex, Box } from '@graphite/uneon';
import emptyFunction from 'empty/function';

import ListItemCircleColors from './ItemCircleColors';

type TItem = $ReadOnly<{|
	id: string,
	color: string,
	name: string,
|}>;

type TProps = $ReadOnly<{|
	active?: ?string,
	items: $ReadOnlyArray<TItem>,
	onClick?: (string) => void,
|}>;

const flexStyle = {
	flexDirection: 'row',
	height: '42px',
	alignItems: 'center',
};

const boxStyle = {
	marginRight: '11px',
	':last-of-type': {
		marginRight: 0,
	},
};

function ListCircleColors({ items, active = null, onClick = emptyFunction }: TProps) {
	return (
		<Flex sx={flexStyle}>
			{items.map(({ id, color, name }) => (
				<Box key={id} sx={boxStyle}>
					<ListItemCircleColors
						id={id}
						color={color}
						isActive={id === active}
						onClick={onClick}
						name={name}
					/>
				</Box>
			))}
		</Flex>
	);
}

ListCircleColors.defaultProps = {
	active: null,
	onClick: emptyFunction,
};

export default React.memo<TProps>(ListCircleColors);
