// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Params as List } from '@graphite/lists';
import type { TParams, TAnimation } from '@graphite/types';
import { defaultValue } from '@graphite/animations';

type TProps = $ReadOnly<{|
	t: (string) => string,
	params: TAnimation,
	changeEffect: (TAnimation) => void,
|}>;

const SpringComponent = ({ t, params, changeEffect }: TProps) => {
	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Delay'),
				key: 'delay',
				kind: 'unit',
				info: {
					domain: 'nonnegative',
					showUnits: true,
					unitKey: 'delayUnit',
					units: ['s'],
				},
			},
			{
				title: t('Bounce'),
				key: 'bounce',
				kind: 'unit',
				info: {
					showUnits: false,
					unitKey: null,
				},
			},
			{
				title: t('Stiffness'),
				key: 'stiffness',
				kind: 'unit',
				info: {
					showUnits: false,
					unitKey: null,
				},
			},
			{
				title: t('Damping'),
				key: 'damping',
				kind: 'unit',
				info: {
					showUnits: false,
					unitKey: null,
				},
			},
			{
				title: t('Mass'),
				key: 'mass',
				kind: 'unit',
				info: {
					showUnits: false,
					unitKey: null,
				},
			},
		],
		[t],
	);

	const paramSource = React.useMemo(() => {
		const { bounce, damping, stiffness, mass, delay } = _.assignAll([
			defaultValue.transition.repeat,
			defaultValue.transition.spring,
			params.transition,
		]);

		return {
			bounce: `${bounce}`,
			damping: `${damping}`,
			stiffness: `${stiffness}`,
			mass: `${mass}`,
			delay: `${delay}`,
			delayUnit: 's',
		};
	}, [params]);

	const changeParam = React.useCallback(
		(key, value) => {
			changeEffect(_.set(`transition.${key}`, value, params));
		},
		[changeEffect, params],
	);

	return (
		<List
			listName="widget-settings-layout"
			paramSource={paramSource}
			paramList={paramList}
			unit={0}
			onChange={changeParam}
		/>
	);
};

export default React.memo<TProps>(SpringComponent);
