// @flow
import { takeEvery, put, fork, select, cancelled, take, call } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import { type Saga, eventChannel, type EventChannel } from 'redux-saga';
import _ from 'lodash/fp';
import logger from '@graphite/logger';
import { collection, storage } from 'libs/firebase';
import { getCurrentSiteId } from '@graphite/selectors';
import { saveSpecs } from 'Editor/ducks/specs';
import { saveWidgets } from 'Editor/ducks/widgets';
import type {
	TAction,
	TId,
	TQueryConstraints,
	TQueryConstraint,
	TPublicationType,
	TPublications,
	TPublicationStatus,
} from '@graphite/types';
import { getPublishStatus } from '../selectors/editor';
import { getSiteData } from './libs';

type TState = $ReadOnly<{|
	status: TPublicationStatus,
|}>;

type TStatusProcessing = 'publishing' | 'exporting';

const PUBLISH = 'PUBLISH/PUBLISH';
const REQUEST = 'PUBLISH/REQUEST';
const RECEIVE = 'PUBLISH/RECEIVE';
const REJECT = 'PUBLISH/REJECT';
const RESET = 'PUBLISH/RESET';
const FETCH_IF_NEEDED = 'PUBLISH/FETCH_IF_NEEDED';

export const fetchIfNeeded = (userId: ?TId, siteId: ?TId): TAction => ({
	type: FETCH_IF_NEEDED,
	payload: { userId, siteId },
});

export const publish = (type: TPublicationType = 'netlify'): TAction => ({
	type: PUBLISH,
	payload: { type },
});

export const request = (type: TStatusProcessing): TAction => ({
	type: REQUEST,
	payload: { type },
});

export const receive = (): TAction => ({
	type: RECEIVE,
	payload: {},
});

export const reject = (): TAction => ({
	type: REJECT,
	payload: {},
});
export const reset = (): TAction => ({
	type: RESET,
	payload: {},
});

const getPublicationEventsChannel = (
	userId: string,
	siteId: string,
): EventChannel<{ publications: TPublications }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ publications: TPublications }>(
		(emit: ({ publications: TPublications }) => void): (() => void) => {
			const unsubscriptors: $ReadOnlyArray<() => void> = [
				{
					siteId: ['==', siteId],
					userId: ['==', userId],
					doneAt: ['>', minDate],
				},
			].map((constraints: TQueryConstraints): (() => void) => {
				let publications = collection('publications');
				_.forEach(([k, v]: [string, TQueryConstraint]) => {
					publications = publications.where(k, v[0], v[1]);
				}, _.entries(constraints));
				return publications.onSnapshot((snapshot: any) => {
					const publications = {};
					snapshot.docChanges().forEach((change: any) => {
						const publication = change.doc.data();
						publications[change.doc.id] = publication;
					});
					if (_.size(publications)) {
						emit({ publications });
					}
				});
			});
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

export function* fetchIfNeededSaga(): Saga<void> {
	let publicEventsChannel = null;
	yield takeEvery(FETCH_IF_NEEDED, function*({
		payload: { userId, siteId },
	}: {
		payload: { userId: ?TId, siteId: ?TId },
	}): any {
		if (publicEventsChannel) {
			publicEventsChannel.close();
			publicEventsChannel = null;
		}
		if (!userId || !siteId) return;

		publicEventsChannel = getPublicationEventsChannel(userId, siteId);

		try {
			while (!0) {
				const { publications } = yield take(publicEventsChannel);
				const publishStatus: TPublicationStatus = yield select(getPublishStatus);

				for (const [key, publication] of Object.entries(publications)) {
					if (
						publication &&
						typeof publication.type === 'string' &&
						publication.status === 'done' &&
						['publishing', 'exporting'].includes(publishStatus)
					) {
						logger.info(`${publication.type} Finished`);

						if (publication.type === 'export') {
							// get url for download public.zip
							const zipUrl = yield storage
								.ref(`user/${userId}/${siteId}/public.zip`)
								.getDownloadURL();

							if (zipUrl) window.location.href = zipUrl;
							yield put(reset());
						} else {
							yield put(receive());
						}
						// need to clean up the status for right working
						yield collection('publications')
							.doc(key)
							.set({ status: null }, { merge: true });
					}
				}
			}
		} catch (e) {
			logger.error(e);
		} finally {
			if (yield cancelled() && publicEventsChannel) publicEventsChannel.close();
		}
	});
}

export function* publishSaga(): Saga<void> {
	yield takeEvery(PUBLISH, function*({
		payload: { type },
	}: {
		payload: { type: TPublicationType },
	}): any {
		try {
			yield put(request((type === 'export' && 'exporting') || 'publishing'));

			const siteId: ?TId = yield select(getCurrentSiteId);
			if (!siteId) {
				throw new Error('Project, Site, and Page should all be specified.');
			}

			const { widgets, specs, targetId } = yield call(getSiteData, siteId);

			// Сохраняем
			yield put(saveWidgets(widgets));
			yield put(saveSpecs(specs));

			// TODO: if updateAt didnt change in site and other data that dont do
			yield collection('publications')
				.doc(targetId)
				.set({
					status: 'active',
					updateAt: new Date().toISOString(),
					doneAt: null,
					userId: widgets[targetId].userId,
					siteId,
					type,
				});

			logger.info('publishSite', { type });
		} catch (e) {
			yield put(reject());
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(publishSaga);
	yield fork(fetchIfNeededSaga);
}

const initialState: TState = {
	status: 'unpublished',
};

export default handleActions<TState, TAction>(
	{
		[RESET](state: TState): TState {
			return _.set('status', 'unpublished', state);
		},
		[REQUEST](
			state: TState,
			{ payload: { type } }: { +payload: { +type: TStatusProcessing } },
		): TState {
			return _.set('status', type, state);
		},
		[RECEIVE](state: TState): TState {
			return _.set('status', 'published', state);
		},
		[REJECT](state: TState): TState {
			return _.set('status', 'error', state);
		},
	},
	initialState,
);
