// @flow

import { repositionWidget, editWidget } from 'Editor/ducks/widgets';
import { startEdit, resetEdit } from 'Editor/ducks/editor';
import type {
	Dispatch,
	TWidgetOwnProps,
	TId,
	TAction,
	TPositionValue,
	TOffsetDevice,
	TWidgetDiff,
} from '@graphite/types';

const getMapDispatchToPropsEdit = (
	dispatch: Dispatch<TAction>,
	ownProps: TWidgetOwnProps,
) => {
	const { instanceId, id, widgetChain, originId, containerId } = ownProps;

	return {
		dispatch,
		startEdit: () => {
			if (widgetChain) dispatch(startEdit(id, widgetChain, instanceId));
		},
		resetEdit: () => {
			dispatch(resetEdit());
		},
		editWidget: (diff: TWidgetDiff) => {
			dispatch(editWidget(id, instanceId, originId, diff));
		},
		repositionWidget: (
			targetId: TId,
			position: TPositionValue,
			offset: TOffsetDevice,
		) => {
			dispatch(repositionWidget(targetId, originId, containerId, position, offset));
		},
	};
};

export default getMapDispatchToPropsEdit;
