/* eslint-disable flowtype/require-valid-file-annotation */
/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import createReducer from './editor-reducers';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middlewares = [sagaMiddleware];

	if (history) {
		middlewares.push(routerMiddleware(history));
	}

	const enhancers = [
		applyMiddleware(...middlewares, LogRocket.reduxMiddleware()),
		Sentry.createReduxEnhancer({}),
	];

	// If Redux DevTools Extension is installed use it, otherwise use Redux compose
	/* eslint-disable no-underscore-dangle */
	const composeEnhancers =
		process.env.NODE_ENV !== 'production' &&
		typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					// TODO Try to remove when `react-router-redux` is out of beta,
					// LOCATION_CHANGE should not be fired more than once after hot reloading
					// Prevent recomputing reducers for `replaceReducer`
					shouldHotReload: false,
			  })
			: compose;
	/* eslint-enable */

	const store = createStore(
		createReducer(),
		initialState,
		composeEnhancers(...enhancers),
	);

	// Extensions
	store.runSaga = sagaMiddleware.run;
	store.injectedReducers = {}; // Reducer registry
	store.injectedSagas = {}; // Saga registry

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (module.hot) {
		module.hot.accept('./editor-reducers', () => {
			store.replaceReducer(createReducer(store.injectedReducers));
			store.dispatch({ type: '@@REDUCER_INJECTED' });
		});
	}

	return store;
}
