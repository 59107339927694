// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import { Params } from '@graphite/lists';
import type { TParams, TWidget, TWidgetDiff } from '@graphite/types';

type TProps = $ReadOnly<{|
	t: (string) => string,
	data: TWidget,
	onChange: (TWidgetDiff) => void,
	currentRef?: {| current: ?HTMLDivElement |},
	options?: $ReadOnly<{
		isIdShow: boolean,
		isClassNameShow: boolean,
	}>,
|}>;

const DEFAULT_OPT = {
	isIdShow: true,
	isClassNameShow: true,
};

const PropsComponent = ({ t, onChange, data, options = DEFAULT_OPT }: TProps) => {
	const { id, className } = data;

	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Id'),
				key: 'id',
				kind: 'string',
				info: { maxLength: 0, placeholder: t('id') },
				hidden: !options.isIdShow,
			},
			{
				title: t('Class'),
				key: 'className',
				kind: 'string',
				info: { maxLength: 0, placeholder: t('class') },
				hidden: !options.isClassNameShow,
			},
		],
		[t, options],
	);

	const paramSource = React.useMemo(
		() => ({
			id: `${id || ''}`,
			className: `${className || ''}`,
		}),
		[id, className],
	);

	const changeParam = React.useCallback(
		(key, value) => {
			onChange({ [key]: value });
		},
		[onChange],
	);

	return (
		<Section label={t('Props')}>
			<Params
				listName="widget-settings-props"
				paramSource={paramSource}
				paramList={paramList}
				onChange={changeParam}
			/>
		</Section>
	);
};

export default React.memo<TProps>(PropsComponent);
