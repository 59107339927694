// @flow
import React from 'react';
import { Box, PopupWhite, ToolbarBtn, Text } from '@graphite/uneon';
import type { TGridBreakpointName, TWidget, TWidgetDiff } from '@graphite/types';

import AnimateItems from './AnimateItems';

type TProps = $ReadOnly<{|
	t: string => string,
	data: TWidget,
	onSave: TWidgetDiff => void,
	currentDevice: TGridBreakpointName,
|}>;

const wrapBtn = {
	marginLeft: '12px',
	width: '36px',
	backgroundColor: 'bg.warning',
	borderRadius: 'rounded.all',
	boxShadow: 'lg',
};

const wrapBoxSx = {
	display: 'flex',
	flexDirection: 'column',
};

const headerSx = {
	cursor: 'default',
	textTransform: 'capitalize',
	marginBottom: '16px',
};

const animateBtnSx = {
	borderRadius: 'rounded.all',
};

const AnimateSettingComponent = ({ t, data, onSave, currentDevice }: TProps) => {
	const [isOpenedPanel, setOpenedPanel] = React.useState(false);

	const btnEl = React.useRef();
	const handleToogleOpen = React.useCallback(() => {
		setOpenedPanel(status => !status);
	}, []);

	const handleClose = React.useCallback(() => {
		setOpenedPanel(false);
	}, [setOpenedPanel]);

	return (
		<>
			<Box sx={wrapBtn}>
				<ToolbarBtn
					iconName="animation-24"
					variant="md"
					onClick={handleToogleOpen}
					ref={btnEl}
					isActive={isOpenedPanel}
					sx={animateBtnSx}
				/>
			</Box>

			{isOpenedPanel && (
				<PopupWhite
					isOpen
					offsetLeft={48}
					onClose={handleClose}
					mutex="settings"
					width={288}
					anchorEl={btnEl}
					placement="right"
					isFixed
				>
					<Box sx={wrapBoxSx}>
						<Text variant="title4" color="text.primaryalt" sx={headerSx}>
							{t('Animate')}
						</Text>

						<AnimateItems
							t={t}
							data={data}
							onSave={onSave}
							currentDevice={currentDevice}
						/>
					</Box>
				</PopupWhite>
			)}
		</>
	);
};

export default React.memo<TProps>(AnimateSettingComponent);
