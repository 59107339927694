// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getTrueIds, getWidget } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import logger from '@graphite/logger';

import { placeWidget, editWidget } from 'Editor/ducks/widgets';
import { logOut } from 'Editor/ducks/users';
import { addNotice } from 'Editor/ducks/editor';
import type { TWidgetOwnProps, TWidget } from '@graphite/types';
import { auth } from '../../../libs/firebase';

import User from './User';
import UserEditor from './UserEditor';

const UserEdit = ({ id, originId, instanceId }: TWidgetOwnProps) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		logger.info('dashboardVisit');
	}, []);

	const user: ?TWidget = useSelector((state) => getWidget(state, { id }));
	const users = useSelector((state) => state.users);

	const onInsert = React.useCallback(() => {
		dispatch(
			placeWidget('project', id, null, id, {
				kind: 'grid',
				prevId: null,
				nextId: null,
				destRect: null,
				dragRect: null,
				breakpoints: null,
			}),
		);
	}, [dispatch, id]);

	const onUpdate = React.useCallback(
		(diff) => {
			if (!originId) return;
			dispatch(editWidget(id, instanceId, originId, diff));
		},
		[dispatch, id, instanceId, originId],
	);

	const showNotice = React.useCallback(
		(title, desc) => {
			dispatch(addNotice(id, title, desc));
		},
		[dispatch, id],
	);

	// eslint-disable-next-line no-void
	const logOutHandler = React.useCallback(() => void dispatch(logOut()), [dispatch]);

	const babies = user
		? getTrueIds({
				...user,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
		  })
		: [];

	const fallbackName = users?.name || user?.name || users?.email || user?.email;

	return (
		<Switch>
			<Route path="/profile">
				{user ? (
					<UserEditor
						user={user}
						fallbackName={fallbackName}
						onUpdate={onUpdate}
						logOut={logOutHandler}
						showNotice={showNotice}
					/>
				) : (
					<Redirect to="/login" />
				)}
			</Route>

			<Route path="/">
				{user ? (
					<User
						id={id}
						name={auth.currentUser.displayName || fallbackName}
						photoURL={auth.currentUser.photoURL}
						babies={babies}
						onInsert={onInsert}
						logOut={logOutHandler}
					/>
				) : (
					<Redirect to="/login" />
				)}
			</Route>
		</Switch>
	);
};

export default React.memo<TWidgetOwnProps>(UserEdit);
