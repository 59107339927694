// @flow
import React from 'react';
import styled from '@emotion/styled';
import _ from 'lodash/fp';

import Text from '../Text';

export type TComponentProps = $ReadOnly<{|
	value: string,
|}>;

const normalizeText4Mac = value =>
	({
		meta: '⌘',
		shift: '⇧',
		control: '⌃',
		alt: '⌥',
		ctrl: '⌃',
	}[_.toLower(_.trim(value))] || _.toUpper(_.trim(value)));

const TextMac = styled(Text)`
	font-family: Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana,
		sans-serif;
`;

const HotkeyMac = ({ value }: TComponentProps) => {
	const values = value.split('+');

	return <TextMac as="span">{values.map(normalizeText4Mac).join(' ')}</TextMac>;
};

export default React.memo<TComponentProps>(HotkeyMac);
