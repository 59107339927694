// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type EditorState as TEditorState } from 'draft-js';
import { Toolbar, ToolbarBtn, ToolbarDelimitr, Popper, Tooltip } from '@graphite/uneon';

import InlineStylesBtn from './InlineStylesBtn';
import RemoveStylesBtn from './RemoveStylesBtn';

type TProps = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
|}>;

const InlineStyles = (props: TProps) => {
	const { t } = useTranslation();

	const { editorState, setEditorState, setOpenedPanel, openedPanel } = props;

	const inlineStylesBtnEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnBoldEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnItalicEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnUnderlineEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnStrikethroughEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnSubscriptEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnSuperscriptEl: React$ElementRef<any> = React.useRef(null);
	const inlineStylesBtnRemoveEl: React$ElementRef<any> = React.useRef(null);
	const handleInlineStyle = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === 'inlineStyle' ? null : 'inlineStyle',
			);
		},
		[setOpenedPanel],
	);

	const isActive = openedPanel === 'inlineStyle';

	return (
		<>
			<ToolbarBtn
				onClick={handleInlineStyle}
				iconName="bold-18"
				ref={inlineStylesBtnEl}
				isActive={isActive}
			/>
			<Tooltip elRef={inlineStylesBtnEl} placement={isActive ? 'bottom' : 'top'}>
				{t('Format')}
			</Tooltip>

			<Popper
				isOpen={openedPanel === 'inlineStyle'}
				anchorEl={inlineStylesBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<InlineStylesBtn
						iconName="bold-18"
						editorState={editorState}
						setEditorState={setEditorState}
						type="fontWeight"
						value="bold"
						ref={inlineStylesBtnBoldEl}
					/>
					<Tooltip elRef={inlineStylesBtnBoldEl} placement="top">
						{t('Bold')}
					</Tooltip>

					<InlineStylesBtn
						iconName="italic-18"
						editorState={editorState}
						setEditorState={setEditorState}
						type="fontStyle"
						value="italic"
						ref={inlineStylesBtnItalicEl}
					/>
					<Tooltip elRef={inlineStylesBtnItalicEl} placement="top">
						{t('Italic')}
					</Tooltip>

					<InlineStylesBtn
						iconName="underline-18"
						editorState={editorState}
						setEditorState={setEditorState}
						type="textDecoration"
						value="underline"
						ref={inlineStylesBtnUnderlineEl}
					/>
					<Tooltip elRef={inlineStylesBtnUnderlineEl} placement="top">
						{t('Underline')}
					</Tooltip>

					<InlineStylesBtn
						iconName="strikethrough-18"
						editorState={editorState}
						setEditorState={setEditorState}
						type="textDecoration"
						value="line-through"
						ref={inlineStylesBtnStrikethroughEl}
					/>
					<Tooltip elRef={inlineStylesBtnStrikethroughEl} placement="top">
						{t('Strikethrough')}
					</Tooltip>

					<ToolbarDelimitr />

					<InlineStylesBtn
						iconName="subscript-18"
						editorState={editorState}
						setEditorState={setEditorState}
						type="verticalAlign"
						value="sub"
						ref={inlineStylesBtnSubscriptEl}
					/>
					<Tooltip elRef={inlineStylesBtnSubscriptEl} placement="top">
						{t('Subscript')}
					</Tooltip>

					<InlineStylesBtn
						iconName="superscript-18"
						editorState={editorState}
						setEditorState={setEditorState}
						type="verticalAlign"
						value="super"
						ref={inlineStylesBtnSuperscriptEl}
					/>
					<Tooltip elRef={inlineStylesBtnSuperscriptEl} placement="top">
						{t('Superscript')}
					</Tooltip>

					<ToolbarDelimitr />

					<RemoveStylesBtn
						editorState={editorState}
						setEditorState={setEditorState}
						ref={inlineStylesBtnRemoveEl}
					/>
					<Tooltip elRef={inlineStylesBtnRemoveEl} placement="top">
						{t('Remove formatting')}
					</Tooltip>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(InlineStyles);
