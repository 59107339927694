// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Button, Box, Text, Icon, Dialog } from '@graphite/uneon';
import type { TId } from '@graphite/types';

type TNotice = $ReadOnly<{|
	id: TId,
	title: string,
	description: string,
|}>;

type TProps = $ReadOnly<{|
	data: $ReadOnlyArray<TNotice>,
	close: TId => void,
|}>;

const dialogSx = { position: 'relative', padding: '36px 0 42px 0' };
const dialogCloseBtnSx = {
	position: 'absolute',
	right: '-20px',
	top: '39px',
};

const Notices = ({ data, close }: TProps) => {
	const value = _.first(data);

	const closeHandler = React.useCallback(() => {
		if (value.id) close(value.id);
	}, [close, value]);

	if (!value) return null;

	const { title, description } = value;

	return (
		<Dialog onClose={closeHandler}>
			<Box width={360} sx={dialogSx}>
				<Text as="h4" variant="title3" color="text.primaryalt">
					{title}
				</Text>
				<Button
					variant="primaryflat"
					onClick={closeHandler}
					sx={dialogCloseBtnSx}
				>
					<Icon name="cross" iconSize={24} colors="primaryflat" />
				</Button>
				<Text variant="bodymd" color="text.primary" mt="36px">
					{description}
				</Text>
			</Box>
		</Dialog>
	);
};

export default React.memo<TProps>(Notices);
