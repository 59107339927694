// @flow
import React from 'react';
import get from 'lodash/fp/get';
import { Box, Text, Flex } from '@graphite/uneon';
import type {
	TParamSource,
	TParam,
	TListParamsOnClick,
	TListParamsOnChange,
} from '@graphite/types';

import ListItemUnitParams from './ListItemUnitParams';
import ListItemSelectParams from './ListItemSelectParams';
import ListItemStringParams from './ListItemStringParams';
import ListItemCurveParams from './ListItemCurveParams';
import ListItemSwitchParams from './ListItemSwitchParams';
import ListItemButtonParams from './ListItemButtonParams';

type TProps = $ReadOnly<{|
	paramSource: TParamSource,
	param: TParam,
	unit: number,
	onChange?: ?TListParamsOnChange,
	onClick?: ?TListParamsOnClick,
|}>;

const paramItemStyle = {
	alignItems: 'center',
	height: '42px',
	textAlign: 'left',
};

const paramLeftStyle = {
	flexGrow: 1,
	maxWidth: '50%',
};

const ListItemParams = ({
	paramSource,
	param,
	unit,
	onChange = null,
	onClick = null,
}: TProps) => {
	const changeParam = React.useCallback(
		value => {
			if (onChange) {
				onChange(param.key, value);
			}
		},
		[param, onChange],
	);
	const clickSelect = React.useCallback(
		(e, item, button) => {
			if (onClick) {
				onClick(e, param.key, item, button);
			}
		},
		[onClick, param.key],
	);

	const clickButton = React.useCallback(
		e => {
			if (onClick) {
				onClick(e, param.key);
			}
		},
		[onClick, param.key],
	);

	const value: string = get(param.key, paramSource) || '';

	return (
		<Flex sx={paramItemStyle}>
			<Box sx={paramLeftStyle}>
				<Text variant="bodysm" color="text.primary">
					{param.title}
				</Text>
			</Box>
			{(param.kind === 'unit' && (
				<ListItemUnitParams
					sign={
						(param.info.signKey && get(param.info.signKey, paramSource)) ||
						null
					}
					value={value}
					valueUnit={
						(param.info.unitKey && get(param.info.unitKey, paramSource)) ||
						null
					}
					param={param}
					unit={unit}
					onChange={changeParam}
					onClick={clickSelect}
				/>
			)) ||
				(param.kind === 'string' && (
					<ListItemStringParams
						sign={
							(param.info.signKey &&
								get(param.info.signKey, paramSource)) ||
							null
						}
						value={value}
						param={param}
						onChange={changeParam}
					/>
				)) ||
				(param.kind === 'curve' && (
					<ListItemCurveParams
						value={value}
						param={param}
						onChange={changeParam}
					/>
				)) ||
				(param.kind === 'select' && (
					<ListItemSelectParams
						value={value}
						param={param}
						onChange={changeParam}
						onClick={clickSelect}
					/>
				)) ||
				(param.kind === 'switch' && (
					<ListItemSwitchParams
						value={value}
						param={param}
						onChange={changeParam}
					/>
				)) ||
				(param.kind === 'button' && (
					<ListItemButtonParams param={param} onClick={clickButton} />
				))}
		</Flex>
	);
};

export default React.memo<TProps>(ListItemParams);
