// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import Widget from 'Widget';
import { anyKind } from 'Widget/libs';
import ComponentContext from 'ComponentContext';

import {
	getCurrentPageId,
	getCurrentSite,
	getCurrentSiteGridSpec,
	getCurrentSiteWidgetSpec,
	getCurrentSiteColorSpec,
	getCurrentSiteEffectSpec,
} from '@graphite/selectors';

import { TriggerProvider } from '@graphite/animations';
import type {
	TWidget,
	TSpecsGrid,
	TSpecsWidget,
	TSpecsColor,
	TSpecsEffect,
} from '@graphite/types';

export type TProps = $ReadOnly<{||}>;

const Render = () => {
	const pageId = useSelector(getCurrentPageId);
	const site: ?TWidget = useSelector(getCurrentSite);

	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const widgetspec: ?TSpecsWidget = useSelector(getCurrentSiteWidgetSpec);
	const colorspec: ?TSpecsColor = useSelector(getCurrentSiteColorSpec);
	const effectspec: ?TSpecsEffect = useSelector(getCurrentSiteEffectSpec);

	if (!pageId || !site || !widgetspec || !colorspec || !gridspec || !effectspec)
		return 'Page not found';

	return (
		<ComponentContext.Provider value={anyKind('get-view-component')}>
			<TriggerProvider>
				<Widget
					id={pageId}
					containerId={null}
					instanceId={null}
					originId={pageId}
					widgetspec={widgetspec}
					colorspec={colorspec}
					gridspec={gridspec}
					effectspec={effectspec}
				/>
			</TriggerProvider>
		</ComponentContext.Provider>
	);
};

export default React.memo<TProps>(Render);
