// @flow
import React from 'react';

import type { TProps } from './types';

const Code = (props: TProps, ref) => {
	const { data, onClick } = props;

	return (
		<div
			id={data.id}
			className={data.className}
			ref={ref}
			onClick={onClick}
			aria-hidden="true"
			dangerouslySetInnerHTML={{
				__html: data.code,
			}}
		/>
	);
};

export default React.memo<TProps>(React.forwardRef(Code));
