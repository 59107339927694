// @flow
import React from 'react';
import { getTrueIds, getWidget, getSpec } from '@graphite/selectors';
import { useSelector } from '@graphite/use-redux';
import { defaultDevice } from '@graphite/constants';
import type {
	TWidgetOwnProps,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
} from '@graphite/types';

import Site from './Site';

const SitePublished = ({ id }: TWidgetOwnProps) => {
	const site = useSelector(state => getWidget(state, { id }));

	const babies = site
		? getTrueIds({
				...site,
				currentDevice: defaultDevice,
		  })
		: [];
	const pageId = babies[0];

	const gridspec: ?TSpecsGrid = useSelector(state =>
		getSpec(state, { id: site?.gridspecId }),
	);
	const widgetspec: ?TSpecsWidget = useSelector(state =>
		getSpec(state, { id: site?.widgetspecId }),
	);
	const colorspec: ?TSpecsColor = useSelector(state =>
		getSpec(state, { id: site?.colorspecId }),
	);
	const effectspec: ?TSpecsEffect = useSelector(state =>
		getSpec(state, { id: site?.effectspecId }),
	);

	const widgetChain = React.useMemo(() => [pageId], [pageId]);
	if (!gridspec || !widgetspec || !colorspec || !effectspec) return null;

	return (
		<Site
			childId={pageId}
			widgetChain={widgetChain}
			gridspec={gridspec}
			widgetspec={widgetspec}
			colorspec={colorspec}
			effectspec={effectspec}
		/>
	);
};

export default React.memo<TWidgetOwnProps>(SitePublished);
