// @flow
import { getTrueIds } from '@graphite/selectors';
import { composeCached } from 'Widget/selectors';
import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';

import type { TWidgetPage } from '../constants/types';

const mapStateToProps = ({ widgets }: TStateEditor, { id }: TWidgetOwnProps) => {
	const data: TWidgetPage = composeCached(widgets, widgets[id]);
	const babies = getTrueIds({
		_id: data._id,
		children: data.children,
		order: data.order,
		// FixMe: на опубликованном сайте нет editor
		currentDevice: 'desktop',
	});

	return {
		data,
		babies,
	};
};

export default mapStateToProps;
