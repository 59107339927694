// @flow
import React from 'react';
import emptyFunction from 'empty/function';
import { ButtonColor as CircleColor, Tooltip } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	id: string,
	color: string,
	name: string,
	isActive?: boolean,
	onClick?: string => void,
|}>;

function ListItemColors({
	id,
	color,
	name,
	isActive = false,
	onClick = emptyFunction,
}: TProps) {
	const colorRef: React$ElementRef<any> = React.useRef(null);
	const boundClick = React.useCallback(() => onClick(id), [id, onClick]);

	return (
		<>
			<CircleColor
				color={color}
				onClick={boundClick}
				isActive={isActive}
				ref={colorRef}
			/>
			<Tooltip elRef={colorRef} placement="top" variant="darkblue.rounded.xsm">
				{name}
			</Tooltip>
		</>
	);
}

ListItemColors.defaultProps = {
	isActive: false,
	onClick: emptyFunction,
};

export default React.memo<TProps>(ListItemColors);
