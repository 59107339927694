// @flow
import React from 'react';
import _ from 'lodash/fp';
import { m, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import type { TAnimations } from '@graphite/types';

import { baseSx } from './constants';

import useTransition from './libs/useTransition';

type TProps = $ReadOnly<{|
	data: TAnimations,
	children: React$Node,
|}>;

const AppearComponent = (props: TProps) => {
	const {
		children,
		data: { appear },
	} = props;
	const [isAnimationComplete, setAnimationComplete] = React.useState(true);
	const animation = useAnimation();
	const { transition, filter, sensetivity } = useTransition(appear?.transition || {});

	const [ref, inView] = useInView(sensetivity);

	const variants = React.useMemo(
		() => ({
			from: appear?.from || {},
			to: appear?.to || {},
		}),
		[appear],
	);

	const onStartAnimation = React.useCallback(() => {
		if (isAnimationComplete) {
			animation.start('to');
			setAnimationComplete(false);
		}
	}, [isAnimationComplete, animation]);

	const onEndAnimation = React.useCallback(() => {
		animation.start('from');
	}, [animation]);

	React.useEffect(() => {
		if (inView) {
			onStartAnimation();
		} else {
			onEndAnimation();
		}
	}, [inView, onStartAnimation, onEndAnimation]);

	const onAnimationComplete = React.useCallback(() => {
		setAnimationComplete(true);
	}, []);

	if (!appear || _.isEmpty(variants.to)) return children;

	return (
		<m.div
			data-kind="appear-animation"
			ref={ref}
			variants={variants}
			initial="from"
			transition={inView ? transition : filter}
			onAnimationComplete={onAnimationComplete}
			animate={animation}
			style={baseSx}
		>
			{children}
		</m.div>
	);
};

export default React.memo<TProps>(AppearComponent);
