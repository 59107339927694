// @flow

import React from 'react';
import reduce from 'lodash/fp/reduce';
import values from 'lodash/fp/values';
import { Map, type Map as TMap } from 'immutable';
import styled from '@emotion/styled';
import Box from '@graphite/uneon/lib/Box';
import { getDesignSx } from '@graphite/selectors';
import type {
	TSpecsWidget,
	TSpecsColor,
	TDesign,
	TDesigns,
	TSpecsEffect,
	TSpecsGrid,
} from '@graphite/types';

/**
	Types
 */
type TBlockType = $ReadOnly<{|
	element: string,
	wrapper?: React$Node,
|}>;

type TBlockTypes = $ReadOnly<{|
	[string]: TBlockType,
|}>;

/**
	Blocks List
 */
const ListOrdered = styled.ul`
	list-style: decimal;
	margin-left: 1.5em;
`;

const ListUnOrdered = styled.ol`
	list-style: disc;
	margin-left: 1.5em;
`;

const blockRenderMapList: TMap<string, TBlockType> = Map({
	'ordered-list-item': {
		element: 'li',
		wrapper: <ListOrdered />,
	},
	'unordered-list-item': {
		element: 'li',
		wrapper: <ListUnOrdered />,
	},
});

export const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div'];

export const getBlockRenderMap = ({
	designs,
	widgetspec,
	colorspec,
	gridspec,
	effectspec,
}: {
	designs?: TDesigns,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	effectspec: TSpecsEffect,
	gridspec: TSpecsGrid,
}): TMap<string, TBlockType> =>
	Map(
		reduce(
			(blocks, block: TDesign) => {
				if (block.target !== 'text') {
					return blocks;
				}
				const design: TDesign = block;
				return reduce(
					// eslint-disable-next-line no-shadow
					(blocks, tag) => {
						const sx = getDesignSx({
							design,
							gridspec,
							colorspec,
							effectspec,
							widgetspec,
						});
						return {
							...blocks,
							[`${design._id}-${tag}`]: {
								wrapper: <Box as={tag} sx={sx} />,
							},
						};
					},
					blocks,
					tags,
				);
			},
			Object.freeze(
				(({
					unstyled: {
						element: 'div',
					},
				}: any): TBlockTypes),
			),
			designs ? [...widgetspec.text, ...values(designs)] : widgetspec.text,
		),
	).merge(blockRenderMapList);
