// @flow
import React from 'react';
import { Box, Overlay } from '@graphite/uneon';
import emptyFunction from 'empty/function';

type TProps = $ReadOnly<{|
	hue: number,
	onChange?: number => void,
	onPreview?: number => void,
|}>;

const containerStyle = {
	position: 'relative',
	userSelect: 'none',
	height: '18px',
	cursor: 'pointer',
};

const handleWrapperStyle = {
	position: 'relative',
	width: '0',
	height: '0',
};

const boundStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
};

const handleStyle = {
	position: 'absolute',
	left: '-7px',
	top: '-7px',
	width: '14px',
	height: '14px',
	cursor: 'pointer',
	backgroundColor: 'spec.lightblue10',
	borderRadius: 'rounded.all',
	boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
};

const previewStyle = {
	position: 'absolute',
	left: '-4px',
	top: '-4px',
	width: '8px',
	height: '8px',
	cursor: 'pointer',
	borderRadius: 'rounded.all',
};

const leftRightStyle = {
	...boundStyle,
	borderRadius: 'rounded.all',
	background: [
		'linear-gradient(270deg, ',
		'#FF0000 0%, ',
		'#FF00EC 18.9%, ',
		'#0018FF 35.94%, ',
		'#00FBFF 52.57%, ',
		'#00FF1A 68.83%, ',
		'#FFFF00 84.67%, ',
		'#FF0000 100%)',
	].join(''),
};

const cursorSx = {
	cursor: 'pointer',
};

function SliderColorHue({
	hue,
	onChange = emptyFunction,
	onPreview = emptyFunction,
}: TProps) {
	const [width, setWidth] = React.useState(0);
	const [ownHue, setOwnHue] = React.useState(hue);
	const [isGrab, setGrab] = React.useState(false);

	React.useEffect(() => setOwnHue(hue), [hue]);

	const x = (ownHue * width) / 360;

	const containerRef = React.useRef();

	const pickColor = React.useCallback(
		clientX => {
			if (!containerRef.current) {
				return null;
			}
			const rect = containerRef.current.getBoundingClientRect();
			// eslint-disable-next-line no-shadow
			const x = Math.max(0, Math.min(width, clientX - rect.x));
			const newHue = (x / width) * 360;
			setOwnHue(newHue);
			return newHue;
		},
		[width, setOwnHue],
	);

	const onMouseMove = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const hue = pickColor(clientX);
				if (hue !== null) {
					onPreview(hue);
				}
			});
		},
		[pickColor, onPreview],
	);

	const onMouseUp = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const hue = pickColor(clientX);
				if (hue !== null) {
					onChange(hue);
				}
			});

			setGrab(false);
		},
		[pickColor, onChange],
	);

	const onMouseDown = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const hue = pickColor(clientX);
				if (hue !== null) {
					onPreview(hue);
				}
			});

			setGrab(true);
		},
		[pickColor, onPreview],
	);

	React.useEffect(() => {
		window.requestAnimationFrame(() => {
			if (containerRef.current) {
				setWidth(containerRef.current.getBoundingClientRect().width);
			}
		});
	}, []);

	const positionStyle = React.useMemo(
		() => ({
			position: 'absolute',
			left: `${x}px`,
			top: '50%',
		}),
		[x],
	);

	const handleColorStyle = React.useMemo(
		() => ({
			background: `hsl(${ownHue}, 100%, 50%)`,
		}),
		[ownHue],
	);

	return (
		<>
			<Box sx={containerStyle} ref={containerRef}>
				<Box sx={leftRightStyle} />
				<Box style={positionStyle}>
					<Box sx={handleWrapperStyle} onMouseDown={onMouseDown}>
						<Box sx={handleStyle} />
						<Box sx={previewStyle} style={handleColorStyle} />
					</Box>
				</Box>
				<Box style={boundStyle} onMouseDown={onMouseDown} />
			</Box>
			{isGrab && (
				<Box sx={cursorSx} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
					<Overlay isActive={isGrab} />
				</Box>
			)}
		</>
	);
}

SliderColorHue.defaultProps = {
	onChange: emptyFunction,
	onPreview: emptyFunction,
};

export default React.memo<TProps>(SliderColorHue);
