// @flow

import reSelect from 'libs/re-select';
import { getActiveColAmount } from 'Editor/selectors/site';
import {
	closestDeviceWithKey,
	getCurrentPageId,
	getTrueWidgetIds,
	getColSizeMap,
} from '@graphite/selectors';

import { composeCached } from 'Widget/selectors';
import type { TColumnsGroup } from 'libs/types/calc-columns';
import type {
	TWidgets,
	TWidgetComposed,
	TGridBreakpointName,
	TSpecsGrid,
	TId,
	TWidgetKind,
	TOffsetDevice,
} from '@graphite/types';

import { type TStateTopBarLayers } from './constants/types';

export type TTreeDataElement = $ReadOnly<{|
	_id: TId,
	kind: TWidgetKind,
	expanded: ?boolean,
	selected: ?boolean,
	title: string,
	subtitle: string,
	prevId: ?TId,
	nextId: ?TId,
	colSizeMap: ?TColumnsGroup,
	containerId: ?TId,
	instanceId: ?TId,
	position: ?string,
	offset: ?TOffsetDevice,
	key: string,
	modified?: [],
	children: $ReadOnlyArray<TTreeDataElement>,
|}>;

type TRouter = { location: { pathname: string } };

export type TGetTreeDataParams = $ReadOnly<{|
	widgets: TWidgets,
	topBarLayers: TStateTopBarLayers,
	editor: {
		currentDevice: TGridBreakpointName,
	},
	router: TRouter,
	position: ?string,
	gridspec: TSpecsGrid,
|}>;
export type TGetTreeDataFn = TGetTreeDataParams => TTreeDataElement;

export const findNodeById = reSelect<
	$ReadOnly<{
		data: $ReadOnlyArray<TTreeDataElement>,
		id: string,
	}>,
	$ReadOnlyArray<TTreeDataElement>,
	string,
	?TTreeDataElement,
>(
	({ data }) => data,
	({ id }) => id,
	(data, id) => {
		let foundNode: ?TTreeDataElement = null;
		let l: number = data?.length || 0;
		while (l--) {
			const item: TTreeDataElement = data[l];
			if (item._id === id) {
				foundNode = item;
				break;
			}
			if (item.children) {
				foundNode = findNodeById({ data: item.children, id });
				if (foundNode) {
					break;
				}
			}
		}
		return foundNode;
	},
)(({ id }) => `topbar__layers@findNodeById-${id}`);

/**
 * Превращает плоскую структуру виджетов в дерево
 */
export const getTreeData: TGetTreeDataFn = reSelect<
	TGetTreeDataParams,
	TWidgets,
	TStateTopBarLayers,
	TRouter,
	TGridBreakpointName,
	?string,
	TSpecsGrid,
	TTreeDataElement,
>(
	({ widgets }): TWidgets => widgets,
	({ topBarLayers }): TStateTopBarLayers => topBarLayers,
	({ router }) => router,
	({ editor }): TGridBreakpointName => editor.currentDevice,
	({ position }): ?string => position || null,
	({ gridspec }): TSpecsGrid => gridspec,
	(widgets, topBarLayers, router, currentDevice, position, gridspec) => {
		const currentPage = getCurrentPageId({ router }) || '---';

		const widget: TWidgetComposed = composeCached(widgets, widgets[currentPage]);
		let colSizeMap = null;

		if (widget.kind === 'block') {
			const colAmount = getActiveColAmount({
				currentDevice,
				gridspec: widget.gridspec || gridspec,
			});
			colSizeMap = getColSizeMap({
				data: { ...widget },
				currentDevice,
				colAmount,
				orderList: getTrueWidgetIds({
					...widget,
					currentDevice,
				}),
			});
			if (!colSizeMap.find(i => i.orderList?.length)) {
				colSizeMap = null;
			}
		}
		const {
			_id,
			name = 'Widget',
			children = {},
			order = {},
			positions = {},
		} = widget;

		const orderTrue = closestDeviceWithKey(order, {
			currentDevice,
			key: `order-${_id}`,
		});

		let prevId = null;

		return {
			_id,
			expanded: topBarLayers?.expandeds[_id],
			title: `${widget.modified ? 'Component ' : ''}${name}`,
			subtitle: _id,
			kind: widget.kind,
			colSizeMap,
			prevId: null,
			nextId: null,
			containerId: null,
			position,
			instanceId: null,
			offset: (position && widget.box?.[currentDevice]?.offset) || null,
			key: '',
			selected: false,
			children: widget.modified
				? []
				: Object.keys(children)
						.sort((a, b) =>
							typeof orderTrue[a] !== 'undefined'
								? orderTrue[a] - orderTrue[b]
								: 0,
						)
						.map(id =>
							getTreeData({
								position: positions[id] || null,
								widgets,
								topBarLayers,
								editor: { currentDevice },
								gridspec,
								router: {
									location: {
										// Intended! Only the page ID required
										pathname: `/project/projectId/site/siteId/page/${id}`,
									},
								},
							}),
						)
						.map((children, index, array) => {
							const newChildren = {
								...children,
								prevId,
								nextId: array[index + 1] ? array[index + 1]._id : null,
								containerId: _id,
							};
							prevId = children._id;
							return newChildren;
						}),
		};
	},
)(
	({
		widgets,
		router: {
			location: { pathname },
		},
	}) =>
		`topbar__layers@getTreeData-${Object.keys(widgets)
			.map(_id => _id)
			.join('')}-${pathname}`,
);

export default {};
