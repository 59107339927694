// @flow
import { takeEvery, put, fork } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import { type Saga } from 'redux-saga';
import _ from 'lodash/fp';

import logger from '@graphite/logger';
import { collection, genId } from 'libs/firebase';

import type { TAction, TId } from '@graphite/types';

type TStatusProcessing =
	| 'new'
	| 'processing'
	| 'fail';

type TState = $ReadOnly<{|
	status: TStatusProcessing,
|}>;

const CREATE = 'QUEUE/CREATE';
const PROCESSING = 'QUEUE/PROCESSING';
const FAIL = 'QUEUE/FAIL';

export const creteTask = (siteId: ?TId, projectId: ?TId, userId: ?TId ): TAction => ({
	type: CREATE,
	payload: { projectId, siteId, userId },
});

export const processing = (): TAction => ({
	type: PROCESSING,
	payload: {},
});

export const fail = (): TAction => ({
	type: FAIL,
	payload: {},
});

const initialState: TState = {
	status: 'new',
};

export function* creteTaskSaga(): Saga<void> {
	yield takeEvery(CREATE, function*({
		payload: { siteId, projectId, userId },
	}: {
		payload: { siteId: ?TId, projectId: ?TId, userId: ?TId },
	}): Saga<void> {
		try {
			yield collection('queue')
				.doc(genId('queue'))
				.set({
					status: 'new',
					updateAt: new Date().toISOString(),
					doneAt: null,
					projectId,
					siteId,
                    userId,
                    scope: projectId ? 'project' : 'site',
                    type: 'clean'
				});
			logger.info('remove site/project');
		} catch (e) {
			yield put(fail());
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(creteTaskSaga);
}


export default handleActions<TState, TAction>(
	{
		[PROCESSING](state: TState): TState {
			return _.set('status', 'processing', state);
		},
		[FAIL](state: TState): TState {
			return _.set('status', 'fail', state);
		},
	},
	initialState,
);
