// @flow
import React from 'react';
import { Box, Text } from '@graphite/uneon';
import type { TPopupOnClick, TButtonParam } from '@graphite/types';

type TProps = $ReadOnly<{|
	param: TButtonParam,
	onClick?: ?TPopupOnClick,
|}>;

const customSelectValueSx = {
	flexGrow: 1,
	maxWidth: '50%',
	color: 'text.primary',
	transitionProperty: 'color',
	transitionTimingFunction: 'ease-out',
	transitionDuration: '0.25s',
	'&:hover': {
		cursor: 'pointer',
		color: 'text.accent',
	},
};

const textSx = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
};

function ListItemButtonParams({ param, onClick = () => {} }: TProps) {
	const sx = React.useMemo(
		() => ({
			...customSelectValueSx,
			...(param.info.active ? { color: 'text.accent' } : {}),
		}),
		[param.info.active],
	);

	return (
		<Box sx={sx} onClick={onClick}>
			<Text sx={textSx} variant="headlinesm">
				{param.info.label}
			</Text>
		</Box>
	);
}

export default React.memo<TProps>(ListItemButtonParams);
