// @flow
import React from 'react';
import _ from 'lodash/fp';
import { m, useAnimation } from 'framer-motion';
import type { TId, TAnimations } from '@graphite/types';
import { baseSx } from './constants';
import useTrigger from './libs/useTrigger';
import useTransition from './libs/useTransition';

type TProps = $ReadOnly<{|
	data: TAnimations,
	children: React$Node,
	type: 'hover' | 'click' | 'switch' | 'tap',
	widgetId: TId,
|}>;

const MouseComponent = (props: TProps) => {
	const { children, data, type, widgetId } = props;
	const [isAnimationComplete, setAnimationComplete] = React.useState(true);
	const [triggered, handleTrigger] = React.useState(false);
	const animation = useAnimation();
	const triggerAction = useTrigger();

	const { transition, filter } = useTransition(data[type]?.transition || {});

	const handleAnimationStart = React.useCallback(() => {
		if (isAnimationComplete) {
			handleTrigger(true);
			setAnimationComplete(false);
		}
	}, [isAnimationComplete]);

	const handleAnimationStop = React.useCallback(() => {
		handleTrigger(false);
	}, []);

	React.useEffect(() => {
		if (!data[type]?.anchor) return;
		triggerAction.onReg({
			widgetId,
			triggerIds: data[type].anchor,
			fx: handleTrigger,
			type,
		});
	}, [data, type, triggerAction, handleTrigger, widgetId]);

	const variants = React.useMemo(
		() => ({
			from: data[type]?.from || {},
			to: data[type]?.to || {},
		}),
		[data, type],
	);

	React.useEffect(() => {
		if (triggered) {
			animation.start('to');
		} else {
			animation.start('from');
		}
	}, [animation, triggered]);

	const animationProps = React.useMemo(() => {
		if (data[type]) {
			if (data[type].anchor) {
				return {};
			}

			if (type === 'click' && data[type]) {
				return {
					onClick: handleAnimationStart,
				};
			}

			if (type === 'hover' && data[type]) {
				return {
					onMouseEnter: handleAnimationStart,
					onMouseLeave: handleAnimationStop,
				};
			}
		}

		return null;
	}, [data, type, handleAnimationStart, handleAnimationStop]);

	const onAnimationComplete = React.useCallback(() => {
		setAnimationComplete(true);
		if (triggered && type === 'click') handleAnimationStop();
	}, [type, triggered, handleAnimationStop]);

	if (!data[type] || _.isEmpty(variants.to)) return children;

	return (
		<m.div
			data-kind="mouse-animation"
			initial="from"
			style={baseSx}
			variants={variants}
			animate={animation}
			transition={triggered ? transition : filter}
			onAnimationComplete={onAnimationComplete}
			/* eslint-disable-next-line react/jsx-props-no-spreading */
			{...animationProps}
		>
			{children}
		</m.div>
	);
};

export default React.memo<TProps>(MouseComponent);
