// @flow

import React from 'react';

import { useTriggerContext, TriggerContext } from './libs/triggerReducer';

type TProps = $ReadOnly<{|
	children: React$Node,
|}>;

function TriggerProvider({ children }: TProps) {
	const dataTrigger = useTriggerContext();
	return (
		<TriggerContext.Provider value={dataTrigger}>{children}</TriggerContext.Provider>
	);
}

export default React.memo<TProps>(TriggerProvider);
