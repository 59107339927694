// @flow
import React from 'react';
import type { TAnimation } from '@graphite/types';
import { AnimatePlayContext } from './animatePlayReducer';

const useActions = () => {
	const [, dispatch] = React.useContext(AnimatePlayContext);

	const onPlay = React.useCallback((): void => {
		dispatch({
			type: 'play',
			payload: {},
		});
	}, [dispatch]);

	const setAnimation = React.useCallback(
		(animation: TAnimation): void => {
			dispatch({
				type: 'animation',
				payload: { animation },
			});
		},
		[dispatch],
	);

	const onStop = React.useCallback((): void => {
		dispatch({
			type: 'stop',
			payload: {},
		});
	}, [dispatch]);

	return React.useMemo(() => ({ onPlay, onStop, setAnimation }), [
		onPlay,
		onStop,
		setAnimation,
	]);
};

export default useActions;
