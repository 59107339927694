// @flow
import React from 'react';
import { Editor } from 'draft-js';
import emptyFunction from 'empty/function';
import Box from '@graphite/uneon/lib/Box';
import fromRaw from './libs/from-raw';
import { customStyleMap, getCustomStyleFn } from './libs/style-map';
import { getBlockRenderMap } from './libs/blockRenderMap';
import blockRendererFn from './libs/blockRendererFn';
import type { TProps } from './constants/types';

const baseSx = {
	wordBreak: 'break-word',
	flexDirection: 'column',
};

const Text = (props: TProps, ref) => {
	const { data, widgetspec, colorspec, gridspec, effectspec, onClick, children } =
		props;
	const { raw } = data;

	const [editorState, setEditorState] = React.useState(() => fromRaw(raw));

	React.useEffect(() => setEditorState(fromRaw(raw)), [raw, setEditorState]);

	const blockRenderMap = React.useMemo(
		() =>
			getBlockRenderMap({
				designs: data.designs,
				widgetspec,
				colorspec,
				gridspec,
				effectspec,
			}),
		[colorspec, data.designs, gridspec, widgetspec, effectspec],
	);

	if (!widgetspec || !colorspec || !gridspec || !effectspec) return null;

	return (
		<Box
			id={data.id}
			className={data.className}
			sx={baseSx}
			onClick={onClick}
			ref={ref}
		>
			{children || (
				<Editor
					readOnly
					editorState={editorState}
					customStyleFn={getCustomStyleFn(colorspec)}
					customStyleMap={customStyleMap}
					blockRenderMap={blockRenderMap}
					blockRendererFn={blockRendererFn}
					onChange={emptyFunction}
				/>
			)}
		</Box>
	);
};

export default React.memo<TProps>(React.forwardRef(Text));
