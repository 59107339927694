// @flow
import React from 'react';
import styled from '@emotion/styled';
import ImageLib from '@graphite/image-lib';
import { Box, Button } from '@graphite/uneon';
import _ from 'lodash/fp';
import { defaultBgImage } from '@graphite/constants';
import { Params as ListParams } from '@graphite/lists';
import type {
	TId,
	TBgImage,
	TUnit,
	TActiveButtons,
	TSx,
	TParamSource,
	TParams,
	TListParamsOnClick,
	TSpecsGrid,
	TWidgets,
	TImageLibraryListUploads,
} from '@graphite/types';

const DEFAULT_POS_PERCENT = 50;
const DEFAULT_POS_PX = 0;
const DEFAULT_SIZE_PERCENT = 100;
const DEFAULT_SIZE_PX = 500;

type TProps = $ReadOnly<{|
	t: string => string,
	value?: TBgImage,
	onChange?: ?(TBgImage) => void,
	onPreview?: ?(TBgImage) => void,
	gridspec: TSpecsGrid,
	images: ?TWidgets,
	insertImage: FileList => void,
	removeImage: (imageId: TId) => void,
	resetImage: () => void,
	uploads: TImageLibraryListUploads,
|}>;

const paramListCommon: TParams = [
	{
		title: 'Link',
		key: 'src',
		kind: 'string',
		info: {
			maxLength: 0,
		},
	},
	{
		title: 'Type',
		key: 'kind',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'fill', label: 'Fill' },
					{ name: 'fit', label: 'Fit' },
					{ name: 'crop', label: 'Crop' },
					{ name: 'tile', label: 'Tile' },
				],
			},
		},
	},
	{
		title: 'Position X',
		key: 'x',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'xUnit',
			units: ['px', '%'],
		},
	},
	{
		title: 'Position Y',
		key: 'y',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'yUnit',
			units: ['px', '%'],
		},
	},
];

const paramListCropItems: TParams = [
	{
		title: 'Size',
		key: 'width',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'widthUnit',
			units: ['%', 'px', 'auto'],
		},
	},
];

const paramListCrop: TParams = [...paramListCommon, ...paramListCropItems];

const tileUnits = ['auto', '%', 'px'];

const paramListTile: TParams = [
	...paramListCommon,
	...paramListCropItems.map(item => _.set('info.units', tileUnits, item)),
	{
		title: 'Repeat',
		key: 'repeat',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'repeat', label: 'All' },
					{ name: 'repeat-x', label: 'X' },
					{ name: 'repeat-y', label: 'Y' },
					{ name: 'no-repeat', label: 'None' },
				],
			},
		},
	},
];

const paramListMap = {
	fill: paramListCommon,
	fit: paramListCommon,
	crop: paramListCrop,
	tile: paramListTile,
};

const previewHeightSx: TSx = {
	height: '240px',
	marginBottom: '12px',
};

const previewWidthSx: TSx = {
	position: 'absolute',
	left: '24px',
	right: '24px',
};

const previewContainerSx: TSx = {
	height: '240px',
	position: 'relative',
	margin: '0 -24px',
};

const spacerSx = {
	height: '6px',
};

const Image = styled.img`
	position: absolute;
	max-width: 100%;
	max-height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Replace = styled(Button)`
	z-index: 1;
`;

const Cover = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: ${({ theme }) => theme.colors.spec.darkblue80};
	opacity: 0.5;
`;

const Controls = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-property: opacity;
	transition-timing-function: ease-out;
	transition-duration: 0.25s;
`;

const ImagePicker = styled(Box)`
	background-color: ${({ theme }) => theme.colors.spec.darkblue80};
	cursor: pointer;

	&:hover {
		${Controls} {
			opacity: 1;
		}
	}
`;

function BgImage({
	t,
	value = defaultBgImage,
	onChange = null,
	onPreview = null,
	gridspec,
	images,
	insertImage,
	removeImage,
	resetImage,
	uploads,
}: TProps) {
	const { unit } = gridspec;

	const [isShowImageLib, shownImageLib] = React.useState(false);

	const [ownValue, setOwnValue] = React.useState<TBgImage>(defaultBgImage);
	React.useEffect(
		() => {
			if (value && ownValue !== value) {
				setOwnValue(value);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value],
	);

	const changeDebounced = React.useMemo(
		() => _.debounce(500, changed => (onChange && onChange(changed)) || undefined),
		[onChange],
	);

	const changeBound = React.useCallback(
		changed => {
			setOwnValue(changed);
			if (onPreview) {
				onPreview(changed);
			}
			changeDebounced(changed);
		},
		[changeDebounced, onPreview],
	);

	const paramList: TParams = paramListMap[ownValue.kind];

	const paramSource: TParamSource = React.useMemo(
		() => ({
			kind: ownValue.kind,
			src: ownValue.src,
			x: `${ownValue.x}`,
			xUnit: ownValue.xUnit,
			y: `${ownValue.y}`,
			yUnit: ownValue.yUnit,
			width: `${ownValue.width}`,
			widthUnit: ownValue.widthUnit,
			repeat: ownValue.repeat,
		}),
		[ownValue],
	);

	const changeParam = React.useCallback(
		(key, changed) => {
			let updated = _.set(key, changed, ownValue);
			if (key === 'kind') {
				if (changed === 'tile') {
					updated = _.set('widthUnit', 'auto', updated);
				} else if (changed === 'crop') {
					updated = _.set('widthUnit', '%', updated);
					updated = _.set('width', DEFAULT_SIZE_PERCENT, updated);
				}
			}
			changeBound(updated);
		},
		[changeBound, ownValue],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key: string, name: ?TActiveButtons) => {
			if (name !== '%' && name !== 'px' && name !== 'auto') {
				return;
			}
			const unitNext: TUnit = name;
			const param = paramList.find(p => p.key === key);
			if (!param || param.kind !== 'unit' || !param.info.unitKey) {
				return;
			}

			const unitPath: string = param.info.unitKey;
			const unitPrev: TUnit = _.get(unitPath, ownValue);
			if (unitPrev === unitNext) {
				return;
			}

			let updated = _.set(unitPath, unitNext, ownValue);

			if (key === 'x' || key === 'y') {
				if (unitNext === 'px') {
					updated = { ...updated, [`${key}`]: DEFAULT_POS_PX };
				} else if (unitNext === '%') {
					updated = { ...updated, [`${key}`]: DEFAULT_POS_PERCENT };
				}
			} else if (key === 'width') {
				if (unitNext === 'px') {
					updated = { ...updated, width: DEFAULT_SIZE_PX };
				} else if (unitNext === '%') {
					updated = { ...updated, width: DEFAULT_SIZE_PERCENT };
				}
			}

			changeBound(updated);
		},
		[changeBound, paramList, ownValue],
	);

	const changeShowImageLib = React.useCallback(() => {
		shownImageLib(!isShowImageLib);
	}, [isShowImageLib]);

	const handleChange = React.useCallback(src => changeParam('src', src), [changeParam]);

	const handleClose = React.useCallback(
		e => {
			e.preventDefault();
			shownImageLib(false);
			resetImage();
		},
		[resetImage],
	);

	return (
		<>
			<Box sx={previewHeightSx}>
				<Box sx={previewWidthSx}>
					<ImagePicker onClick={changeShowImageLib} sx={previewContainerSx}>
						<Image src={ownValue.src} alt="" />
						<Controls>
							<Cover />
							<Replace
								variant="whiteghost.rounded.md"
								onClick={changeShowImageLib}
							>
								{t('Replace')}
							</Replace>
						</Controls>
					</ImagePicker>
				</Box>
			</Box>
			<Box sx={spacerSx} />
			<ListParams
				listName="bg-image"
				paramSource={paramSource}
				paramList={paramList}
				unit={unit}
				onChange={changeParam}
				onClick={clickUnit}
			/>
			<Box sx={spacerSx} />
			{isShowImageLib && (
				<ImageLib
					t={t}
					images={images}
					isShow={isShowImageLib}
					onClose={handleClose}
					onChange={handleChange}
					onUpload={insertImage}
					onRemove={removeImage}
					uploads={uploads}
					currentImg={ownValue.src}
				/>
			)}
		</>
	);
}

BgImage.defaultProps = {
	value: defaultBgImage,
	onChange: null,
	onPreview: null,
};

export default React.memo<TProps>(BgImage);
