// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import type {
	THOCFilterProps,
	THOCFilter,
	THOCFilterFunc,
	THOCFilterPropsOut,
} from '../constants/types';

const filterProps: THOCFilterFunc = props => ({
	id: props.id,
	containerId: props.containerId,
	instanceId: props.instanceId,
	originId: props.originId,
	children: props.children,
	data: props.data,
	babies: props.babies,
	dispatch: props.dispatch,
	gridspec: props.gridspec,
	colorspec: props.colorspec,
	widgetspec: props.widgetspec,
	effectspec: props.effectspec,
	dragHandler: props.dragHandler,
	dragContainer: props.dragContainer,
	dragFlip: props.dragFlip,
	rowId: props.rowId,
	widgetChain: props.widgetChain,
	style: props.style,
	onClick: props.onClick,
	colSizes: props.colSizes,
});

/**
	Фильтрует пропсы, оставляет только те пропсы, которые нужны
	обычному компоненту блока. Чтобы служебные пропсы (нужные только для конструктора)
	не долетели до компонента, используемого на опубликованном сайте.
 */
const withFilter: THOCFilter = Component => {
	const Filter = (props, ref) => {
		const propsNew: THOCFilterPropsOut = filterProps(props);
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...propsNew} ref={ref} />;
	};

	Filter.displayName = `withFilter(${getDisplayName(Component)})`;

	return React.memo<THOCFilterProps>(React.forwardRef(Filter));
};

export default withFilter;
