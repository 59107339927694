// @flow
import _ from 'lodash/fp';
import { takeEvery, put, select, fork, call } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import { commitSpecsToBd, commitWidgetsToBd } from 'libs/firebase';

import reSelect from 'libs/re-select';
import logger from '@graphite/logger';
import type {
	TId,
	TAction,
	TStateEditor,
	TWidgets,
	THistory,
	THistoryData,
	THistoryUpdated,
	THistoryItem,
	TSpecs,
} from '@graphite/types';
import type { Saga } from 'redux-saga';
import { apply } from './editor';

/* экшены для вызова из внешнего кода */
const HISTORY_EMPTY = 'HISTORY/HISTORY_EMPTY';
const HISTORY_NEW_ACTION = 'HISTORY/NEW_ACTION';
const HISTORY_BACK = 'HISTORY/BACK';
const HISTORY_FORWARD = 'HISTORY/FORWARD';

/* внутренние экшены, работающие с редюсером history */
const HISTORY_SLICE = 'HISTORY/SLICE';
const HISTORY_PUSH = 'HISTORY/PUSH';
const HISTORY_REDO = 'HISTORY/REDO';
const HISTORY_UNDO = 'HISTORY/UNDO';

/* список отслеживаемых св-в в стейте для записи bd в операциях UNDO REDO */
const TARGET_UNDO_REDO_LIST = { specs: commitSpecsToBd, widgets: commitWidgetsToBd };

export const historyEmpty = (): TAction => ({
	type: HISTORY_EMPTY,
	payload: {},
});

export const historyNewAction = (data: THistoryUpdated): TAction => ({
	type: HISTORY_NEW_ACTION,
	payload: { data },
});

export const historyForward = (): TAction => ({
	type: HISTORY_FORWARD,
	payload: {},
});

export const historyBack = (): TAction => ({
	type: HISTORY_BACK,
	payload: {},
});

export const historyPush = (data: THistoryItem): TAction => ({
	type: HISTORY_PUSH,
	payload: { data },
});

export const historySlice = (data: THistoryItem, sliceEnd: number): TAction => ({
	type: HISTORY_SLICE,
	payload: { data, sliceEnd },
});

export const historyRedo = (cursorChange: boolean): TAction => ({
	type: HISTORY_REDO,
	payload: { cursorChange },
});

export const historyUndo = (cursorChange: boolean): TAction => ({
	type: HISTORY_UNDO,
	payload: { cursorChange },
});

export const getStoreByName = reSelect<
	{ widgets: TWidgets },
	string,
	TWidgets | TSpecs,
	TWidgets | TSpecs,
>(
	(state: { widgets: TWidgets }, storeName: string): TWidgets | TSpecs =>
		state[storeName],
	(store: TWidgets | TSpecs): TWidgets | TSpecs => store,
)(
	(state: { widgets: TWidgets }, storeName: string): string =>
		`getStoreByName-${storeName}`,
);

export const getHistoryState = reSelect<TStateEditor, THistory, THistory>(
	(state: TStateEditor): THistory => state.constructorHistory,
	(history: THistory): THistory => history,
)((): string => 'getHistoryState');

export function* historyNewActionSaga(): Saga<void> {
	yield takeEvery(HISTORY_NEW_ACTION, function*({
		payload,
	}: {
		payload: THistoryData,
	}): Saga<void> {
		const { data } = payload;
		const state = yield select(getHistoryState);
		const { history, cursor, forward } = state;

		const affectedStores: THistoryUpdated = {};
		const dataBeforeUpdate: THistoryUpdated = {};
		for (const storeName in data) {
			if (Object.prototype.hasOwnProperty.call(data, storeName)) {
				affectedStores[storeName] = yield select(getStoreByName, storeName);

				dataBeforeUpdate[storeName] = {};
				for (const id in data[storeName]) {
					const value = data[storeName][id];
					if (affectedStores[storeName] && dataBeforeUpdate[storeName]) {
						dataBeforeUpdate[storeName][value._id] =
							affectedStores[storeName][value._id];
					}
				}
			}
		}

		if (cursor === history.length - 1 && forward) {
			/* Push в history */
			yield put(historyPush({ before: dataBeforeUpdate, after: data }));
		} else {
			/* Детач ветки истории с новыми элементами */
			let sliceEnd = cursor;
			if (forward) {
				sliceEnd = cursor + 1;
			}
			yield put(historySlice({ before: dataBeforeUpdate, after: data }, sliceEnd));
		}

		yield put(apply(data));
	});
}

export function* historyRedoSaga(): Saga<void> {
	yield takeEvery(HISTORY_FORWARD, function*(): Saga<void> {
		const state = yield select(getHistoryState);
		const { history, cursor, forward } = state;

		let redoData;
		let cursorChange;

		if (forward && cursor + 1 >= history.length) {
			return;
		}
		if (forward) {
			redoData = history[cursor + 1].after;
			cursorChange = true;
		} else {
			redoData = history[cursor].after;
			cursorChange = false;
		}

		for (const target of Object.keys(TARGET_UNDO_REDO_LIST)) {
			const update = redoData?.[target];
			if (_.size(update)) {
				Object.keys(update).forEach((key: TId) => {
					update[key].updatedAt = new Date().toISOString();
				});
				yield call(TARGET_UNDO_REDO_LIST[target], update);
			}
		}
		yield put(historyRedo(cursorChange));
		yield put(apply(redoData));
		logger.info('history', { type: 'redo' });
	});
}

export function* historyUndoSaga(): Saga<void> {
	yield takeEvery(HISTORY_BACK, function*(): Saga<void> {
		const state = yield select(getHistoryState);
		const { history, cursor, forward } = state;
		let undoData;
		let cursorChange;

		if (cursor < 0 || (cursor === 0 && !forward)) {
			return;
		}
		if (forward) {
			undoData = history[cursor].before;
			cursorChange = false;
		} else {
			undoData = history[cursor - 1].before;
			cursorChange = true;
		}

		for (const target of Object.keys(TARGET_UNDO_REDO_LIST)) {
			const update = undoData?.[target];
			const current = yield select(getStoreByName, target);

			if (_.size(update)) {
				// в случ. если откатываем новый виджет в старом стейте его нет
				Object.keys(update).forEach((key: TId) => {
					if (!update[key]) {
						update[key] = {
							...current[key],
							removedAt: new Date().toISOString(),
						};
					}
					// для того что бы коллаборация отработала
					update[key].updatedAt = new Date().toISOString();
				});

				yield call(TARGET_UNDO_REDO_LIST[target], update);
			}
		}
		yield put(historyUndo(cursorChange));
		yield put(apply(undoData));
		logger.info('history', { type: 'undo' });
	});
}

export function* saga(): Saga<void> {
	yield fork(historyNewActionSaga);
	yield fork(historyRedoSaga);
	yield fork(historyUndoSaga);
}

// state.history
const initialState: THistory = {
	history: [],
	cursor: -1,
	// direction
	forward: true,
};

// type: enum['widgets', 'specs', ... ]

export default handleActions<$ReadOnly<THistory>, TAction>(
	{
		[HISTORY_EMPTY](): $ReadOnly<THistory> {
			return initialState;
		},
		[HISTORY_PUSH](
			state: $ReadOnly<THistory>,
			{ payload: { data } }: { +payload: { +data: THistoryItem } },
		): $ReadOnly<THistory> {
			// push
			return {
				...state,
				history: [...state.history, { ...data }],
				cursor: state.cursor + 1,
				forward: true,
			};
		},
		[HISTORY_SLICE](
			state: $ReadOnly<THistory>,
			{
				payload: { data, sliceEnd },
			}: { +payload: { data: THistoryItem, sliceEnd: number } },
		): $ReadOnly<THistory> {
			const newHistoryBranch = state.history.slice(0, sliceEnd);
			const cursor = newHistoryBranch.length;

			// push
			return {
				...state,
				history: [...newHistoryBranch, data],
				cursor,
				forward: true,
			};
		},
		[HISTORY_REDO](
			state: $ReadOnly<THistory>,
			{ payload: { cursorChange } }: { +payload: { cursorChange: boolean } },
		): $ReadOnly<THistory> {
			return {
				...state,
				cursor: cursorChange ? state.cursor + 1 : state.cursor,
				forward: true,
			};
		},
		[HISTORY_UNDO](
			state: $ReadOnly<THistory>,
			{ payload: { cursorChange } }: { +payload: { cursorChange: boolean } },
		): $ReadOnly<THistory> {
			return {
				...state,
				cursor: cursorChange ? state.cursor - 1 : state.cursor,
				forward: false,
			};
		},
	},
	initialState,
);
