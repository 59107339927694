// @flow
import React from 'react';
import _ from 'lodash/fp';

import getDisplayName from '@graphite/get-display-name';
import Poper from 'Widget/Poper';
import { closestDeviceWithKey } from '@graphite/selectors';

import { editWidget } from 'Editor/ducks/widgets';
import { ContextSetActiveLevel } from 'Editor/libs/with-symbiote/Provider';
import type { TPaddingDevice, TWidgetBox, TWidgetBoxBreakpoint } from '@graphite/types';

import Resize from '../Resize';
import PoperTitle from '../PoperTitle';
import type {
	TMinimalPropsWithResize,
	TResizeUpdateCallback,
	TResizeStartCallback,
	TResizeStopCallback,
	TStateUseResize,
	TWidgetBlock,
} from '../constants/types';

const FPS = 16; // 60фпс
const zeroPadding: TPaddingDevice = { top: 0, bottom: 0 };

const withResize = <
	TProps: TMinimalPropsWithResize,
	WrapedComponent: React$ComponentType<TProps>,
>(
	Component: WrapedComponent,
): React$ComponentType<TProps> => {
	const WithResize = (props: TProps) => {
		const {
			data,
			setData,
			currentDevice,
			dispatch,
			originId,
			instanceId,
			hovered,
			controls,
		} = props;

		const setActiveLevel = React.useContext(ContextSetActiveLevel);

		const isActive: boolean = hovered && controls !== 'none';

		const [dir, setDir] = React.useState<TStateUseResize>(() => null);

		const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
			currentDevice,
			key: `box-${data._id}`,
		});
		const padding: TPaddingDevice = React.useMemo(() => {
			if (!box.padding) {
				return zeroPadding;
			}
			return {
				...zeroPadding,
				...box.padding,
			};
		}, [box.padding]);

		const dataInitial = React.useRef<TWidgetBlock>(data);
		const paddingCurrent = React.useRef<TPaddingDevice>(padding);

		React.useEffect(() => {
			paddingCurrent.current = padding;
		}, [padding]);

		const onResizeStart: TResizeStartCallback = React.useCallback(
			dir => {
				dataInitial.current = data;
				paddingCurrent.current = padding;
				setDir(dir);
				setActiveLevel('block-padding-resize');
			},
			[setDir, setActiveLevel, data, padding],
		);

		const onResize: TResizeUpdateCallback = React.useMemo(
			() =>
				_.throttle(FPS, (dir, delta) => {
					const data: TWidgetBlock = dataInitial.current;
					const paddingNew: TPaddingDevice = (_.set(
						dir,
						Math.max(padding[dir] + delta, 0),
						padding,
					): TPaddingDevice);

					const dataNew: TWidgetBlock = {
						...data,
						style: {
							paddingTop: `${paddingNew.top}px`,
							paddingBottom: `${paddingNew.bottom}px`,
						},
					};

					paddingCurrent.current = paddingNew;
					setData(dataNew);
				}),
			[padding, setData],
		);

		const onResizeStop: TResizeStopCallback = React.useCallback(() => {
			if (!originId) return;
			const paddingNew: TPaddingDevice = paddingCurrent.current;
			const initialData: TWidgetBlock = dataInitial.current;
			dispatch(
				editWidget(initialData._id, instanceId, originId, {
					box: (_.set(
						`${currentDevice}.padding`,
						paddingNew,
						initialData.box,
					): TWidgetBox),
				}),
			);

			setDir(null);
			setActiveLevel(null);
		}, [originId, dispatch, instanceId, currentDevice, setActiveLevel]);

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} data={data} />
				{(isActive || !!dir) && (
					<Resize
						padding={paddingCurrent.current}
						onResize={onResize}
						onResizeStart={onResizeStart}
						onResizeStop={onResizeStop}
					/>
				)}
				{(dir === 'top' || dir === 'bottom') && (
					<Poper>
						<PoperTitle padding={paddingCurrent.current[dir]} />
					</Poper>
				)}
			</>
		);
	};

	WithResize.displayName = `withResize(${getDisplayName(Component)})`;

	return React.memo<TProps>(WithResize);
};

export default withResize;
