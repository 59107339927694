// @flow
import React from 'react';
import { Flex, Text } from '@graphite/uneon';
import ButtonPosition from './ButtonPosition';
import { PARAMS } from './constants';
import type { TPropsAlignItems } from './types';

const AlignItems = ({
	t,
	direction,
	alignItems,
	onChange,
	alignSelf,
}: TPropsAlignItems) => (
	<Flex>
		<ButtonPosition
			name={alignItems}
			iconName={PARAMS[direction][alignItems].icon}
			isActive={!alignSelf}
			onChange={onChange}
			color="tertiaryflat"
		>
			{t('Default')}&nbsp;&nbsp;
			<Text as="span" color="spec.lightblue80">
				{t('from container')}
			</Text>
		</ButtonPosition>
	</Flex>
);

export default React.memo<TPropsAlignItems>(AlignItems);
