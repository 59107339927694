// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box } from '@graphite/uneon';
import { getBoxSx } from '@graphite/selectors';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import type { TSx } from '@graphite/types';

import type { TConnectProps } from './constants/types';

const Spacer = (props: TConnectProps, ref) => {
	const { dragContainer, dragFlip, data, position, gridspec } = props;

	const boxSx = gridspec ? getBoxSx({ data, position, gridspec }) : emptyObject;

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const otherProps = _.assign(dragContainer, dragFlip);

	return (
		<Box
			id={data.id}
			className={data.className}
			ref={ref}
			style={dynamicStyle}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...otherProps}
			sx={boxSx}
		/>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Spacer));
