// @flow
import 'firebase/remote-config';
import firebase from '../firebase';

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
	minimumFetchIntervalMillis: 3600000,
	fetchTimeoutMillis: 10 * 60 * 1000,
};

type TConfig = $ReadOnly<{|
	sentryDNS: ?string,
	gtmId: ?string,
	logRocketId: ?string,
	mixpanelId: ?string,
	yandexMetrikaId: ?string,
	apiKeyAmplitudeKey: ?string,
|}>;

remoteConfig.defaultConfig = ({
	sentryDNS: null,
	gtmId: null,
	logRocketId: null,
	mixpanelId: null,
	yandexMetrikaId: null,
	apiKeyAmplitudeKey: null,
}: TConfig);

type TGetConfig = () => Promise<TConfig>;
export const getConfig: TGetConfig = async () => {
	await remoteConfig.fetchAndActivate();
	return {
		sentryDNS:
			remoteConfig.getString('sentryDNS') === 'null'
				? null
				: remoteConfig.getString('sentryDNS'),
		gtmId:
			remoteConfig.getString('gtmId') === 'null'
				? null
				: remoteConfig.getString('gtmId'),
		logRocketId:
			remoteConfig.getString('logRocketId') === 'null'
				? null
				: remoteConfig.getString('logRocketId'),
		mixpanelId:
			remoteConfig.getString('mixpanelId') === 'null'
				? null
				: remoteConfig.getString('mixpanelId'),
		yandexMetrikaId:
			remoteConfig.getString('yandexMetrikaId') === 'null'
				? null
				: remoteConfig.getString('yandexMetrikaId'),
		apiKeyAmplitudeKey:
			remoteConfig.getString('apiKeyAmplitudeKey') === 'null'
				? null
				: remoteConfig.getString('apiKeyAmplitudeKey'),
	};
};

remoteConfig.fetchAndActivate();

export default remoteConfig;
