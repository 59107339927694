// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import type { TAnimation } from '@graphite/types';
import UnitInputs from './UnitInputs';

type TProps = $ReadOnly<{|
	t: string => string,
	prop: string,
	params: TAnimation,
	changeEffect: TAnimation => void,
	removeEffect?: string => void,
|}>;

const PropItemComponent = ({ t, prop, params, changeEffect, removeEffect }: TProps) => {
	const btnRemoveEffect = React.useMemo(
		() =>
			removeEffect && {
				buttons: [
					{
						name: 'remove',
						icons: [
							{
								title: t('Remove effect'),
								name: 'minus',
								iconSize: 18,
							},
						],
						colors: 'primary',
					},
				],
			},
		[t, removeEffect],
	);

	const handleRemoveEffect = React.useCallback(() => {
		if (removeEffect) removeEffect(prop);
	}, [removeEffect, prop]);

	return (
		<>
			<Section
				size="sm"
				label={t(prop)}
				buttonGroup={btnRemoveEffect}
				onClick={handleRemoveEffect}
			>
				<UnitInputs prop={prop} params={params} changeEffect={changeEffect} />
			</Section>
		</>
	);
};

export default React.memo<TProps>(PropItemComponent);
