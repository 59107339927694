// @flow
import { takeEvery, put, fork, select } from 'redux-saga/effects';
import { type Saga } from 'redux-saga';
import { handleActions } from 'redux-actions';
import set from 'lodash/fp/set';

import { moveWidget as moveWidgetOriginal } from 'Editor/ducks/widgets';
import { getEditor } from 'Editor/selectors/editor';
import { getCurrentSiteBreakpoints, getCurrentPageId } from '@graphite/selectors';
import logger from '@graphite/logger';
import type {
	TAction,
	TId,
	TSpecsGridBreakpoints,
	TEditor,
	TOffsetDevice,
} from '@graphite/types';
import { type TStateTopBarLayers } from './constants/types';

const MOVE_WIDGET = 'EDITOR_TOPBAR_LAYERS/MOVE_WIDGET';
const VISIBILITY_TOGGLE = 'EDITOR_TOPBAR_LAYERS/VISIBILITY_TOGGLE';

export type TMoveWidgetData = $ReadOnly<{|
	node: {
		_id: TId,
		prevId: ?TId,
		nextId: ?TId,
		containerId: ?TId,
		position: ?string,
		offset: ?TOffsetDevice,
	},
	srcContainerId: ?TId,
	newRow: boolean,
	nextNode?: ?{
		_id: TId,
		prevId: ?TId,
		nextId: ?TId,
		containerId: ?TId,
	},
	prevNode?: ?{
		_id: TId,
		prevId: ?TId,
		nextId: ?TId,
		containerId: ?TId,
	},
|}>;

export const moveWidget = (data: TMoveWidgetData): TAction => ({
	type: MOVE_WIDGET,
	payload: {
		data,
	},
});

export const visibilityToggle = ({
	node: { _id },
	expanded,
}: {
	node: { _id: string },
	expanded: boolean,
}): TAction => ({
	type: VISIBILITY_TOGGLE,
	payload: {
		_id,
		expanded,
	},
});

export const makeAbsolute = (
	targetId: string,
	originId: string,
	containerId: string,
	position: ?string,
): TAction => ({
	// FixMe Переделать после того как эта ветка смержится с UPRO-137
	type: 'WIDGETS/REPOSITION_WIDGET',
	payload: {
		targetId,
		originId,
		containerId,
		position: position === 'absolute' ? null : 'absolute',
	},
});

export function* moveWidgetSaga(): Saga<void> {
	yield takeEvery(MOVE_WIDGET, function*({
		payload: { data },
	}: {
		payload: {
			data: TMoveWidgetData,
		},
	}): Saga<void> {
		try {
			const editor: TEditor = yield select(getEditor);
			const currentPage: ?TId = yield select(getCurrentPageId);
			const breakpoints: TSpecsGridBreakpoints = yield select(
				getCurrentSiteBreakpoints,
			);

			if (!currentPage) throw new Error('Could not find currentPage');

			const { prevNode, nextNode, node, srcContainerId, newRow } = data;
			yield put(
				moveWidgetOriginal(
					node._id, // ид ноды
					srcContainerId || currentPage, // ид контенера
					node.containerId || currentPage, // след контенер
					null,
					currentPage,
					node.position === 'absolute' && node.offset
						? {
								// TODO: need recount x and y into new position
								kind: 'absolute',
								destX: node.offset.left || 0,
								destY: node.offset.top || 0,
								destWidth: node.offset.width,
								destHeight: node.offset.height,
								srcWidth: node.offset.width,
								srcHeight: node.offset.height,
								prevId: prevNode?._id || null,
								nextId: nextNode?._id || null,
						  }
						: {
								kind: 'grid',
								destRect: null,
								dragRect: null,
								breakpoints,
								prevId: prevNode?._id || null,
								nextId: nextNode?._id || null,
								row: newRow ? 'new' : null,
						  },
					editor.currentDevice,
				),
			);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(moveWidgetSaga);
}

export default handleActions<TStateTopBarLayers, TAction>(
	{
		[VISIBILITY_TOGGLE](
			state,
			{
				payload: { _id, expanded },
			}: { +payload: { _id: string, expanded: boolean } },
		) {
			return set(`expandeds.${_id}`, expanded, state);
		},
	},
	{
		expandeds: {},
	},
);
