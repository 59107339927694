// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import getDisplayName from '@graphite/get-display-name';

import type { TConnectProps } from '../constants/types';

const withMeta = (Component: React$ComponentType<TConnectProps>) => {
	const Meta = (props: TConnectProps, ref) => {
		const { title, description, keywords } = props.data;

		return (
			<>
				<Helmet>
					{title && <title>{title}</title>}
					{description && <meta name="description" content={description} />}
					{keywords && <meta name="Keywords" content={keywords} />}
					<meta charset="utf-8" />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta httpEquiv="X-UA-Compatible" content="ie=edge" />
				</Helmet>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} ref={ref} />
			</>
		);
	};

	Meta.displayName = `withMeta(${getDisplayName(Component)})`;

	return React.memo<TConnectProps>(React.forwardRef(Meta));
};

export default withMeta;
