// @flow
import React from 'react';
import type { TTransition } from '@graphite/types';

type TTransitionParams = $ReadOnly<{|
	transition: TTransition,
	loop: TTransition,
	filter: TTransition,
	sensetivity: TTransition,
|}>;

export default (params: TTransition): TTransitionParams => {
	const transitionLoopParams: TTransition = React.useMemo(() => {
		const { loop, repeatType, delay } = params;

		if (loop)
			return {
				repeat: Infinity,
				repeatType,
				repeatDelay: delay || 0,
			};

		return {};
	}, [params]);

	const transitionFilterParams: TTransition = React.useMemo(() => {
		if (params.type === 'tween') {
			const { type, duration, delay, ease } = params;

			return {
				type,
				duration,
				delay,
				ease,
			};
		}

		if (params.type === 'spring') {
			const { type, damping, stiffness, bounce, mass, delay } = params;

			return {
				type,
				damping,
				stiffness,
				bounce,
				mass,
				delay,
			};
		}

		return {};
	}, [params]);

	const sensetivity: TTransition = React.useMemo(() => {
		const { triggerOnce, threshold } = params;

		return {
			triggerOnce,
			threshold: threshold ? parseInt(threshold, 10) / 100 : 0,
		};
	}, [params]);

	const transition: TTransition = React.useMemo(
		() => ({
			...transitionLoopParams,
			...transitionFilterParams,
		}),
		[transitionFilterParams, transitionLoopParams],
	);

	return {
		loop: transitionLoopParams,
		filter: transitionFilterParams,
		transition,
		sensetivity,
	};
};
