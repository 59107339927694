// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import { Themes as ListThemes } from '@graphite/lists';
import type { TId, TSpecsColor } from '@graphite/types';

type TProps = $ReadOnly<{|
	label: string,
	active: ?TId,
	colorspec: TSpecsColor,
	onChange?: ?(?TId) => void,
	t: string => string,
|}>;

function SectionTheme({ label, active, colorspec, onChange = null, t }: TProps) {
	const selectTheme = React.useCallback(
		id => {
			if (onChange) {
				onChange(id);
			}
		},
		[onChange],
	);

	return (
		<Section label={label}>
			<ListThemes
				hasNull
				colorspec={colorspec}
				activeTheme={active}
				onClick={selectTheme}
				t={t}
			/>
		</Section>
	);
}

export default React.memo<TProps>(SectionTheme);
