// @flow

import { useEffect, useRef, useState, useCallback } from 'react';
import _ from 'lodash/fp';
import type { TUnsplashImage, TUnsplashImages } from '@graphite/types';

import { getUnsplash, type TImgsUnsplash } from '../Unsplash/driver';

const MIN_IMAGE_HEIGHT = 75;
const MAX_PAGE_NUMBER = 50;

type TOptions = $ReadOnly<{|
	searchLine?: ?string,
	imgsCount: number,
	columnWidth: number,
	spacer: number,
|}>;

export type TOutUnsplash = $ReadOnly<{|
	listImgs: TUnsplashImages,
	loadMore: () => void,
	isWarn: boolean,
	isProcessing: boolean,
	height: number,
|}>;

export const useUnsplash = ({
	searchLine = null,
	columnWidth,
	imgsCount,
	spacer,
}: TOptions): TOutUnsplash => {
	const [listImgs, setListImgs] = useState<TUnsplashImages>([]);
	const [page, setPage] = useState<number>(1);

	const currentSearch = useRef<?string>(null);

	const [height, setHeight] = useState<number>(0);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [isWarn, setIsWarn] = useState<boolean>(false);

	const heightLeft = useRef(0);
	const heightRight = useRef(0);

	const loadMore = useCallback(() => {
		if (isProcessing) return;
		setIsProcessing(true);
		if (MAX_PAGE_NUMBER >= page + 1) setPage(page + 1);
	}, [isProcessing, page]);

	const setImgs = useCallback(
		imgs => {
			const preparedImgs = _.map((img): TUnsplashImage => {
				const heightImg = Math.max(
					Math.floor(img.height / (img.width / columnWidth)),
					MIN_IMAGE_HEIGHT,
				);

				// Тут решается с каком столбце визуально будет картинка
				// Предпочтительно будет добавляться в левую часть
				// Считаем позицию для transform
				const side = heightLeft.current > heightRight.current ? 'right' : 'left';
				let left = 0;
				let top = 0;

				if (side === 'left') {
					left = columnWidth + spacer;
					top = heightLeft.current;

					heightLeft.current = heightLeft.current + heightImg + spacer;
				}
				if (side === 'right') {
					left = 0;
					top = heightRight.current;

					heightRight.current = heightRight.current + heightImg + spacer;
				}

				return {
					src: img.urls.thumb,
					key: img.id,
					srcOrig: img.urls.regular,
					heightImg,
					author: {
						name: img.user.name || img.user.username,
						link: img.user.links.html || img.user.links.self,
					},
					widthImg: columnWidth,
					caption: img?.alt_description,
					top,
					left,
					downloadLocation: img.links.download_location,
				};
			}, imgs);

			setListImgs(oldList => [...oldList, ...preparedImgs]);
		},
		[columnWidth, spacer],
	);

	useEffect(() => {
		let isMounted: boolean = true;
		setIsProcessing(true);

		async function fetchImgs() {
			try {
				if (!isMounted) {
					return;
				}

				if (currentSearch.current !== searchLine) {
					currentSearch.current = searchLine;
					setListImgs([]);
					setPage(1);

					heightLeft.current = 0;
					heightRight.current = 0;
				}

				const imgs: TImgsUnsplash = await getUnsplash(
					imgsCount,
					searchLine,
					page,
				);

				if (!isMounted) {
					return;
				}

				setImgs(imgs);
				setIsWarn(false);

				setIsProcessing(false);
			} catch (e) {
				if (!isMounted) {
					return;
				}
				setIsWarn(true);
				setIsProcessing(false);
			}
		}

		fetchImgs();

		return () => {
			isMounted = false;
		};
	}, [imgsCount, searchLine, page, setImgs]);

	useEffect(() => {
		// Как только мы получили картинки и их размеры
		// устанавливаем высоту контейнера
		setHeight(Math.max(heightLeft.current, heightRight.current));
	}, [listImgs]);

	return { listImgs, loadMore, isWarn, isProcessing, height };
};

export default useUnsplash;
