// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { Section } from '@graphite/uneon';
import { emptySelection } from '@graphite/constants';
import useBreakpoint from '@graphite/use-breakpoint';
import { Params as ListParams } from '@graphite/lists';
import type { TDesignStyleProps, TParams } from '@graphite/types';

const paramList: TParams = [
	{
		title: 'Radius',
		key: 'radius',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
	{
		title: 'Border',
		key: 'border',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
];

function Style({
	design,
	gridspec: { unit },
	effectspec,
	device,
	onChange = null,
}: TDesignStyleProps) {
	const { paramSource, changeParam } = useBreakpoint(onChange, design, device);
	const { t } = useTranslation();

	const paramListFinal: TParams = React.useMemo(
		() =>
			_.set(
				[1, 'info', 'list', 'items'],
				[
					emptySelection,
					...effectspec.borders.map(border => ({
						name: border.id,
						label: border.name,
					})),
				],
				_.set(
					[0, 'info', 'list', 'items'],
					[
						emptySelection,
						...effectspec.radii.map(radius => ({
							name: radius.id,
							label: radius.name,
						})),
					],
					paramList,
				),
			),
		[effectspec.borders, effectspec.radii],
	);

	return (
		<Section label={t('Style')}>
			<ListParams
				listName={`${device}`}
				paramSource={paramSource}
				paramList={paramListFinal}
				unit={unit}
				onChange={changeParam}
			/>
		</Section>
	);
}

export default React.memo<TDesignStyleProps>(Style);
