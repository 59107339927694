// @flow
import React from 'react';
import { Flex, Text } from '@graphite/uneon';
import ButtonPosition from './ButtonPosition';
import { PARAMS } from './constants';
import type { TPropsAlignSelf } from './types';

const wrapSx = {
	flexGrow: 1,
	justifyContent: 'center',
};

const params = ['flex-start', 'center', 'flex-end', 'baseline', 'stretch'];

const tooltipSx = {
	textTransform: 'capitalize',
};

const AlignSelf = ({ t, alignSelf, direction, onChange }: TPropsAlignSelf) => {
	const currentAlignSelf = React.useMemo(() => {
		// для baseline в row, что бы кнопка активная была
		if (alignSelf === 'baseline' && direction === 'vertical') return 'flex-start';
		return alignSelf;
	}, [direction, alignSelf]);

	return (
		<Flex sx={wrapSx}>
			{params.map(name => {
				// baseline для row не нужен, т.к дублирует left
				if (name === 'baseline' && direction === 'vertical') return null;

				return (
					<ButtonPosition
						key={name}
						name={name}
						iconName={PARAMS[direction][name].icon}
						isActive={currentAlignSelf === name}
						onChange={onChange}
					>
						<Text as="span" sx={tooltipSx}>
							{t(PARAMS[direction][name].tooltip)}
						</Text>
					</ButtonPosition>
				);
			})}
		</Flex>
	);
};

export default React.memo<TPropsAlignSelf>(AlignSelf);
