// @flow
import React from 'react';
import { m, useAnimation } from 'framer-motion';
import { baseSx } from './constants';
import { AnimatePlayContext } from './libs/animatePlayReducer';
import useAnimatePlay from './libs/useAnimatePlay';
import useTransition from './libs/useTransition';
import { filterProp } from './libs/selectors';

type TProps = $ReadOnly<{|
	children: React$Node,
|}>;

const PlayComponent = (props: TProps) => {
	const { children } = props;
	const animationAction = useAnimation();

	const [{ animation, isPlay }] = React.useContext(AnimatePlayContext);
	const action = useAnimatePlay();

	const variants = React.useMemo(
		() => ({
			from: filterProp(animation.from),
			to: filterProp(animation.to),
		}),
		[animation],
	);

	const onAnimationComplete = React.useCallback(() => {
		if (isPlay) action.onStop();
	}, [action, isPlay]);

	const forceKey = React.useMemo(
		() => Math.random(),

		// Это нужно для перерендера вариантов
		// eslint-disable-next-line
		[variants],
	);

	React.useEffect(() => {
		if (isPlay) animationAction.start('to');
		else animationAction.start('from');
	}, [animationAction, isPlay]);

	const { transition, filter } = useTransition(animation.transition || {});

	return (
		<m.div
			key={forceKey}
			variants={variants}
			initial="from"
			animate={animationAction}
			style={baseSx}
			data-kind="play-animation"
			onAnimationComplete={onAnimationComplete}
			transition={isPlay ? transition : filter}
		>
			{children}
		</m.div>
	);
};

export default React.memo<TProps>(PlayComponent);
