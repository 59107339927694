// @flow
import React, { memo, useMemo, useCallback } from 'react';
import { Flex, Box } from 'rebass';
import _ from 'lodash/fp';
import type {
	TListItemEmbed,
	TButtonGroupEmbed,
	TListItemOnClick,
} from '@graphite/types';

import { namedColors } from '../constants';
import ButtonGroup from '../ButtonGroup';

type TProps = $ReadOnly<{|
	children?: ?React$Node,
	...TListItemEmbed,
	onClick?: ?TListItemOnClick,
|}>;

const heights = {
	md: {
		height: '36px',
	},
	lg: {
		height: '42px',
	},
	xlg: {
		height: '48px',
	},
	xxlg: {
		height: '54px',
	},
	xxxlg: {
		height: '60px',
	},
	auto: {
		height: 'auto',
	},
};

const buttonSizes = {
	md: 'sm',
	lg: 'md',
	xlg: 'lg',
	xxlg: 'lg',
	xxxlg: 'lg',
	auto: 'lg',
};

const containerStyle = {
	justifyContent: 'space-between',
	padding: '0  24px',
	margin: '0 -24px',
	width: 'calc(100% + 48px)',
	alignItems: 'center',
};

const boxSx = {
	margin: '0 12px 0 18px',
};

const opacityStyle = {
	'& .js-opacity-receiver': {
		transitionTimingFunction: 'cubic-bezier(.25, .46, .45, .94)',
		transitionDuration: '0.25s',
		transitionProperty: 'opacity',
		opacity: 0,
	},
	'&:hover .js-opacity-receiver': {
		opacity: 1,
	},
};

const buttonsStyle = {
	flexGrow: 0,
	marginRight: '-21px',
};

const ListItem = ({
	children = null,
	name = null,
	size = 'lg',
	colors = 'primaryflat',
	isActive = false,
	isDisabled = false,
	isExplicit = false,
	buttonGroup = null,
	sx = null,
	style = null,
	onClick = null,
}: TProps) => {
	const clickBound = useCallback(
		(e) => {
			if (isDisabled) return;
			if (onClick) onClick(e, name);
		},
		[onClick, name, isDisabled],
	);

	const clickButtonBound = useCallback(
		(e, buttonName) => {
			if (onClick) {
				// Если этого не сделать, то кликнется сразу ещё и ListItem
				e.stopPropagation();
				onClick(e, name, buttonName);
			}
		},
		[onClick, name],
	);

	const { bg, bghover, bgfocus, bgactive } =
		(colors !== null && typeof colors === 'object' && colors) ||
		(typeof colors === 'string' && namedColors[colors]) ||
		namedColors.primary;

	const containerThemedStyle = useMemo(
		() =>
			_.assignAll([
				heights[size],
				containerStyle,
				{
					backgroundColor: isActive ? bghover : bg,
				},
				sx,
				{ opacity: isDisabled ? 0.4 : 1 },
				(!isActive && {
					':hover': _.assign(
						{ backgroundColor: bghover },
						sx &&
							typeof sx[':hover'] === 'object' &&
							!Array.isArray(sx[':hover'])
							? sx[':hover']
							: null,
					),
					':focus': _.assign(
						{ backgroundColor: bgfocus },
						sx &&
							typeof sx[':focus'] === 'object' &&
							!Array.isArray(sx[':focus'])
							? sx[':focus']
							: null,
					),
					':active': _.assign(
						{ backgroundColor: bgactive },
						sx &&
							typeof sx[':active'] === 'object' &&
							!Array.isArray(sx[':active'])
							? sx[':active']
							: null,
					),
				}) ||
					null,
				(!isExplicit && opacityStyle) || null,
			]),
		[bg, bgactive, bgfocus, bghover, isActive, size, sx, isExplicit, isDisabled],
	);

	const buttonGroupProps: ?TButtonGroupEmbed = useMemo(
		() =>
			(buttonGroup && {
				...buttonGroup,
				isDisabled: isDisabled || buttonGroup.isDisabled,
				size: buttonGroup.size || buttonSizes[size],
				variant: buttonGroup.variant || 'flat',
				sx: _.assign(buttonsStyle, buttonGroup.style),
			}) ||
			null,
		[buttonGroup, isDisabled, size],
	);

	return (
		<Flex
			data-kind={`uneon-list-item${name ? `-${name}` : ''}`}
			sx={containerThemedStyle}
			style={style}
			onClick={clickBound}
		>
			{children || <Box />}
			{buttonGroupProps && (
				<Box sx={boxSx} className="js-opacity-receiver">
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<ButtonGroup {...buttonGroupProps} onClick={clickButtonBound} />
				</Box>
			)}
		</Flex>
	);
};

export default memo<TProps>(ListItem);
