// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Section, Box, ListItem, ButtonGroup } from '@graphite/uneon';
import { emptySelection } from '@graphite/constants';
import useBreakpoint from '@graphite/use-breakpoint';
import { Params as ListParams } from '@graphite/lists';
import type {
	TActiveButtons,
	TUnit,
	TDesignStyleProps,
	TParams,
	TListParamsOnClick,
} from '@graphite/types';
import InputPosition from './InputPosition';

const absButtonSx = {
	position: 'absolute',
	top: '12px',
	right: '0',
};

const paramPadding = [
	{
		title: 'Padding',
		key: 'padding',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
];

const paramListContainer: TParams = [
	{
		title: 'Radius',
		key: 'radius',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
	{
		title: 'Border',
		key: 'border',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
];

const paramListText: TParams = [
	{
		title: 'Variant',
		key: 'textDesign',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
];

const detailButton = [
	{
		name: 'detail',
		icons: [
			{
				name: 'square-side',
				iconSize: 18,
			},
		],
	},
];

const paramListAll = [...paramListContainer, ...paramListText];

function Style({
	design,
	gridspec: { unit },
	widgetspec,
	effectspec,
	device,
	onChange = null,
}: TDesignStyleProps) {
	const { paramSource, changeParam } = useBreakpoint(onChange, design, device);

	const { padding } = paramSource;

	const paddingSides = _.get(['breakpoints', device, 'paddingSides'], design);

	const [isShowPaddingBlock, setShowPaddingBlock] = React.useState(!!paddingSides);

	const paramListContainerFinal: TParams = React.useMemo(
		() =>
			_.set(
				[1, 'info', 'list', 'items'],
				[
					emptySelection,
					...effectspec.borders.map(border => ({
						name: border.id,
						label: border.name,
					})),
				],
				_.set(
					[0, 'info', 'list', 'items'],
					[
						emptySelection,
						...effectspec.radii.map(radius => ({
							name: radius.id,
							label: radius.name,
						})),
					],
					paramListContainer,
				),
			),
		[effectspec.borders, effectspec.radii],
	);

	const paramListTextFinal: TParams = React.useMemo(
		() =>
			_.set(
				[0, 'info', 'list', 'items'],
				_.filter(design => !design.removedAt, widgetspec.text).map(text => ({
					name: text._id,
					label: text.name,
				})),
				paramListText,
			),
		[widgetspec.text],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key: string, name: ?TActiveButtons) => {
			if (!onChange || (name !== 'px' && name !== 'unit')) {
				return;
			}

			const unitNext: TUnit = name;
			const param = paramListAll.find(p => p.key === key);
			if (!param || param.kind !== 'unit' || !param.info.unitKey) {
				return;
			}

			const unitPath = ['breakpoints', device, param.info.unitKey];
			const unitPrev: TUnit = _.get(unitPath, design);
			if (unitPrev === unitNext) {
				return;
			}

			let next = _.set(unitPath, unitNext, design);

			const valuePath = ['breakpoints', device, param.key];

			const valuePrev = _.get(valuePath, next);
			if (unitNext === 'px') {
				next = _.set(valuePath, valuePrev * unit, next);
			} else if (unitNext === 'unit') {
				next = _.set(valuePath, Math.round(valuePrev / unit), next);
			}

			onChange(next);
		},
		[onChange, device, design, unit],
	);

	const changeDetail = React.useCallback(() => {
		setShowPaddingBlock(!isShowPaddingBlock);
	}, [isShowPaddingBlock]);

	const savePadding = React.useCallback(
		(side, value) => {
			if (!onChange) {
				return;
			}
			const next = _.set(
				['breakpoints', device, 'paddingSides', side],
				value,
				design,
			);
			onChange(next);
		},
		[design, device, onChange],
	);

	return (
		<Box>
			<Section label="Container">
				<Box sx={{ position: 'relative' }}>
					<ListParams
						listName={`${device}-p`}
						paramSource={paramSource}
						paramList={paramPadding}
						unit={1}
						onChange={changeParam}
					/>
					<ButtonGroup
						sx={absButtonSx}
						behavior="checkbox"
						buttons={detailButton}
						colors="tertiaryflat"
						activeColors="accentflat"
						variant="flat"
						active={isShowPaddingBlock ? ['detail'] : ''}
						onClick={changeDetail}
					/>
					{isShowPaddingBlock && (
						<ListItem>
							{['top', 'right', 'bottom', 'left'].map(side => (
								<InputPosition
									key={side}
									size={paddingSides?.[side]}
									defSize={padding.toString() || ''}
									side={side}
									onChange={savePadding}
								/>
							))}
						</ListItem>
					)}
				</Box>
				<ListParams
					listName={`${device}-c`}
					paramSource={paramSource}
					paramList={paramListContainerFinal}
					unit={unit}
					onChange={changeParam}
					onClick={clickUnit}
				/>
			</Section>

			<Section label="Textarea">
				<ListParams
					listName={`${device}-t`}
					paramSource={paramSource}
					paramList={paramListTextFinal}
					unit={unit}
					onChange={changeParam}
					onClick={clickUnit}
				/>
			</Section>
		</Box>
	);
}

export default React.memo<TDesignStyleProps>(Style);
