// @flow
import { takeEvery, put, fork, select, call, all } from 'redux-saga/effects';
import _ from 'lodash/fp';
import { handleActions } from 'redux-actions';
import logger from '@graphite/logger';
import { genId, storage, commitWidgetsToBd } from 'libs/firebase';

import { getCurrentSiteId, getWidget, getWidgets } from '@graphite/selectors';
import type {
	TId,
	TAction,
	TWidgets,
	TImageLibraryListUploads,
	TImageLibraryErrors,
	TSite,
} from '@graphite/types';
import type { Saga, PutEffect } from 'redux-saga';

import { apply } from './editor';

const AVAILABLE_FORMAT = ['jpeg', 'jpg', 'png', 'svg', 'webp', 'gif'];
const MAX_SIZE_FILE = 20 * 1024 * 1024;
const MAX_COUNT_FILE = 20;
const PRESET_FILE = {
	userId: null,
	kind: 'file',
	name: 'fileImage.png',
	display: 'preset',
	protoId: null,
	scope: 'system',
	scopeId: null,
	removedAt: null,
	alt: '',
	cropMode: 'fill',
	box: {
		desktop: {
			height: 400,
		},
	},
	src:
		// eslint-disable-next-line max-len
		'https://images.unsplash.com/photo-1505322715123-90e9aa1e2d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=640&q=80',
};
const INSERT = 'IMAGE_LIBRARY/INSERT';
const MULTI_INSERT = 'IMAGE_LIBRARY/MULTI_INSERT';
const REMOVE = 'IMAGE_LIBRARY/REMOVE';
const UPLOADING = 'IMAGE_LIBRARY/UPLOADING';
const UPLOADED = 'IMAGE_LIBRARY/UPLOADED';
const FAILED = 'IMAGE_LIBRARY/FAILDE';
const RESET = 'IMAGE_LIBRARY/RESET';

export const insertImages = (files: FileList): TAction => ({
	type: MULTI_INSERT,
	payload: { files },
});

export const insertImage = (file: File): TAction => ({
	type: INSERT,
	payload: { file },
});

export const removeImage = (imageId: TId): TAction => ({
	type: REMOVE,
	payload: { imageId },
});

export const uploading = (key: TId): TAction => ({
	type: UPLOADING,
	payload: { key },
});

export const uploaded = (key: TId): TAction => ({
	type: UPLOADED,
	payload: { key },
});

export const failed = (key: TId, error: ?string): TAction => ({
	type: FAILED,
	payload: { key, error },
});

export const reset = (): TAction => ({
	type: RESET,
	payload: {},
});

export function* insertImagesSaga(): Saga<void> {
	yield takeEvery(MULTI_INSERT, function*({
		payload: { files },
	}: {
		payload: {
			files: FileList,
		},
	}): Saga<void> {
		try {
			// reset last uploaded status
			yield put(reset());

			yield all(
				Object.keys(files).map((key: string): PutEffect<any, any, any> =>
					parseInt(key, 10) < MAX_COUNT_FILE
						? put(insertImage(files[parseInt(key, 10)]))
						: put(failed(genId('widgets'), 'limit')),
				),
			);
			logger.info('uploadImage');
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* insertImageSaga(): Saga<void> {
	yield takeEvery(INSERT, function*({
		payload: { file },
	}: {
		payload: {
			file: File,
		},
	}): Saga<void> {
		try {
			const siteId: TId = yield select(getCurrentSiteId);

			const site: TSite = yield select(getWidget, { id: siteId });

			const { userId } = site;

			if (!userId) {
				throw new Error('Dont find userId');
			}

			const imageId: TId = genId('widgets');
			const { name, size, type } = file;
			const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();

			if (
				!AVAILABLE_FORMAT.includes(ext) ||
				!type.includes('image') ||
				size > MAX_SIZE_FILE
			) {
				return yield put(
					failed(
						imageId,
						((!AVAILABLE_FORMAT.includes(ext) || !type.includes('image')) &&
							'format') ||
							(size > MAX_SIZE_FILE && 'size') ||
							'common',
					),
				);
			}
			// marking file that it uploading
			yield put(uploading(imageId));
			// upload in firestore
			const ref = storage.ref(`user/${userId}/${siteId}/images/${imageId}.${ext}`);
			// Upload on firestore
			yield call([ref, 'put'], file);
			// get link for img
			const src = yield ref.getDownloadURL();

			const data = {
				[imageId]: {
					...PRESET_FILE,
					userId,
					_id: imageId,
					kind: 'file',
					name,
					size,
					src,
					display: 'normal',
					protoId: null,
					removedAt: null,
					scope: 'site',
					scopeId: siteId,
					children: {},
					updatedAt: null,
				},
			};
			// Create widget with kind = file
			yield all([
				call(commitWidgetsToBd, data),
				put(apply({ widgets: data })),
				put(uploaded(imageId)),
			]);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* removeImageSaga(): Saga<void> {
	yield takeEvery(REMOVE, function*({
		payload: { imageId },
	}: {
		payload: { imageId: string },
	}): Saga<void> {
		try {
			const widgets: TWidgets = yield select(getWidgets);
			const siteId: ?TId = yield select(getCurrentSiteId);
			const { userId, name } = widgets[imageId];
			if (!siteId || !userId || !name) {
				throw new Error('Site or widget didnt find');
			}
			const updated: TWidgets = _.assign(widgets, {
				[imageId]: {
					...widgets[imageId],
					removedAt: new Date().toISOString(),
				},
			});
			yield all([
				call(commitWidgetsToBd, updated),
				put(apply({ widgets: updated })),
			]);
			logger.info('removeImage');
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(insertImageSaga);
	yield fork(insertImagesSaga);
	yield fork(removeImageSaga);
}

const initialState: TImageLibraryListUploads = {};

export default handleActions<TImageLibraryListUploads, TAction>(
	{
		[UPLOADING](
			state: TImageLibraryListUploads,
			{ payload: { key } }: { +payload: { +key: TId } },
		): TImageLibraryListUploads {
			return _.assign(state, { [key]: { status: 'uploading' } });
		},
		[UPLOADED](
			state: TImageLibraryListUploads,
			{ payload: { key } }: { +payload: { +key: TId } },
		): TImageLibraryListUploads {
			return _.assign(state, { [key]: { status: 'uploaded' } });
		},
		[RESET](): TImageLibraryListUploads {
			return {};
		},
		[FAILED](
			state: TImageLibraryListUploads,
			{
				payload: { key, error },
			}: { +payload: { +key: TId, error: ?TImageLibraryErrors } },
		): TImageLibraryListUploads {
			return _.assign(state, { [key]: { status: 'fail', error } });
		},
	},
	initialState,
);
