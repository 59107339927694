// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex, Box, ToolbarDelimitr } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';

import AlignItems from './AlignItems';
import AlignSelf from './AlignSelf';
import type { TPropsPanel } from './types';

const fullSx = {
	margin: '0 -8px',
	paddingRight: '36px', // Это пустое место для кнопки АБС
};

const compactSx = {
	margin: '0',
};

const delimitrSx = {
	margin: '0 -24px',
	height: '1px',
	backgroundColor: 'bg.primaryalt',
};

const Panel = ({
	t,
	data,
	alignItems,
	direction,
	currentDevice,
	onChange,
	variant = 'full',
	position,
}: TPropsPanel) => {
	const { alignSelf } = closestDeviceWithKey(data.box, {
		currentDevice,
		// eslint-disable-next-line
		key: `box-${data._id}`,
	});

	const changeAlignSelf = React.useCallback(
		value => {
			onChange({
				box: _.set(`${currentDevice}.alignSelf`, value, data.box),
			});
		},
		[data.box, currentDevice, onChange],
	);

	const resetAlignSelf = React.useCallback(() => {
		onChange({
			box: _.unset(`${currentDevice}.alignSelf`, data.box),
		});
	}, [data.box, currentDevice, onChange]);

	if (position === 'absolute') return null;

	return (
		<>
			<Flex sx={variant === 'compact' ? compactSx : fullSx}>
				<AlignItems
					t={t}
					onChange={resetAlignSelf}
					direction={direction}
					alignSelf={alignSelf}
					alignItems={alignItems}
				/>
				{variant === 'compact' && <ToolbarDelimitr />}
				<AlignSelf
					t={t}
					onChange={changeAlignSelf}
					direction={direction}
					alignSelf={alignSelf}
				/>
			</Flex>
			{variant === 'full' && <Box sx={delimitrSx} />}
		</>
	);
};

export default React.memo<TPropsPanel>(Panel);
