// @flow
import React from 'react';
import { ListItem, ButtonColor, Flex, Text } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import { getBgImage, getGradient } from '@graphite/selectors';
import type { TSpecsColor, TLibraryValue, TButtonGroupEmbed } from '@graphite/types';

import getColorName from './get-color-name';

type TProps = $ReadOnly<{|
	name?: ?string,
	color: TLibraryValue,
	unit?: ?number,
	colorspec: TSpecsColor,
	isActive?: boolean,
	buttonGroup?: ?TButtonGroupEmbed,
	onClick?: (MouseEvent, ?string, ?string) => void,
|}>;

const textStyle = {
	marginLeft: '15px',
};

const flexSx = {
	alignItems: 'center',
};

function LabelBackground({
	name = null,
	unit = 1,
	color,
	colorspec,
	buttonGroup = null,
	isActive = false,
	onClick = null,
}: TProps) {
	const effectiveColor = React.useMemo(() => {
		if (color.kind === 'color') {
			return getColor(colorspec, color.value);
		}
		if (color.kind === 'gradient') {
			return getGradient({ colorspec, gradient: color.value });
		}
		if (color.kind === 'bgimage') {
			return getBgImage({ unit: unit || 1, image: color.value });
		}
		return 'none';
	}, [colorspec, color, unit]);

	const effectiveColorName = React.useMemo(() => {
		if (color.kind === 'color') {
			return getColorName(colorspec, color.value);
		}
		if (color.kind === 'gradient') {
			return 'Gradient';
		}
		if (color.kind === 'bgimage') {
			return 'Image';
		}
		return color.kind;
	}, [colorspec, color]);

	const clickBound = React.useCallback(
		(e, itemName, buttonName) => {
			if (onClick && (!buttonName || typeof buttonName === 'string')) {
				onClick(e, name, buttonName);
			}
		},
		[name, onClick],
	);

	return (
		<ListItem buttonGroup={buttonGroup} onClick={clickBound}>
			<Flex sx={flexSx}>
				<ButtonColor color={effectiveColor} isActive={isActive} />
				<Text
					variant="bodysm"
					sx={textStyle}
					color={isActive ? 'text.accent' : 'text.primary'}
				>
					{effectiveColorName}
				</Text>
			</Flex>
		</ListItem>
	);
}

export default React.memo<TProps>(LabelBackground);
