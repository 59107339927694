// @flow
import _ from 'lodash/fp';
import { eventChannel } from 'redux-saga';
import { takeEvery, take, cancelled, call, put, fork } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import logger from '@graphite/logger';
import {
	auth,
	collection,
	googleAuthProvider,
	signInWithPopup,
	createUserWithEmailAndPassword as createUserWithEmailAndPasswordAuth,
	signInWithEmailAndPassword as signInWithEmailAndPasswordAuth,
	signInWithCustomToken,
} from 'libs/firebase';
import history from 'libs/history';
import type { Saga } from 'redux-saga';
import type { TId, TAction, TUsersState as TState } from '@graphite/types';
import { getConfig, logEvent } from '../../libs/firebase';
import { editWidget } from './widgets';

const RECEIVE = 'USERS/RECEIVE';
const REJECT = 'USERS/REJECT';
const SIGN_IN_WITH_TOKEN = 'USERS/SIGN_IN_WITH_TOKEN';
const SIGN_IN_WITH_GOOGLE = 'USERS/SIGN_IN_WITH_GOOGLE';
const SIGN_IN_WITH_EMAIL_AND_PASSWORD = 'USERS/SIGN_IN_WITH_EMAIL_AND_PASSWORD';
const CREATE_USER_WITH_EMAIL_AND_PASSWORD = 'USERS/CREATE_USER_WITH_EMAIL_AND_PASSWORD';
const SIGN_UP = 'USERS/SIGN_UP';
const LOG_OUT = 'USERS/LOG_OUT';
const REQUEST_AUTH = 'USERS/REQUEST_AUTH';
const RECEIVE_AUTH = 'USERS/RECEIVE_AUTH';
const REJECT_AUTH = 'USERS/REJECT_AUTH';

export const receive = (id: TId, name: string, email: string): TAction => ({
	type: RECEIVE,
	payload: { id, name, email },
});

export const reject = (): TAction => ({
	type: REJECT,
	payload: {},
});

export const signInWithToken = (): TAction => ({
	type: SIGN_IN_WITH_TOKEN,
	payload: {},
});

export const signInWithGoogle = (): TAction => ({
	type: SIGN_IN_WITH_GOOGLE,
	payload: {},
});

export const signInWithEmailAndPassword = (email: string, password: string): TAction => ({
	type: SIGN_IN_WITH_EMAIL_AND_PASSWORD,
	payload: { email, password },
});

export const createUserWithEmailAndPassword = (
	email: string,
	password: string,
	name: string,
): TAction => ({
	type: CREATE_USER_WITH_EMAIL_AND_PASSWORD,
	payload: { email, password, name },
});

export const signUp = (): TAction => ({
	type: SIGN_UP,
	payload: {},
});

export const logOut = (): TAction => ({
	type: LOG_OUT,
	payload: {},
});

export const requestAuth = (): TAction => ({
	type: REQUEST_AUTH,
	payload: {},
});

export const receiveAuth = (): TAction => ({
	type: RECEIVE_AUTH,
	payload: {},
});

export const rejectAuth = (code: string, message: string): TAction => ({
	type: REJECT_AUTH,
	payload: { code, message },
});

export function* signInWithTokenSaga(): Saga<void> {
	yield takeEvery(SIGN_IN_WITH_TOKEN, function*(): Saga<void> {
		try {
			const params = new URLSearchParams(window.location.search);
			const token = params.get('token');
			yield call(signInWithCustomToken, token);
			yield call([history, 'replace'], `/`);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* signInWithGoogleSaga(): Saga<void> {
	yield takeEvery(SIGN_IN_WITH_GOOGLE, function*(): Saga<void> {
		try {
			yield put(requestAuth());
			yield call(signInWithPopup, googleAuthProvider);
			yield put(receiveAuth());
		} catch (e) {
			logger.error(e);
			yield put(rejectAuth(e.code, e.massage));
		}
	});
}

export function* signInWithEmailAndPasswordSaga(): Saga<void> {
	yield takeEvery(SIGN_IN_WITH_EMAIL_AND_PASSWORD, function*({
		payload: { email, password },
	}: {
		payload: { email: string, password: string },
	}): Saga<void> {
		try {
			yield put(requestAuth());
			yield call(signInWithEmailAndPasswordAuth, email, password);
			yield put(receiveAuth());
			logger.info('signIn', { type: 'email' });
		} catch (e) {
			yield put(rejectAuth(e.code, e.message));
		}
	});
}

const waitUserCreate = (uid: string): Promise<void> =>
	new Promise((res: () => void, rej: (error: any) => void) => {
		// FixMe: грязная функция
		const unsubscribe = collection('widgets')
			.where('userId', '==', uid)
			// .doc(uid)
			.onSnapshot((snapshot: Object) => {
				if (snapshot.docChanges().length) {
					unsubscribe();
					res();
				}
			}, rej);
	});

export function* createUserWithEmailAndPasswordSaga(): Saga<void> {
	yield takeEvery(CREATE_USER_WITH_EMAIL_AND_PASSWORD, function*({
		payload: { email, password, name },
	}: {
		payload: { email: string, password: string, name: string },
	}): Saga<void> {
		try {
			yield put(requestAuth());

			// регистрируем пользователя
			const { user } = yield call(
				createUserWithEmailAndPasswordAuth,
				email,
				password,
			);
			const { uid } = user;
			yield call([user, 'updateProfile'], {
				displayName: name,
			});

			// ждём пока запись о пользователе появится в БД
			yield call(waitUserCreate, uid);

			yield put(editWidget(uid, null, uid, { name, email }));

			// обновляем имя
			yield put(receiveAuth());

			logger.info('signUp', { type: 'email' });
		} catch (e) {
			logger.error(e);
			yield put(rejectAuth(e.code, e.message));
		}
	});
}

export function* logOutSaga(): Saga<void> {
	yield takeEvery(LOG_OUT, () => {
		try {
			auth.signOut();
			logger.info('logOut');
		} catch (e) {
			logger.error(e);
		}
	});
}

/**
	Следим за статусом авторизации пользователя

	// FixMe: Проверить, что авторизация не может сработать раньше этой саги
	// иначе будет пиздец, тк колбэк никогда не вызоветься
 */
export function* watchAuthChange(): Saga<void> {
	// Подписываемся на статус изменения статуса авторизации пользователя
	const authEventsChannel = eventChannel(
		(emit: ({ user: ?{ uid: string } }) => void): (() => void) => {
			const unsubscribe = auth.onAuthStateChanged((user: ?{ uid: string }) => {
				emit({ user });
			});
			// return a function that can be used to unregister listeners when the saga is cancelled
			return unsubscribe;
		},
	);
	try {
		while (true) {
			const { user } = yield take(authEventsChannel);
			const {
				sentryDNS,
				apiKeyAmplitudeKey,
				mixpanelId,
				yandexMetrikaId,
				logRocketId,
			} = yield call(getConfig);
			const loggerConfig =
				process.env.NODE_ENV !== 'production' || process.env.ENV_MODE === 'server'
					? { browser: true }
					: {
							sentry: {
								DNS: sentryDNS,
							},
							analytics: {
								logEvent,
							},
							fb: true,
							amplitude: {
								key: apiKeyAmplitudeKey,
							},
							mixpanel: {
								key: mixpanelId,
							},
							yandex: {
								key: yandexMetrikaId,
							},
							logRocket: {
								key: logRocketId,
							},
					  };

			logger.init(
				loggerConfig,
				user && {
					id: user.uid,
					username: user.displayName,
					email: user.email,
				},
			);

			if (user) {
				// Входим в аккаунт
				const { uid, displayName, email } = user;
				yield put(receive(uid, displayName, email));
			} else {
				yield put(reject());
			}
		}
	} catch (e) {
		logger.error(e);
	} finally {
		// unregister listener if the saga was cancelled
		if (yield cancelled()) {
			authEventsChannel.close();
		}
	}
}

export function* saga(): Saga<void> {
	// ToDo: написать документацию
	yield fork(signInWithTokenSaga);

	// Сначала подписываемся на авторизацию
	yield fork(watchAuthChange);

	// Потом запускаем саги логина, регистрации и разлогина
	yield fork(signInWithGoogleSaga);
	yield fork(signInWithEmailAndPasswordSaga);
	yield fork(createUserWithEmailAndPasswordSaga);
	yield fork(logOutSaga);
}

const initialState: TState = {
	currentUser: null,
	isFetching: true,
	didInvalidate: false,
	isAuth: false,
	authError: null,
};

export default handleActions<TState, TAction>(
	{
		[REQUEST_AUTH](state: TState): TState {
			return _.flow(_.set('isAuth', false), _.set('authError', null))(state);
		},
		[RECEIVE_AUTH](state: TState): TState {
			// return _.flow(_.set('isAuth', false))(state);
			return state;
		},
		[REJECT_AUTH](
			state: TState,
			{
				payload: { code, message },
			}: { +payload: { code: string, message: string } },
		): TState {
			return _.flow(
				_.set('isAuth', false),
				_.set('authError', { code, message }),
			)(state);
		},
		[REJECT](state: TState): TState {
			return _.flow(
				_.set('currentUser', null),
				_.set('name', null),
				_.set('email', null),
				_.set('isFetching', false),
				_.set('isAuth', false),
				_.set('didInvalidate', false),
			)(state);
		},
		[RECEIVE](
			state: TState,
			{
				payload: { id, name, email },
			}: { +payload: { +id: TId, +name: string, +email: string } },
		): TState {
			return _.flow(
				_.set('currentUser', id),
				_.set('name', name),
				_.set('email', email),
				_.set('isFetching', false),
				_.set('isAuth', true),
				_.set('didInvalidate', false),
			)(state);
		},
	},
	initialState,
);
