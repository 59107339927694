// @flow
import React from 'react';

import type { TProps } from './types';
import Wrap from './Wrap';
import Element from './Element';

const Image = (props: TProps, ref) => {
	const { colorspec, effectspec, widgetspec, gridspec, data } = props;

	if (!colorspec || !effectspec || !widgetspec || !gridspec) return null;

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Wrap {...props} ref={ref}>
			<Element data={data} gridspec={gridspec} />
		</Wrap>
	);
};

export default React.memo<TProps>(React.forwardRef(Image));
