// @flow
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { Link, themes, Box, Flex, Text, Logo } from '@graphite/uneon';
import { config } from '@graphite/constants';
import logger from '@graphite/logger';
import InputAuth from '../InputAuth';
import ButtonAuth from '../ButtonAuth';
import FormGroupAuth from '../FormGroupAuth';
import bgImage from './bg.jpg';

// FixMe переделать mouse на synthetic event, потому что типы ругаются
type TProps = $ReadOnly<{|
	signInWithGoogle: (e: MouseEvent) => void,
	signInWithEmailAndPassword: ({ email: string, password: string }) => void,
	isAuth: boolean,
	error: ?{
		code: string,
		message: string,
	},
|}>;

const bodySx = {
	minHeight: '100vh',
	backgroundColor: 'bg.primary',
	alignItems: 'stretch',
};

const sidebarWrapperSx = {
	display: ['none', 'block'],
	minWidth: '300px',
};

const sidebarSx = {
	height: '100%',

	position: 'relative',
	flexDirection: 'column',
	justifyContent: 'center',

	backgroundColor: 'bg.primary',
	backgroundImage: `url(${bgImage})`,
	backgroundSize: 'cover',
};

const sidebarWrapperWidth = 1 / 5;

const sidebarOverlaySx = {
	position: 'absolute',
	top: '0',
	left: '0',
	bottom: '0',
	right: '0',
	opacity: '0.5',
	background: '#000',
};

const logoSidebarSx = {
	position: 'absolute',
	top: '30px',
};

const sidebarTitleSx = {
	position: 'relative',
	h2: {
		color: 'text.primaryalt',
		marginBottom: '12px',
	},
	h5: {
		color: 'text.primaryalt',
	},
};

const contentSx = {
	position: 'relative',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: ['start', 'center'],
	textAlign: 'center',
};

const contentWidth = [1, 4 / 5];

const logoWrapperSx = {
	display: ['block', 'none'],
	margin: '48px 0 0 0',
};

const logoFormSx = {
	width: '120px',
};

const authFormSx = {
	width: '300px',
	margin: '60px 0',
	h1: {
		marginBottom: '36px',
	},
};

const separatorWrapperSx = {
	marginBottom: '18px',
	height: '21px',
};

const separatorSx = {
	position: 'relative',
	top: '12px',
	left: '0',
	right: '0',
	height: '1px',
	marginBottom: '-1px',
	backgroundColor: 'bg.primaryalt',
};

const separatorTextSx = {
	position: 'relative',
	display: 'inline-block',
	padding: '0 12px',
	backgroundColor: 'bg.primary',
	color: 'text.secondary',
};

const errorSx = {
	color: 'text.error',
	marginBottom: '18px',
	textAlign: 'left',
};

const googleIcon = [
	{
		name: 'social-google',
		iconSize: 24,
		size: 'xsm',
	},
];

const Login = ({
	signInWithGoogle,
	signInWithEmailAndPassword,
	isAuth,
	error,
}: TProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [isChange, setChanged] = React.useState(true);
	const { handleSubmit, register } = useForm();

	// for analytics
	React.useEffect(() => {
		logger.info('signInPageVisit');
	}, []);

	const signInWithGoogleHandler = React.useCallback(
		e => {
			signInWithGoogle(e);
			logger.info('signIn', { type: 'google' });
		},
		[signInWithGoogle],
	);

	const signupLinkHandler = React.useCallback(
		e => {
			e.preventDefault();
			history.push('/signup');
		},
		[history],
	);

	const handleSubmitForm = React.useCallback(
		e => {
			setChanged(false);
			handleSubmit(signInWithEmailAndPassword)(e);
		},
		[handleSubmit, signInWithEmailAndPassword],
	);

	const handleChangeForm = React.useCallback(() => {
		setChanged(true);
	}, []);

	const isInvalidEmail = !isChange && error && error.code.includes('email');

	return (
		<Flex sx={bodySx}>
			<ThemeProvider theme={themes.dark}>
				<Box width={sidebarWrapperWidth} sx={sidebarWrapperSx}>
					<Flex variant="container.fluid" sx={sidebarSx}>
						<Box sx={sidebarOverlaySx} />
						<a href={config.appHome}>
							<Logo sx={logoSidebarSx} />
						</a>
						<Box sx={sidebarTitleSx}>
							<Text as="h2" variant="hero3">
								{t('auth.login.sidebar title')}
							</Text>
							<Text as="h5" variant="title4">
								{t('auth.login.sidebar tagline')}
							</Text>
						</Box>
					</Flex>
				</Box>
			</ThemeProvider>

			<Flex variant="container.fluid" width={contentWidth} sx={contentSx}>
				<Box sx={logoWrapperSx}>
					<a href={config.appHome}>
						<Logo sx={logoFormSx} />
					</a>
				</Box>
				<Box sx={authFormSx}>
					<Text as="h1" variant="title2" color="text.primaryalt">
						{t('auth.login.form title')}
					</Text>
					<ButtonAuth
						type="button"
						name="start"
						size="lg"
						label={t('auth.login.with google')}
						colors="accent"
						shape="rounded"
						onClick={signInWithGoogleHandler}
						icons={googleIcon}
					/>
					<Box sx={separatorWrapperSx}>
						<Box sx={separatorSx} />
						<Text variant="bodymd" sx={separatorTextSx}>
							{t('auth.separator')}
						</Text>
					</Box>

					<form onSubmit={handleSubmitForm} onChange={handleChangeForm}>
						<FormGroupAuth>
							<InputAuth
								name="email"
								className="protected"
								size="lg"
								placeholder={t('auth.email')}
								ref={register({
									required: 'Required',
								})}
								disabled={isAuth}
								isError={isInvalidEmail}
							/>
							{error && isInvalidEmail && !isChange && (
								<Text variant="bodysm" sx={errorSx}>
									{t(error.message, { keySeparator: false })}
								</Text>
							)}

							<InputAuth
								name="password"
								className="protected"
								size="lg"
								placeholder={t('auth.password')}
								type="password"
								ref={register({
									required: 'Required',
								})}
								disabled={isAuth}
							/>
						</FormGroupAuth>

						{error && error.message && !isChange && !isInvalidEmail && (
							<Text variant="bodysm" sx={errorSx}>
								{t(error.message, { keySeparator: false })}
							</Text>
						)}

						<ButtonAuth
							type="submit"
							size="lg"
							label={t('auth.login.link')}
							colors="accentghost"
							shape="rounded"
							isDisabled={isAuth}
						/>
					</form>
					<Text variant="bodysm" color="text.secondary">
						{t('auth.login.dont have account')}
						<Link href="/signup" onClick={signupLinkHandler} variant="link">
							{t('auth.signup.link')}
						</Link>
					</Text>
				</Box>
			</Flex>
		</Flex>
	);
};

export default React.memo<TProps>(Login);
