// @flow
import React from 'react';
import { detect } from 'detect-browser';

import HotkeyMac from './Mac';
import HotkeyWin from './Win';

export type TProps = $ReadOnly<{|
	mac?: string,
	win?: string,
|}>;

const Hotkey = ({ mac, win }: TProps) => {
	const [browserInfo] = React.useState(detect);

	switch (true) {
		case browserInfo?.os === 'Mac OS':
			return mac ? <HotkeyMac value={mac} /> : '';
		default:
			return win ? <HotkeyWin value={win} /> : '';
	}
};

export default React.memo<TProps>(Hotkey);
