// @flow
import React from 'react';
import { editWidget } from 'Editor/ducks/widgets';
import { useTranslation } from 'react-i18next';
import WidgetSettings from '@graphite/widget-settings';
import { AlignSelfToolbar } from '@graphite/align-self';
import type { TWidgetDiff } from '@graphite/types';

import type { TConnectPropsWithControls } from '../constants/types';

const Controls = ({
	gridspec,
	position,
	currentDevice,
	dispatch,
	data,
	instanceId,
	originId,
	currentRef,
	direction,
	alignItems,
}: TConnectPropsWithControls) => {
	const { t } = useTranslation();
	const [openedPanel, setOpenedPanel] = React.useState(null);
	const { _id } = data;
	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, instanceId, originId, dispatch],
	);

	return (
		<>
			<AlignSelfToolbar
				t={t}
				onChange={saveDiff}
				direction={direction}
				data={data}
				alignItems={alignItems}
				currentDevice={currentDevice}
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				position={position}
			/>
			<WidgetSettings
				t={t}
				onSave={saveDiff}
				data={data}
				unit={gridspec.unit}
				position={position}
				currentDevice={currentDevice}
				currentRef={currentRef}
				direction={direction}
				alignItems={alignItems}
			/>
		</>
	);
};

export default React.memo<TConnectPropsWithControls>(Controls);
