// @flow
import type { TId, TCurrentWidget, TWidget, TWidgetChain } from '@graphite/types';

const getDragAvailable = (
	id: TId,
	currentWidget: ?TCurrentWidget,
	firstWidgetInChain: ?TWidget,
	widgetChain: TWidgetChain,
	widgetMode: 'none' | 'widget-edit' | 'widget-resize',
) => {
	const isParentEdited: boolean = !!widgetChain && widgetChain[0] === currentWidget?.id;
	const isResized: boolean = widgetMode === 'widget-resize';
	const parentKind = firstWidgetInChain?.kind;
	const excludesParentTypes = ['col', 'block'];
	/*
		Драгать можно если:
		- виджет лежит в колонке или блоке
		- виджет лежит в стеке, который сейчас редактируется
		- виджет находится в режиме ресайза
		- виджет находится не в режиме редактирования
		- другой виджет не находится в режиме редактирования (как в фигме)
	*/

	if (
		currentWidget?.id &&
		currentWidget?.controls &&
		id !== currentWidget?.id &&
		currentWidget?.controls === 'widget-edit'
	)
		return false;

	return (
		excludesParentTypes.includes(parentKind) ||
		(parentKind === 'stack' && isParentEdited) ||
		isResized
	);
};

export default getDragAvailable;
