// @flow
import { takeEvery, put, fork, select, delay, takeLatest } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import logger from '@graphite/logger';
import { editorEmptyState } from '@graphite/constants';
import { getWidget } from '@graphite/selectors';
import type {
	TAction,
	TId,
	TWidgets,
	TEditor,
	TEditorSoft,
	TWidgetChain,
	TEditorPayload,
	THistoryUpdated,
} from '@graphite/types';
import type { Saga } from 'redux-saga';

import { getCurrentWidget } from '../selectors/editor';

export const APPLY = 'EDITOR/APPLY';

const EDITOR_EMPTY = 'EDITOR/EDITOR_EMPTY';
const UPDATE_EDITOR = 'EDITOR/UPDATE_EDITOR';
const APPLY_EDITOR = 'EDITOR/APPLY_EDITOR';
const START_EDIT = 'EDITOR/START_EDIT';
const RESET_EDIT = 'EDITOR/RESET_EDIT';
const SET_EDITED = 'EDITOR/SET_EDITED';
const BEGIN_EDIT = 'BEGIN_EDIT';
const SET_WIDGET_BUFFER = 'EDITOR/SET_WIDGET_BUFFER';
const ADD_NOTICE = 'EDITOR/ADD_NOTICE';
const REMOVE_NOTICE = 'EDITOR/REMOVE_NOTICE';

const resizeMode: 'widget-resize' = 'widget-resize';
const editMode: 'widget-edit' = 'widget-edit';

export const editorEmpty = (): TAction => ({
	type: EDITOR_EMPTY,
	payload: {},
});

export const apply = (data: THistoryUpdated): TAction => ({
	type: APPLY,
	payload: {
		widgets: {},
		specs: {},
		site: {},
		...data,
	},
});

export const updateEditor = (editor: $Shape<TEditor>): TAction => ({
	type: UPDATE_EDITOR,
	payload: { editor },
});

export const applyEditor = (editor: TEditor): TAction => ({
	type: APPLY_EDITOR,
	payload: { editor },
});

export const startEdit = (
	id: ?TId,
	widgetChain: TWidgetChain,
	instanceId: ?TId,
): TAction => ({
	type: START_EDIT,
	payload: { id, widgetChain, instanceId },
});

export const resetEdit = (): TAction => ({
	type: RESET_EDIT,
	payload: {},
});

export const beginEdit = (currentWidget: TEditorSoft): TAction => ({
	type: BEGIN_EDIT,
	payload: currentWidget,
});

export const setEdit = (editor: TEditorSoft): TAction => ({
	type: SET_EDITED,
	payload: { editor },
});

export const setWidgetsBuffer = (widgetsBuffer: ?TWidgets): TAction => ({
	type: SET_WIDGET_BUFFER,
	payload: {
		widgetsBuffer,
	},
});

export const addNotice = (id: TId, title: string, description: string): TAction => ({
	type: ADD_NOTICE,
	payload: {
		id,
		title,
		description,
	},
});

export const removeNotice = (id: TId): TAction => ({
	type: REMOVE_NOTICE,
	payload: {
		id,
	},
});

function* beginEditSaga(): Saga<void> {
	/* Рулит результатом при одновременном старте саг reset и startEdit */
	yield takeLatest(BEGIN_EDIT, function*({
		payload: editor,
	}: TEditorPayload): Saga<void> {
		yield put(setEdit(editor));
	});
}

export function* updateEditorSaga(): Saga<void> {
	yield takeEvery(UPDATE_EDITOR, function*({
		payload: { editor },
	}: {
		payload: { +editor: $Shape<TEditor> },
	}): Saga<void> {
		yield put(applyEditor(editor));
	});
}

export function* resetEditSaga(): Saga<void> {
	yield takeEvery(RESET_EDIT, function*(): Saga<void> {
		const currentWidget = yield select(getCurrentWidget);
		if (!currentWidget) {
			return;
		}
		yield put(
			beginEdit({
				currentWidget: null,
			}),
		);
	});
}

export function* startEditSaga(): Saga<void> {
	yield takeEvery(START_EDIT, function*({
		payload: { id, widgetChain, instanceId },
	}: {
		payload: { id: TId, widgetChain: TWidgetChain, instanceId: TId },
	}): Saga<void> {
		const currentWidget = yield select(getCurrentWidget);
		const widget = yield select(getWidget, { id: currentWidget?.id });

		const _editor: TEditorSoft = {
			currentWidget: null,
		};

		let editor: (_editor: TEditorSoft) => TEditorSoft = (
			_editor: TEditorSoft,
		): TEditorSoft => _editor;

		if (
			currentWidget?.id === id &&
			currentWidget?.controls === resizeMode &&
			['block', 'col', 'stack'].includes(widget?.kind)
		)
			return;

		if (currentWidget?.id === id && currentWidget?.controls === resizeMode) {
			/* Повторный клик по виджету в режиме ресайза */
			editor = _.set('currentWidget', {
				id,
				controls: editMode,
				widgetChain,
				instanceId,
			});
		} else {
			/* клик по одиноко лежащему виджету */
			editor = _.set('currentWidget', {
				id,
				controls: resizeMode,
				widgetChain,
				instanceId,
			});
			const widgetNew = yield select(getWidget, { id });
			logger.info('selectWidget', {
				type: widgetNew?.kind,
			});
		}
		yield delay(0);
		yield put(beginEdit(editor(_editor)));
	});
}

export function* checkCurrentWidgetSaga({
	targetId,
	updated,
	instanceId,
}: $ReadOnly<{|
	targetId: TId,
	instanceId: ?TId,
	updated: TWidgets,
|}>): Saga<void> {
	const currentWidget = yield select(getCurrentWidget);

	if (
		instanceId &&
		currentWidget?.id === targetId &&
		instanceId === currentWidget?.instanceId
	) {
		const newId = _.findKey({ protoId: targetId }, { ...updated });
		const { widgetChain } = currentWidget || emptyObject;

		if (newId) {
			yield put(startEdit(newId, widgetChain, instanceId));
		}
	}
}

export function* saga(): Saga<void> {
	yield fork(updateEditorSaga);
	yield fork(startEditSaga);
	yield fork(resetEditSaga);
	yield fork(beginEditSaga);
}

export default handleActions<$ReadOnly<TEditor>, TAction>(
	{
		[EDITOR_EMPTY](): $ReadOnly<TEditor> {
			return editorEmptyState;
		},
		[APPLY_EDITOR](
			state: $ReadOnly<TEditor>,
			{ payload: { editor } }: { +payload: { +editor: TEditor } },
		): $ReadOnly<TEditor> {
			return _.assign(state, editor);
		},
		[SET_EDITED](
			state: $ReadOnly<TEditor>,
			{ payload: { editor } }: { +payload: { +editor: TEditor } },
		): $ReadOnly<TEditor> {
			return _.assign(state, editor);
		},
		[APPLY](
			state: $ReadOnly<TEditor>,
			{ payload: { editor } }: { +payload: { +editor: TEditor } },
		): $ReadOnly<TEditor> {
			return _.assign(state, editor);
		},
		[SET_WIDGET_BUFFER](
			state: $ReadOnly<TEditor>,
			{ payload: { widgetsBuffer } }: { +payload: { +widgetsBuffer: TWidgets } },
		): $ReadOnly<TEditor> {
			return _.assign(state, { widgetsBuffer });
		},
		[ADD_NOTICE](
			state: $ReadOnly<TEditor>,
			{
				payload: { id, title, description },
			}: { +payload: { id: TId, title: string, description: string } },
		): $ReadOnly<TEditor> {
			return _.update(
				'notices',
				(
					notices: $PropertyType<TEditor, 'notices'>,
				): $PropertyType<TEditor, 'notices'> => [
					...notices,
					{ id, title, description },
				],
				state,
			);
		},
		[REMOVE_NOTICE](
			state: $ReadOnly<TEditor>,
			{ payload: { id } }: { +payload: { id: TId } },
		): $ReadOnly<TEditor> {
			return _.update(
				'notices',
				(
					notices: $PropertyType<TEditor, 'notices'>,
				): $PropertyType<TEditor, 'notices'> =>
					_.filter(
						(
							notice: $ReadOnly<{
								id: TId,
								title: string,
								description: string,
							}>,
						): boolean => notice.id !== id,
						notices,
					),
				state,
			);
		},
	},
	editorEmptyState,
);
