// @flow
import React from 'react';
import { Box, Separator } from '@graphite/uneon';
import { ShadedColors as ListShadedColors } from '@graphite/lists';
import type { TSpecsColor, TColorReference } from '@graphite/types';

type TProps = $ReadOnly<{|
	value: TColorReference,
	colorspec: TSpecsColor,
	onChange?: ?(TColorReference) => void,
|}>;

const separatorBoxSx = {
	margin: '0 -24px 18px -24px',
	height: 0,
};

const spacerSx = {
	marginBottom: '12px',
};

function Palette({ value, colorspec, onChange }: TProps) {
	const clickColor = React.useCallback(
		(e, entryId, shadeId) => {
			const editAt = colorspec.palette.findIndex(p => p.id === entryId);
			if (editAt < 0) {
				return;
			}

			const newEntry = {
				...colorspec.palette[editAt],
			};

			let srcColor = newEntry.color;
			if (shadeId) {
				const shade = newEntry.shades.find(s => s.id === shadeId);
				if (shade) {
					srcColor = shade.color;
				}
			}

			if (onChange) {
				onChange({ entryId, shadeId, snapshot: srcColor });
			}
		},
		[colorspec, onChange],
	);
	return (
		<>
			<Box sx={separatorBoxSx}>
				<Separator />
			</Box>
			<ListShadedColors
				active={value}
				items={colorspec.palette}
				onClick={clickColor}
			/>
			<Box sx={spacerSx} />
		</>
	);
}

Palette.defaultProps = {
	onChange: null,
};

export default React.memo<TProps>(Palette);
