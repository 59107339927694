// @flow


import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';

import getMapDispatchToPropsEdit from 'Widget/libs/primitive/getMapDispatchToPropsEdit';
import type { TId, Dispatch, TAction, TWidgetOwnProps } from '@graphite/types';

const mapDispatchToPropsEdit = (
	dispatch: Dispatch<TAction>,
	ownProps: TWidgetOwnProps,
) => ({
		...getMapDispatchToPropsEdit(dispatch, ownProps),
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
		resetImage: () => {
			dispatch(reset());
		},
	});

export default mapDispatchToPropsEdit;
