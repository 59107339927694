// @flow
import React from 'react';
import reduce from 'lodash/fp/reduce';
import assign from 'lodash/fp/assign';
import concat from 'lodash/fp/concat';
import keys from 'lodash/fp/keys';
import { m, useTransform, useViewportScroll } from 'framer-motion';
import type { TAnimations } from '@graphite/types';

import { baseSx } from './constants';

type TProps = $ReadOnly<{|
	data: TAnimations,
	children: React$Node,
|}>;

const DEFAULT = [0, 0];
const DISTANCE = [0, 1]; // в будущем добавить в панельку как настройку

const useTransformProp = (scrollYProgress, props) => {
	const prop = useTransform(scrollYProgress, DISTANCE, props || DEFAULT);
	if (!props) return null;
	return prop;
};

const useMergeProps = (scroll = {}, scrollYProgress) => {
	const { from = {}, to = {} } = scroll;
	const allKeys = [...new Set(concat(keys(from), keys(to)))];
	const props = reduce(
		(acc, key) => ({
			...acc,
			[`${key}`]: [from[key], to[key]],
		}),
		{},
		allKeys,
	);

	// Тут явно нужно что то придумать
	const allProps = {
		x: useTransformProp(scrollYProgress, props?.x || null),
		y: useTransformProp(scrollYProgress, props?.y || null),
		z: useTransformProp(scrollYProgress, props?.z || null),
		opacity: useTransformProp(scrollYProgress, props?.opacity || null),
		rotate: useTransformProp(scrollYProgress, props?.rotate || null),
		rotateX: useTransformProp(scrollYProgress, props?.rotateX || null),
		rotateY: useTransformProp(scrollYProgress, props?.rotateY || null),
		rotateZ: useTransformProp(scrollYProgress, props?.rotateZ || null),
		scale: useTransformProp(scrollYProgress, props?.scale || null),
		scaleX: useTransformProp(scrollYProgress, props?.scaleX || null),
		scaleY: useTransformProp(scrollYProgress, props?.scaleY || null),
		scaleZ: useTransformProp(scrollYProgress, props?.scaleZ || null),
		skew: useTransformProp(scrollYProgress, props?.skew || null),
		skewX: useTransformProp(scrollYProgress, props?.skewX || null),
		skewY: useTransformProp(scrollYProgress, props?.skewY || null),
		transformPerspective: useTransformProp(
			scrollYProgress,
			props?.transformPerspective || null,
		),
	};

	return reduce(
		(acc, key) => ({
			...acc,
			...(allProps[key] ? { [`${key}`]: allProps[key] } : {}),
		}),
		{},
		allKeys,
	);
};

const ScrollComponent = (props: TProps) => {
	const {
		children,
		data: { scroll },
	} = props;
	const { scrollYProgress } = useViewportScroll(); // можно сделать анимацию по Х
	const ref = React.useRef();
	const animateProps = useMergeProps(scroll, scrollYProgress);

	const style = React.useMemo(() => assign(baseSx, animateProps), [animateProps]);

	if (!scroll) return children;

	return (
		<m.div data-kind="scroll-animation" ref={ref} style={style}>
			{children}
		</m.div>
	);
};

export default React.memo<TProps>(ScrollComponent);
