// @flow

import React, { memo, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInjectSaga } from 'libs/inject-saga';
import { Text, Flex } from '@graphite/uneon';
import { startEdit } from 'Editor/ducks/editor';
import logger from '@graphite/logger';
import { useDispatch } from '@graphite/use-redux';
import reverse from 'lodash/fp/reverse';
import unionBy from 'lodash/fp/unionBy';
import type { TWidgetChain, TCurrentWidget } from '@graphite/types';
import { getCurrentWidget, getCurrentDevice } from '../../../selectors/editor';
import { getTreeData, findNodeById, type TTreeDataElement } from './selectors';
import { moveWidget, saga as sagaLayer } from './ducks';

import LayerList from './LayerList';

const layerTitleStyle = {
	marginBottom: '24px',
	cursor: 'default',
};

const columnStyle = {
	flexDirection: 'column',
	marginBottom: '30px',
};

// key of all nesting node
const getNodePathKey = (key: string, preKey: string): string =>
	preKey ? `${preKey}-${key}` : key;

// some logic for expanding node
const checkNodeIsExpanded = (
	key: string,
	expandedKeys,
	isCurrentWidget: boolean,
): boolean => {
	const expKey = expandedKeys?.find(item => item.key === key);

	return !!((expandedKeys && expKey?.expanded) || (isCurrentWidget && !expKey));
};

// add meta data for tree
const openTree = (
	treeData: $ReadOnlyArray<TTreeDataElement>,
	preKey: string = '',
	currentWidget,
	expandedKeys,
): $ReadOnlyArray<TTreeDataElement> =>
	treeData.map(node => ({
			...node,
			expanded: checkNodeIsExpanded(
				getNodePathKey(node._id, preKey),
				expandedKeys,
				!!(
					node._id === currentWidget?.id ||
					currentWidget?.widgetChain.includes(node._id)
				),
			),
			selected: node._id === currentWidget?.id,
			key: getNodePathKey(node._id, preKey),
			children: openTree(
				node.children,
				getNodePathKey(node._id, preKey),
				currentWidget,
				expandedKeys,
			),
		}));

function Layers() {
	useInjectSaga({ key: 'layer', saga: sagaLayer });
	const [expandedKeys, setexpandedKeys] = useState<
		$ReadOnlyArray<{ key: string, expanded: boolean }>,
	>([]);
	const currentWidget: ?TCurrentWidget = useSelector(getCurrentWidget);
	const isDraggble: boolean = useSelector(getCurrentDevice) === 'desktop';
	const treeData: TTreeDataElement = useSelector(getTreeData);
	const dispatch = useDispatch();
	const selectedWidget = useRef(null);
	const { t } = useTranslation();

	React.useEffect(() => {
		logger.info('openLayersPanel');
	}, []);

	const data = useMemo(
		() => openTree(treeData.children, treeData._id, currentWidget, expandedKeys),
		[treeData, expandedKeys, currentWidget],
	);

	const onExpand = useCallback(
		(key: string, isExpanded: boolean) => {
			const expKey: boolean = !!expandedKeys?.find(item => item.key === key);
			const keys =
				(expKey
					? expandedKeys.map(item =>
							key !== item.key ? item : { ...item, expanded: isExpanded },
					  )
					: expandedKeys.concat([{ key, expanded: isExpanded }])) || [];

			setexpandedKeys(keys);
		},
		[setexpandedKeys, expandedKeys],
	);

	const onDrop = useCallback(
		(dragNode, dropKey = '', dropPosition = 'center') => {
			let isPrev: boolean = !1;
			let isNext: boolean = !1;
			const dropNode: TTreeDataElement =
				findNodeById({
					data: treeData.children,
					id: dropKey,
				}) || treeData;

			isPrev = dropPosition !== 'center' && dropPosition === 'bottom';
			isNext = dropPosition !== 'center' && dropPosition === 'top';
			const containerId = !isPrev && !isNext ? dropNode._id : dropNode.containerId;
			// if move into node when we are then nothing do
			if (containerId === dragNode.containerId && dropPosition === 'center') return;

			dispatch(
				moveWidget({
					prevNode: isPrev ? { ...dropNode } : null,
					nextNode: isNext ? { ...dropNode } : null,
					node: {
						...dragNode,
						containerId,
					},
					srcContainerId: dragNode?.containerId,
					newRow: dropNode.kind === 'block',
				}),
			);
		},
		[dispatch, treeData],
	);

	const onSelect = useCallback(
		({ kind, key, instanceId, _id }) => {
			// if it block or col need just open/close
			if (['block', 'col'].includes(kind)) {
				return onExpand(
					key || '',
					!(expandedKeys?.find(item => key === item.key) || { expanded: false })
						.expanded,
				);
			}

			const widgetChain: TWidgetChain =
				key
					?.split('-')
					.reverse()
					.slice(1) || [];

			// eslint-disable-next-line no-unused-expressions
			if (window.document.querySelector(`[data-id='${_id}']`))
				window.document
					.querySelector(`[data-id='${_id}']`)
					.scrollIntoView({ behavior: 'smooth', block: 'center' });

			if (currentWidget?.id !== _id)
				dispatch(startEdit(_id, widgetChain, instanceId));
		},
		[currentWidget?.id, dispatch, onExpand, expandedKeys],
	);

	useEffect(() => {
		if (currentWidget && selectedWidget.current !== currentWidget.id) {
			selectedWidget.current = currentWidget.id;
			// проставляем всем вложенностям и самому виджету expand
			let keys = [];
			reverse(currentWidget?.widgetChain).reduce((accum, id) => {
				const key = accum ? `${accum}-${id}` : id;
				keys = keys.concat({ key, expanded: true });
				return key;
			});
			setexpandedKeys(unionBy('key', keys, expandedKeys));
		}
	}, [currentWidget, expandedKeys]);

	return (
		<>
			<Text as="h2" variant="title4" color="text.primaryalt" sx={layerTitleStyle}>
				{t('Layers')}
			</Text>
			<Flex sx={columnStyle}>
				<LayerList
					source={data}
					onExpand={onExpand}
					onSelect={onSelect}
					onDropEnd={onDrop}
					isDraggble={isDraggble}
				/>
			</Flex>
		</>
	);
}

export default memo<{}>(Layers);
