// @flow

import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { useTranslation } from 'react-i18next';
import { themes, PopupWhite, Icon, Text, Tooltip } from '@graphite/uneon';
import { useDispatch } from '@graphite/use-redux';
import { Params as ListParams } from '@graphite/lists';
import { updateEditor } from 'Editor/ducks/editor';
import logger from '@graphite/logger';
import FrameContext from 'Editor/Frame/Context';
import useHotkeys from '@graphite/use-hotkeys';
import type { TEditorLang, TParamSource, TParams } from '@graphite/types';

type TProps = $ReadOnly<{|
	currentGridHighlight: boolean,
	language: TEditorLang,
|}>;

const titleSx = {
	marginBottom: '18px',
	cursor: 'default',
};

const iconSx = {
	marginRight: '6px',
};

const paramList: TParams = [
	{
		title: 'Highlight grid',
		key: 'currentGridHighlight',
		kind: 'switch',
		info: {},
	},
	// TODO: Открыть когда будут нормальные переводы, а пока нет толку позориться
	// {
	// 	title: 'Language',
	// 	key: 'language',
	// 	kind: 'select',
	// 	info: {
	// 		list: {
	// 			items: [
	// 				{ name: 'en', label: 'English' },
	// 				{ name: 'ru', label: 'Russian' },
	// 			],
	// 		},
	// 	},
	// },
];

function ButtonExtra({ currentGridHighlight, language }: TProps) {
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);

	const { document: documentFrame, window: windowFrame } = React.useContext(
		FrameContext,
	);

	const dispatch = useDispatch();
	const panelRef: React$ElementRef<any> = React.useRef(null);
	const helpRef: React$ElementRef<any> = React.useRef(null);

	const togglePanel = React.useCallback(() => {
		if (!isOpen) logger.info('openDisplayPanel');
		setIsOpen(!isOpen);
	}, [isOpen]);

	const hidePanel = React.useCallback(() => setIsOpen(false), []);

	const paramSource: TParamSource = React.useMemo(
		() => ({
			currentGridHighlight: currentGridHighlight ? 'true' : '',
			language,
		}),
		[currentGridHighlight, language],
	);

	const changeParam = React.useCallback(
		(key, value) => {
			if (key === 'language' && (value === 'en' || value === 'ru')) {
				dispatch(updateEditor({ language: value }));
				i18n.changeLanguage(value);
				return;
			}
			if (key === 'currentGridHighlight' && typeof value === 'boolean') {
				logger.info(`gridHighlight${value ? 'On' : 'Off'}`);
				dispatch(updateEditor({ currentGridHighlight: value }));
			}
		},
		[dispatch, i18n],
	);

	const hotkeyGridHighlightHandler = React.useCallback(
		e => {
			e.preventDefault();
			changeParam('currentGridHighlight', !currentGridHighlight);
		},
		[changeParam, currentGridHighlight],
	);

	useHotkeys('Shift+g', hotkeyGridHighlightHandler, documentFrame, windowFrame);

	return (
		<>
			<Icon
				ref={panelRef}
				name="dots-horizontal-3"
				iconSize={18}
				size="md"
				colors="secondary"
				onClick={togglePanel}
				sx={iconSx}
			/>
			<Tooltip elRef={panelRef} delayShow={1000}>
				{t('Canvas settings')}
			</Tooltip>
			<a
				href="//feedback.graphite.space"
				target="_blank"
				rel="noreferrer"
				alt={t('Help')}
			>
				<Icon
					ref={helpRef}
					name="question-mark"
					iconSize={18}
					size="md"
					colors="secondary"
				/>
			</a>
			<Tooltip elRef={helpRef} delayShow={1000}>
				{t('Help')}
			</Tooltip>
			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={isOpen}
					anchorEl={panelRef}
					offsetTop={56}
					offsetLeft={-100}
					onClose={hidePanel}
					isFixed
				>
					<Text variant="title4" color="text.primaryalt" sx={titleSx}>
						{t('Display')}
					</Text>
					<ListParams
						listName="display"
						paramSource={paramSource}
						paramList={paramList}
						onChange={changeParam}
					/>
				</PopupWhite>
			</ThemeProvider>
		</>
	);
}

export default React.memo<TProps>(ButtonExtra);
