// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Flex, Logo } from '@graphite/uneon';

const footerSx = {
	flexDirection: 'column',
	alignItems: 'center',
	padding: '18px 0',
	backgroundColor: 'bg.primaryalt',
};

const socialSx = {
	position: 'relative',
	justifyContent: 'center',
	paddingBottom: '18px',
};

const socialLinksSx = {
	margin: ['0 12px 0 0', '0px 15px'],
};

const logoSx = {
	width: '130px',
	marginBottom: '12px',
	'#letters': {
		fill: 'text.tertiary',
	},
	'#alpha': {
		display: 'none',
	},
};

const Footer = () => {
	const { t } = useTranslation();

	return (
		<Flex sx={footerSx} variant="container.fluid">
			<Flex sx={socialSx}>
				<Button
					as="a"
					href="//www.facebook.com/graphite.space/"
					target="_blank"
					variant="secondaryflat.flat.sm"
					sx={socialLinksSx}
				>
					{t('social.facebook')}
				</Button>
				<Button
					as="a"
					href="//twitter.com/graphite_space"
					target="_blank"
					variant="secondaryflat.flat.sm"
					sx={socialLinksSx}
				>
					{t('social.twitter')}
				</Button>
				<Button
					as="a"
					href="//www.youtube.com/channel/UCCzN6VL5FSrzF8NccIhBs1Q"
					target="_blank"
					variant="secondaryflat.flat.sm"
					sx={socialLinksSx}
				>
					{t('Youtube')}
				</Button>
				<Button
					as="a"
					href="//changelog.graphite.space/"
					target="_blank"
					variant="secondaryflat.flat.sm"
					sx={socialLinksSx}
				>
					{t('Changelog')}
				</Button>
			</Flex>

			<Link to="/">
				<Logo sx={logoSx} />
			</Link>
		</Flex>
	);
};

export default Footer;
