// @flow
import get from 'lodash/fp/get';

import { defaultColor } from '@graphite/constants';
import type { TSpecsColor, TColorReference } from '@graphite/types';

const getColor = (spec: TSpecsColor, ref: ?TColorReference): string => {
	try {
		if (!ref) {
			return defaultColor;
		}
		if (typeof ref === 'string') {
			// eslint-disable-next-line no-shadow
			const theme = spec.themes.find(theme => theme.id === spec.activeTheme);
			const fromTheme = get(ref, theme);
			if (typeof fromTheme !== 'string') {
				return getColor(spec, fromTheme);
			}
			return defaultColor;
		}
		if (!ref.entryId) {
			return ref.snapshot;
		}
		const entry = spec.palette.find(e => e.id === ref.entryId);
		if (!entry) {
			return ref.snapshot;
		}
		if (!ref.shadeId) {
			return entry.color;
		}
		const shade = entry.shades.find(s => s.id === ref.shadeId);
		if (!shade) {
			return ref.snapshot;
		}
		return shade.color;
	} catch (e) {
		return defaultColor;
	}
};

export default getColor;
