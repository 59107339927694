// @flow
import React from 'react';
import Widget from 'Widget';
import type {
	TId,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
} from '@graphite/types';


type TProps = $ReadOnly<{|
	childId: TId,
	widgetChain: $ReadOnlyArray<TId>,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
|}>;

const Site = ({
	childId,
	widgetChain,
	gridspec,
	widgetspec,
	colorspec,
	effectspec,
}: TProps) => (
	<Widget
		id={childId}
		containerId={null}
		instanceId={null}
		originId={childId}
		widgetChain={widgetChain}
		widgetspec={widgetspec}
		colorspec={colorspec}
		gridspec={gridspec}
		effectspec={effectspec}
		/>
	);

export default React.memo<TProps>(Site);
