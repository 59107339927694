// @flow

import React, { memo, useCallback, useRef, useMemo } from 'react';
import emptyFunction from 'empty/function';
import {
	ListItem,
	Section,
	PopupWhite,
	ButtonGroup,
	Flex,
	Box,
	Text,
} from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';
import { genId } from 'libs/firebase';
import logger from '@graphite/logger';
import type { TId, TSpecs, TSpecsEffect, TEffectBorder } from '@graphite/types';
import ListBorders from './ListBorders';
import InputBorder from './InputBorder';

type TProps = $ReadOnly<{|
	effectspec: TSpecsEffect,
	updateSpecs?: TSpecs => void,
|}>;

const insertButton = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'primary',
		},
	],
};

const detailButton = [
	{
		name: 'detail',
		icons: [
			{
				name: 'square-side',
				iconSize: 18,
			},
		],
	},
];

const paramList = [
	{
		title: 'Name',
		key: 'name',
		kind: 'string',
		info: {
			maxLength: 16,
		},
	},
	{
		title: 'Width',
		key: 'size',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
];

const relContainerSx = {
	position: 'relative',
};

const absButtonSx = {
	position: 'absolute',
	bottom: '12px',
	right: '0',
};

const detailActive = ['detail'];

function Borders({ effectspec, updateSpecs = emptyFunction }: TProps) {
	const [editedBorderId, setEditedBorder] = React.useState<?TId>(null);
	const editedBorder = React.useMemo(() => effectspec.borders.find(p => p.id === editedBorderId), [effectspec, editedBorderId]);

	const insertBorder = useCallback(() => {
		try {
			updateSpecs({
				[effectspec._id]: {
					...effectspec,
					borders: [
						({
							id: genId('specs'),
							name: 'Border',
							kind: 'solid',
							size: 1,
							sides: null,
						}: TEffectBorder),
						...effectspec.borders,
					],
				},
			});
		} catch (e) {
			logger.error(e);
		}
	}, [effectspec, updateSpecs]);

	const removeBorder = useCallback(
		id => {
			try {
				const removeAt = effectspec.borders.findIndex(p => p.id === id);
				if (removeAt < 0) {
					return;
				}
				if (editedBorder && editedBorder.id === id) {
					setEditedBorder(null);
				}
				updateSpecs({
					[effectspec._id]: {
						...effectspec,
						borders: [
							...effectspec.borders.slice(0, removeAt),
							...effectspec.borders.slice(removeAt + 1),
						],
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[effectspec, updateSpecs, editedBorder],
	);

	const clickBorder = useCallback(
		id => {
			try {
				const target = effectspec.borders.find(p => p.id === id);
				if (!target) {
					return;
				}
				setEditedBorder(id);
			} catch (e) {
				logger.error(e);
			}
		},
		[effectspec.borders],
	);

	const uneditBorder = useCallback(() => setEditedBorder(null), [setEditedBorder]);

	const changeBorder = useCallback(
		value => {
			try {
				if (!editedBorder) {
					return;
				}

				const editAt = effectspec.borders.findIndex(p => p.id === value.id);
				if (editAt < 0) {
					return;
				}

				updateSpecs({
					[effectspec._id]: {
						...effectspec,
						borders: [
							...effectspec.borders.slice(0, editAt),
							value,
							...effectspec.borders.slice(editAt + 1),
						],
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[editedBorder, effectspec, updateSpecs],
	);

	const changeParam = useCallback(
		(key, value) => {
			if (editedBorder && Object.keys(editedBorder).includes(key)) {
				const newBorder = {
					...editedBorder,
					[key]: value,
				};
				changeBorder(newBorder);
			}
		},
		[changeBorder, editedBorder],
	);

	const changeSide = useCallback(
		(side, value) => {
			if (!editedBorder) {
				return;
			}

			const newBorder = {
				...editedBorder,
				sides: {
					...editedBorder.sides,
					[`${side}`]: value,
				},
			};
			changeBorder(newBorder);
		},
		[changeBorder, editedBorder],
	);

	const changeDetail = useCallback(
		(e, names) => {
			if (!editedBorder || !names || typeof names === 'string') {
				return;
			}
			const newBorder = {
				...editedBorder,
				sides: names[0] === 'detail' ? {} : null,
			};
			changeBorder(newBorder);
		},
		[changeBorder, editedBorder],
	);

	const paramSource = useMemo(
		() =>
			(editedBorder && {
				name: editedBorder.name,
				kind: editedBorder.kind,
				size: `${editedBorder.size}`,
			}) ||
			null,
		[editedBorder],
	);

	const anchorRef = useRef();

	return (
		<>
			<Flex>
				<Box flexGrow={1}>
					<Section
						label="Borders"
						buttonGroup={insertButton}
						onClick={insertBorder}
					>
						<ListBorders
							active={editedBorder ? editedBorder.id : null}
							borders={effectspec.borders}
							onRemove={removeBorder}
							onClick={clickBorder}
						/>
					</Section>
				</Box>
				<Box ref={anchorRef} />
			</Flex>
			{editedBorder && paramSource && (
				<PopupWhite
					isOpen={!!editedBorder}
					anchorEl={anchorRef}
					offsetLeft={36}
					offsetTop={-20}
					onClose={uneditBorder}
					mutex="color"
					isFixed
				>
					<Text variant="title4" color="text.primaryalt" marginBottom={21}>
						Border
					</Text>
					<Box sx={relContainerSx}>
						<ListParams
							listName={editedBorder.id}
							paramSource={paramSource}
							paramList={paramList}
							unit={1}
							onChange={changeParam}
						/>
						<ButtonGroup
							sx={absButtonSx}
							behavior="checkbox"
							buttons={detailButton}
							colors="tertiaryflat"
							activeColors="accentflat"
							variant="flat"
							active={editedBorder.sides ? detailActive : null}
							onClick={changeDetail}
						/>
					</Box>
					{editedBorder.sides && (
						<ListItem>
							<InputBorder
								border={editedBorder}
								side="n"
								onChange={changeSide}
							/>
							<InputBorder
								border={editedBorder}
								side="e"
								onChange={changeSide}
							/>
							<InputBorder
								border={editedBorder}
								side="s"
								onChange={changeSide}
							/>
							<InputBorder
								border={editedBorder}
								side="w"
								onChange={changeSide}
							/>
						</ListItem>
					)}
				</PopupWhite>
			)}
		</>
	);
}

Borders.defaultProps = {
	updateSpecs: emptyFunction,
};

export default memo<TProps>(Borders);
