// @flow
import { placeWidget } from 'Editor/ducks/widgets';
import type { TId, Dispatch, TAction, TWidgetOwnProps } from '@graphite/types';


const mapDispatchToPropsEdit = (
	dispatch: Dispatch<TAction>,
	ownProps: TWidgetOwnProps,
) => {
	const { id } = ownProps;

	return {
		dispatch,
		placeWidget: (protoId: TId) => {
			dispatch(
				placeWidget(protoId, id, null, id, {
					kind: 'grid',
					prevId: null,
					nextId: null,
					destRect: null,
					dragRect: null,
					breakpoints: null,
				}),
			);
		},
	};
};

export default mapDispatchToPropsEdit;
