// @flow
import React from 'react';
import { Box, Overlay } from '@graphite/uneon';
import emptyFunction from 'empty/function';
import chroma from 'chroma-js';

type TProps = $ReadOnly<{|
	saturation: number,
	value: number,
	hue: number,
	onChange?: (number, number) => void,
	onPreview?: (number, number) => void,
|}>;

const HEIGHT = 240;

const cursorSx = {
	cursor: 'pointer',
};

const containerStyle = {
	position: 'relative',
	userSelect: 'none',
	height: `${HEIGHT}px`,
};

const handleWrapperStyle = {
	position: 'relative',
	width: '0',
	height: '0',
};

const boundStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	cursor: 'pointer',
};

const handleStyle = {
	position: 'absolute',
	left: '-7px',
	top: '-7px',
	width: '14px',
	height: '14px',
	backgroundColor: 'spec.lightblue10',
	borderRadius: 'rounded.all',
	boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
};

const previewStyle = {
	position: 'absolute',
	left: '-4px',
	top: '-4px',
	width: '8px',
	height: '8px',
	borderRadius: 'rounded.all',
};

const topDownStyle = {
	...boundStyle,
	background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
};

const getLeftRightStyle = hue => ({
	background: [
		'linear-gradient(90deg, hsla(',
		hue,
		', 0%, 50%, 0) 0%, hsla(',
		hue,
		', 100%, 50%, 1) 100%)',
	].join(''),
});

function SliderColorSv({
	saturation,
	value,
	hue,
	onChange = emptyFunction,
	onPreview = emptyFunction,
}: TProps) {
	const [width, setWidth] = React.useState(0);
	const [ownSaturation, setOwnSaturation] = React.useState(saturation);
	const [ownValue, setOwnValue] = React.useState(value);
	const [isGrab, setGrab] = React.useState(false);

	React.useEffect(() => setOwnSaturation(saturation), [saturation]);
	React.useEffect(() => setOwnValue(value), [value]);

	const x = ownSaturation * width;
	const y = (1 - ownValue) * HEIGHT;

	const containerRef = React.useRef();

	const pickColor = React.useCallback(
		(clientX, clientY) => {
			if (!containerRef.current) {
				return null;
			}
			const rect = containerRef.current.getBoundingClientRect();
			// eslint-disable-next-line no-shadow
			const x = Math.max(0, Math.min(width, clientX - rect.x));
			// eslint-disable-next-line no-shadow
			const y = Math.max(0, Math.min(HEIGHT, clientY - rect.y));
			const newSaturation = x / width;
			const newValue = 1 - y / HEIGHT;
			setOwnSaturation(newSaturation);
			setOwnValue(newValue);
			return [newSaturation, newValue];
		},
		[width, setOwnSaturation, setOwnValue],
	);

	const onMouseMove = React.useCallback(
		({ clientX, clientY }: MouseEvent) => {
			if (!isGrab) return;

			window.requestAnimationFrame(() => {
				const colors = pickColor(clientX, clientY);
				if (colors) {
					onPreview(...colors);
				}
			});
		},
		[pickColor, onPreview, isGrab],
	);

	const onMouseUp = React.useCallback(
		({ clientX, clientY }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				const colors = pickColor(clientX, clientY);
				if (colors) {
					onChange(...colors);
				}
			});

			setGrab(false);
		},
		[pickColor, onChange],
	);

	const onMouseDown = React.useCallback(
		({ clientX, clientY }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				const colors = pickColor(clientX, clientY);
				if (colors) {
					onPreview(...colors);
				}
			});

			setGrab(true);
		},
		[pickColor, onPreview],
	);

	React.useEffect(() => {
		window.requestAnimationFrame(() => {
			if (containerRef.current) {
				setWidth(containerRef.current.getBoundingClientRect().width);
			}
		});
	}, []);

	const leftRightStyle = React.useMemo(() => getLeftRightStyle(hue), [hue]);

	const positionStyle = React.useMemo(
		() => ({
			cursor: 'pointer',
			position: 'absolute',
			left: `${x}px`,
			top: `${y}px`,
		}),
		[x, y],
	);

	const handleColorStyle = React.useMemo(() => {
		const [h, s, l] = chroma(hue, ownSaturation, ownValue, 'hsv').hsl();
		return {
			background: `hsl(${h || 0}, ${s * 100}%, ${l * 100}%)`,
		};
	}, [hue, ownSaturation, ownValue]);

	return (
		<>
			<Box sx={containerStyle} ref={containerRef}>
				<Box sx={boundStyle} backgroundColor="#FFF" />
				<Box sx={boundStyle} style={leftRightStyle} />
				<Box sx={topDownStyle} />
				<Box style={positionStyle}>
					<Box sx={handleWrapperStyle} onMouseDown={onMouseDown}>
						<Box sx={handleStyle} />
						<Box sx={previewStyle} style={handleColorStyle} />
					</Box>
				</Box>
				<Box style={boundStyle} onMouseDown={onMouseDown} />
			</Box>
			{isGrab && (
				<Box sx={cursorSx} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
					<Overlay isActive={isGrab} />
				</Box>
			)}
		</>
	);
}

SliderColorSv.defaultProps = {
	onChange: emptyFunction,
	onPreview: emptyFunction,
};

export default React.memo<TProps>(SliderColorSv);
