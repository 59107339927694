// @flow
import _ from 'lodash/fp';
import type { TWidgetMethodPlaceWidget } from '@graphite/types';

export const placeWidgetHook: TWidgetMethodPlaceWidget = ({
	widgets,
	id,
	containerId,
}) => {
	const widget = widgets[id];
	const container = widgets[containerId];
	const { userId, scope, scopeId } = container;

	return {
		updated: {
			[widget._id]: _.flow(
				_.set('scope', scope),
				_.set('scopeId', scopeId),
				_.set('userId', userId),
			)(widget),
		},
	};
};

export default placeWidgetHook;
