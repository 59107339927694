// @flow
import React from 'react';

const useWatchResize = () => {
	const innerElRef: React$ElementRef<any> = React.useRef(null);
	const [frameStyle, setFrameStyle] = React.useState(() => ({}));
	React.useEffect(() => {
		const changeResize = () => {
			const el = innerElRef.current;
			if (!el) return;

			const scale = (el.clientWidth + 1) / 1200;
			const height = (el.clientHeight + 1) / scale;

			setFrameStyle({
				transform: `scale(${scale})`,
				height: `${height}px`,
			});
		};

		changeResize();
		window.addEventListener('resize', changeResize);

		return () => {
			window.removeEventListener('resize', changeResize);
		};
	}, []);

	return { frameStyle, innerElRef };
};

export default useWatchResize;
