// @flow

import React from 'react';
import filter from 'lodash/fp/filter';
import type { TriggerData } from './types';

const contextDefaults = {
	actions: [],
	dispatch: () => ({ payload: '', type: '' }),
};

const reg = (state, { action }) => {
	const actions = filter(act => {
		if (act.widgetId === action.widgetId && act.type === action.type) return false;
		return true;
	}, state.actions);

	return {
		actions: [...actions, action],
	};
};

const onEvent = (state, { triggerId }, type) => {
	const actions = filter(action => {
		const actionData = action?.triggerIds.includes(triggerId);
		if (actionData && type === action.type) return true;
		return false;
	}, state.actions);

	actions.forEach(action => {
		action.fx();
	});
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'reg':
			return {
				...state,
				...reg(state, action.payload),
			};
		// наверное лучше закинуть тип ивента в payload
		case 'click':
			onEvent(state, action.payload, 'click');
			return state;
		case 'hover':
			onEvent(state, action.payload, 'hover');
			return state;
		default:
			return state;
	}
};

export const useTriggerContext = (): TriggerData => {
	const trigger = React.useReducer(reducer, contextDefaults);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return React.useMemo(() => trigger, [trigger[0]]);
};

export const TriggerContext = React.createContext<TriggerData>([
	contextDefaults,
	() => {},
]);
