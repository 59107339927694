// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { ToolbarBtn } from '@graphite/uneon';
import { removeInlineStyles } from '@graphite/widget-text';

type TPropsRemoveStylesBtn = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
|}>;

const RemoveStylesBtn = (props: TPropsRemoveStylesBtn, ref) => {
	const { editorState, setEditorState } = props;

	const changeInlineStyle = React.useMemo(() => {
		const setInlineStyle = removeInlineStyles(editorState);

		return e => {
			e.preventDefault();

			const editorStateNew = setInlineStyle();
			setEditorState(editorStateNew);
		};
	}, [editorState, setEditorState]);

	return <ToolbarBtn onClick={changeInlineStyle} iconName="erase-18" ref={ref} />;
};

export default React.memo<TPropsRemoveStylesBtn>(React.forwardRef(RemoveStylesBtn));
