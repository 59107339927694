// @flow
import React from 'react';
import type { TWidget, TWidgetMode } from '@graphite/types';
import useHandlers from './libs/useHandlers';

type TMinimalProps = $ReadOnly<{
	data: TWidget,
	widgetMode: TWidgetMode,
}>;

const withTrigger = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const WidgetWrapper = (props: T, ref) => {
		const { data, widgetMode } = props;
		const { onClick = null, onMouseEnter = null, onMouseLeave = null } = useHandlers(
			data.id,
		);

		/* eslint-disable-next-line react/jsx-props-no-spreading */
		if (widgetMode) return <Component {...props} ref={ref} />;

		return (
			<Component
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...props}
				ref={ref}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		);
	};

	return React.memo(React.forwardRef(WidgetWrapper));
};

export default withTrigger;
