// @flow
import React from 'react';

type TProps = $ReadOnly<{|
	name: string,
|}>;

const Icon = ({ name }: TProps, ref) => {
	const [module, setModule] = React.useState(null);

	React.useEffect(() => {
		let isCancelled = false;

		// Нужно явно отмонтировать старый компонент
		setModule(null);

		(async () => {
			// eslint-disable-next-line no-shadow
			const Component = await import(`./icons/${name}.svg`);
			if (!isCancelled) setModule(Component);
		})();

		return () => {
			isCancelled = true;
		};
	}, [name, setModule]);

	const Component = module ? module.ReactComponent : null;

	return Component ? <Component ref={ref} /> : null;
};

export default React.memo<TProps>(React.forwardRef(Icon));
