// @flow
import React from 'react';
import { Toolbar, ToolbarBtn, Popper, Tooltip, Text } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';

import AlignSelfPanel from './AlignSelfPanel';
import type { TPropsToolbar } from './types';
import { PARAMS } from './constants';

const tooltipSx = {
	textTransform: 'capitalize',
};

const AlignSelfToolbar = ({
	t,
	data,
	alignItems = 'stretch',
	direction,
	currentDevice,
	onChange,
	openedPanel,
	setOpenedPanel,
	position,
}: TPropsToolbar) => {
	const alignBtnEl: React$ElementRef<any> = React.useRef(null);
	const isActive = openedPanel === 'align-self';

	const { alignSelf } = closestDeviceWithKey(data.box, {
		currentDevice,
		// eslint-disable-next-line
		key: `box-${data._id}`,
	});

	const handleTooltipAlign = React.useCallback(
		e => {
			e.preventDefault();
			setOpenedPanel(opened => (opened === 'align-self' ? null : 'align-self'));
		},
		[setOpenedPanel],
	);

	if (position === 'absolute') return null;

	return (
		<>
			<ToolbarBtn
				onClick={handleTooltipAlign}
				iconName={PARAMS[direction][alignSelf || alignItems].icon}
				ref={alignBtnEl}
				isActive={isActive}
				color={alignSelf ? 'primaryflat' : 'tertiaryflat'}
			/>
			<Tooltip elRef={alignBtnEl} placement={isActive ? 'bottom' : 'top'}>
				{t('Align self')}&nbsp;&nbsp;
				<Text as="span" color="spec.lightblue80" sx={tooltipSx}>
					{t(PARAMS[direction][alignSelf || alignItems].tooltip)}
				</Text>
			</Tooltip>
			<Popper
				isOpen={isActive}
				anchorEl={alignBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<AlignSelfPanel
						t={t}
						variant="compact"
						onChange={onChange}
						direction={direction}
						data={data}
						alignItems={alignItems}
						currentDevice={currentDevice}
						position={position}
					/>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TPropsToolbar>(AlignSelfToolbar);
