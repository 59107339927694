// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Box, Text } from '@graphite/uneon';

import Topbar from './Topbar';

type TProps = $ReadOnly<{|
	name: string,
	photoURL: ?string,
	logOut: () => void,
	insertProject: () => void,
|}>;
const menuBarSx = {
	flexDirection: ['column', 'row'],
	justifyContent: 'space-between',
	alignItems: ['start', 'center'],
	paddingTop: '35px',
	paddingBottom: '57px',
	h2: {
		marginRight: '30px',
	},
};

const Header = ({ logOut, insertProject, name, photoURL }: TProps) => {
	const { t } = useTranslation();

	return (
		<Box data-kind="user-header" variant="container.fluid">
			<Topbar logOut={logOut} name={name} photoURL={photoURL} t={t} />

			<Flex sx={menuBarSx}>
				<Text as="h2" variant="title1" color="text.primaryalt">
					{t('dashboard.header.projects')}
				</Text>
				<Button
					data-kind="user-header-insert-proect"
					variant="accentflat.flat.sm"
					onClick={insertProject}
				>
					{t('dashboard.header.new project')}
				</Button>
			</Flex>
		</Box>
	);
};
export default React.memo<TProps>(Header);
