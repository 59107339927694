// @flow
import mapValues from 'lodash/fp/mapValues';
import forEach from 'lodash/fp/forEach';
import entries from 'lodash/fp/entries';
import keys from 'lodash/fp/keys';
import sortBy from 'lodash/fp/sortBy';

import { defaultDevice } from '@graphite/constants';
import type {
	TId,
	TOrderDevice,
	TOrder,
	TGridBreakpointName,
	TEntityChildren,
} from '@graphite/types';

export default (
	order: TOrder = {},
	children: $ReadOnly<TEntityChildren> = {},
	currentDevice?: ?TGridBreakpointName,
): TOrder => {
	const activeSet = (() => {
		if (Object.keys(order).length) {
			return order;
		}
		if (currentDevice) {
			return { [`${currentDevice}`]: {} };
		}
		return { [`${defaultDevice}`]: {} };
	})();
	return (mapValues.convert({ cap: false })(
		(od: TOrderDevice, key: TGridBreakpointName): TOrderDevice => {
			let i = 0;
			let filtered: TOrderDevice = {};

			const deviceOrder = order[key];
			if (deviceOrder) {
				const sortedOrder = sortBy(item => item[1], entries(deviceOrder));
				forEach(([k]: [TId, number]) => {
					if (children[k]) {
						// eslint-disable-next-line no-plusplus
						filtered = { ...filtered, [k]: i++ };
					}
				}, sortedOrder);
			}

			forEach((k: TId) => {
				if (filtered[k] === undefined) {
					// eslint-disable-next-line no-plusplus
					filtered = { ...filtered, [k]: i++ };
				}
			}, keys(children));

			return filtered;
		},
		activeSet,
	): TOrder);
};
