// @flow
import React from 'react';
import logger from '@graphite/logger';

type Props = $ReadOnly<{|
	children: React$Node,
|}>;

type State = $ReadOnly<{|
	hasError: boolean,
|}>;

class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(/* error */) {
		return { hasError: true };
	}

	componentDidCatch(
		error: Error,
		errorInfo: {
			componentStack: string,
		},
	) {
		logger.error(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <h2>Something went wrong.</h2>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
