// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { PopupMenu, PopupWhite, Flex, Button, Text, IconClear } from '@graphite/uneon';
import { Params } from '@graphite/lists';
import encodeUrl from 'Editor/libs/encodeUrl';
import type { TId, TWidget, TWidgetDiff, TParams, TParamSource } from '@graphite/types';

import Textarea from './Textarea';

const ItemTitle = styled(Text)`
	margin-left: 18px;
	margin-right: 24px;
	flex-grow: 1;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const ItemIcon = styled(Flex)`
	align-items: center;
	opacity: 0;
	transition-property: opacity;
	transition-timing-function: ease-out;
	transition-duration: 0.15s;
`;

const IsMainIcon = styled(ItemIcon)`
	justify-content: center;
	position: absolute;
	pointer-events: none;
	right: 0;
	opacity: 1;
	width: 30px;
	height: 30px;

	svg {
		fill: ${({ isActive, theme }) =>
			isActive ? 'white' : theme.colors.text.tertiary};
	}
`;

const ItemIconWrap = styled(Flex)`
	align-items: center;
	position: absolute;
	right: 18px;
	top: 0;
	bottom: 0;
`;

const Border = styled.div`
	position: absolute;
	width: 100%;
	height: 45%;
	left: 0;

	&:after {
		content: '';
		display: none;
		position: absolute;
		height: 2px;
		background: ${({ theme }) => theme.colors.spec.blue10};
		left: 24px;
		right: 24px;
	}
`;

const TopBorder = styled(Border)`
	top: -3px;
	&:after {
		top: -1px;
	}
`;

const BottomBorder = styled(Border)`
	bottom: -3px;
	&:after {
		bottom: -1px;
	}
`;

const Element = styled(Flex)`
	position: relative;
	padding: 7px 24px 8px;
	margin: 6px 0;
	cursor: pointer;
	background: ${({ theme, isActive }) =>
		isActive ? theme.colors.spec.blue10 : 'inherit'};

	/* Короче, если он сейчас isEdit, то подсвечиваем всегда, иначе только по ховеру */
	&${({ isEdit }) => !isEdit && ':hover'} {
		background: ${({ theme, isActive }) =>
			theme.colors.spec.blue10 + (isActive ? '' : '15')};

		${ItemIcon} {
			opacity: 1;
		}

		${IsMainIcon} {
			display: none;
		}

		& ${ItemTitle} {
			margin-right: 48px;
		}
	}

	${TopBorder}, ${BottomBorder} {
		display: ${({ isDrag }) => (isDrag ? 'block' : 'none')};
	}

	${TopBorder} {
		&:after {
			display: ${({ dropSide }) => (dropSide === 'top' ? 'block' : 'none')};
		}
	}

	${BottomBorder} {
		&:after {
			display: ${({ dropSide }) => (dropSide === 'bottom' ? 'block' : 'none')};
		}
	}

	& ${ItemTitle} {
		${({ isActive }) => (isActive ? 'color: white;' : '')}
	}
`;

type TProps = $ReadOnly<{|
	isActive: boolean,
	isDrag: boolean,
	dropSide: ?$ReadOnly<{| targetId: ?string, side: ?string |}>,
	page: TWidget,
	regItem: (TId, HTMLDivElement) => void,
	unregItem: TId => void,
	remove: TId => void,
	clone: TId => void,
	makeHomePage: TId => void,
	update: (TId, TWidgetDiff) => void,
	onSelect: TId => void,
|}>;

const Item = ({
	isActive,
	isDrag,
	dropSide,
	page,
	regItem,
	unregItem,
	remove,
	clone,
	update,
	onSelect,
	makeHomePage,
}: TProps) => {
	const { t } = useTranslation();
	const itemRef = React.useRef(null);

	const isMain = page.url === 'index';

	React.useEffect(() => {
		if (!(itemRef.current instanceof HTMLDivElement)) return;

		regItem(page._id, itemRef.current);

		return () => {
			unregItem(page._id);
		};
	}, [page, regItem, unregItem]);

	const popupMenuButton = React.useMemo(
		() => ({
			variant: 'flat',
			colors: isActive ? 'accent' : 'primaryflat',
			icons: 'dots-horizontal-3',
			size: 'sm',
			title: t('Actions'),
		}),
		[t, isActive],
	);

	const popupMenuList = React.useMemo(
		() => ({
			colors: 'primaryflat',
			items: [
				{
					label: t('Make Home Page'),
					name: 'makeHomePage',
				},
				{
					label: t('Duplicate'),
					name: 'clone',
				},
				{
					label: t('Remove'),
					name: 'remove',
				},
			],
		}),
		[t],
	);

	const popupMenuListMain = React.useMemo(
		() => ({
			colors: 'primaryflat',
			items: [
				{
					label: t('Duplicate'),
					name: 'clone',
				},
			],
		}),
		[t],
	);

	const popupMenuClick = React.useCallback(
		(e, action) => {
			e.stopPropagation();
			if (!action || typeof action !== 'string') return;

			if (action === 'remove') return remove(page._id);
			if (action === 'clone') return clone(page._id);
			if (action === 'makeHomePage') return makeHomePage(page._id);
		},
		[clone, page, remove, makeHomePage],
	);

	const anchorRef = React.useRef(null);
	const [isOpenEditPopup, setOpenEditPopup] = React.useState(false);
	const onOpenEditPopup = React.useCallback(
		e => {
			e.stopPropagation();
			setOpenEditPopup(!isOpenEditPopup);
		},
		[isOpenEditPopup],
	);

	const onCloseEditPopup = React.useCallback(e => {
		e.stopPropagation();
		setOpenEditPopup(false);
	}, []);

	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Name'),
				key: 'name',
				kind: 'string',
				info: {
					maxLength: 78,
					placeholder: 'Name',
					validate: value => {
						if (!value) return false;
						if (value.length < 2) return false;
						if (value.toLowerCase() === 'index') return false;
					},
				},
			},
			{
				title: t('Title'),
				key: 'title',
				kind: 'string',
				info: { maxLength: 0, placeholder: page.name || '' },
			},
			{
				title: t('URL'),
				key: 'url',
				kind: 'string',
				info: {
					maxLength: 0,
					placeholder: encodeUrl(page.name || ''),
					signKey: 'sign',
					validate: value => {
						if (isMain) return true;
						if (value === 'index') return false;
						return /^[-a-z0-9_]{2,256}$/.test(value);
					},
					isDisabled: isMain,
				},
			},
		],
		[page.name, t, isMain],
	);

	const paramSource: TParamSource = React.useMemo(
		() => ({
			sign: '/',
			name: page.name || '',
			title: page.title || '',
			url: page.url || '',
		}),
		[page.name, page.title, page.url],
	);

	const onChange = React.useCallback(
		(key, value) => {
			update(page._id, {
				[key]: value,
			});
		},
		[page._id, update],
	);

	const onChangeTextarea = React.useCallback(
		(name: string, value: string) => {
			update(page._id, {
				[name]: value,
			});
		},
		[page._id, update],
	);

	const onSelectHandler = React.useCallback(() => {
		onSelect(page._id);
	}, [page._id, onSelect]);

	const propagationStopHandle = React.useCallback(e => {
		e.stopPropagation();
	}, []);

	const colorGear = React.useMemo(() => {
		if (isOpenEditPopup && !isActive) return 'accentflat.icon.sm';
		return isActive ? 'whiteflat.icon.sm' : 'primaryflat.icon.sm';
	}, [isOpenEditPopup, isActive]);

	return (
		<Element
			ref={itemRef}
			draggable
			isDrag={isDrag}
			dropSide={dropSide}
			data-id={page._id}
			isEdit={isOpenEditPopup}
			isActive={isActive}
			onClick={onSelectHandler}
		>
			<TopBorder />

			<ItemTitle variant="captionlg" color="text.primary">
				{page.name}
			</ItemTitle>

			{/* Кнопки управление страницами */}
			<ItemIconWrap>
				<ItemIcon ref={anchorRef}>
					<Button variant={colorGear} onClick={onOpenEditPopup}>
						<IconClear name="gear-18" />
					</Button>
				</ItemIcon>
				<ItemIcon>
					<PopupMenu
						button={popupMenuButton}
						list={isMain ? popupMenuListMain : popupMenuList}
						onClick={popupMenuClick}
					/>
				</ItemIcon>
				{isMain && (
					<IsMainIcon isActive={isActive}>
						<IconClear name="home-18" />
					</IsMainIcon>
				)}
			</ItemIconWrap>

			<BottomBorder />

			<PopupWhite
				isOpen={isOpenEditPopup}
				anchorEl={anchorRef}
				offsetLeft={90}
				offsetTop={12}
				onClose={onCloseEditPopup}
				mutex="design"
				isFixed
			>
				<div onClick={propagationStopHandle}>
					<Text variant="title4" mb="18px">
						{page.name}
					</Text>
					<Params
						listName={page._id}
						paramSource={paramSource}
						paramList={paramList}
						onChange={onChange}
					/>
					<Textarea
						name="description"
						label={t('Description')}
						value={page.description || ''}
						onChange={onChangeTextarea}
					/>
					<Textarea
						name="keywords"
						label={t('Keywords')}
						value={page.keywords || ''}
						onChange={onChangeTextarea}
					/>
				</div>
			</PopupWhite>
		</Element>
	);
};

export default React.memo<TProps>(Item);
