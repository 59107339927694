// @flow
import React from 'react';
import emptyObject from 'empty/object';
import { useSelector } from '@graphite/use-redux';
import { withAbsolute } from '@graphite/abs-grid';
import { getWidgets } from '@graphite/selectors';
import { composeCached } from 'Widget/selectors';
import { presetWidgets } from '@graphite/constants';
import Image, { type TProps } from '@graphite/widget-image';
import type { TWidgetOwnProps } from '@graphite/types';
import withHydrate from '../../libs/with-hydrate';

const ImageWithDecorators = withAbsolute<TProps>()(withHydrate<TProps>(Image));

const ImageWithProps = ({
	id,
	containerId,
	gridspec,
	widgetspec,
	colorspec,
	effectspec,
}: TWidgetOwnProps) => {
	const widgets = useSelector(getWidgets);

	if (!containerId) return null;
	if (!gridspec) return null;
	if (!widgetspec) return null;
	if (!colorspec) return null;
	if (!effectspec) return null;

	const data = composeCached(
		widgets,
		// Fixme: Подправить после выкатки шаблонов
		widgets[id] || presetWidgets[id],
	);

	const parent = composeCached(
		widgets,
		// Fixme: Подправить после выкатки шаблонов
		widgets[containerId] || presetWidgets[containerId],
	);
	const positions = parent ? parent.positions : emptyObject;

	return (
		<ImageWithDecorators
			data={data}
			widgetspec={widgetspec}
			colorspec={colorspec}
			gridspec={gridspec}
			effectspec={effectspec}
			position={positions[data._id]}
		/>
	);
};

export default ImageWithProps;
