// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Section, Box } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';
import { Params as ListParams } from '@graphite/lists';
import type {
	TWidgetDiff,
	TWidgetBoxBreakpoint,
	TWidgetBox,
	TParamSource,
	TSelectParam,
	TParams,
	TSwitchParam,
} from '@graphite/types';

import type { TConnectPropsControlsSetting } from './types';

const spacerSx = {
	height: '18px',
};

const Settings = ({ onSave, currentDevice, data }: TConnectPropsControlsSetting) => {
	const { t } = useTranslation();

	const directionParam: TSelectParam = React.useMemo(
		() => ({
			title: t('Direction'),
			key: 'flexDirection',
			kind: 'select',
			info: {
				list: {
					items: [
						{ name: 'row', label: t('Row') },
						{ name: 'column', label: t('Column') },
						{ name: 'row-reverse', label: t('Row reverse') },
						{ name: 'column-reverse', label: t('Column reverse') },
					],
				},
			},
		}),
		[t],
	);

	const horizontalParam: TSelectParam = React.useMemo(
		() => ({
			title: t('Horizontal'),
			key: 'justifyContent',
			kind: 'select',
			info: {
				list: {
					items: [
						{ name: 'flex-start', label: t('Left') },
						{ name: 'center', label: t('Center') },
						{ name: 'flex-end', label: t('Right') },
					],
				},
			},
		}),
		[t],
	);

	const verticalParam: TSelectParam = React.useMemo(
		() => ({
			title: t('Vertical'),
			key: 'alignItems',
			kind: 'select',
			info: {
				list: {
					items: [
						{ name: 'flex-start', label: t('Top') },
						{ name: 'center', label: t('Center') },
						{ name: 'flex-end', label: t('Bottom') },
					],
				},
			},
		}),
		[t],
	);

	const spaceListParams = React.useMemo(
		() => [
			{ name: 'space-between', label: t('Space between') },
			{ name: 'space-around', label: t('Space around') },
			{ name: 'space-evenly', label: t('Space evenly') },
		],
		[t],
	);

	const wrapParam: TSwitchParam = React.useMemo(
		() => ({
			title: t('Wrap'),
			key: 'flexWrap',
			kind: 'switch',
			info: emptyObject,
		}),
		[t],
	);

	const formParam: TParams = React.useMemo(
		() => [
			{
				title: t('Tag'),
				key: 'tag',
				kind: 'select',
				info: {
					list: {
						items: [
							{ name: 'div', label: t('Div') },
							{ name: 'form', label: t('Form') },
						],
					},
				},
			},
			{
				title: t('Name'),
				key: 'title',
				kind: 'string',
				info: { placeholder: t('form name'), maxLength: 140 },
			},
		],
		[t],
	);

	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		key: `box-${data._id}`,
	});

	/**
		Flex
	*/
	const paramSourceForm: TParamSource = React.useMemo(
		() => ({
			// так работает Params, честно сказать я в трихуе
			tag: data?.tag || 'div',
			title: data?.title || '',
		}),
		[data?.tag, data?.title],
	);

	/**
		Flex
	*/
	const paramSourceFlex: TParamSource = React.useMemo(
		() => ({
			flexDirection: `${box.flexDirection || 'column'}`,
			alignItems: `${box.alignItems || 'stretch'}`,
			justifyContent: `${box.justifyContent || 'center'}`,
			flexWrap: box.flexWrap === 'wrap' ? 'true' : '',
			title: data?.title || '',
		}),
		[
			box.flexDirection,
			box.alignItems,
			box.justifyContent,
			box.flexWrap,
			data?.title,
		],
	);

	const paramListFlex: TParams = React.useMemo(
		() => [directionParam, wrapParam],
		[directionParam, wrapParam],
	);

	const paramListAlign: TParams = React.useMemo(() => {
		const newHorizontalParams: TSelectParam = {
			..._.set(
				'info.list.items',
				[
					..._.get('info.list.items', horizontalParam),
					...(box.flexDirection === 'row'
						? spaceListParams
						: [{ name: 'stretch', label: t('Stretch') }]),
				],
				horizontalParam,
			),
			key: box.flexDirection === 'row' ? 'justifyContent' : 'alignItems',
		};

		const newVerticalParams: TSelectParam = {
			..._.set(
				'info.list.items',
				[
					..._.get('info.list.items', verticalParam),
					...(box.flexDirection === 'row'
						? [
								{ name: 'baseline', label: t('Baseline') },
								{ name: 'stretch', label: t('Stretch') },
						  ]
						: spaceListParams),
				],
				verticalParam,
			),
			key: box.flexDirection !== 'row' ? 'justifyContent' : 'alignItems',
		};
		return [newHorizontalParams, newVerticalParams];
	}, [box.flexDirection, horizontalParam, spaceListParams, t, verticalParam]);

	const saveFormName = React.useCallback(
		(key, value) => {
			if (typeof value !== 'string' && typeof value !== 'boolean') {
				return;
			}

			const diff: TWidgetDiff = {
				...data,
				[key]: value,
			};

			onSave(diff);
		},
		[data, onSave],
	);

	const saveFlex = React.useCallback(
		(key, value) => {
			if (typeof value !== 'string' && typeof value !== 'boolean') {
				return;
			}

			const finalValue = key === 'flexWrap' ? (value && 'wrap') || 'nowrap' : value;
			let diff: TWidgetDiff = {
				box: (_.set(
					currentDevice,
					(_.set(key, finalValue, box): TWidgetBoxBreakpoint),
					data.box || emptyObject,
				): TWidgetBox),
			};

			if (key === 'flexDirection') {
				diff = _.update(
					`box.${currentDevice}`,
					(data) => ({
						...data,
						alignItems:
							value !== 'row' && box.alignItems === 'baseline'
								? 'flex-start'
								: box.alignItems,
					}),
					diff,
				);
			}

			onSave(diff);
		},
		[box, currentDevice, data.box, onSave],
	);

	return (
		<>
			<Section label={t('Form')}>
				<ListParams
					listName="form"
					paramSource={paramSourceForm}
					paramList={formParam}
					unit={1}
					onChange={saveFormName}
				/>
				<Box sx={spacerSx} />
			</Section>
			<Section label={t('Flow')}>
				<ListParams
					listName="flex"
					paramSource={paramSourceFlex}
					paramList={paramListFlex}
					unit={1}
					onChange={saveFlex}
				/>
				<Box sx={spacerSx} />
			</Section>
			<Section label={t('Content alignment')}>
				<ListParams
					listName="alignment"
					paramSource={paramSourceFlex}
					paramList={paramListAlign}
					unit={1}
					onChange={saveFlex}
				/>
				<Box sx={spacerSx} />
			</Section>
		</>
	);
};

export default React.memo<TConnectPropsControlsSetting>(Settings);
