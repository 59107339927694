// @flow

import React from 'react';
import { InputInplace } from '@graphite/uneon';
import type { TPaddingSide } from '@graphite/types';

type TProps = $ReadOnly<{|
	size: ?string,
	side: TPaddingSide,
	defSize: string,
	onChange?: ?(TPaddingSide, ?number) => void,
|}>;

const onValidate = value => value.length === 0 || !Number.isNaN(+value);

function InputPosition({ size, side, onChange = null, defSize }: TProps) {
	const boundChange = React.useMemo(
		() => value => {
			if (!onChange) {
				return;
			}
			onChange(side, value === '' ? null : +value);
		},
		[onChange, side],
	);

	const value = size === null || size === undefined ? '' : `${size}`;
	return (
		<InputInplace
			suffix="px"
			placeholder={`${defSize || 0}`}
			onValidate={onValidate}
			value={value}
			onEnter={boundChange}
			onBlur={boundChange}
			isGray={!value}
		/>
	);
}

export default React.memo<TProps>(InputPosition);
