// @flow
import React from 'react';
import { AnimatePlayProvider } from '@graphite/animations';

type TMinimalProps = $ReadOnly<{}>;

const withPlay = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const WidgetWrapper = (props: T, ref) => (
		<AnimatePlayProvider>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Component {...props} ref={ref} />
		</AnimatePlayProvider>
	);

	return React.memo(React.forwardRef(WidgetWrapper));
};

export default withPlay;
