// @flow
import React, { memo } from 'react';
import { Wrap, DragSizes, DragMargin, DragMarginBottom } from '../elements';
import type { TResizeControls, TAxis, TSides } from '../types';

const n = 'top';
const e = 'right';
const s = 'bottom';
const w = 'left';
const nw = 'top-left';
const ne = 'top-right';
const sw = 'bottom-left';
const se = 'bottom-right';

const sides: TSides = {
	corners: [nw, ne, sw, se],
	we: [w, e], // Горизонтально
	ns: [n, s], // Вертикально
	n: [n], // Верх
	e: [e], // Право
	s: [s], // Низ
	w: [n], // Вертикально
};

const emptyMargin = {
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
};

const Controls = (
	{
		isShown,
		dynamicSide,
		margin,
		actions,
		requiredControls: { height, width, margin: isMargin },
		isResize,
		isAbsolute,
	}: TResizeControls,
	ref,
): React$Node => {
	const axis: TAxis = React.useMemo(() => {
		if (!width && !height) return [];
		if (width && !height) return sides.we;
		if (!width && height) return sides.s;

		return sides.corners;
	}, [height, width]);

	return (
		<>
			{isMargin && !isAbsolute && (
				<DragMarginBottom
					isShown={isShown}
					dir="bottom"
					resizeUpdate={actions.onUpdate}
					resizeStart={actions.onStart}
					resizeStop={actions.onStop}
					control="margin"
				/>
			)}
			<Wrap ref={ref} margin={isAbsolute ? emptyMargin : margin} isShown={isShown}>
				{isMargin &&
					!isAbsolute &&
					dynamicSide.map(side => (
						<DragMargin
							isShown={isShown}
							key={`resize-${side || ''}`}
							dir={side}
							control="margin"
							resizeUpdate={actions.onUpdate}
							resizeStart={actions.onStart}
							resizeStop={actions.onStop}
						/>
					))}
				{axis.map(side => (
					<DragSizes
						isShown={isShown}
						dir={side}
						key={side}
						control={isAbsolute ? 'offset' : 'sizes'}
						resizeUpdate={actions.onUpdate}
						resizeStart={actions.onStart}
						resizeStop={actions.onStop}
						isResize={isResize}
						margin={isAbsolute ? emptyMargin : margin}
						/>
					))}
			</Wrap>
		</>
	);
};

export default memo<TResizeControls>(React.forwardRef(Controls));
