// @flow
import React from 'react';

const useHover = (ref: React$ElementRef<any>) => {
	const [value, setValue] = React.useState(false);

	React.useEffect(() => {
		const handleMouseOver = (e: MouseEvent) => {
			const node = ref.current;
			if (!node || !(node instanceof node.ownerDocument.defaultView.Node))
				setValue(false);
			else setValue(e.target === node || node.contains(e.target));
		};

		const node = ref.current;
		const doc = node ? node.ownerDocument : null;

		if (doc) doc.addEventListener('mouseover', handleMouseOver);

		return () => {
			if (doc) doc.removeEventListener('mouseover', handleMouseOver);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref.current]);

	return value;
};

export default useHover;
