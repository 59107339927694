// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import emptyObject from 'empty/object';

import Widget from 'Widget';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import { getBoxSx, getDesignSx } from '@graphite/selectors';
import type { TSx, TDesign } from '@graphite/types';

import type { TConnectProps } from './constants/types';

const baseSx = {
	position: 'relative',
};

const Stack = (props: TConnectProps, ref) => {
	const {
		data,
		instanceId,
		babies,
		dragContainer,
		dragFlip,
		widgetChain,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
		position,
		onClick,
		originId,
		widgetMode,
	} = props;
	const { _id } = data;
	const parentChain = React.useMemo(() => [_id, ...(widgetChain || [])], [
		widgetChain,
		_id,
	]);
	const boxSx = gridspec ? getBoxSx({ data, position, gridspec }) : emptyObject;

	const designSx = React.useMemo(() => {
		if (!data.designId || !widgetspec || !gridspec || !colorspec || !effectspec) {
			return null;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.stack.find(d => d._id === data.designId);
		if (!design) {
			return null;
		}

		return getDesignSx({
			design,
			gridspec,
			colorspec,
			effectspec,
			widgetspec,
		});
	}, [data, widgetspec, gridspec, colorspec, effectspec]);

	const sx: TSx = React.useMemo(() => _.mergeAll([baseSx, designSx, boxSx]), [
		designSx,
		boxSx,
	]);

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const fromProps = React.useMemo(() => {
		if (widgetMode) return {};

		if (data?.tag === 'form') {
			return {
				as: 'form',
				name: data?.title || '',
				method: 'POST',
				'data-netlify': 'true',
				'data-netlify-honeypot': 'bot-field',
			};
		}

		return {};
	}, [data, widgetMode]);

	const otherProps = React.useMemo(
		() => _.mergeAll([dragContainer, dragFlip, fromProps]),
		[dragContainer, dragFlip, fromProps],
	);

	return (
		<Box
			id={data.id}
			className={data.className}
			sx={sx}
			style={dynamicStyle}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...otherProps}
			ref={ref}
			data-kind="stack"
			onMouseDown={onClick}
		>
			{babies.map(id => (
				<Widget
					key={id}
					id={id}
					containerId={data._id}
					instanceId={data.modified ? data._id : instanceId}
					originId={originId}
					widgetChain={parentChain}
					widgetspec={widgetspec}
					colorspec={colorspec}
					gridspec={gridspec}
					effectspec={effectspec}
				/>
			))}
		</Box>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Stack));
