// @flow
import React from 'react';
import _ from 'lodash/fp';
import styled from '@emotion/styled';
import { Button, Flex, Box, Text, Icon, ModalFullScreen } from '@graphite/uneon';
import type { TId, TWidgets } from '@graphite/types';

import Preset from './Preset';
import Preview from './Preview';

type TProps = $ReadOnly<{|
	id: TId,
	presets: TWidgets,
	t: (string) => string,
	onCreateSite: (TId, ?TId) => void,
	onShowPresetsHandler: () => void,
	className?: string,
|}>;

const presetSx = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(435px, 1fr))',
	marginBottom: '60px',
};

const descriptionBoxSx = { paddingBottom: '30px' };

const headerModalSx = {
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	paddingBottom: '33px',
};

const backToTemplatesSx = {
	position: 'absolute',
	left: '42px',
};

const closeButtonSx = {
	position: 'absolute',
	right: '21px',
};

const headerPreviewModalSx = {
	justifyContent: 'center',
	alignItems: 'center',
	paddingBottom: '15px',
	paddingTop: '15px',
	zIndex: 2,
	backgroundColor: 'bg.primary',
	borderBottom: '1px solid',
	borderColor: 'bg.primaryalt',
};

const createButtonSx = { position: 'absolute', right: '99px' };
const templateTextSx = { marginLeft: '13px' };

const TopBar = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

const Presets = ({
	id,
	presets,
	t,
	onCreateSite,
	onShowPresetsHandler,
	className,
}: TProps) => {
	const [idPreview, setIdPreview] = React.useState<?TId>(null);

	const onSelectPreviewHandler = React.useCallback((id) => {
		setIdPreview(id);
	}, []);

	const onClosePreviewHandler = React.useCallback(() => {
		setIdPreview(null);
	}, []);

	const onCreateSiteHandler = React.useCallback(() => {
		if (idPreview) onCreateSite(idPreview);
	}, [idPreview, onCreateSite]);

	const modalSx = React.useMemo(
		() => ({
			'> div': {
				position: 'relative',
				overflowX: 'hidden',
				overflowY: idPreview ? 'hidden' : 'auto',
			},
		}),
		[idPreview],
	);

	return (
		<ModalFullScreen
			onClose={onShowPresetsHandler}
			sx={modalSx}
			className={className}
		>
			{idPreview && (
				<>
					<TopBar data-kind="preview-preset-head">
						<Flex sx={headerPreviewModalSx}>
							<Button
								data-kind="preview-preset-head-back"
								variant="accentflat.flat.sm"
								sx={backToTemplatesSx}
								onClick={onClosePreviewHandler}
							>
								<Icon
									name="arrow-top"
									iconSize={18}
									colors="accentflat"
									rotation={-90}
								/>
								<Text variant="headlinemd" sx={templateTextSx}>
									{t('Templates')}
								</Text>
							</Button>
							<Text as="h4" variant="title3" color="text.primaryalt">
								{presets[idPreview].name}
							</Text>
							<Button
								data-kind="preview-preset-head-create"
								variant="accent.rounded"
								onClick={onCreateSiteHandler}
								sx={createButtonSx}
							>
								<Text variant="headlinemd">{t('Create site')}</Text>
							</Button>
							<Button
								data-kind="preview-preset-head-close"
								variant="primaryflat.icon.lg"
								onClick={onShowPresetsHandler}
								sx={closeButtonSx}
							>
								<Icon name="cross" iconSize={24} colors="primaryflat" />
							</Button>
						</Flex>
					</TopBar>
					<Preview
						key={presets[idPreview]._id}
						containerId={id}
						site={presets[idPreview]}
					/>
				</>
			)}

			{!idPreview && (
				<Box
					data-kind="preview-preset-gallery"
					mt="32px"
					mb="32px"
					ml="42px"
					mr="42px"
				>
					<Flex sx={headerModalSx}>
						<Text as="h4" variant="title3" color="text.primaryalt">
							{t('Create new site')}
						</Text>
						<Button
							data-kind="preview-preset-gallery-close"
							variant="primaryflat.icon.lg"
							onClick={onShowPresetsHandler}
							sx={closeButtonSx}
						>
							<Icon name="cross" iconSize={24} colors="primaryflat" />
						</Button>
					</Flex>
					<Box sx={descriptionBoxSx}>
						<Text variant="bodymd" color="text.secondary">
							{t('Start from scratch or choose a template')}
						</Text>
					</Box>
					<Box variant="grid" sx={presetSx}>
						{_.map(
							(preset) => (
								<Preset
									key={preset._id}
									t={t}
									containerId={id}
									site={preset}
									onCreate={onCreateSite}
									onPreview={onSelectPreviewHandler}
								/>
							),
							presets,
						)}
					</Box>
				</Box>
			)}
		</ModalFullScreen>
	);
};
export default React.memo<TProps>(Presets);
