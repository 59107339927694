// @flow
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useInput = (value: string, onChange: string => void, delay: number = 300) => {
	const currentValue = React.useRef(value);
	const [newValue, changeParam] = React.useState<string>(value);
	const saveParam = useDebouncedCallback(onChange, delay);

	React.useEffect(() => {
		if (currentValue.current !== newValue) {
			saveParam(newValue);
		}
	}, [newValue, saveParam]);

	React.useEffect(() => {
		if (currentValue.current === value) return;
		changeParam(value);
		currentValue.current = value;
	}, [value]);

	return [newValue, changeParam];
};

export default useInput;
