// @flow
import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';

import mapStateToProps from './mapStateToProps';

const mapStateToPropsEdit = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const { editor } = state;
	return {
		...mapStateToProps(state, ownProps),
		currentDevice: editor.currentDevice,
	};
};

export default mapStateToPropsEdit;
