// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute, withAbsoluteDrag } from '@graphite/abs-grid';
import Image, { type TProps } from '@graphite/widget-image';
import { withPlay } from '@graphite/animation-edit';
import withRef from '@graphite/with-ref';

import withSymbiote from 'Editor/libs/with-symbiote';
import withControls from 'Editor/libs/with-controls';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import withDrag from 'Widget/libs/dnd/drag';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';
import withWidgetEdit from 'Widget/libs/with-widget-edit';
import type { TWidgetOwnProps } from '@graphite/types';

import type {
	TConnectPropsEdit,
	TConnectPropsDragInSymbioteWithControls,
	TConnectPropsDragWithImageLib,
} from './constants/types';
import Controls from './Controls';
import withImageLib from './libs/with-image-lib';
import withHydrate from '../../libs/with-hydrate';
import mapDispatchToPropsEdit from './libs/mapDispatchToPropsEdit';
import mapStateToPropsEdit from './libs/mapStateToPropsEdit';

const WidgetComponentEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		mapStateToPropsEdit,
		mapDispatchToPropsEdit,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	(Component) => withRef<TConnectPropsEdit>(Component),
	withDrag,
	withWidgetResizer,
	// Выносим ХОКи на служебный слой
	withPlay,
	withSymbiote(
		compose(
			// Component => withWidgetEdit<TConnectPropsDragInSymbiote>(Component),
			withWidgetEdit,
			withWidgetControls({
				margin: true,
			}),
			(Component) =>
				withControls<TConnectPropsDragInSymbioteWithControls>(
					checkActiveWidget,
					Controls,
					Component,
					{
						isAnimateAvailable: true,
					},
				),
			(Component) => withImageLib<TConnectPropsDragWithImageLib>(Component),
		),
	),
	// Выносим ХОКи на служебный слой
	withSymbiote(
		// Добавляет возможность таскать абсолютные виджеты
		withAbsoluteDrag,
		{
			level: 'abs-drag-place',
		},
	),
	withAbsolute<TProps>(),
	withHydrate,
)(Image);

export default WidgetComponentEdit;
