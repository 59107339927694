// @flow
import React from 'react';
import styled from '@emotion/styled';
import { zIndices } from '@graphite/constants';
import { Box } from '@graphite/uneon';

import type { TMinimalPropsWith } from './constants/types';
import { INITIAL_RECT } from './constants';
import Context from './Context';
import checkMove from './libs/check-move';

const Wrapper = styled(Box)`
	position: relative;
	width: 100%;
	height: 100%;
`;

const withDrag = <T1: TMinimalPropsWith>(
	Component: React$ComponentType<$ReadOnly<{| ...$Exact<T1> |}>>,
): React$ComponentType<$Exact<T1>> => {
	const WithDrag = (props: $Exact<T1>) => {
		const {
			currentRef,
			id,
			editId,
			containerId,
			instanceId,
			originId,
			position,
			widgetMode,
			startEdit,
			widgetOrderIndex,
			hovered,
			dragAvailable,
			editChain,
			widgetChain = [],
		} = props;

		const elRef = React.useRef<?HTMLElement>(null);

		const isMouseDown = React.useRef(false);
		const isDraging = React.useRef(false);

		const swipeX = React.useRef(0);
		const swipeY = React.useRef(0);

		const ref = React.useRef(null);

		const { onStart, onStop, onDrag } = React.useContext(Context);

		const isEdit: boolean = widgetMode === 'widget-edit';

		const sx = React.useMemo(() => {
			const style = {
				pointerEvents: 'none',
				zIndex: zIndices.absControlsFactor + widgetOrderIndex,
			};

			if (hovered || dragAvailable) style.pointerEvents = 'auto';
			if (isEdit) style.pointerEvents = 'none !important';

			return style;
		}, [hovered, isEdit, dragAvailable, widgetOrderIndex]);

		const onMouseMove = React.useCallback(
			(e: MouseEvent) => {
				const x = (swipeX.current - e.pageX) * -1;
				const y = (swipeY.current - e.pageY) * -1;

				// если сдвиг больше погрешности
				if (checkMove({ x, y }) && !isDraging.current) {
					// Если задетектили драг и пока не в нём, то инициализируем начало драга
					isDraging.current = true;
					onStart({
						ref,
						rect: currentRef.current
							? currentRef.current.getBoundingClientRect()
							: INITIAL_RECT,
						id,
						containerId,
						instanceId,
						originId,
						position: { x, y },
					});
					e.preventDefault();
				} else if (checkMove({ x, y })) {
					// Если драг уже идёт, то продолжаем слать события
					onDrag(e, { deltaX: x, deltaY: y });
					e.preventDefault();
				}
			},
			[containerId, currentRef, id, instanceId, onDrag, onStart, originId],
		);

		const onMouseUp = React.useCallback(
			(e: MouseEvent) => {
				document.removeEventListener('mousemove', onMouseMove);
				document.removeEventListener('mouseup', onMouseUp);
				isMouseDown.current = false;

				if (!isDraging.current && startEdit) {
					e.preventDefault();
					/* Если уже в редактировании, то лишний раз не кидаем диспатч */
					if (isEdit) return;

					startEdit(editId || id, editChain || widgetChain);
					return;
				}

				isDraging.current = false;

				e.stopPropagation();

				onStop();
			},
			[onMouseMove, onStop, editId, id, editChain, widgetChain, isEdit, startEdit],
		);

		const onMouseDown = React.useCallback(
			(e: MouseEvent) => {
				if (isEdit) return;
				if (e.button !== 0) return;

				isMouseDown.current = true;

				swipeX.current = e.pageX;
				swipeY.current = e.pageY;

				document.addEventListener('mouseup', onMouseUp);
				document.addEventListener('mousemove', onMouseMove);
			},
			[onMouseMove, onMouseUp, isEdit],
		);

		if (!position || !position.includes('absolute')) {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <Component {...props} />;
		}

		return (
			<>
				<Wrapper
					onMouseDown={onMouseDown}
					data-kind="with-absolute-drag"
					ref={elRef}
					sx={sx}
				>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Component {...props} />
				</Wrapper>
			</>
		);
	};

	return React.memo<$Exact<T1>>(WithDrag);
};

export default withDrag;
