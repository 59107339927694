// @flow
import React from 'react';
import { ToolbarBtn, Tooltip } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	isActive: boolean,
	onClick: string => void,
	icon: string,
	title: string,
	align: string,
|}>;

const ToolbarBtnAlign = ({ isActive, onClick, icon, title, align }: TProps) => {
	const alignBtnEl: React$ElementRef<any> = React.useRef(null);
	const handleOnClick = React.useCallback(
		e => {
			e.preventDefault();
			onClick(align);
		},
		[onClick, align],
	);
	return (
		<>
			<ToolbarBtn
				onClick={handleOnClick}
				iconName={icon}
				ref={alignBtnEl}
				isActive={isActive}
			/>
			<Tooltip elRef={alignBtnEl} placement="top">
				{title}
			</Tooltip>
		</>
	);
};

export default React.memo<TProps>(ToolbarBtnAlign);
