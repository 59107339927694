// @flow
import React from 'react';

import { Flex } from '@graphite/uneon';
import styled from '@emotion/styled';
import chroma from 'chroma-js';

import { getActiveBreakpointNames } from '@graphite/selectors';

import useDefaultDevice from 'Editor/libs/use-default-device';
import type {
	TGridBreakpointName,
	TSpecsGrid,
	TSpecsGridBreakpoints,
} from '@graphite/types';

const DEVICE_FON_COLORS = { phone_p: 'tiffany50', tablet_p: 'darkblue30' };

const BreakPointLine = styled(Flex)`
	border-left: 1px dashed;
	border-right: 1px dashed;
	border-color: ${({ theme }) => theme.colors.spec.darkblue60};
	background-color: ${({ theme, fonDevice }) =>
		fonDevice
			? `rgba(${chroma(
					theme.colors.spec[
						DEVICE_FON_COLORS?.[fonDevice]
							? DEVICE_FON_COLORS[fonDevice]
							: 'darkblue30'
					],
			  )
					.rgb()
					.join(',')}, 0.1)`
			: ''};
	position: absolute;
	left: ${({ width, breakpoint }) => `${Math.floor((width - breakpoint) / 2)}px`};
	top: ${({ topGap }) => `${topGap}px`};
	bottom: ${({ bottomGap }) => `${bottomGap}px`};
	width: ${({ breakpoint }) => `${breakpoint}px`};
	pointer-events: none;
`;

type TProps = $ReadOnly<{|
	currentDevice: TGridBreakpointName,
	breakpoints: TSpecsGridBreakpoints,
	width: number,
	gridspec: TSpecsGrid,
|}>;

const BreakPointLines = (props: TProps) => {
	const { currentDevice, width, breakpoints, gridspec } = props;

	const breakpointNames = getActiveBreakpointNames({ gridspec });

	const defaultDevice = useDefaultDevice();

	return breakpointNames.map(name => {
		if (
			!(
				breakpointNames[breakpointNames.indexOf(currentDevice) + 1] === name ||
				(currentDevice === name && name !== defaultDevice)
			)
		)
			return null;

		return (
			<BreakPointLine
				key={name}
				topGap={-Math.floor(window.innerHeight * 0.8)}
				bottomGap={-Math.floor(window.innerHeight * 0.8)}
				width={width}
				breakpoint={breakpoints[name].breakpoint}
				fonDevice={currentDevice === name ? name : ''}
			/>
		);
	});
};

export default React.memo<TProps>(BreakPointLines);
