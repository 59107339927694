// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import type { TId, TPositionValue } from '@graphite/types';

import type { TDragGridProps } from './DragGrid/types';
import DragGrid from './DragGrid';

type TWithoutDragProps = $ReadOnly<{||}>;

type TMinimalProps = $ReadOnly<{
	...$Exact<TDragGridProps>,
	...$Exact<TWithoutDragProps>,
	instanceId: ?TId,
	position?: TPositionValue,
}>;

const DragWrapper = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$Exact<T>> => {
	const DragGridComponent = DragGrid<T>(Component);

	const DragHOC = (props: T, ref: React$ElementRef<*>) => {
		const { position, instanceId } = props;
		const isAbsolute = position === 'absolute' || position === 'absolute-container';
		const isInstance = !!instanceId;

		if (isInstance || isAbsolute) {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <Component {...props} ref={ref} />;
		}

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <DragGridComponent {...props} ref={ref} />;
	};

	DragHOC.displayName = `Drag(${getDisplayName(Component)})`;
	return React.memo<T>(React.forwardRef(DragHOC));
};

export default DragWrapper;
