// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Text } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	insertProject: () => void,
|}>;

const Wrap: React$ComponentType<{}> = styled(Box)`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid;
	border-color: ${({ theme }) => theme.colors.bg.secondary};
	margin-top: 46px;
	height: 240px;
	cursor: pointer;
	transition: border-color 0.15s ease-out;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.5;
		transition: background-color 0.15s ease-out;
	}

	&:hover {
		&:before {
			background-color: ${({ theme }) => theme.colors.bg.primaryalt};
		}
		
		border-color: ${({ theme }) => theme.colors.bg.accent};
	},
`;

const TextStyled = styled(Text)`
	margin-top: 7px;
	z-index: 1;
`;

const EmptyProject = ({ insertProject }: TProps) => {
	const { t } = useTranslation();

	return (
		<Wrap data-kind="user-empty-project-insert" onClick={insertProject}>
			<Icon colors="accentflat" name="plus" iconSize={30} />
			<TextStyled variant="headlinemd" color="text.accent">
				{t('dashboard.project.new project')}
			</TextStyled>
		</Wrap>
	);
};

export default EmptyProject;
