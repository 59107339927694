// @flow
import React from 'react';
import {
	Icon,
	List,
	PopupSelect,
	InputInplace,
	useInput,
	Text,
	Flex,
} from '@graphite/uneon';
import type { TPopupOnClick, TUnitParam, TSx } from '@graphite/types';

type TProps = $ReadOnly<{|
	sign?: ?string,
	value: string,
	valueUnit?: ?string,
	param: TUnitParam,
	unit: number,
	onChange: number => void,
	onClick?: TPopupOnClick,
	placeholder?: string,
	sx?: TSx,
	maxWidth?: string,
|}>;

const paramRightSx = {
	flexGrow: 1,
	maxWidth: '50%',
	position: 'relative',
	height: '100%',
	alignItems: 'center',
};

const paramHoverSx = {
	...paramRightSx,
	'> div:last-child': {
		opacity: 0,
	},
	':hover > div:last-child': {
		opacity: 1,
	},
};

const paramLeftUnitSx = {
	minWidth: '50%',
	flexShrink: 0,
};

const signSx = {
	textAlign: 'right',
	position: 'absolute',
	right: '100%',
	marginRight: '15px',
};

const selectSx = {
	position: 'absolute',
	right: 0,
	top: 0,
	bottom: 0,
	// FIXME: надо параметризировать, потому что зависит от паддинга листайтема
	backgroundImage: 'linear-gradient(90deg, #FAFCFD00 0%, #FAFCFDFF 100%)',
	alignItems: 'center',
	justifyContent: 'flex-end',
	transition: 'opacity 0.15s ease-in',
};

const inputSx = {
	marginLeft: '-9px',
	marginRight: '6px',
};

const domains = {
	any: value => value !== undefined,
	positive: value => value > 0,
	nonnegative: value => value >= 0,
	onehundred: value => value >= 0 && value <= 100,
};

const validateReq = value => value.length > 0 && !Number.isNaN(+value);
const validateNreq = value => !value || validateReq(value);

const getSuffix = ({ showUnits, unitKey }, valueUnit) => {
	if (!showUnits || (unitKey && !valueUnit)) {
		return '';
	}
	if (!unitKey) {
		return 'px';
	}
	if (valueUnit === 'auto') {
		return '';
	}
	return valueUnit;
};

const emptyUnits = [];
const defaultKeysWithoutValue = ['auto'];

function ListItemUnitParams({
	sign,
	value,
	valueUnit,
	param,
	unit,
	onChange,
	onClick,
	sx = null,
	placeholder,
	maxWidth = '34px',
}: TProps) {
	const { info } = param;
	const units = info.units || emptyUnits;
	const isSelectable: boolean = units.length > 1;

	const handleChange = React.useCallback(
		(val: string) => {
			// т.к мы принимаем строку для работы в инпут,
			// но должны вернуть число, то преобразовываем перед сохранением
			onChange(+val);
		},
		[onChange],
	);

	const [isOpen, setIsOpen] = React.useState(false);
	const toggleOpen = React.useCallback(() => {
		const newOpen = !isOpen;
		setIsOpen(newOpen);
	}, [isOpen, setIsOpen]);

	const suffix = getSuffix(info, valueUnit);

	const [currentValue, changeValue] = useInput(value, handleChange);

	const boundClick = React.useCallback(
		(e, itemName, buttons) => {
			setIsOpen(false);
			if (!onClick || (typeof itemName !== 'string' && itemName !== null)) {
				return;
			}
			onClick(e, itemName, buttons);
		},
		[onClick],
	);

	const list = React.useMemo(
		() => ({
			items: units.map(name => ({ name, label: name })),
			active: info.unitKey ? valueUnit : null,
			colors: 'primaryflat',
			activeColors: 'accentflat',
			behavior: 'radio',
		}),
		[info.unitKey, valueUnit, units],
	);

	const anchorRef = React.useRef();

	const validator = info.isRequired === false ? validateNreq : validateReq;
	// Этот инпут используется всегда для чисел, на кой тут такие сложные проверки?
	// ToDo надеюсь мы отрефакторим когда нибудь этот код
	const validInteger = (val, { max = 0, min = 0, isInteger = false }) => {
		if (isInteger) {
			if ((max && Number(val) > max) || (min && Number(val) < min)) return false;
			if (/^-?\d*$/.test(val)) return true;
			return false;
		}

		if (/[+-]?([0-9]*[.])?[0-9]+$/.test(val)) return true;
		return false;
	};

	const validate = React.useCallback(
		changed => {
			const preValid = validator(changed) && validInteger(changed, info);
			if (changed) {
				return preValid && domains[info.domain || 'any'](+changed);
			}
			return preValid;
		},
		[info, validator],
	);

	const keyDown = React.useCallback(
		e => {
			if (!['ArrowUp', 'ArrowDown'].includes(e.key)) {
				return;
			}
			e.preventDefault();

			const direction = e.key === 'ArrowUp' ? 1 : -1;
			const factor =
				(e.ctrlKey && 100) || (e.shiftKey && 10) || (e.altKey && 0.1) || 1;
			const newValue = (+currentValue || 0) + direction * factor;
			if (validate(`${newValue}`)) {
				changeValue(`${newValue}`);
			}
		},
		[changeValue, currentValue, validate],
	);

	const wrapSx = React.useMemo(
		() => ({
			...(isSelectable ? paramHoverSx : paramRightSx),
			...sx,
		}),
		[isSelectable, sx],
	);

	const isUnit = info.showUnits && info.unitKey && valueUnit === 'unit';
	const isHideUnitInput = React.useMemo(() => {
		const keyWithoutValue = info.keyWithoutValue
			? [...defaultKeysWithoutValue, ...info.keyWithoutValue]
			: defaultKeysWithoutValue;
		return info.showUnits && keyWithoutValue.includes(valueUnit);
	}, [info.keyWithoutValue, info.showUnits, valueUnit]);

	return (
		<>
			<Flex sx={wrapSx}>
				{info.signKey && (
					<Text variant="bodysm" color="text.tertiary" sx={signSx}>
						{sign || ''}
					</Text>
				)}
				{(!isHideUnitInput && (
					<Flex sx={paramLeftUnitSx}>
						<InputInplace
							placeholder={placeholder}
							value={`${currentValue}`}
							sx={inputSx}
							suffix={suffix}
							onValidate={validate}
							onBlur={changeValue}
							onEnter={changeValue}
							onChange={changeValue}
							onKeyDown={keyDown}
							maxWidth={maxWidth}
						/>
					</Flex>
				)) || (
					<Text variant="bodysm" color="text.primary">
						{valueUnit}
					</Text>
				)}
				{isUnit && (
					<Flex>
						<InputInplace
							value={`${+currentValue * unit}`}
							sx={inputSx}
							suffix="px"
							isDisabled
						/>
					</Flex>
				)}
				{isSelectable && (
					<Flex ref={anchorRef} sx={selectSx}>
						<Icon
							name="expand-small-top"
							rotation={isOpen ? 0 : 180}
							colors="secondaryflat"
							size="sm"
							onClick={toggleOpen}
						/>
					</Flex>
				)}
			</Flex>
			{isSelectable && (
				<PopupSelect
					isOpen={isOpen}
					anchorEl={anchorRef}
					offsetTop={0}
					offsetLeft={0}
					onClose={toggleOpen}
				>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<List {...list} onClick={boundClick} />
				</PopupSelect>
			)}
		</>
	);
}

ListItemUnitParams.defaultProps = {
	sign: null,
	valueUnit: null,
	onChange: null,
	onClick: null,
};

export default React.memo<TProps>(ListItemUnitParams);
