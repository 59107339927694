// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash/fp';
import { ButtonGroup, Text, Box } from '@graphite/uneon';
import { deviceTabsMd, gridBreakpointsNames } from '@graphite/constants';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import logger from '@graphite/logger';
import { getCurrentSiteGridSpec } from '@graphite/selectors';
import { updateEditor } from 'Editor/ducks/editor';
import { useDispatch } from '@graphite/use-redux';
import { getCurrentDevice } from 'Editor/selectors/editor';
import useDefaultDevice from 'Editor/libs/use-default-device';
import type { TSpecsGrid } from '@graphite/types';

type TProps = $ReadOnly<{||}>;

const DEVICE_MSG_BOX_COLORS = { phone_p: 'tiffany50' };

const groupStyle = {
	flexShrink: 0,
	marginTop: '10px',
};

const BreakPointMsgBox = styled(Box)`
	position: absolute;
	height: 24px;
	background-color: ${({ theme, device }) =>
		DEVICE_MSG_BOX_COLORS[device]
			? theme.colors.spec[DEVICE_MSG_BOX_COLORS[device]]
			: theme.colors.bg.accentplus};
	top: 70px;
	border-radius: 99px;
	padding: 3px 12px;
	margin-left: -24px;
`;

const ButtonsDevices = () => {
	const currentDevice = useSelector(getCurrentDevice);
	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const { t } = useTranslation();
	const defaultDevice = useDefaultDevice();

	const dispatch = useDispatch();

	const availableDevices = React.useMemo(
		() =>
			deviceTabsMd.filter(
				({ name }) => !!_.get(`breakpoints.${name}.active`, gridspec),
			),
		[gridspec],
	);

	const device = React.useMemo(
		() =>
			availableDevices.find(d => d.name === currentDevice) ||
			availableDevices[0] ||
			deviceTabsMd[0],
		[currentDevice, availableDevices],
	);

	const changeDevice = React.useCallback(
		(e, device) => {
			const currentDevice =
				gridBreakpointsNames.find(name => name === device) ||
				gridBreakpointsNames[0];
			logger.info('pickDevice', { device: currentDevice });
			dispatch(updateEditor({ currentDevice }));
		},
		[dispatch],
	);

	return (
		<Box>
			<ButtonGroup
				behavior="radio"
				active={device.name}
				buttons={availableDevices}
				variant="flat"
				size="md"
				colors="secondary"
				activeColors="primary"
				onClick={changeDevice}
				sx={groupStyle}
				margin={6}
			/>
			{defaultDevice !== currentDevice && (
				<BreakPointMsgBox device={currentDevice}>
					<Text variant="notelg" color="white">
						{t('Changes apply for ')}
						<Text variant="captionlg" color="white" as="span">
							{currentDevice.split('_')[0]}
						</Text>
					</Text>
				</BreakPointMsgBox>
			)}
		</Box>
	);
};

export default React.memo<TProps>(ButtonsDevices);
