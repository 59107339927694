// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { Section, PopupWhite, SliderSimple, Flex, Box, Text } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import { defaultColor } from '@graphite/constants';
import Library from '@graphite/library';

import { genId } from 'libs/firebase';
import logger from '@graphite/logger';
import { useDispatch } from '@graphite/use-redux';
import { updateSpecs } from 'Editor/ducks/specs';
import { Color as LabelColor } from '@graphite/labels';
import { ShadedColors as ListShadedColors, ListItemParams } from '@graphite/lists';
import type { TSpecsColor, TPaletteEntry, TSpecsGrid } from '@graphite/types';

const MAX_SHADES = 9;

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
|}>;

const baseTextStyle = {
	margin: '48px 0 18px 1px',
};

const baseItemStyle = {
	marginBottom: '45px',
};

const sliderStyle = {
	marginTop: '12px',
	marginBottom: '30px',
};

const libColorCustom = { color: { tabs: ['custom'] } };

const colorButtons = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'primary',
		},
	],
};

const genShade = () => ({ id: genId('specs'), color: defaultColor });

const incShades = (shades, mainId) => {
	const shadeAt = shades.findIndex(s => s.id === mainId);
	const isLeft = shadeAt > -1 && shadeAt <= (shades.length - 1) * 0.5;
	return [...(isLeft ? [genShade()] : []), ...shades, ...(!isLeft ? [genShade()] : [])];
};

const decShades = (shades, mainId) => {
	const shadeAt = shades.findIndex(s => s.id === mainId);
	const isLeft = shadeAt > -1 && shadeAt <= (shades.length - 1) * 0.5;
	return shades.slice(isLeft ? 0 : 1, isLeft ? -1 : undefined);
};

const resizeShades = (entry: TPaletteEntry, size: number): TPaletteEntry => {
	const { id, color, shades } = entry;
	const finalSize = Math.max(0, Math.min(MAX_SHADES, size));
	if (size === shades.length) {
		return entry;
	}
	if (finalSize === 0) {
		return { ...entry, shades: [] };
	}
	if (finalSize === 1) {
		return { ...entry, shades: [{ id, color }] };
	}
	let newShades = shades.length ? shades : [{ id, color }];
	while (newShades.length < finalSize) {
		newShades = incShades(newShades, id);
	}
	while (newShades.length > finalSize) {
		newShades = decShades(newShades, id);
	}
	return { ...entry, shades: newShades };
};

function Palette({ colorspec, gridspec }: TProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [editedColor, setEditedColor] = React.useState(null);

	const insertColor = React.useCallback(() => {
		try {
			const name = 'Color';
			const shadeCount = 0;
			const id = genId('specs');
			const shades = [];
			for (let i = 0; i < shadeCount; i++) {
				if (i === Math.floor(shadeCount * 0.5)) {
					shades.push({ id, color: defaultColor });
					continue;
				}
				shades.push(genShade());
			}
			const updated = {
				...colorspec,
				palette: [
					{
						id,
						name,
						color: defaultColor,
						shades,
					},
					...colorspec.palette,
				],
			};
			logger.info('editColorSpec');
			dispatch(updateSpecs({ [updated._id]: updated }));
			setEditedColor({
				kind: 'color',
				value: { entryId: id, shadeId: null, snapshot: defaultColor },
			});
		} catch (e) {
			logger.error(e);
		}
	}, [colorspec, dispatch]);

	const removeColor = React.useCallback(
		id => {
			try {
				const removeAt = colorspec.palette.findIndex(p => p.id === id);
				if (removeAt < 0) {
					return;
				}
				if (editedColor && editedColor.value.entryId === id) {
					setEditedColor(null);
				}
				const updated = {
					...colorspec,
					palette: [
						...colorspec.palette.slice(0, removeAt),
						...colorspec.palette.slice(removeAt + 1),
					],
				};
				dispatch(updateSpecs({ [updated._id]: updated }));
			} catch (e) {
				logger.error(e);
			}
		},
		[colorspec, dispatch, editedColor],
	);

	const clickColor = React.useCallback(
		(e, entryId, shadeId) => {
			try {
				const editAt = colorspec.palette.findIndex(p => p.id === entryId);
				if (editAt < 0) {
					return;
				}

				const newEntry = {
					...colorspec.palette[editAt],
				};

				let srcColor = newEntry.color;
				if (shadeId) {
					const shade = newEntry.shades.find(s => s.id === shadeId);
					if (shade) {
						srcColor = shade.color;
					}
				}

				setEditedColor({
					kind: 'color',
					value: { entryId, shadeId, snapshot: srcColor },
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[colorspec],
	);

	const uneditColor = React.useCallback(() => setEditedColor(null), [setEditedColor]);

	const changeColor = React.useCallback(
		value => {
			try {
				if (
					!editedColor ||
					value.kind !== 'color' ||
					typeof value.value === 'string'
				) {
					return;
				}

				const newColor = getColor(colorspec, value.value);
				const {
					value: { shadeId, entryId },
				} = editedColor;

				const editAt = colorspec.palette.findIndex(p => p.id === entryId);
				if (editAt < 0) {
					return;
				}

				const newEntry = {
					...colorspec.palette[editAt],
				};

				if (!shadeId || entryId === shadeId) {
					newEntry.color = newColor;
				}

				const shadeAt = newEntry.shades.findIndex(
					s => s.id === (shadeId || entryId),
				);
				if (shadeAt > -1) {
					const newShade = {
						...newEntry.shades[shadeAt],
						color: newColor,
					};
					newEntry.shades = [
						...newEntry.shades.slice(0, shadeAt),
						newShade,
						...newEntry.shades.slice(shadeAt + 1),
					];
				}
				const updated = {
					...colorspec,
					palette: [
						...colorspec.palette.slice(0, editAt),
						newEntry,
						...colorspec.palette.slice(editAt + 1),
					],
				};
				dispatch(updateSpecs({ [updated._id]: updated }));
			} catch (e) {
				logger.error(e);
			}
		},
		[editedColor, colorspec, dispatch],
	);

	const editAt: number = React.useMemo(() => {
		if (!editedColor) {
			return -1;
		}
		return colorspec.palette.findIndex(p => p.id === editedColor.value.entryId);
	}, [colorspec.palette, editedColor]);

	const changeShades = React.useCallback(
		value => {
			if (editAt < 0) {
				return;
			}
			dispatch(
				updateSpecs({
					[colorspec._id]: _.set(
						`palette.${editAt}`,
						resizeShades(colorspec.palette[editAt], value),
						colorspec,
					),
				}),
			);
		},
		[editAt, colorspec, dispatch],
	);

	const anchorRef = React.useRef();

	const numShades =
		editAt > -1 ? Math.min(MAX_SHADES, colorspec.palette[editAt].shades.length) : 0;

	const nameParamProps = React.useMemo(() => ({
			paramSource: { name: _.get(`palette.${editAt}.name`, colorspec) },
			unit: 1,
			param: {
				title: t('Name'),
				key: 'name',
				kind: 'string',
				info: {
					maxLength: 0,
					placeholder: t('Color name'),
				},
			},
			onChange: (key, newName) => {
				dispatch(
					updateSpecs({
						[colorspec._id]: _.set(
							`palette.${editAt}.name`,
							newName,
							colorspec,
						),
					}),
				);
			},
		}), [colorspec, dispatch, editAt, t]);

	return (
		<>
			{colorspec.generator.isEnabled && (
				<>
					<Text variant="headlinemd" color="spec.darkblue80" sx={baseTextStyle}>
						{t('Base')}
					</Text>
					<Box sx={baseItemStyle}>
						<LabelColor
							title={colorspec.generator.baseColor || '#000000'}
							color={colorspec.generator.baseColor || '#000000'}
						/>
					</Box>
				</>
			)}
			<Flex>
				<Box flexGrow={1}>
					<Section
						label={t('Colors')}
						buttonGroup={colorButtons}
						onClick={insertColor}
					>
						<ListShadedColors
							active={editedColor ? editedColor.value : null}
							items={colorspec.palette}
							onRemove={removeColor}
							onClick={clickColor}
						/>
					</Section>
				</Box>
				<Box ref={anchorRef} />
			</Flex>

			{editedColor && (
				<PopupWhite
					isOpen={!!editedColor}
					anchorEl={anchorRef}
					offsetLeft={36}
					offsetTop={-100}
					onClose={uneditColor}
					mutex="color"
					isFixed
				>
					<Library
						t={t}
						config={libColorCustom}
						value={editedColor}
						onChange={changeColor}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					>
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<ListItemParams {...nameParamProps} />
						<Box sx={sliderStyle}>
							<SliderSimple
								min={0}
								max={MAX_SHADES}
								title={t('Shades')}
								value={numShades}
								unit=""
								onChange={changeShades}
							/>
						</Box>
					</Library>
				</PopupWhite>
			)}
		</>
	);
}

export default React.memo<TProps>(Palette);
