// @flow
import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';
import type { Dispatch, TId, TAction } from '@graphite/types';


const mapDispatchToProps = (dispatch: Dispatch<TAction>) => ({
		// FixMe: выпилить dispatch
		dispatch,
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
		resetImage: () => {
			dispatch(reset());
		},
	});

export default mapDispatchToProps;
