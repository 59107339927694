// @flow
import React from 'react';
import useTrigger from './useTrigger';

const useHandlers = (id: ?string) => {
	const trigger = useTrigger();

	const handlers = React.useMemo(() => {
		if (!id) return {};
		return {
			onClick: () => trigger.onClick(id),
			onMouseEnter: () => trigger.onMouseEnter(id),
			onMouseLeave: () => trigger.onMouseLeave(id),
		};
	}, [trigger, id]);

	return handlers;
};

export default useHandlers;
