// @flow
import React from 'react';
import _ from 'lodash/fp';
import getDisplayName from '@graphite/get-display-name';
import styled from '@emotion/styled';
import type { TWidgetMode, TWidgetDiff } from '@graphite/types';
import type { TWidgetButton } from '../constants/types';

type TMinimalProps = $ReadOnly<{
	widgetMode: TWidgetMode,
	data: TWidgetButton,
	editWidget: (diff: TWidgetDiff) => void,
}>;

const SpanEditable = styled.span`
	outline: none;
	border: none;

	&[contentEditable='false'] {
		user-select: none;
	}
	&:empty {
		/* чтобы курсор было видно */
		padding-left: 1px;
	}
`;

const DELAY_SAVE = 1e3;

const withEditable = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const Editable = (props: T, ref) => {
		const { data, widgetMode, editWidget } = props;
		const { text = { isShown: false, label: '' } } = data;

		const editableRef = React.useRef();
		const prevLabel = React.useRef(text?.label);
		const [html, setInnerHTML] = React.useState({ __html: text?.label });

		// Сохранение
		const save = React.useMemo(
			() =>
				_.debounce(DELAY_SAVE, (label = '') => {
					prevLabel.current = label;

					editWidget({
						text: {
							isShown: (text && text.isShown) || false,
							label,
						},
					});
				}),
			[editWidget, text],
		);

		React.useEffect(() => {
			if (prevLabel.current !== text?.label) {
				prevLabel.current = text?.label;
				setInnerHTML({ __html: text?.label });
			}
		}, [text?.label]);

		const prevWidgetMode = React.useRef(widgetMode);
		React.useEffect(() => {
			// При входе в режим редактирования селектим весь текст
			if (
				prevWidgetMode.current !== widgetMode &&
				widgetMode === 'widget-edit' &&
				editableRef.current
			) {
				const el: HTMLElement = editableRef.current;
				const documentFrame = el.ownerDocument;
				const windowFrame = documentFrame.defaultView;
				const selection = windowFrame.getSelection();
				const range = documentFrame.createRange();
				range.selectNodeContents(el);
				selection.removeAllRanges();
				selection.addRange(range);
				el.focus();
			}

			prevWidgetMode.current = widgetMode;
		}, [widgetMode]);

		const onInputHandler = React.useCallback(
			e => {
				e.stopPropagation();

				if (
					e.target instanceof
					editableRef.current?.ownerDocument.defaultView.Node
				) {
					const text = e.target.innerHTML;
					save(text);
				}
			},
			[save],
		);

		const onBlurHandler = React.useCallback(() => {
			if (editableRef.current?.innerHTML) {
				const text = editableRef.current.innerHTML;
				setInnerHTML({ __html: text });
			}
		}, []);

		const onKeyPressHandler = React.useCallback((e: KeyboardEvent) => {
			if (e.charCode === 13 && editableRef.current) {
				const el: HTMLElement = editableRef.current;
				const documentFrame = el.ownerDocument;
				documentFrame.execCommand('insertLineBreak', false, null);
				e.preventDefault();
			}
		}, []);

		const onPasteHandler = React.useCallback(e => {
			// cancel paste
			e.preventDefault();

			if (editableRef.current) {
				const el: HTMLElement = editableRef.current;
				const documentFrame = el.ownerDocument;

				// get text representation of clipboard
				const text = (e.originalEvent || e).clipboardData.getData('text/plain');

				// insert text manually
				documentFrame.execCommand('insertHTML', false, text);
			}
		}, []);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Component {...props} ref={ref}>
				{text && text.isShown ? (
					<SpanEditable
						contentEditable={widgetMode === 'widget-edit'}
						spellCheck="false"
						onBlur={onBlurHandler}
						onInput={onInputHandler}
						onKeyPress={onKeyPressHandler}
						onPaste={onPasteHandler}
						ref={editableRef}
						dangerouslySetInnerHTML={html}
					/>
				) : null}
			</Component>
		);
	};

	Editable.displayName = `withFontsEdit(${getDisplayName(Component)})`;

	return React.memo<T>(React.forwardRef(Editable));
};

export default withEditable;
