// @flow
import _ from 'lodash/fp';
import type { TWidgetMethodPlaceWidget } from '@graphite/types';

export { addWidgetHook } from '../../../libs/stack-hooks';

export const placeWidgetHook: TWidgetMethodPlaceWidget = ({
	widgets,
	id,
	containerId,
}) => {
	const project = widgets[id];
	const user = widgets[containerId];

	return {
		updated: {
			[project._id]: _.flow(
				_.set('scope', 'user'),
				_.set('scopeId', user._id),
				_.set('userId', user._id),
			)(project),
		},
	};
};

export default {};
