// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import {
	takeEvery,
	put,
	fork,
	select,
	call,
	all,
	take,
	cancel,
} from 'redux-saga/effects';
import { eventChannel, type EventChannel } from 'redux-saga';
import { handleActions } from 'redux-actions';
import { presetWidgets, defaultDevice } from '@graphite/constants';
import i18n from 'i18next';

import * as operations from 'libs/operations/widgets';
import { getSpecsFromPreset } from 'libs/operations/specs';
import traceContainers from 'libs/trace-containers';
import logger from '@graphite/logger';
import compose from 'libs/compose';
import cast from 'libs/types/widgets';
import refinePositions from 'libs/refine-positions';
import findWidgetChain from 'libs/build-chains';
import history from 'libs/history';
import { commitWidgetsToBd, collection, genId } from 'libs/firebase';

import {
	getWidgets,
	closestDeviceWithKey,
	getPresets,
	getCurrentUserId,
	getCurrentSiteWidgetSpec,
	getCurrentSiteBreakpoints,
	getSpecBySite,
} from '@graphite/selectors';

import { getEditor, getWidgetsBuffer, getCurrentWidget } from 'Editor/selectors/editor';

import widgetLibs from 'Widget/libs/chunk';
import type {
	TSpecs,
	TSpecsWidget,
	TDesign,
	TGridBreakpointName,
	TSpecsGridBreakpoints,
	TAction,
	TId,
	TQueryConstraints,
	TQueryConstraint,
	TWidgetKind,
	TOffsetDevice,
	TWidget,
	TWidgets,
	TOffsetKey,
	TPositioned,
	TPositionValue,
	TPosition,
	TWidgetDiff,
	TWidgetComposed,
	TWidgetLeaveParams,
	TWidgetLeaveResult,
	TWidgetEnterResult,
	TWidgetOpMoveWidgetParams,
	TWidgetOpPlaceWidgetParams,
	TWidgetTraceContainersResult,
	TWidgetOpFeedbackEmpty,
	TWidgetOpFeedbackFull,
	TWidgetBoxBreakpoint,
	TWidgetBox,
	TOrder,
	TOrderDevice,
	TEditor,
	TEntityScope,
	TWidgetEnterParams,
	TWidgetMethodEqualize,
} from '@graphite/types';
import type { Saga } from 'redux-saga';
import { creteTask } from '../queue';

import { historyNewAction } from '../history';
import {
	APPLY,
	apply,
	checkCurrentWidgetSaga,
	resetEdit,
	setWidgetsBuffer,
	startEdit,
	addNotice,
} from '../editor';
import { saveSpecs } from '../specs';
import { getSiteData, uploadDataSite } from '../libs';

const initialState: TWidgets = presetWidgets;

const SYNC_SCOPED_WIDGETS = 'WIDGETS/SYNC_SCOPED_WIDGETS';
const UNSYNC_SCOPED_WIDGETS = 'WIDGETS/UNSYNC_SCOPED_WIDGETS';
const DELETE_SCOPE = 'WIDGETS/DELETE_SCOPE';
const SAVE_WIDGETS = 'WIDGETS/SAVE_WIDGETS';
const ADD_WIDGET = 'WIDGETS/ADD_WIDGET';
const PLACE_WIDGET = 'WIDGETS/PLACE_WIDGET';
const DETACH_WIDGET = 'WIDGETS/DETACH_WIDGET';
const CLONE_WIDGET = 'WIDGETS/CLONE_WIDGET';
const SYMBOLIZE_WIDGET = 'WIDGETS/SYMBOLIZE_WIDGET';
const REMOVE_WIDGET = 'WIDGETS/REMOVE_WIDGET';
const MOVE_WIDGET = 'WIDGETS/MOVE_WIDGET';
const EDIT_WIDGET = 'WIDGETS/EDIT_WIDGET';
const UNHIDE_CHILDREN_WIDGET = 'WIDGETS/UNHIDE_CHILDREN_WIDGET';
const REPOSITION_WIDGET = 'WIDGETS/REPOSITION_WIDGET';
const OFFSET_WIDGET = 'WIDGETS/OFFSET_WIDGET';
const CREATE_PRESET = 'WIDGETS/CREATE_PRESET';

const ABS_KINDS = ['absolute', 'absolute-container'];
const UNEDITABLE_KINDS = ['col', 'block', 'page', 'site', 'project', 'user'];

export const syncScopedWidgets = (
	scope: string,
	scopeId: ?TId,
	userId: ?TId,
	key: ?string,
): TAction => ({
	type: SYNC_SCOPED_WIDGETS,
	payload: {
		scope,
		scopeId,
		userId,
		key,
	},
});

export const unsyncScopedWidgets = (
	scope: string,
	scopeId: ?TId,
	userId: ?TId,
	key: ?string,
): TAction => ({
	type: UNSYNC_SCOPED_WIDGETS,
	payload: {
		scope,
		scopeId,
		userId,
		key,
	},
});

export const deleteScope = (scope: string): TAction => ({
	type: DELETE_SCOPE,
	payload: {
		scope,
	},
});

export const saveWidgets = (widgets: TWidgets): TAction => ({
	type: SAVE_WIDGETS,
	payload: {
		widgets,
	},
});

export const addWidget = (protoId: TId): TAction => ({
	type: ADD_WIDGET,
	payload: {
		protoId,
	},
});

export const placeWidget = (
	protoId: TId,
	destContainerId: TId,
	destInstanceId: ?TId,
	destOriginId: TId,
	position: TPosition,
	widget?: TWidgetDiff,
): TAction => ({
	type: PLACE_WIDGET,
	payload: {
		protoId,
		destContainerId,
		destInstanceId,
		destOriginId,
		position,
		widget,
	},
});

export const detachWidget = (
	targetId: TId,
	instanceId: ?TId,
	originId: TId,
): TAction => ({
	type: DETACH_WIDGET,
	payload: {
		targetId,
		instanceId,
		originId,
	},
});

export const cloneWidget = (
	targetId: TId,
	containerId: ?TId,
	instanceId: ?TId,
	originId: TId,
	diff?: TWidgetDiff,
): TAction => ({
	type: CLONE_WIDGET,
	payload: {
		targetId,
		containerId,
		instanceId,
		originId,
		diff,
	},
});

export const symbolizeWidget = (
	targetId: TId,
	originId: TId,
	scopeObject: {
		scope: TEntityScope,
		_id: TId,
	},
): TAction => ({
	type: SYMBOLIZE_WIDGET,
	payload: {
		targetId,
		originId,
		scopeObject,
	},
});

export const removeWidget = (
	targetId: TId,
	containerId: ?TId,
	instanceId: ?TId,
	originId: TId,
): TAction => ({
	type: REMOVE_WIDGET,
	payload: {
		targetId,
		containerId,
		instanceId,
		originId,
	},
});

export const moveWidget = (
	srcId: TId,
	srcContainerId: TId,
	destContainerId: TId,
	destInstanceId: ?TId,
	destOriginId: TId,
	position: TPosition,
	currentDevice: TGridBreakpointName,
): TAction => ({
	type: MOVE_WIDGET,
	payload: {
		srcId,
		srcContainerId,
		destContainerId,
		destInstanceId,
		destOriginId,
		position,
		currentDevice,
	},
});

export const editWidget = (
	targetId: TId,
	instanceId: ?TId,
	originId: TId,
	diff: TWidgetDiff,
): TAction => ({
	type: EDIT_WIDGET,
	payload: {
		targetId,
		instanceId,
		originId,
		diff,
	},
});

export const unhideChildrenWidget = (
	targetId: TId,
	instanceId: ?TId,
	originId: TId,
): TAction => ({
	type: UNHIDE_CHILDREN_WIDGET,
	payload: {
		targetId,
		instanceId,
		originId,
	},
});

export const repositionWidget = (
	targetId: TId,
	originId: TId,
	containerId: ?TId,
	position: TPositionValue,
	offset: TOffsetDevice,
): TAction => ({
	type: REPOSITION_WIDGET,
	payload: {
		targetId,
		originId,
		containerId,
		position,
		offset,
	},
});

export const offsetWidget = (
	targetId: TId,
	instanceId: ?TId,
	originId: TId,
	offset: TOffsetDevice,
): TAction => ({
	type: OFFSET_WIDGET,
	payload: {
		targetId,
		instanceId,
		originId,
		offset,
	},
});

export const createPreset = (id: TId): TAction => ({
	type: CREATE_PRESET,
	payload: {
		id,
	},
});

const buildQuery = (
	scope: string,
	scopeId: ?TId,
	minDate: string,
): $ReadOnlyArray<TQueryConstraints> => [
	{ scope: ['==', scope], scopeId: ['==', scopeId], removedAt: ['==', null] },
	{ scope: ['==', scope], scopeId: ['==', scopeId], removedAt: ['>', minDate] },
];

const syncTasks = {};

const subscribe = (
	constraints: TQueryConstraints,
	emit: ({ widgets: TWidgets }) => void,
	userId: ?TId,
): (() => void) => {
	let widgets = collection('widgets').where('userId', '==', userId);
	_.forEach(([k, v]: [string, TQueryConstraint]) => {
		widgets = widgets.where(k, v[0], v[1]);
	}, _.entries(constraints));

	return widgets.onSnapshot((snapshot: any) => {
		const widgets = {};
		// Если изменения локальные
		if (snapshot.metadata.hasPendingWrites) return;
		snapshot.docChanges().forEach((change: any) => {
			const widget = change.doc.data();
			widgets[widget._id] = widget;
		});
		if (_.size(widgets)) {
			emit({ widgets });
		}
	});
};

/**
	Коллаборация
 */
const createDbChannel = (
	scope: string,
	scopeId: ?TId,
	userId: ?TId,
): EventChannel<{ widgets: TWidgets }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ widgets: TWidgets }>(
		(emit: ({ widgets: TWidgets }) => void): (() => void) => {
			const query = buildQuery(scope, scopeId, minDate);
			const unsubscriptors: $ReadOnlyArray<
				() => void,
			> = query.map((constraints: TQueryConstraints): (() => void) =>
				subscribe(constraints, emit, userId),
			);
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

type TWithWidgets = { widgets: TWidgets };
type TDbChannel = EventChannel<TWithWidgets>;

type TSyncParams = $ReadOnly<{|
	dbEventChannel: TDbChannel,
|}>;

// Расчёты позиции для абс драга
// пока что тут, т.к используется в 2х сагах
const calcPositions = ({
	destX,
	destY,
	destWidth,
	destHeight,
	srcWidth,
	srcHeight,
}: $ReadOnly<{|
	destX: number,
	destY: number,
	destWidth: number,
	destHeight: number,
	srcWidth: number,
	srcHeight: number,
|}>): TOffsetDevice => ({
	left: destX,
	top: destY,
	right: destWidth - (destX + srcWidth),
	bottom: destHeight - (destY + srcHeight),
	centerx: destX - destWidth / 2,
	centery: destY - destHeight / 2,
});

const equalizeWidget = async ({
	targetId,
	widgets,
	originId,
	instanceId,
	ObjectId,
	currentDevice,
}: TWidgetMethodEqualize): Promise<TWidgets> => {
	const { box: widgetBox = {} } = compose(widgets, widgets[targetId]);
	const initialBox = closestDeviceWithKey(widgetBox, {
		currentDevice,
		key: `box-${targetId}`,
	});

	const box = {
		[`${defaultDevice}`]: _.flow(
			_.set('heightUnit', initialBox?.offset?.height ? 'px' : 'auto'),
			_.set('widthUnit', initialBox?.offset?.width ? 'px' : 'auto'),
			_.set('margin', {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			}),
			(box: TWidgetBox): TWidgetBox => {
				if (initialBox?.offset?.height)
					return _.set('height', initialBox.offset.height, box);
				return _.unset('height', box);
			},
			(box: TWidgetBox): TWidgetBox => {
				if (initialBox?.offset?.width)
					return _.set('width', initialBox.offset.width, box);
				return _.unset('width', box);
			},
		)(initialBox),
	};

	const updatedChild = await operations.editWidget({
		widgets,
		targetId,
		diff: {
			box,
		},
		originId,
		instanceId,
		ObjectId,
	});

	return updatedChild;
};

export function* watchDbSaga({ dbEventChannel }: TSyncParams): Saga<void> {
	try {
		while (!0) {
			const { widgets } = yield take(dbEventChannel);
			const widgetsAll: TWidgets = yield select(getWidgets);
			let widgetsBuffer: ?TWidgets = yield select(getWidgetsBuffer);

			// если такого виджета нет или updateAt = null
			// и также дата всех изм. должна опережать текущую в стайте
			let updated: TWidgets = _.pickBy(
				(widget: TWidget): boolean =>
					!widgetsAll[widget._id]?.updatedAt ||
					Date.parse(widgetsAll[widget._id].updatedAt || '') <
						Date.parse(widget.updatedAt || ''),
				widgets,
			);

			const { transaction = null } =
				_.findLast(
					(widget: TWidget): boolean => !!widget?.transaction,
					updated,
				) || {};

			// пытаемся определить полную загрузку
			let isStartUpdate: boolean =
				!transaction ||
				// если все виджеты новые(в стайте нет) или без udateAt
				// то это скорее всего несинхронизация!
				_.size(updated) ===
					_.filter(
						(widget: TWidget): boolean =>
							!widget.updatedAt || !widgetsAll?.[widget._id],
						updated,
					).length ||
				// или номер транзакции у них разный
				!!_.filter(
					(widget: TWidget): boolean =>
						!widget?.transaction || transaction.id !== widget.transaction.id,
					updated,
				).length;

			// первая загрузка для того чтобы в хистори не записывать
			const isFirstLoad: boolean =
				isStartUpdate && _.size(updated) === _.size(widgets);

			// если есть в буфере, то добавляем к изменениям
			if (_.size(widgetsBuffer) && transaction) {
				updated = { ...updated, ...widgetsBuffer };
				widgetsBuffer = null;
				yield put(setWidgetsBuffer(widgetsBuffer));
			}

			// проверяем законченность пакета изм.
			if (
				transaction &&
				_.size(updated) &&
				transaction.count > _.size(updated) &&
				!isStartUpdate
			) {
				widgetsBuffer = updated;
				yield put(setWidgetsBuffer(widgetsBuffer));
				isStartUpdate = true;
			}

			// если есть изменения или первая загрузка
			if (isFirstLoad) {
				yield put(apply({ widgets: updated }));
			} else if (_.size(updated) && !isStartUpdate) {
				yield put(historyNewAction({ widgets: updated }));
			}
		}
	} catch (e) {
		logger.error(e);
	} finally {
		if (dbEventChannel) {
			dbEventChannel.close();
		}
	}
}

export function* syncScopedWidgetsSaga(): Saga<void> {
	yield takeEvery(SYNC_SCOPED_WIDGETS, function*({
		payload: { scope, scopeId, userId, key },
	}: {
		payload: { scope: string, scopeId: ?TId, userId: ?TId, key: ?string },
	}): Saga<void> {
		const taskId = `${scope}-${scopeId || ''}-${userId || ''}-${key || ''}`;
		// Cancel the previous task
		if (syncTasks[taskId]) {
			yield cancel(syncTasks[taskId]);
		}

		const dbEventChannel = createDbChannel(scope, scopeId, userId);
		if (!dbEventChannel) {
			return;
		}
		syncTasks[taskId] = yield fork(watchDbSaga, { dbEventChannel });
	});
}

export function* unsyncScopedWidgetsSaga(): Saga<void> {
	yield takeEvery(UNSYNC_SCOPED_WIDGETS, function*({
		payload: { scope, scopeId, userId, key },
	}: {
		payload: { scope: string, scopeId: ?TId, userId: ?TId, key: ?string },
	}): Saga<void> {
		const taskId = `${scope}-${scopeId || ''}-${userId || ''}-${key || ''}`;
		// Cancel the previous task
		if (syncTasks[taskId]) {
			yield cancel(syncTasks[taskId]);
		}
		syncTasks[taskId] = null;
	});
}

export function* saveWidgetsSaga(): Saga<void> {
	yield takeEvery(SAVE_WIDGETS, function*({
		payload: { widgets },
	}: {
		payload: {
			widgets: TWidgets,
		},
	}): Saga<void> {
		try {
			yield all([
				call(commitWidgetsToBd, widgets),
				// FixMe: переделать на call
				// чтобы можно было ловить ошибки внизу
				put(historyNewAction({ widgets })),
			]);
		} catch (e) {
			// FixMe: тут по хорошему нужно обработать ошибку
			// если мы сюда попали, значит
			// или упало сохранение на сервер, или упало сохранение в стейт
			// оба варианта плохи.
			// Поэтому в идеале нужно с сервера подтянуть актуальный стейт
			// и полностью его обновить на клиенте.
			logger.error(e);
		}
	});
}

export function* placeWidgetSaga(): Saga<void> {
	yield takeEvery(PLACE_WIDGET, function*({
		payload,
	}: {
		payload: {
			protoId: string,
			destContainerId: TId,
			destInstanceId: ?TId,
			destOriginId: TId,
			position: TPosition,
			widget?: TWidgetDiff,
		},
	}): Saga<void> {
		try {
			const userId: TId = yield select(getCurrentUserId);

			const widgetPresets = yield select(getPresets);

			let widgets: TWidgets = yield select(getWidgets);

			const finalProtoId = (
				widgets[payload.protoId] ||
				_.find(
					({ kind }: { +kind?: string }): boolean => kind === payload.protoId,
					widgetPresets,
				)
			)._id;

			if (!finalProtoId) {
				throw new Error("Don't find proto widget");
			}

			const widgetspec: ?TSpecsWidget = yield select(getCurrentSiteWidgetSpec);
			let sitespec: ?TSpecs = yield select(getSpecBySite, {
				id: finalProtoId,
			});

			if (widgets[finalProtoId].kind === 'site') {
				if (history.push)
					yield call(
						[history, 'push'],
						`/project/loading/site/loading/page/loading`,
					);
			}

			if (widgets[finalProtoId].scope === 'market') {
				const { widgets: widgetsFromMarket, specs: specsFromMarket } = yield call(
					uploadDataSite,
					payload.protoId,
				);
				widgets = {
					...widgets,
					...widgetsFromMarket,
				};
				sitespec = {
					...sitespec,
					...specsFromMarket,
				};
			}

			const editor: TEditor = yield select(getEditor);

			const breakpoints: TSpecsGridBreakpoints = yield select(
				getCurrentSiteBreakpoints,
			);

			const position: TPosition =
				payload.position.kind === 'grid'
					? { ...payload.position, breakpoints }
					: payload.position;

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			let enterResult: ?TWidgetEnterResult = null;

			let enterParams: TWidgetEnterParams = {
				widgets,
				editor,
				srcId: finalProtoId,
				destContainerId: payload.destContainerId,
				destInstanceId: payload.destInstanceId,
				destOriginId: payload.destOriginId,
				currentDevice: editor.currentDevice,
				position,
				operations,
			};

			// Подготавливаем место для дропа
			do {
				const { kind }: TWidgetComposed = compose(
					enterParams.widgets,
					enterParams.widgets[enterParams.destContainerId],
				);

				const enter = yield call(widgetLibs, 'enter', kind);

				if (enter) {
					enterResult = yield call(enter, enterParams);

					if (enterResult) {
						enterParams = {
							...enterParams,
							widgets: {
								...enterParams.widgets,
								...enterResult.updated,
							},
							destContainerId: enterResult.destContainerId,
							position: enterResult.position,
						};
						// подбираем изменения
						updated = ({ ...updated, ...enterResult.updated }: TWidgets);
					}
				} else {
					enterResult = null;
				}
			} while (enterResult);
			updatedWidgets = enterParams.widgets;

			// Сюда будет записан _id нового виджета
			const feedbackEmpty: TWidgetOpFeedbackEmpty = {};

			let placeParams: TWidgetOpPlaceWidgetParams = {
				widgets: updatedWidgets,
				protoId: enterParams.srcId,
				destId: enterParams.destContainerId,
				destInstanceId: enterParams.destInstanceId,
				destOriginId: enterParams.destOriginId,
				position: enterParams.position,
				feedback: feedbackEmpty,
				currentDevice: editor.currentDevice,
				widget: payload.widget || {},
			};

			// Если надо создать новый стек
			if (position.side === 'stack' && position.kind === 'grid') {
				const protoStack: ?TWidget = _.find(
					({ kind }: { +kind?: string }): boolean => kind === 'stack',
					widgetPresets,
				);

				if (!protoStack) {
					return;
				}
				const protoStackId = protoStack._id;

				const { prevId } = position;
				if (!prevId) {
					return;
				}
				const feedbackStackEmpty: TWidgetOpFeedbackEmpty = {};

				// Стек кладется рядом с тем виджетом на который навели дроп
				const withStack = yield call(operations.placeWidget, {
					destInstanceId: null,
					destOriginId: placeParams.destOriginId,
					// так как стак при броске на холст должен иметь padding
					// в данном случае нет
					widgets: _.set(
						`${protoStackId || ''}.box.${editor.currentDevice}.padding`,
						{},
						updatedWidgets,
					),
					protoId: protoStackId,
					destId: placeParams.destId,
					position: {
						kind: 'grid',
						prevId: (prevId: TId),
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					feedback: feedbackStackEmpty,
					currentDevice: placeParams.currentDevice,
					widget: payload.widget || {},
				});

				const feedbackStack: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
					feedbackStackEmpty,
				);

				if (!feedbackStack) {
					return;
				}

				updated = ({ ...updated, ...withStack }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				// Переместить в стек тот виджет на который дропали
				// Целевой виджет: prevId
				// Забрать его из: placeParams.destContainerId
				// Положить его в: feedbackStack.targetId
				const moveToStackParams: TWidgetOpMoveWidgetParams = {
					widgets: updatedWidgets,
					srcId: prevId,
					srcContainerId: placeParams.destId,
					destContainerId: feedbackStack.targetId,
					destInstanceId: null,
					destOriginId: enterParams.destOriginId,
					position: {
						kind: 'grid',
						prevId: null,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					currentDevice: editor.currentDevice,
				};

				const withMovedFirst: TWidgets = yield call(
					operations.moveWidget,
					moveToStackParams,
				);

				updated = ({ ...updated, ...withMovedFirst }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				// Применить нужные размеры виджету на который дропали
				const equalizePrevWidget = yield call(equalizeWidget, {
					targetId: prevId,
					widgets: updatedWidgets,
					originId: enterParams.destOriginId,
					instanceId: enterParams.destInstanceId,
					currentDevice: editor.currentDevice,
				});

				updated = ({ ...updated, ...equalizePrevWidget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				placeParams = {
					...placeParams,
					destId: feedbackStack.targetId,
					position: {
						side: 'right',
						kind: 'grid',
						prevId,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					widgets: updatedWidgets,
				};
			}

			const updatedPlace: TWidgets = yield call(
				operations.placeWidget,
				placeParams,
			);

			const feedback: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
				feedbackEmpty,
			);

			if (!feedback) {
				logger.error(new Error('Widget was not created'));
				return;
			}

			updated = ({ ...updated, ...updatedPlace }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

			const isEqNeeded = updatedWidgets[placeParams.destId].kind === 'stack';

			// После того как получили виджет в стеке, мы можем его модифицировать
			if (isEqNeeded && position.kind === 'grid') {
				// Применить нужные размеры виджет который тащим
				const updatedEqualizeWidget = yield call(equalizeWidget, {
					targetId: feedback.targetId,
					widgets: updatedWidgets,
					originId: enterParams.destOriginId,
					instanceId: enterParams.destInstanceId,
					currentDevice: editor.currentDevice,
				});

				updated = ({ ...updated, ...updatedEqualizeWidget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			const { destContainerId, destInstanceId, destOriginId } = enterParams;

			// Попытка применить дефолтный не-кастомный дизайн к созданному виджету
			let designDiff = null;
			// Для иконки и кнопки структура хранения одинаковая
			// Выбираем первый соответствующий дизайн, если хоть 1 есть(and undeleted)
			const kindWidget: ?TWidgetKind = updated[feedback.targetId].kind;
			const design =
				widgetspec &&
				kindWidget &&
				widgetspec[kindWidget]?.find((val: TDesign): boolean => !val.removedAt);

			if (updated[feedback.targetId].kind === 'text' && widgetspec) {
				// Попытаться найти первый дизайн ТЕКСТА из секции BODY
				// Если такого нету, то хоть какой-нибудь сойдет
				const designText =
					widgetspec.text.find(
						(text: TDesign): boolean =>
							text.section === 'body' && !text.removedAt,
					) || design;
				if (designText) {
					// Поменять в новосозданном виджете, в первом параграфе дизайн текста
					// С учетом, что в виджете текст формат дизайна "ид-тег"
					designDiff = {
						raw: _.set(
							'blocks.0.type',
							`${designText._id}-div`,
							updated[feedback.targetId].raw,
						),
					};
				}
			} else if (design && !updated[feedback.targetId].designId) {
				designDiff = { designId: design._id };
			}

			// Если удалось найти подходящий редизайн для виджета, то применяем его
			// через стандартный механизм editWidget
			if (designDiff) {
				const updatedTarget = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: feedback.targetId,
					instanceId: destInstanceId,
					originId: destOriginId,
					diff: designDiff,
				});

				updated = ({ ...updated, ...updatedTarget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			// TODO: код ниже очень похож на moveWidgetSaga, можно вынести

			// Это для инстанций, сейчас пока не надо
			// const destContainerComposed: TWidgetComposed = compose(
			// 	updatedWidgets,
			// 	updatedWidgets[destContainerId]
			// );

			const destContainerComposed: TWidget = updatedWidgets[destContainerId];

			let newDevPos: TPositioned = { ...(destContainerComposed.positions || null) };
			if (position.kind === 'absolute') {
				newDevPos = ({
					...newDevPos,
					[feedback.targetId]: position.kind,
				}: TPositioned);
			}

			const updatedPos: TWidgets = yield call(operations.editWidget, {
				widgets: updatedWidgets,
				targetId: destContainerId,
				instanceId: destInstanceId,
				originId: destOriginId,
				diff: { positions: newDevPos },
			});

			// подбираем изменения
			updated = ({ ...updated, ...updatedPos }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

			// Если перемещение абс виджета то надо записать его новую позицию
			if (position.kind === 'absolute') {
				const target: TWidget = updatedWidgets[feedback.targetId];

				let box: TWidgetBoxBreakpoint = closestDeviceWithKey(target.box, {
					currentDevice: editor.currentDevice,
					key: `box-${feedback.targetId}`,
				});

				let newDevOffset: TOffsetDevice = box.offset || emptyObject;
				if (
					!newDevOffset.left &&
					!newDevOffset.top &&
					!newDevOffset.right &&
					!newDevOffset.bottom &&
					!newDevOffset.centerx &&
					!newDevOffset.centery
				) {
					newDevOffset = ({ ...newDevOffset, left: 0, top: 0 }: TOffsetDevice);
				}

				// Отсекам ненужные из position
				const {
					destX,
					destY,
					destWidth,
					destHeight,
					srcWidth,
					srcHeight,
				} = position;

				const values = calcPositions({
					destX,
					destY,
					destWidth,
					destHeight,
					srcWidth,
					srcHeight,
				});

				Object.keys(newDevOffset).forEach((direction: TOffsetKey) => {
					if (direction === 'width' || direction === 'height') {
						return;
					}
					newDevOffset = ({
						...newDevOffset,
						[`${direction}`]: values[direction],
					}: TOffsetDevice);
				});
				box = (_.set('offset', newDevOffset, box): TWidgetBoxBreakpoint);

				const updatedTarget = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: feedback.targetId,
					instanceId: destInstanceId,
					originId: destOriginId,
					diff: { box: _.set(editor.currentDevice, box, target.box) },
				});

				updated = ({ ...updated, ...updatedTarget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			if (updated[feedback.targetId].kind === 'site' && sitespec) {
				const site = updated[feedback.targetId];
				// Создаём спеки
				const siteId: TId = feedback.targetId;
				let dictionaryIds = {};
				for (const specId of Object.keys(sitespec)) {
					dictionaryIds = {
						...dictionaryIds,
						[specId]: genId('specs'),
					};
				}

				const specs = getSpecsFromPreset({
					userId,
					dictionaryIds,
					sitespec,
					display: 'preset',
					scope: 'site',
					scopeId: siteId,
				});

				// FixMe: тут атомарность неработает
				// и вообще много проблем
				// решать эти проблемы надо переносом спек в коллекцию виджетов
				yield put(saveSpecs(specs));

				updated = {
					...updated,
					[feedback.targetId]: {
						...updated[feedback.targetId],
						gridspecId: site.gridspecId
							? dictionaryIds[site.gridspecId]
							: null,
						effectspecId: site.effectspecId
							? dictionaryIds[site.effectspecId]
							: null,
						widgetspecId: site.widgetspecId
							? dictionaryIds[site.widgetspecId]
							: null,
						colorspecId: site.colorspecId
							? dictionaryIds[site.colorspecId]
							: null,
					},
				};
			}
			yield put(saveWidgets(updated));
			const kind =
				updated[feedback.targetId]?.kind ||
				widgets[payload.protoId]?.kind ||
				'block';

			if (kind === 'site') {
				logger.info('createSite', { template: widgets[finalProtoId]?.name });
				const pageId = Object.values(
					updated[feedback.targetId].children || {},
				)[0];
				if (history.push && typeof pageId === 'string')
					yield call(
						[history, 'replace'],
						`/project/${destContainerId}/site/${feedback.targetId}/page/${pageId}`,
					);
			} else if (kind === 'project') logger.info('createProject');
			else if (kind === 'page') logger.info('createPage');
			else
				logger.info('createWidget', {
					type: kind,
					position: position.kind,
				});

			// Входим в редактирвоание
			if (feedback.targetId && !UNEDITABLE_KINDS.includes(kind)) {
				yield put(
					startEdit(
						feedback.targetId,
						findWidgetChain(destContainerId, [destContainerId], widgets),
						destInstanceId,
					),
				);
			}
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* detachWidgetSaga(): Saga<void> {
	yield takeEvery(DETACH_WIDGET, function*({
		payload: { targetId, instanceId, originId, containerId },
	}: {
		payload: {
			targetId: TId,
			instanceId: ?TId,
			originId: TId,
			containerId: TId,
		},
	}): Saga<void> {
		try {
			const widgets: TWidgets = yield select(getWidgets);

			const updated: TWidgets = yield call(operations.detachWidget, {
				widgets,
				targetId,
				instanceId,
				originId,
				containerId,
			});

			yield put(saveWidgets(updated));
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* cloneWidgetSaga(): Saga<void> {
	yield takeEvery(CLONE_WIDGET, function*({
		payload: { targetId, containerId, instanceId, originId, diff = emptyObject },
	}: {
		payload: {
			targetId: TId,
			containerId: ?TId,
			instanceId: ?TId,
			originId: TId,
			diff?: TWidgetDiff,
		},
	}): Saga<void> {
		try {
			if (!containerId) {
				return;
			}
			const widgets: TWidgets = yield select(getWidgets);

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			// FixMe: Этой перемннной ввобще не должно существовать
			const feedbackEmpty: TWidgetOpFeedbackEmpty = { targetId: null };

			const withCloned: TWidgets = yield call(operations.cloneWidget, {
				widgets: updatedWidgets,
				targetId,
				containerId,
				instanceId,
				originId,
				// FixMe: Зачем туда передавать эту переменную?
				feedback: feedbackEmpty,
				diff,
			});

			// FixMe: Это костыли вдвойне:
			// 1. Тут мутирует feedback
			// 2. Тут кастуется feedback
			const feedbackFull: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
				feedbackEmpty,
			);
			// FixMe: Чё, бля?
			if (!feedbackFull) {
				return;
			}

			updated = ({ ...updated, ...withCloned }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...withCloned }: TWidgets);

			const composedContainer = compose(
				updatedWidgets,
				updatedWidgets[containerId],
			);
			const positionKind =
				(composedContainer.positions && composedContainer.positions[targetId]) ||
				'grid';

			const withEdited: TWidgets = yield call(operations.editWidget, {
				widgets: updatedWidgets,
				targetId: containerId,
				instanceId,
				originId,
				diff: {
					order: (_.mapValues(
						(devOrder: TOrderDevice): TOrderDevice =>
							({
								...devOrder,
								// Добавить новому виджету ордер ТАК чтобы после нормализации
								// он занял позицию после клонируемого.
								// Нормализация расставляет целочисленные ордеры от 0.
								// Если не передавать сюда то попадёт в конец при нормализации.
								[feedbackFull.targetId]:
									devOrder[targetId] !== undefined
										? devOrder[targetId] + 0.5
										: 0,
							}: TOrderDevice),
						composedContainer.order,
					): TOrder),
					...(composedContainer.positions &&
					composedContainer.positions[targetId]
						? {
								positions: {
									...composedContainer.positions,
									[feedbackFull.targetId]:
										composedContainer.positions[targetId],
								},
						  }
						: null),
				},
			});

			updated = { ...updated, ...withEdited };
			updatedWidgets = { ...updatedWidgets, ...withEdited };

			const composedEdited = compose(updatedWidgets, updatedWidgets[containerId]);

			// TODO: add new smart adjust col for cloning
			const applyPosition = yield call(
				widgetLibs,
				'apply-position',
				composedEdited.kind,
			);
			if (applyPosition) {
				const positionDiff = yield call(
					applyPosition,
					composedEdited,
					{ kind: positionKind, prevId: targetId },
					feedbackFull.targetId,
				);

				const editedPosition = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: containerId,
					instanceId,
					originId,
					diff: positionDiff,
				});

				updated = { ...updated, ...editedPosition };
				updatedWidgets = { ...updatedWidgets, ...editedPosition };
			}

			if (positionKind.includes('absolute')) {
				const composedTarget = compose(
					updatedWidgets,
					updatedWidgets[feedbackFull.targetId],
				);
				const editedOffset = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: feedbackFull.targetId,
					instanceId,
					originId,
					diff: {
						box: (_.mapValues(
							(breakpoint: TWidgetBoxBreakpoint): TWidgetBoxBreakpoint => {
								if (!breakpoint.offset) {
									return breakpoint;
								}
								const oldOffset: TOffsetDevice = breakpoint.offset;
								return ({
									...breakpoint,
									offset: (_.reduce(
										(
											accum: TOffsetDevice,
											key: TOffsetKey,
										): TOffsetDevice => {
											if (key === 'width' || key === 'height') {
												return {
													...accum,
													[`${key}`]: oldOffset[key],
												};
											}
											return {
												...accum,
												[`${key}`]: oldOffset[key] + 10,
											};
										},
										emptyObject,
										_.keys(oldOffset),
									): TOffsetDevice),
								}: TWidgetBoxBreakpoint);
							},
							composedTarget.box,
						): TWidgetBox),
					},
				});

				updated = { ...updated, ...editedOffset };
				updatedWidgets = { ...updatedWidgets, ...editedOffset };
			}

			yield put(saveWidgets(updated));

			const kind = widgets[targetId]?.kind || 'block';
			// selecting after clone
			if (targetId && !UNEDITABLE_KINDS.includes(kind)) {
				yield put(
					startEdit(
						feedbackFull.targetId,
						findWidgetChain(containerId, [containerId], widgets),
						instanceId,
					),
				);
			}
			if (kind === 'site') logger.info('cloneSite');
			else if (kind === 'project') logger.info('cloneProject');
			else if (kind === 'page') logger.info('clonePage');
			else
				logger.info('cloneWidget', {
					type: kind,
					position: positionKind,
				});
		} catch (e) {
			logger.error(e);
		}
	});
}

// 1. Внутри инстанций не символизируем. Это можно добавить,
// но без продуктовой необходимости бессмысленно тратить время сейчас.
// 2. Не символизируем инстанции. Пока что, в этом не видно киллер-фичи.
// А вот проблемы есть: ресимволизация ответвляет вложенные инстанции и
// наводит хаос в поле children c учётом особенностей работы compose.
export function* symbolizeWidgetSaga(): Saga<void> {
	yield takeEvery(SYMBOLIZE_WIDGET, function*({
		payload: { targetId, originId, scopeObject },
	}: {
		payload: {
			targetId: TId,
			originId: TId,
			scopeObject: {
				scope: TEntityScope,
				_id: TId,
			},
		},
	}): Saga<void> {
		try {
			const { scope, _id: scopeId } = scopeObject;
			const widgetPresets = yield select(getPresets);
			// ToDo: так не должно быть, нужно нормально переписать операции
			const widgets: TWidgets = { ...widgetPresets, ...(yield select(getWidgets)) };

			const editor: TEditor = yield select(getEditor);

			const protoStack: ?TWidget = _.find(
				({ kind }: { +kind?: string }): boolean => kind === 'stack',
				widgetPresets,
			);
			if (!protoStack) {
				return;
			}
			const protoStackId = protoStack._id;

			const target: TWidget = widgets[targetId];

			if (target.modified) {
				return;
			}

			const composed: TWidgetComposed = compose(widgets, target);

			let finalTargetId: TId = targetId;

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			if (composed.kind === 'col') {
				// Надо создать стек и передать ему children/order колонки.
				// Колонке оставить в children/order только новосозданный стек.

				// Стек создаётся в фейковом виджете чтоб не засирать children.
				const __fake = {
					_id: '__fake',
					children: {},
					order: {},
					sizes: {},
					userId: '',
					display: 'normal',
					protoId: '',
					scope: 'user',
					scopeId: '',
				};

				const feedbackStackEmpty: TWidgetOpFeedbackEmpty = {};

				const withStack = yield call(operations.placeWidget, {
					destInstanceId: null,
					destOriginId: originId,
					widgets: { ...updatedWidgets, __fake },
					protoId: protoStackId,
					destId: __fake._id,
					position: {
						kind: 'grid',
						prevId: null,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					feedback: feedbackStackEmpty,
					currentDevice: editor.currentDevice,
					widget: {
						scope,
						scopeId,
					},
				});

				// Фейковый виджет изымается, т.к. свою роль выполнил
				delete withStack.__fake;

				const feedbackStack: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
					feedbackStackEmpty,
				);

				if (!feedbackStack) {
					return;
				}

				// Подменяется финальный ИД который уйдёт на символизацию
				finalTargetId = feedbackStack.targetId;

				updated = ({ ...updated, ...withStack }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				// Передать новому стеку поля колонки
				const composedBox: TWidgetBoxBreakpoint = closestDeviceWithKey(
					composed.box,
					{ currentDevice: editor.currentDevice, key: composed._id },
				);
				const colComposed: TWidgetComposed = {
					...composed,
					box: {
						[`${defaultDevice}`]: {
							alignItems: 'stretch',
							alignContent: 'stretch',
							justifyContent: 'space-evenly',
							flexWrap: 'nowrap',
							...composedBox,
						},
					},
				};
				const finalBox: TWidgetBoxBreakpoint = closestDeviceWithKey(
					updatedWidgets[finalTargetId].box,
					{ currentDevice: editor.currentDevice, key: finalTargetId },
				);
				const withUpdatedStack: TWidgets = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: finalTargetId,
					instanceId: null,
					originId,
					diff: {
						children: colComposed.children,
						order: colComposed.order,
						positions: colComposed.positions,
						box: {
							[`${defaultDevice}`]: {
								flexDirection: 'column',
								alignItems: composedBox.alignItems || finalBox.alignItems,
								alignContent:
									composedBox.alignContent || finalBox.alignContent,
								justifyContent:
									composedBox.justifyContent || finalBox.justifyContent,
								flexWrap: composedBox.flexWrap || finalBox.flexWrap,
							},
						},
					},
				});

				updated = ({ ...updated, ...withUpdatedStack }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				// Оставить колонке только стек в качестве чайлда
				const withUpdatedCol: TWidgets = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId,
					instanceId: null,
					originId,
					diff: {
						children: {
							[feedbackStack.targetId]: feedbackStack.targetId,
						},
						order: {
							[feedbackStack.targetId]: 0,
						},
						positions: {
							[feedbackStack.targetId]: null,
						},
					},
				});

				updated = ({ ...updated, ...withUpdatedCol }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			const withSymbol: TWidgets = yield call(operations.symbolizeWidget, {
				widgets: updatedWidgets,
				targetId: finalTargetId,
				originId,
				scopeObject,
			});

			updated = ({ ...updated, ...withSymbol }: TWidgets);

			yield put(saveWidgets(updated));
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* removeWidgetSaga(): Saga<void> {
	yield takeEvery(REMOVE_WIDGET, function*({
		payload: { targetId, containerId, instanceId, originId },
	}: {
		payload: {
			targetId: TId,
			containerId: ?TId,
			instanceId: ?TId,
			originId: TId,
		},
	}): Saga<void> {
		try {
			// FixMe: Чё за бред? Почему этот параметр просто бы не сделать обязательным?
			if (!containerId) {
				return;
			}
			const widgets: TWidgets = yield select(getWidgets);
			const editor: TEditor = yield select(getEditor);
			const breakpoints: TSpecsGridBreakpoints = yield select(
				getCurrentSiteBreakpoints,
			);
			const position: TPosition = {
				kind: 'grid',
				breakpoints,
				destRect: null,
				dragRect: null,
				prevId: null,
				nextId: null,
			};

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			// Получаем родителя
			const feedbackEmpty: TWidgetOpFeedbackEmpty = {};
			const tracedBefore: TWidgetTraceContainersResult = traceContainers({
				widgets: updatedWidgets,
				start: {
					targetId: originId,
					instanceId: null,
					containerId: null,
				},
				finalId: targetId,
				finalInstanceId: instanceId,
			}).slice(1);

			// зачем этот код? явно необдуманный копипаст!!
			// пустой Edit, что бы получить новые Ids
			const updatedEdit: TWidgets = yield call(operations.editWidget, {
				widgets: updatedWidgets,
				targetId: tracedBefore[0].targetId,
				instanceId: tracedBefore[0].instanceId,
				originId,
				diff: {},
				feedback: feedbackEmpty,
			});

			const feedback: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
				feedbackEmpty,
			);

			if (!feedback) {
				return;
			}

			// подбираем изменения
			updated = ({ ...updated, ...updatedEdit }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...updatedEdit }: TWidgets);

			const tracedAfter: TWidgetTraceContainersResult = traceContainers({
				widgets: updatedWidgets,
				start: {
					targetId: originId,
					instanceId: null,
					containerId: null,
				},
				finalId: feedback.targetId,
				finalInstanceId: tracedBefore[0].instanceId,
			});
			// конец непонятного копипаста!!
			// удалени виджета
			const updatedRemove: TWidgets = yield call(operations.removeWidget, {
				widgets: updatedWidgets,
				containerId: feedback.targetId,
				position,
				targetId,
				instanceId,
				originId,
				currentDevice: editor.currentDevice,
			});

			// подбираем изменения
			updated = ({ ...updated, ...updatedRemove }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...updatedRemove }: TWidgets);

			// Ходим по следам и удаляем если "пустой"
			let i = 0;

			let leaveParams: TWidgetLeaveParams = {
				widgets: updatedWidgets,
				originId,
				targetId,
				containerId,
				instanceId,
				operations,
				position,
				currentDevice: editor.currentDevice,
			};

			let leaveResult: ?TWidgetLeaveResult = null;

			do {
				const traceItem = tracedAfter[i];

				if (traceItem.containerId) {
					leaveParams = {
						...leaveParams,
						targetId: traceItem.targetId,
						containerId: traceItem.containerId,
						instanceId: traceItem.instanceId,
					};

					const { kind }: TWidgetComposed = compose(
						leaveParams.widgets,
						leaveParams.widgets[tracedAfter[i].targetId],
					);

					const leave = yield call(widgetLibs, 'leave', kind);
					if (leave) {
						leaveResult = yield call(leave, leaveParams);

						if (leaveResult) {
							leaveParams = {
								...leaveParams,
								widgets: { ...leaveParams.widgets, ...leaveResult },
							};

							// подбираем изменения
							updated = ({ ...updated, ...leaveResult }: TWidgets);
						}
					} else {
						leaveResult = null;
					}

					i++;
				}
			} while (leaveResult && i < tracedAfter.length);

			yield put(resetEdit());
			yield put(saveWidgets(updated));
			const kind = widgets[targetId]?.kind || 'block';

			if (kind === 'site') {
				logger.info('removeSite');
				yield put(creteTask(targetId, null, widgets[targetId]?.userId));
			} else if (kind === 'project'){ 
				logger.info('removeProject');
				yield put(creteTask( null, targetId, widgets[targetId]?.userId));
			}else if (kind === 'page') logger.info('removePage');
			else
				logger.info('removeWidget', {
					type: kind,
				});
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* moveWidgetSaga(): Saga<void> {
	yield takeEvery(MOVE_WIDGET, function*({
		payload,
	}: {
		payload: {
			// то, что тащим
			srcId: TId,
			srcContainerId: TId,
			destContainerId: TId,
			destInstanceId: TId,
			destOriginId: TId,
			position: TPosition,
		},
	}): Saga<void> {
		try {
			const widgetPresets = yield select(getPresets);

			const widgets: TWidgets = { ...widgetPresets, ...(yield select(getWidgets)) };
			const editor: TEditor = yield select(getEditor);

			const breakpoints: TSpecsGridBreakpoints = yield select(
				getCurrentSiteBreakpoints,
			);
			const position: TPosition =
				payload.position.kind === 'grid'
					? { ...payload.position, breakpoints }
					: payload.position;

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			let enterParams: TWidgetEnterParams = {
				widgets,
				editor,
				srcId: payload.srcId,
				destContainerId: payload.destContainerId,
				destInstanceId: payload.destInstanceId,
				destOriginId: payload.destOriginId,
				currentDevice: editor.currentDevice,
				position,
				operations,
			};

			let enterResult: ?TWidgetEnterResult = null;

			do {
				const { kind }: TWidgetComposed = compose(
					enterParams.widgets,
					enterParams.widgets[enterParams.destContainerId],
				);

				const enter = yield call(widgetLibs, 'enter', kind);
				if (enter) {
					enterResult = yield call(enter, enterParams);

					if (enterResult) {
						enterParams = {
							...enterParams,
							widgets: {
								...enterParams.widgets,
								...enterResult.updated,
							},
							destContainerId: enterResult.destContainerId,
							position: enterResult.position,
						};
						// подбираем изменения
						updated = ({ ...updated, ...enterResult.updated }: TWidgets);
					}
				} else {
					enterResult = null;
				}
			} while (enterResult);

			updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

			// Перемещаем srcId в подготовленный destContainerId
			let moveParams: TWidgetOpMoveWidgetParams = {
				widgets: updatedWidgets,
				srcId: enterParams.srcId,
				srcContainerId: payload.srcContainerId,
				destContainerId: enterParams.destContainerId,
				destInstanceId: enterParams.destInstanceId,
				destOriginId: enterParams.destOriginId,
				position: enterParams.position,
				currentDevice: editor.currentDevice,
			};

			// Если надо создать новый стек
			if (position.side === 'stack' && position.kind === 'grid') {
				const target: TWidget = widgets[payload.srcId];
				const { scope, scopeId } = target;
				if (!scopeId) throw new Error('Mistake scopeId');
				const widgetPresets = yield select(getPresets);
				const protoStack: ?TWidget = _.find(
					({ kind }: { +kind?: string }): boolean => kind === 'stack',
					widgetPresets,
				);
				if (!protoStack) {
					return;
				}
				const protoStackId = protoStack._id;

				const { prevId } = position;
				if (!prevId) {
					return;
				}

				// FixMe: впизду эти мутирующие костыли
				// они явно были впилины в каком-то адовом неадеквате
				// выпилить вообще этот feedback!!
				// Вспомнил привет из юКита, когда ты вызываешь функцию,
				// а у неё два колбека!!! и ещё она что-то тебе сразу возвращает!!!
				// и у неё ещё мутирующие параметры есть!!!
				// К сожалению, это пример из реальной жизни((
				// И походу ту функцию и эту функцию писал один и тот же человек..
				const feedbackStackEmpty: TWidgetOpFeedbackEmpty = {};

				// Стек кладется рядом с тем виджетом на который навели дроп
				const withStack = yield call(operations.placeWidget, {
					destInstanceId: null,
					destOriginId: moveParams.destOriginId,
					// так как стак при броске на холст должен иметь padding
					// в данном случае нет
					widgets: _.set(
						`${protoStackId || ''}.box.${editor.currentDevice}.padding`,
						{},
						updatedWidgets,
					),
					protoId: protoStackId,
					destId: moveParams.destContainerId,
					position: {
						kind: 'grid',
						prevId: (prevId: TId),
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					feedback: feedbackStackEmpty,
					currentDevice: moveParams.currentDevice,
					widget: {
						scope,
						scopeId,
					},
				});

				const feedbackStack: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
					feedbackStackEmpty,
				);

				if (!feedbackStack) {
					return;
				}

				updated = ({ ...updated, ...withStack }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				// Переместить в стек тот виджет на который дропали
				// Целевой виджет: prevId
				// Забрать его из: moveParams.destContainerId
				// Положить его в: feedbackStack.targetId
				const moveToStackParams: TWidgetOpMoveWidgetParams = {
					widgets: updatedWidgets,
					srcId: prevId,
					srcContainerId: moveParams.destContainerId,
					destContainerId: feedbackStack.targetId,
					destInstanceId: null,
					destOriginId: enterParams.destOriginId,
					position: {
						kind: 'grid',
						prevId: null,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					currentDevice: editor.currentDevice,
				};

				const withMovedFirst: TWidgets = yield call(
					operations.moveWidget,
					moveToStackParams,
				);

				updated = ({ ...updated, ...withMovedFirst }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				// Применить нужные размеры виджету на который дропали
				const equalizePrevWidget = yield call(equalizeWidget, {
					targetId: prevId,
					widgets: updatedWidgets,
					originId: enterParams.destOriginId,
					instanceId: enterParams.destInstanceId,
					currentDevice: editor.currentDevice,
				});

				updated = ({ ...updated, ...equalizePrevWidget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				moveParams = {
					...moveParams,
					destContainerId: feedbackStack.targetId,
					position: {
						side: 'right',
						kind: 'grid',
						prevId,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					widgets: updatedWidgets,
				};
			}

			const {
				srcId,
				srcContainerId,
				destContainerId,
				destInstanceId,
				destOriginId,
				currentDevice,
			} = moveParams;

			// Это для инстанций, сейчас пока не надо
			// const srcContainerComposed: TWidgetComposed = compose(
			// 	updatedWidgets,
			// 	updatedWidgets[srcContainerId]
			// );
			const srcContainerComposed: TWidget = updatedWidgets[srcContainerId];
			const srcPositions: TPositioned = ({
				...(srcContainerComposed.positions || null),
			}: TPositioned);

			// Собственно перемещение
			const updatedMove: TWidgets = yield call(operations.moveWidget, moveParams);
			// подбираем изменения
			updated = ({ ...updated, ...updatedMove }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

			const isChangeContainer = srcContainerId !== destContainerId;
			const isContainerStack = updatedWidgets[destContainerId].kind === 'stack';
			if (isChangeContainer && isContainerStack && position.kind === 'grid') {
				// Применить нужные размеры виджет который тащим
				const updatedEqualizeWidget = yield call(equalizeWidget, {
					targetId: srcId,
					widgets: updatedWidgets,
					originId: destOriginId,
					instanceId: destInstanceId,
					currentDevice: editor.currentDevice,
				});

				updated = ({ ...updated, ...updatedEqualizeWidget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			// Это для инстанций, сейчас пока не надо
			// const destContainerComposed: TWidgetComposed = compose(
			// 	updatedWidgets,
			// 	updatedWidgets[destContainerId]
			// );
			const destContainerComposed: TWidget = updatedWidgets[destContainerId];

			const destPositions: TPositioned =
				srcContainerId === destContainerId
					? srcPositions
					: { ...(destContainerComposed.positions || null) };

			let newDevPos: TPositioned = ({ ...destPositions }: TPositioned);

			if (ABS_KINDS.includes(position.kind)) {
				newDevPos = ({
					...newDevPos,
					[srcId]: position.kind,
				}: TPositioned);
			}

			if (!_.isEqual(destPositions, newDevPos)) {
				const updatedPos: TWidgets = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: destContainerId,
					instanceId: destInstanceId,
					originId: destOriginId,
					diff: { positions: newDevPos },
				});

				// подбираем изменения
				updated = ({ ...updated, ...updatedPos }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			// Если перемещение абс виджета то надо записать его новую позицию
			if (position.kind === 'absolute') {
				const target: TWidget = updatedWidgets[srcId];
				const initialBox = target.box || emptyObject;
				const box: TWidgetBoxBreakpoint = closestDeviceWithKey(initialBox, {
					currentDevice,
					key: `box-${target._id}`,
				});

				const isSamePos: boolean =
					srcPositions[srcId] === position.kind &&
					srcContainerId === destContainerId;
				const finalBox: TWidgetBox = isSamePos
					? initialBox
					: _.mapValues(
							(breakpoint: TWidgetBoxBreakpoint): TWidgetBoxBreakpoint => {
								const updated = {
									...breakpoint,
								};

								if (updated.offset) delete updated.offset;
								return updated;
							},
							initialBox,
					  );

				let newDevOffset: TOffsetDevice = { ...(box.offset || null) };
				if (
					newDevOffset.left === undefined &&
					newDevOffset.top === undefined &&
					newDevOffset.right === undefined &&
					newDevOffset.bottom === undefined &&
					newDevOffset.centerx === undefined &&
					newDevOffset.centery === undefined
				) {
					newDevOffset = ({ ...newDevOffset, left: 0, top: 0 }: TOffsetDevice);
				}

				// Отсекам ненужные из position
				const {
					destX,
					destY,
					destWidth,
					destHeight,
					srcWidth,
					srcHeight,
				} = position;

				const values = calcPositions({
					destX,
					destY,
					destWidth,
					destHeight,
					srcWidth,
					srcHeight,
				});

				Object.keys(newDevOffset).forEach((direction: TOffsetKey) => {
					if (direction === 'width' || direction === 'height') {
						return;
					}
					newDevOffset = ({
						...newDevOffset,
						[`${direction}`]: values[direction],
					}: TOffsetDevice);
				});

				// В диф кладётся бокс, с тем рассчётом, что для такого девайса могло не
				// быть бокса, и у нас в наличии ближайший девайс, относительно которого
				// и происходит изменение. Например, там кроме офсета может быть ещё
				// марджин - он тоже скопируется. Поэтому 2 раза `_.set`
				const updatedTarget = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId: srcId,
					instanceId: destInstanceId,
					originId: destOriginId,
					diff: {
						box: (_.set(
							currentDevice,
							_.set('offset', newDevOffset, box),
							finalBox,
						): TWidgetBox),
					},
				});

				updated = ({ ...updated, ...updatedTarget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			}

			// Дальше нужно удалить srcContainerId если он остался пустой
			// Берём след от destOriginId до srcContainerId
			const traced: TWidgetTraceContainersResult = traceContainers({
				widgets: updatedWidgets,
				start: {
					targetId: payload.destOriginId,
					instanceId: null,
					containerId: null,
				},
				finalId: payload.srcContainerId,
			});

			// Ходим по следам и удаляем если "пустой"
			let i = 0;

			let leaveParams: TWidgetLeaveParams = {
				widgets: updatedWidgets,
				originId: payload.destOriginId,
				targetId: payload.destContainerId,
				containerId: payload.destOriginId,
				instanceId: null,
				position,
				operations,
				currentDevice: editor.currentDevice,
			};

			let leaveResult: ?TWidgetLeaveResult = null;

			do {
				const traceItem = traced[i];

				if (traceItem.containerId) {
					leaveParams = {
						...leaveParams,
						targetId: traceItem.targetId,
						containerId: traceItem.containerId,
						instanceId: traceItem.instanceId,
					};

					const { kind }: TWidgetComposed = compose(
						leaveParams.widgets,
						leaveParams.widgets[traced[i].targetId],
					);

					const leave = yield call(widgetLibs, 'leave', kind);
					if (leave) {
						leaveResult = yield call(leave, leaveParams);

						if (leaveResult) {
							leaveParams = {
								...leaveParams,
								widgets: { ...leaveParams.widgets, ...leaveResult },
							};

							// подбираем изменения
							updated = ({ ...updated, ...leaveResult }: TWidgets);
						}
					} else {
						leaveResult = null;
					}

					i++;
				}
			} while (leaveResult && i < traced.length);

			yield put(saveWidgets(updated));

			// try selected widgets but only one (next time broke it)
			const currentWidget = yield select(getCurrentWidget);
			if (
				(!currentWidget || currentWidget?.id !== srcId) &&
				!UNEDITABLE_KINDS.includes(widgets[srcId]?.kind)
			)
				yield put(
					startEdit(
						srcId,
						findWidgetChain(destContainerId, [destContainerId], widgets),
						destInstanceId,
					),
				);
			logger.info('moveWidget', {
				type: widgets[srcId]?.kind,
				position: position?.kind,
			});
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* editWidgetSaga(): Saga<void> {
	yield takeEvery(EDIT_WIDGET, function*({
		payload: { targetId, instanceId, originId, diff },
	}: {
		payload: {
			targetId: TId,
			instanceId: ?TId,
			originId: TId,
			diff: TWidgetDiff,
		},
	}): Saga<void> {
		try {
			const widgets: TWidgets = yield select(getWidgets);

			const updated: TWidgets = yield call(operations.editWidget, {
				widgets,
				targetId,
				instanceId,
				originId,
				diff,
			});

			yield call(checkCurrentWidgetSaga, { targetId, updated, instanceId });
			yield put(saveWidgets(updated));
			if (!['user'].includes(widgets[targetId]?.kind))
				logger.info('editWidget', {
					type: widgets[targetId]?.kind,
				});
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* unhideChildrenWidgetSaga(): Saga<void> {
	yield takeEvery(UNHIDE_CHILDREN_WIDGET, function*({
		payload: { targetId, instanceId, originId },
	}: {
		payload: {
			targetId: TId,
			instanceId: ?TId,
			originId: TId,
		},
	}): Saga<void> {
		try {
			const widgets: TWidgets = yield select(getWidgets);
			const editor: TEditor = yield select(getEditor);

			const { currentDevice } = editor;

			const composed = compose(widgets, widgets[targetId]);
			const finalInstanceId = composed.modified ? targetId : instanceId;

			const children = _.filter(_.identity, _.values(composed.children));

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			for (const childId of children) {
				const composedChild = compose(updatedWidgets, updatedWidgets[childId]);
				const box = closestDeviceWithKey(composedChild.box, {
					currentDevice,
					key: `box-${targetId}`,
				});
				if (box.hidden) {
					updated = yield call(operations.editWidget, {
						widgets: updatedWidgets,
						targetId: childId,
						instanceId: finalInstanceId,
						originId,
						diff: {
							box: _.set(
								currentDevice,
								_.set('hidden', false, box),
								composedChild.box,
							),
						},
					});
					updatedWidgets = { ...updatedWidgets, ...updated };
				}
			}

			yield call(checkCurrentWidgetSaga, { targetId, updated, instanceId });
			yield put(saveWidgets(updated));
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* offsetWidgetSaga(): Saga<void> {
	yield takeEvery(OFFSET_WIDGET, function*({
		payload: { targetId, instanceId, originId, offset },
	}: {
		payload: {
			targetId: TId,
			instanceId: ?TId,
			originId: TId,
			offset: TOffsetDevice,
		},
	}): Saga<void> {
		try {
			const widgets: TWidgets = yield select(getWidgets);
			const editor: TEditor = yield select(getEditor);

			const { currentDevice } = editor;

			// Fixme: use composed for instances
			const target = { ...widgets[targetId] };

			const box: TWidgetBoxBreakpoint = closestDeviceWithKey(target.box, {
				currentDevice,
				key: `box-${target._id}`,
			});
			const updated: TWidgets = yield call(operations.editWidget, {
				widgets,
				targetId,
				instanceId,
				originId,
				diff: { box: _.set(`${currentDevice}.offset`, offset, box) },
			});

			yield put(saveWidgets(updated));
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* repositionWidgetSaga(): Saga<void> {
	yield takeEvery(REPOSITION_WIDGET, function*({
		payload: { targetId, originId, containerId, position, offset },
	}: {
		payload: {
			// ToDo добавить instanceId
			targetId: TId,
			originId: TId,
			containerId: TId,
			position: TPositionValue,
			offset: TOffsetDevice,
		},
	}): Saga<void> {
		try {
			const widgets: TWidgets = yield select(getWidgets);
			const editor: TEditor = yield select(getEditor);

			let updated: TWidgets = {};
			let updatedWidgets: TWidgets = widgets;

			const container: TWidget = widgets[containerId];
			const target: TWidget = widgets[targetId];

			const positions: TPositioned = refinePositions(
				container._id,
				container.positions,
				container.children,
			);

			const refId: ?TId = (_.findKey((v: ?TId): boolean => v === targetId, {
				...container.children,
			}): ?TId);

			const newPositions: TPositioned = refId
				? {
						...positions,
						[refId]: position,
				  }
				: { ...positions };

			const updatedContainer = yield call(operations.editWidget, {
				widgets: updatedWidgets,
				targetId: containerId,
				originId,
				instanceId: null,
				diff: { positions: newPositions },
			});

			updated = ({ ...updated, ...updatedContainer }: TWidgets);
			updatedWidgets = ({ ...updatedWidgets, ...updatedContainer }: TWidgets);

			// Тут происходит добавление данных для абс виджета
			// Очень важно обнулить offset на всех девайсах и сохранить другие настройки
			if (ABS_KINDS.includes(position)) {
				const cleanBox = (_.mapValues(
					(properties: TWidgetBoxBreakpoint): TWidgetBoxBreakpoint => {
						const updated = {
							...properties,
						};

						if (updated.offset) delete updated.offset;
						return updated;
					},
					updatedWidgets[target._id].box,
				): TWidgetBox);

				const updatedTarget = yield call(operations.editWidget, {
					widgets: updatedWidgets,
					targetId,
					originId,
					instanceId: null,
					diff: {
						box: (_.set(
							`${editor.currentDevice}.offset`,
							offset,
							cleanBox,
						): TWidgetBox),
					},
				});

				updated = ({ ...updated, ...updatedTarget }: TWidgets);
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);
			} else if (container.kind === 'block') {
				// Нужно создать прослойку между примитивным виджетом и блоком,
				// в виде колонки, в остальных случаях, когда виджет находится в
				// стеке или колонке, то ничего делать не нужно
				let enterParams: TWidgetEnterParams = {
					widgets: updatedWidgets,
					editor,
					srcId: targetId,
					destContainerId: containerId,
					destInstanceId: null,
					destOriginId: originId,
					currentDevice: editor.currentDevice,
					position: {
						kind: 'grid',
						prevId: null,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
						row: 'new',
					},
					operations,
				};

				let enterResult: ?TWidgetEnterResult = null;

				// ToDo эта хрень есть в половине саг, нужно наверное вынести в отдельную функцию
				do {
					const { kind }: TWidgetComposed = compose(
						enterParams.widgets,
						enterParams.widgets[enterParams.destContainerId],
					);

					const enter = yield call(widgetLibs, 'enter', kind);
					if (enter) {
						enterResult = yield call(enter, enterParams);

						if (enterResult) {
							enterParams = {
								...enterParams,
								widgets: {
									...enterParams.widgets,
									...enterResult.updated,
								},
								destContainerId: enterResult.destContainerId,
								position: enterResult.position,
							};

							// подбираем изменения
							updated = ({ ...updated, ...enterResult.updated }: TWidgets);
						}
					} else {
						enterResult = null;
					}
				} while (enterResult);

				// После того, как получили новую колонку, нужно переместить туда виджет
				updatedWidgets = ({ ...updatedWidgets, ...updated }: TWidgets);

				const moveToNewCol: TWidgetOpMoveWidgetParams = {
					widgets: updatedWidgets,
					srcId: targetId,
					srcContainerId: containerId,
					destContainerId: enterParams.destContainerId,
					destInstanceId: null,
					destOriginId: enterParams.destOriginId,
					position: {
						kind: 'grid',
						prevId: null,
						nextId: null,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					currentDevice: editor.currentDevice,
				};

				const movedWidget: TWidgets = yield call(
					operations.moveWidget,
					moveToNewCol,
				);

				updated = ({ ...updated, ...movedWidget }: TWidgets);
			}

			yield put(saveWidgets(updated));
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* createPresetSaga(): Saga<void> {
	yield takeEvery(CREATE_PRESET, function*({
		payload: { id },
	}: {
		payload: {
			id: TId,
		},
	}): Saga<void> {
		try {
			const { widgets, specs, targetId } = yield call(getSiteData, id);

			const updated = {
				...widgets,
				[targetId]: {
					...(widgets ? widgets[targetId] : {}),
					display: 'preset',
					scope: 'premarket',
					scopeId: targetId,
				},
			};

			yield put(saveWidgets(updated));
			yield put(saveSpecs(specs));

			yield collection('publications')
				.doc(targetId)
				.set({
					status: 'active',
					updateAt: new Date().toISOString(),
					doneAt: null,
					userId: widgets[targetId].userId,
					siteId: targetId,
					type: 'netlify',
				});

			yield put(
				addNotice(
					targetId,
					i18n.t('Great job!'),
					i18n.t('Template was successfully created and sent for moderation.'),
				),
			);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(syncScopedWidgetsSaga);
	yield fork(unsyncScopedWidgetsSaga);
	yield fork(saveWidgetsSaga);
	yield fork(placeWidgetSaga);
	yield fork(detachWidgetSaga);
	yield fork(cloneWidgetSaga);
	yield fork(symbolizeWidgetSaga);
	yield fork(removeWidgetSaga);
	yield fork(moveWidgetSaga);
	yield fork(editWidgetSaga);
	yield fork(repositionWidgetSaga);
	yield fork(unhideChildrenWidgetSaga);
	yield fork(offsetWidgetSaga);
	yield fork(createPresetSaga);
}

export default handleActions<$ReadOnly<TWidgets>, TAction>(
	{
		[APPLY](
			state: $ReadOnly<TWidgets>,
			{ payload: { widgets } }: { +payload: { +widgets: TWidgets } },
		): $ReadOnly<TWidgets> {
			return _.pickBy(_.identity, _.assign(state, widgets));
		},
	},
	initialState,
);
