// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { ToolbarBtn } from '@graphite/uneon';
import {
	getInlineStyles,
	toggleInlineStyles,
	type TInlineStyle,
} from '@graphite/widget-text';

type TPropsInlineStylesBtn = $ReadOnly<{|
	iconName: string,
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	type: TInlineStyle,
	value: string,
|}>;

const InlineStylesBtn = (props: TPropsInlineStylesBtn, ref) => {
	const { iconName, editorState, setEditorState, type, value } = props;

	const changeInlineStyle = React.useMemo(() => {
		const setInlineStyle = toggleInlineStyles(editorState)(type);

		return e => {
			e.preventDefault();

			const editorStateNew = setInlineStyle(value);
			setEditorState(editorStateNew);
		};
	}, [editorState, setEditorState, type, value]);

	return (
		<ToolbarBtn
			onClick={changeInlineStyle}
			iconName={iconName}
			isActive={getInlineStyles(editorState)(type) === value}
			ref={ref}
		/>
	);
};

export default React.memo<TPropsInlineStylesBtn>(React.forwardRef(InlineStylesBtn));
