// @flow
import React from 'react';
import { Box, Flex, Text } from '@graphite/uneon';

import emptyStroke from './img/empty-stroke.inline.svg';
import emptyArrow from './img/empty-arrow.inline.svg';

type TProps = $ReadOnly<{|
	t: (string) => string,
	title: string,
|}>;

const emptySx = {
	position: 'relative',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	paddingBottom: '36px',
};
const arrowSx = {
	top: 0,
	position: 'absolute',
};
const strokeSx = {
	marginTop: '24px',
	marginBottom: '6px',
};

const AnimateItemsComponent = ({ t, title }: TProps) => (
	<Flex sx={emptySx}>
		<Box as="img" src={emptyArrow} alt="" sx={arrowSx} />
		<Box as="img" src={emptyStroke} alt="" sx={strokeSx} />
		<Text variant="headlinesm" color="text.primary">
			{title}
		</Text>
		<Text variant="bodysm" color="text.secondary">
			{t('to create animation')}
		</Text>
	</Flex>
);

export default React.memo<TProps>(AnimateItemsComponent);
