// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toolbar, Box } from '@graphite/uneon';
import WidgetSettings from '@graphite/widget-settings';

import { editWidget } from 'Editor/ducks/widgets';
import useDefaultDevice from 'Editor/libs/use-default-device';
import type { TWidgetDiff } from '@graphite/types';
import type { TPropsRightSide } from './constants/types';
import Actions from './Actions';
import Layout from './Layout';
import Design from './Design';

export const rightSideSx = {
	pointerEvents: 'auto',
	position: 'absolute',
	right: '6px',
	top: '-16px',
	zIndex: 2,
};

const BLOCK_OPT_SETTING = {
	noActive: false,
	padding: {
		isShow: false,
	},
	margin: {
		isShow: false,
	},
	layout: {
		isHeightShow: false,
		isWidthShow: false,
	},
	props: {
		isIdShow: true,
		isClassNameShow: true,
	},
};

const ControlsRightSide = (props: TPropsRightSide): React$Node => {
	const {
		currentDevice,
		gridspec,
		colorspec,
		effectspec,
		widgetspec,
		data,
		setData,
		containerId,
		instanceId,
		originId,
		dispatch,
		openedPanel,
		setOpenedPanel,
		insertImage,
		removeImage,
		resetImage,
		images,
		uploads,
	} = props;
	const isDefaultDevice = currentDevice === useDefaultDevice();

	const { t } = useTranslation();

	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(data._id, instanceId, originId, diff));
		},
		[data._id, instanceId, originId, dispatch],
	);

	return (
		<Box sx={rightSideSx}>
			<Toolbar variant="sm">
				<Design
					currentDevice={currentDevice}
					colorspec={colorspec}
					effectspec={effectspec}
					widgetspec={widgetspec}
					gridspec={gridspec}
					dispatch={dispatch}
					data={data}
					containerId={containerId}
					instanceId={instanceId}
					originId={originId}
					openedPanel={openedPanel}
					setOpenedPanel={setOpenedPanel}
					isDefaultDevice={isDefaultDevice}
					insertImage={insertImage}
					resetImage={resetImage}
					removeImage={removeImage}
					images={images}
					uploads={uploads}
				/>

				<WidgetSettings
					t={t}
					onSave={saveDiff}
					data={data}
					unit={gridspec.unit}
					position="grid"
					currentDevice={currentDevice}
					options={BLOCK_OPT_SETTING}
				>
					{({ activeTab }) =>
						activeTab === 'layout' && (
							<Layout
								currentDevice={currentDevice}
								gridspec={gridspec}
								dispatch={dispatch}
								data={data}
								setData={setData}
								containerId={containerId}
								instanceId={instanceId}
								originId={originId}
								isDefaultDevice={isDefaultDevice}
							/>
						)
					}
				</WidgetSettings>
				<Actions
					dispatch={dispatch}
					data={data}
					containerId={containerId}
					instanceId={instanceId}
					originId={originId}
					isDefaultDevice={isDefaultDevice}
				/>
			</Toolbar>
		</Box>
	);
};

export default React.memo<TPropsRightSide>(ControlsRightSide);
