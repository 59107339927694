// @flow
import emptyArray from 'empty/array';

import { composeCached } from 'Widget/selectors';
import { getOrderIndex, getTrueIds, closestDeviceWithKey } from '@graphite/selectors';
import { dirDefaults } from '@graphite/constants';
import type { TId, TStateEditor, TWidgetOwnProps, TWidget } from '@graphite/types';
import getMapStateToProps from './getMapStateToProps';
import getWidgetMode from '../get-widget-mode';
import getDragAvailable from '../get-drag-available';
import getHoverAvailablity from '../get-hover-availablity';

const getMapStateToPropsEdit = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const {
		editor: { currentWidget, currentDevice },
		widgets,
	} = state;

	const { id, containerId, originId } = ownProps;
	const { widgetChain = emptyArray, ...ownPropsPublished } = ownProps;

	const propsPublised = getMapStateToProps(state, ownPropsPublished);

	const widgetMode = getWidgetMode(currentWidget, {
		_id: propsPublised.data._id,
		kind: propsPublised.data.kind,
	});

	const parent: TWidget = composeCached(widgets, widgets[containerId || 'none']);
	const {
		justifyContent = 'space-evenly',
		alignItems = 'stretch',
		flexDirection = 'row',
	} = closestDeviceWithKey(parent.box, {
		currentDevice,
		key: `box-${parent._id}`,
	});

	const direction = dirDefaults[flexDirection];

	const hoverAvailable = getHoverAvailablity(currentWidget, widgets, widgetChain, id);

	let editId = id;
	let editChain = widgetChain;
	let tmpChain = editChain;
	for (const wId of editChain) {
		if (id === currentWidget?.id) break;
		if (wId === currentWidget?.id || !wId) break;

		if (containerId) {
			// Если сейчас редактируется сосед, то теккущий виджет можно выбрать с первого раза
			const siblings = getTrueIds({ ...widgets[containerId], currentDevice });
			if (siblings.includes(currentWidget?.id)) break;
		}

		tmpChain = (tmpChain.slice(1): $ReadOnlyArray<TId>);

		if (widgets[wId].kind === 'stack') {
			editId = wId;
			editChain = tmpChain;
		}
	}

	const widgetOrderIndex = getOrderIndex({
		id,
		currentPage: originId,
		widgets,
		currentDevice,
	});

	const firstWidgetInChain = widgets[widgetChain[0]];

	return {
		...propsPublised,
		widgetMode,
		currentDevice,
		justifyContent,
		alignItems,
		hoverAvailable,
		editId,
		editChain,
		clickAvailable: true,
		dragAvailable: getDragAvailable(
			id,
			currentWidget,
			firstWidgetInChain,
			widgetChain,
			widgetMode,
		),
		widgetOrderIndex,
		direction,
	};
};

export default getMapStateToPropsEdit;
