// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import { Button, themes, PopupWhite, Box, Text, Link, LoaderIcon } from '@graphite/uneon';
import { getCurrentUser, getCurrentSite } from '@graphite/selectors';
import { useInjectSaga } from 'libs/inject-saga';
import { useInjectReducer } from 'libs/inject-reducer';
import { useDispatch, useSelector } from '@graphite/use-redux';
import { getPublishStatus } from 'Editor/selectors/editor';
import type { TPublicationStatus, TWidget } from '@graphite/types';
import publishReducer, {
	fetchIfNeeded,
	publish,
	saga as sagaPublish,
} from '../ducks/publish';

import { PANEL_DY, PANEL_DX } from './constants';

const HEIGHT = 42; // (54 - 30) / 2 + 30

type TProps = $ReadOnly<{|
	publishUrl: string,
	openDomainPopup?: ({| current: ?React$ElementRef<any> |}) => void,
	isOpenPublishLink: boolean,
	setStatePopupLink: boolean => void,
|}>;

const Wrapper = styled.div`
	position: relative;
	margin-left: 12px;
`;

const titleSx = {
	marginBottom: '18px',
};

const linkSx = {
	textDecoration: 'none',
};

const textSx = {
	marginBottom: '12px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

const LoaderBox = styled.div`
	background-color: ${({ theme }) => theme.colors.bg.accent};
	position: absolute;
	border-radius: ${({ theme }) => theme.radii.rounded.all};
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const PublishBox = styled(Box)`
	& > div:first-child {
		margin-bottom: 6px;
	}
`;

const DomainOpenButton = styled(Button)`
	width: 100%;
	margin-top: 30px;
	margin-bottom: 12px;
`;

function TopBar({
	publishUrl,
	openDomainPopup,
	isOpenPublishLink = false,
	setStatePopupLink,
}: TProps) {
	useInjectSaga({ key: 'publish', saga: sagaPublish });
	useInjectReducer({ key: 'publish', reducer: publishReducer });

	const dispatch = useDispatch();
	const user = useSelector(getCurrentUser);
	const site: ?TWidget = useSelector(getCurrentSite);
	const isConected: boolean = !!site?.domain;

	React.useEffect(() => {
		dispatch(fetchIfNeeded(user?._id, site?._id));

		return () => {
			dispatch(fetchIfNeeded(null));
		};
	}, [dispatch, site?._id, user]);

	const panelRef = React.useRef();

	const publishStatus: TPublicationStatus = useSelector(getPublishStatus);
	const { t } = useTranslation();

	const publishing = React.useCallback(() => {
		dispatch(publish());
		setStatePopupLink(true);
	}, [dispatch, setStatePopupLink]);

	const closePublishPopup = React.useCallback(() => {
		setStatePopupLink(false);
	}, [setStatePopupLink]);

	const handleOpenDomainPopup = React.useCallback(() => {
		if (openDomainPopup) openDomainPopup(panelRef);
	}, [openDomainPopup]);

	return (
		<>
			<Wrapper ref={panelRef}>
				<Button variant="accent.rounded.sm" onClick={publishing}>
					{t('Publish')}
				</Button>
				{publishStatus === 'publishing' && (
					<LoaderBox>
						<LoaderIcon />
					</LoaderBox>
				)}
			</Wrapper>
			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={
						isOpenPublishLink &&
						['published', 'error'].includes(publishStatus)
					}
					anchorEl={panelRef}
					offsetTop={HEIGHT + PANEL_DY}
					offsetLeft={PANEL_DX}
					onClose={closePublishPopup}
					mutex="topright"
					isFixed
					noDrag
				>
					{isOpenPublishLink && (
						<Box>
							<Text variant="title4" color="text.primaryalt" sx={titleSx}>
								{t('Published')}
							</Text>
							{publishUrl && (
								<PublishBox>
									{!isConected && (
										<Text variant="bodysm" color="text.secondary">
											Service URL
										</Text>
									)}
									<Link
										href={
											site?.domain
												? `http://${site?.domain}`
												: publishUrl
										}
										rel="noopener noreferrer"
										target="_blank"
										sx={linkSx}
									>
										<Text
											variant={isConected ? 'bodymd' : 'bodysm'}
											color="text.accent"
											sx={textSx}
										>
											{site?.domain ||
												publishUrl.replace('http://', '')}
										</Text>
									</Link>
									{!isConected && openDomainPopup && (
										<DomainOpenButton
											variant="primaryalt.rounded.md"
											onClick={handleOpenDomainPopup}
										>
											<Text
												variant="headlinesm"
												color="text.primaryalt"
											>
												{t('Connect domain')}
											</Text>
										</DomainOpenButton>
									)}
								</PublishBox>
							)}
							{publishStatus === 'error' && t('Publishing error occured!')}
						</Box>
					)}
				</PopupWhite>
			</ThemeProvider>
		</>
	);
}

export default React.memo<TProps>(TopBar);
