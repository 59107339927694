// @flow
import _ from 'lodash/fp';
import { getRect as getRectOriginal } from '@graphite/use-rect';

import { INITIAL_RECT } from '../constants';
import type { TFindDropId, TWindow } from '../constants/types';

const getPlaces = (places, type4filter) =>
	_.filter(({ type }) => type === type4filter, places);

const getRect = (place, dropRects) => {
	const rect = place.ref.current ? getRectOriginal(place.ref.current) : INITIAL_RECT;

	// eslint-disable-next-line no-param-reassign
	dropRects.current[place.dropId] = rect;
	return rect;
};

const findDropId: TFindDropId = ({ dropPlaces, dropRects, position, dropData }) => {
	// Если у виджета уже есть контейнер, то не меняем его
	const placeCurrent = _.find(
		({ id }) => id === dropData?.current?.containerId,
		dropPlaces.current,
	);
	if (placeCurrent) {
		// eslint-disable-next-line no-param-reassign
		dropRects.current[placeCurrent.dropId] = getRect(placeCurrent, dropRects);
		return placeCurrent.dropId;
	}

	// eslint-disable-next-line no-restricted-syntax
	for (const dropAbsoluteType of ['stack', 'col', 'container', 'block']) {
		const dropPlace = _.find(place => {
			const rect = getRect(place, dropRects);

			const { pageXOffset, pageYOffset } = (window: TWindow);

			return (
				// если не пытаемся кинуть виджет сам в себя
				place.id !== dropData.current?.id &&
				// если не пытаемся кинуть в виджет его родителя
				!place.widgetChain.includes(dropData.current?.id) &&
				_.inRange(
					pageXOffset + rect.left,
					pageXOffset + rect.left + rect.width,
					position.x,
				) &&
				_.inRange(
					pageYOffset + rect.top,
					pageYOffset + rect.top + rect.height,
					position.y,
				)
			);
		}, getPlaces(dropPlaces.current, dropAbsoluteType));

		if (dropPlace) {
			return dropPlace.dropId;
		}
	}

	return null;
};

export default findDropId;
