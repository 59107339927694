// @flow
import { composeCached } from 'Widget/selectors';
import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';

import type { TMinimalConnectProps } from './types';

const getMapStateToProps = (
	// FixMe: тут должен быть TPublishedState
	{ widgets }: TStateEditor,
	{ id, containerId, widgetspec, colorspec, gridspec, effectspec }: TWidgetOwnProps,
) => {
	const parent =
		containerId && widgets[containerId]
			? composeCached(widgets, widgets[containerId])
			: null;
	const positions = parent ? parent.positions : { [id]: 'absolute' };

	const data: $PropertyType<TMinimalConnectProps, 'data'> = composeCached(
		widgets,
		widgets[id],
	);

	return {
		data,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
		position: positions[id],
	};
};

export default getMapStateToProps;
