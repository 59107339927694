// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import { resetEdit } from 'Editor/ducks/editor';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import type { Dispatch } from 'redux';
import type { TAction, TWidgetMode } from '@graphite/types';

type TRequiredProps = $ReadOnly<{
	dispatch: Dispatch<TAction>,
	widgetMode?: TWidgetMode,
}>;

/**
 * Добавляет выход из редактирования по клику
 */
const withUnedit = <TProps: TRequiredProps>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const Hoc = (props: TProps, ref) => {
		const { dispatch, widgetMode } = props;
		const currentRef = React.useRef(ref);
		React.useImperativeHandle(ref, () => currentRef.current);
		const clickHandler = React.useCallback(
			// Что блять?! Какой нахуй второй аргумент у события клика?!
			// Ну как, блять, можно до этого додуматься(((
			// FixMe: сделать по-человечески
			(e: MouseEvent, isForced: ?boolean = false) => {
				if (
					(isForced || e.target === currentRef.current) &&
					(!widgetMode || !checkActiveWidget({ widgetMode }))
				) {
					e.stopPropagation();
					dispatch(resetEdit());
				}
			},
			[dispatch, widgetMode],
		);

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} ref={currentRef} onClick={clickHandler} />;
	};
	Hoc.displayName = `withUnedit(${getDisplayName(Component)})`;
	return React.memo<TProps>(React.forwardRef(Hoc));
};

export default withUnedit;
