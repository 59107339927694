// @flow
import React from 'react';
import { Box, Section, PopupWhite } from '@graphite/uneon';
import { Background as LabelBackground } from '@graphite/labels';
import Library from '@graphite/library';
import type {
	TSpecsColor,
	TColorValue,
	TLibraryValue,
	TLibConfig,
	TSpecsGrid,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	t: string => string,
	label: string,
	isSeparated?: boolean,
	source: ?TColorValue,
	defaultItem: TColorValue,
	config?: ?TLibConfig,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	genId: string => string,
	onChange?: ?(?TColorValue) => void,
|}>;

const anchorBoxOuterStyle = {
	position: 'relative',
	margin: '0 -24px 0 -24px',
	height: 0,
};

const anchorBoxInnerStyle = {
	position: 'absolute',
	width: 0,
	height: 0,
	right: 0,
	top: 0,
};

const libColorAll = { color: { tabs: ['theme', 'palette', 'custom'] } };

const colorInsertButtons = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};
const colorRemoveButtons = {
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

const primaryColor = {
	kind: 'color',
	value: 'text.primary',
};

function SectionColor({
	t,
	label,
	isSeparated = false,
	source,
	config = null,
	defaultItem,
	colorspec,
	gridspec,
	onChange = null,
	genId,
}: TProps) {
	const [isEditedColor, setIsEditedColor] = React.useState<boolean>(false);

	const uneditColor = React.useCallback(() => setIsEditedColor(false), [
		setIsEditedColor,
	]);

	const clickSection = React.useCallback(
		(e, buttonName) => {
			if (buttonName === 'insert') {
				if (source) {
					return;
				}
				if (onChange) {
					onChange(defaultItem);
				}
				return;
			}

			if (buttonName === 'remove') {
				if (!source) {
					return;
				}
				if (onChange) {
					onChange(null);
				}
			}
		},
		[source, onChange, defaultItem],
	);

	const clickItem = React.useCallback(() => {
		if (!source) {
			return;
		}
		setIsEditedColor(true);
	}, [source]);

	const changeColor = React.useCallback(
		(value: TLibraryValue) => {
			if (value.kind !== 'color') {
				return;
			}
			if (!isEditedColor || !source) {
				return;
			}
			if (!onChange) {
				return;
			}

			onChange(value);
		},
		[isEditedColor, source, onChange],
	);

	const anchorRef = React.useRef();

	return (
		<>
			<Section
				size="xsm"
				isSeparated={isSeparated}
				color={source ? 'text.primary' : 'text.secondary'}
				label={label}
				buttonGroup={source ? colorRemoveButtons : colorInsertButtons}
				onClick={clickSection}
			>
				<Box sx={anchorBoxOuterStyle}>
					<Box ref={anchorRef} sx={anchorBoxInnerStyle} />
				</Box>
				{source && (
					<LabelBackground
						color={source}
						colorspec={colorspec}
						onClick={clickItem}
						isActive={isEditedColor}
					/>
				)}
			</Section>
			{isEditedColor && (
				<PopupWhite
					isOpen={!!source}
					anchorEl={anchorRef}
					offsetLeft={12}
					offsetTop={-100}
					onClose={uneditColor}
					mutex="color"
					isFixed
				>
					<Library
						t={t}
						config={config || libColorAll}
						value={source || primaryColor}
						onChange={changeColor}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				</PopupWhite>
			)}
		</>
	);
}

export default React.memo<TProps>(SectionColor);
