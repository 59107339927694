// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box } from '@graphite/uneon';
import getDisplayName from '@graphite/get-display-name';
import { getBoxSx } from '@graphite/selectors';
import type { TSpecsGrid, TSx, TPositionValue, TWidget } from '@graphite/types';

import type {
	TDragPropsFlip,
	TDragPropsContainer,
} from 'Widget/libs/dnd/constants/types';

type TMinimalProps = $ReadOnly<{
	data: TWidget,
	gridspec?: TSpecsGrid,
	position: TPositionValue,
	dragContainer?: TDragPropsContainer,
	dragFlip?: TDragPropsFlip,
}>;

type TConfigure = $ReadOnly<{
	baseSx?: TSx,
}>;

type TDiffProps = $ReadOnly<{
	position: TPositionValue,
}>;

const withHydrate = <TProps: TMinimalProps>(
	Component: React$ComponentType<$ReadOnly<{| ...$Exact<$Diff<TProps, TDiffProps>> |}>>,
	configure: ?TConfigure,
): React$ComponentType<$Exact<TProps>> => {
	const WithHydrate = (props: $Exact<TProps>, ref) => {
		const { position, ...otherProps } = props;
		const { data, gridspec, dragContainer, dragFlip } = otherProps;

		const sx: TSx = React.useMemo(() => {
			const containerSx: TSx = gridspec
				? getBoxSx({
						data,
						position,
						gridspec,
				  })
				: emptyObject;

			return _.assign(configure?.baseSx, containerSx);
		}, [data, position, gridspec]);

		const uneonDragProps = _.assign(dragContainer, dragFlip);

		return (
			/* eslint-disable-next-line react/jsx-props-no-spreading */
			<Box sx={sx} style={data.style} id={data._id} {...uneonDragProps} ref={ref}>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...otherProps} />
			</Box>
		);
	};

	WithHydrate.displayName = `withHydrate(${getDisplayName(Component)})`;
	return React.memo(React.forwardRef(WithHydrate));
};

export default withHydrate;
