// @flow
import React from 'react';
import styled from '@emotion/styled';
import { zIndices } from '@graphite/constants';
import { css } from '@emotion/core';
import DragSide from './DragSide';
import type { WrapProps, WrapPropsWithChildren, PropsDragBottomHeight } from './types';

const UNIFICATION_SIZE = 10;

const FrameWrap = styled.div`
	pointer-events: none;
	position: absolute;
	border: none;
	pointer-events: none;
	border: 1px solid
		${({ theme, isInstance }) =>
			isInstance ? theme.colors.spec.violet10 : theme.colors.spec.blue10};
`;

const Circle: React$ComponentType<{}> = styled(DragSide)`
		z-index: ${zIndices.resizeCircle};

		${({ dir }) => {
			if (dir === 'bottom' || dir === 'top')
				return css`
					left: 40%;
					right: 40%;
				`;

			if (dir === 'left' || dir === 'right')
				return css`
					top: 40%;
					bottom: 40%;
				`;
		}}

		&:before {
			content: '';
			box-sizing: border-box;
			position: absolute;
			height: 10px;
			width: 10px;
			border-radius: 99px;
			background: #fff;
			border: 1px solid ${({ theme }) => theme.colors.spec.blue10};
			box-shadow: ${({ theme }) => theme.shadows.smblue};
			transition: opacity 0.15s;

			${({ dir, isResize, margin, control }) => css`
					opacity: ${dir === 'bottom' &&
					control === 'sizes' &&
					isResize &&
					margin.bottom <= UNIFICATION_SIZE
						? 0
						: 1};
				`}
		}
	}
`;

const Handler: React$ComponentType<{}> = styled(DragSide)`
	z-index: ${zIndices.resizeHandler};

	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		width: 6px;
		height: 24px;
		opacity: 0;
		border-radius: 99px;
		background: #fff;
		border: 1px solid ${({ theme }) => theme.colors.spec.blue10};
		box-shadow: ${({ theme }) => theme.shadows.smblue};
		transition: opacity 0.15s;
		transform: rotate(
			${({ dir }) => (dir === 'top' || dir === 'bottom' ? 90 : 0)}deg
		);
	}

	&:hover {
		&:before {
			opacity: 1;
		}
	}
`;

const Frame = (props: WrapPropsWithChildren, ref) => {
	const { margin, isShown = true, isInstance } = props;
	const { top, left, right, bottom } = margin;

	const style = React.useMemo(() => {
		const style = {
			display: isShown ? 'block' : 'none',
			top: top ? `${top}px` : '0',
			left: left ? `${left}px` : '0',
			right: right ? `${right}px` : '0',
			bottom: bottom ? `${bottom}px` : '0',
		};

		return style;
	}, [isShown, top, left, right, bottom]);

	return (
		<FrameWrap ref={ref} isInstance={isInstance} style={style}>
			{props.children}
		</FrameWrap>
	);
};

export const Wrap = React.memo<WrapPropsWithChildren>(React.forwardRef(Frame));

export const DragMargin = (props: WrapProps) => {
	const { isShown = true, ...otherProps } = props;

	const style = React.useMemo(
		() => ({
			display: isShown ? 'flex' : 'none',
		}),
		[isShown],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Handler {...otherProps} dir={props.dir} style={style} />;
};

export const DragSizes = (props: PropsDragBottomHeight) => {
	const { isShown = true, ...otherProps } = props;

	const style = React.useMemo(
		() => ({
			display: isShown ? 'flex' : 'none',
		}),
		[isShown],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Circle {...otherProps} style={style} />;
};

export const DragMarginBottom = (props: WrapProps) => {
	const { isShown = true, ...otherProps } = props;

	const style = React.useMemo(
		() => ({
			display: isShown ? 'flex' : 'none',
		}),
		[isShown],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Handler {...otherProps} style={style} />;
};
