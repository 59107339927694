// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from '@graphite/uneon';
import useBreakpoint from '@graphite/use-breakpoint';
import logger from '@graphite/logger';
import { defaultCommonEffect, defaultShadow } from '@graphite/constants';
import { State as SelectState } from '@graphite/selects';
import {
	Color as SectionColor,
	Background as SectionBackground,
	Shadow as SectionShadow,
} from '@graphite/sections';
import type {
	TDesignCommonEffect,
	TBackgroundValues,
	TColorValue,
	TShadowValue,
	TShadowValues,
	TDesignColorProps,
} from '@graphite/types';

const defaultColorItem: TColorValue = {
	kind: 'color',
	value: 'text.primary',
};

const defaultBorderItem: TColorValue = {
	kind: 'color',
	value: 'bg.accent',
};

const defaultBgItem: TColorValue = {
	kind: 'color',
	value: 'bg.primary',
};

const defaultBgShadowItem: TShadowValue = {
	kind: 'shadow',
	value: defaultShadow,
};

const defaultTextShadowItem: TShadowValue = {
	kind: 'shadow',
	value: defaultShadow,
};

function Color({
	design,
	device,
	colorspec,
	gridspec,
	onChange = null,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignColorProps) {
	const { t } = useTranslation();

	const { breakpoint, changeBreakpoint } = useBreakpoint(onChange, design, device);

	const [stateName, setStateName] = React.useState('normal');
	const stateData: TDesignCommonEffect =
		breakpoint[stateName || 'normal'] || defaultCommonEffect;

	const bgSource: TBackgroundValues = React.useMemo(
		() => stateData.container.background || [],
		[stateData.container.background],
	);

	const bgShadowSource: TShadowValues = React.useMemo(
		() => stateData.container.shadow || [],
		[stateData.container.shadow],
	);

	const colorSource: ?TColorValue = React.useMemo(
		() =>
			stateData.text.color ? { kind: 'color', value: stateData.text.color } : null,
		[stateData.text.color],
	);

	const textShadowSource: TShadowValues = React.useMemo(
		() => stateData.text.shadow || [],
		[stateData.text.shadow],
	);

	const borderSource: ?TColorValue = React.useMemo(
		() =>
			stateData.container.border
				? { kind: 'color', value: stateData.container.border }
				: null,
		[stateData.container.border],
	);

	const bgChange = React.useCallback(
		(background: TBackgroundValues) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							background,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	const bgShadowChange = React.useCallback(
		(shadow: TShadowValues) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							shadow,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	const textShadowChange = React.useCallback(
		(shadow: TShadowValues) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						text: {
							...stateData.text,
							shadow,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	const colorChange = React.useCallback(
		(color: ?TColorValue) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						text: {
							...stateData.text,
							color: color ? color.value : null,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	const borderChange = React.useCallback(
		(color: ?TColorValue) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							border: color ? color.value : null,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	return (
		<>
			<SelectState state={stateName || 'normal'} onChange={setStateName} t={t} />
			<Section label={t('Container')}>
				<SectionBackground
					t={t}
					label={t('Background')}
					source={bgSource}
					defaultItem={defaultBgItem}
					colorspec={colorspec}
					gridspec={gridspec}
					onChange={bgChange}
					genId={genId}
					isSeparated
					insertImage={insertImage}
					removeImage={removeImage}
					resetImage={resetImage}
					images={images}
					uploads={uploads}
				/>
				<SectionColor
					t={t}
					label={t('Border')}
					source={borderSource}
					defaultItem={defaultBorderItem}
					colorspec={colorspec}
					gridspec={gridspec}
					onChange={borderChange}
					genId={genId}
					isSeparated
				/>
				<SectionShadow
					t={t}
					label={t('Shadow')}
					source={bgShadowSource}
					defaultItem={defaultBgShadowItem}
					colorspec={colorspec}
					gridspec={gridspec}
					onChange={bgShadowChange}
					genId={genId}
				/>
			</Section>
			<Section label={t('Text')}>
				<SectionColor
					t={t}
					label={t('Color')}
					source={colorSource}
					defaultItem={defaultColorItem}
					colorspec={colorspec}
					gridspec={gridspec}
					genId={genId}
					onChange={colorChange}
					isSeparated
				/>
				<SectionShadow
					t={t}
					label={t('Shadow')}
					source={textShadowSource}
					defaultItem={defaultTextShadowItem}
					colorspec={colorspec}
					gridspec={gridspec}
					onChange={textShadowChange}
					genId={genId}
				/>
			</Section>
		</>
	);
}

export default React.memo<TDesignColorProps>(Color);
