// @flow
import React from 'react';
import { Text } from '@graphite/uneon';
import useNameParamProps from '@graphite/use-name-param-props';
import { ListItemParams } from '@graphite/lists';
import type { TDesignSelectProps } from '@graphite/types';

const titleStyle = {
	marginBottom: '18px',
	cursor: 'default',
};

function DesignRectanle({
	design,
	gridspec,
	colorspec,
	effectspec,
	widgetspec,
	device,
	onChange,
	Style,
	Color,
	t,
	genId,
	insertImage,
	resetImage,
	removeImage,
	images,
	uploads,
}: TDesignSelectProps) {
	const nameParamProps = useNameParamProps(design, onChange, t);

	return (
		<>
			<Text variant="title4" color="text.primaryalt" sx={titleStyle}>
				{t('Design')}
			</Text>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<ListItemParams {...nameParamProps} />

			<Style
				design={design}
				device={device}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				onChange={onChange}
				t={t}
				genId={genId}
			/>
			<Color
				design={design}
				device={device}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				onChange={onChange}
				t={t}
				genId={genId}
				insertImage={insertImage}
				resetImage={resetImage}
				removeImage={removeImage}
				images={images}
				uploads={uploads}
			/>
		</>
	);
}

export default React.memo<TDesignSelectProps>(DesignRectanle);
