// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { Toolbar, ToolbarBtn, Popper, Tooltip, Text } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';
import type { TGridBreakpointName, TWidgetDiff } from '@graphite/types';
import type { TWidgetStack } from '../constants/types';
import ToolbarBtnAlign from './ToolbarBtnAlign';

type TProps = $ReadOnly<{|
	device: TGridBreakpointName,
	data: TWidgetStack,
	save: TWidgetDiff => void,
	openedPanel: ?string,
	setOpenedPanel: (?string) => void,
|}>;

const ICONS_ROW = {
	stretch: 'align-y-center-full-18',
	'flex-start': 'align-left-18',
	center: 'align-y-center-18',
	'flex-end': 'align-right-18',
	'space-between': 'align-y-space-between-18',
	'space-around': 'align-y-space-around-18',
	'space-evenly': 'align-y-space-evenly-18',
};

const ICONS_COLUMN = {
	stretch: 'align-y-center-full-18',
	'flex-start': 'align-top-18',
	center: 'align-x-center-18',
	'flex-end': 'align-bottom-18',
	'space-between': 'align-x-space-between-18',
	'space-around': 'align-x-space-around-18',
	'space-evenly': 'align-x-space-evenly-18',
};

const JustifyContent = ({ device, save, data, openedPanel, setOpenedPanel }: TProps) => {
	const { t } = useTranslation();

	const alignBtnEl: React$ElementRef<any> = React.useRef(null);

	const handleJustifyContent = React.useCallback(
		e => {
			e.preventDefault();
			setOpenedPanel(openedPanel !== 'horizontal' ? 'horizontal' : null);
		},
		[openedPanel, setOpenedPanel],
	);

	const saveAlign = React.useCallback(
		value => save(_.set(`box.${device}.justifyContent`, value, data)),
		[device, save, data],
	);

	const { justifyContent = 'center', flexDirection = 'row' } = closestDeviceWithKey(
		data.box,
		{
			currentDevice: device,
			key: `box-${data._id}`,
		},
	);

	const alignlist = React.useMemo(
		() => [
			{
				align: 'flex-start',
				title: flexDirection === 'row' ? t('Left') : t('Top'),
			},
			{ align: 'center', title: t('Center') },
			{
				align: 'flex-end',
				title: flexDirection === 'row' ? t('Right') : t('Bottom'),
			},
			{ align: 'space-between', title: t('Space between') },
			{ align: 'space-around', title: t('Space around') },
			{ align: 'space-evenly', title: t('Space evenly') },
		],

		[flexDirection, t],
	);

	const isOpenedPanel = openedPanel === 'horizontal';

	return (
		<>
			<ToolbarBtn
				onClick={handleJustifyContent}
				iconName={
					flexDirection === 'row'
						? ICONS_ROW[justifyContent]
						: ICONS_COLUMN[justifyContent]
				}
				ref={alignBtnEl}
				isActive={isOpenedPanel}
			/>
			<Tooltip elRef={alignBtnEl} placement={isOpenedPanel ? 'bottom' : 'top'}>
				{flexDirection === 'row'
					? t('Horizontal alignment')
					: t('Vertical alignment')}
				&nbsp;&nbsp;
				<Text as="span" color="spec.lightblue80">
					{alignlist.find(item => item.align === justifyContent)?.title}
				</Text>
			</Tooltip>

			<Popper
				isOpen={isOpenedPanel}
				anchorEl={alignBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					{alignlist.map(({ align, title }) => (
						<ToolbarBtnAlign
							key={align}
							onClick={saveAlign}
							icon={
								flexDirection === 'row'
									? ICONS_ROW[align]
									: ICONS_COLUMN[align]
							}
							isActive={align === justifyContent}
							align={align}
							title={title}
						/>
					))}
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(JustifyContent);
