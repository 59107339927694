// @flow
import React from 'react';
import _ from 'lodash/fp';

export type TComponentProps = $ReadOnly<{|
	value: string,
|}>;

const normalizeText4Win = value =>
	({
		meta: 'Ctrl',
		shift: 'Shift',
		control: 'Ctrl',
		alt: 'Alt',
		ctrl: 'Ctrl',
	}[_.toLower(_.trim(value))] || _.toUpper(_.trim(value)));

const HotkeyWin = ({ value }: TComponentProps) => {
	const values = value.split('+');
	return <span>{values.map(normalizeText4Win).join(' + ')}</span>;
};

export default React.memo<TComponentProps>(HotkeyWin);
