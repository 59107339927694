// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box } from '@graphite/uneon';
import { Textarea as TextareaField } from '@rebass/forms';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import { getBoxSx, getDesignSx } from '@graphite/selectors';
import type { TSx, TDesign } from '@graphite/types';

import type { TConnectProps } from './constants/types';

const baseTextareaSx: TSx = {
	borderWidth: 0,
	borderRadius: 0,
	borderColor: 'transparent',
	outline: 'none',
	background: 'none',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	textDecoration: 'none',
	padding: 0,
	opacity: 1,
};

const Textarea = (props: TConnectProps, ref) => {
	const {
		data,
		position,
		colorspec,
		effectspec,
		gridspec,
		widgetspec,
		dragContainer,
		dragFlip,
	} = props;

	const boxSx = React.useMemo(
		() => (gridspec ? getBoxSx({ data, position, gridspec }) : emptyObject),
		[data, gridspec, position],
	);

	const {
		textarea = {
			label: '',
			placeholder: '',
			resize: 'both',
			maxlength: null,
			isRequired: false,
		},
	} = data;

	const TextareaSx: TSx = React.useMemo(() => {
		if (!gridspec || !widgetspec || !colorspec || !effectspec) return emptyObject;

		const baseTextareaWithResizeSx = {
			...baseTextareaSx,
			resize: textarea.resize || 'both',
			height: '100%',
			width: '100%',
		};

		if (!data.designId) return baseTextareaWithResizeSx;

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.textarea.find(d => d._id === data.designId);

		if (!design) return baseTextareaWithResizeSx;

		const designSx = getDesignSx({
			design,
			widgetspec,
			colorspec,
			effectspec,
			gridspec,
		});
		return _.assign(baseTextareaWithResizeSx, designSx);
	}, [
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
		textarea,
		data.designId,
		data.designs,
	]);

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);
	const otherProps = _.assign(dragContainer, dragFlip);

	return (
		<>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Box sx={boxSx} style={dynamicStyle} ref={ref} {...otherProps}>
				<TextareaField
					name={textarea.label}
					maxLength={textarea.maxlength}
					placeholder={textarea.placeholder}
					required={textarea.isRequired}
					sx={TextareaSx}
				/>
			</Box>
		</>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Textarea));
