// @flow
import React from 'react';
import { Box, Separator } from '@graphite/uneon';
import { defaultTheme } from '@graphite/constants';
import { ThemeColors as ListThemeColors } from '@graphite/lists';
import type { TColorReference } from '@graphite/types';

type TProps = $ReadOnly<{|
	value?: ?TColorReference,
	colorspec: Object,
	onChange?: ?(TColorReference) => void,
|}>;

const separatorBoxStyle = {
	margin: '0 -24px 0 -24px',
	height: 0,
};

function Themes({ value, colorspec, onChange }: TProps) {
	const { themes, activeTheme } = colorspec;
	const themeAt = React.useMemo(
		() => themes.findIndex(theme => theme.id === activeTheme),
		[themes, activeTheme],
	);
	const theme = themeAt < 0 ? defaultTheme : themes[themeAt];

	const clickColor = React.useCallback(
		path => {
			if (onChange) {
				onChange(
					path
						.split('.')
						.slice(2)
						.join('.'),
				);
			}
		},
		[onChange],
	);

	const active = typeof value === 'string' ? `themes.${themeAt}.${value}` : null;

	return (
		<>
			<Box sx={separatorBoxStyle}>
				<Separator />
			</Box>
			<ListThemeColors
				active={active}
				colorspec={colorspec}
				theme={theme}
				themeAt={themeAt}
				onClick={clickColor}
			/>
		</>
	);
}

Themes.defaultProps = {
	value: null,
	onChange: null,
};

export default React.memo<TProps>(Themes);
