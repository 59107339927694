// @flow
import { connect } from 'react-redux';
import { withAbsolute } from '@graphite/abs-grid';
import Code from '@graphite/widget-code';
import type { TWidgetOwnProps } from '@graphite/types';

import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import type { TConnectProps } from './constants/types';
import withHydrate from '../../libs/with-hydrate';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(getMapStateToProps)(
	withAbsolute()(
		withHydrate(Code, {
			baseSx: {
				flexDirection: 'column',
			},
		}),
	),
);
