// @flow
import React, { memo, useMemo, useState, useEffect, forwardRef } from 'react';
import { Flex, Box } from 'rebass';
import type { TIconEmbed } from '@graphite/types';
import { namedColors } from '../constants';

type TProps = $ReadOnly<{|
	...TIconEmbed,
	onClick?: MouseEvent => void,
	className?: string,
|}>;

// HACK: линты ебутся
const iconSizeMap = {};
iconSizeMap[18] = 'xxsm';
iconSizeMap[24] = 'xsm';
iconSizeMap[30] = 'sm';

const sizeMap = {
	xxsm: '18px',
	xsm: '24px',
	sm: '30px',
	md: '36px',
	lg: '42px',
	xlg: '48px',
	xxlg: '54px',
};

const iconContainerStyle = {
	alignItems: 'center',
	borderStyle: 'solid',
	justifyContent: 'center',
};

const iconBoxStyle = {
	overflow: 'hidden',
	svg: {
		transitionTimingFunction: 'cubic-bezier(.25, .46, .45, .94)',
		transitionDuration: '0.25s',
		transitionProperty: 'fill',
	},
};

const Icon = (
	{
		name,
		iconSize = 18,
		rotation = 0,
		colors = 'primary',
		variant = 'flat',
		shape = 'normal',
		size = null,
		isDisabled = false,
		isActive = false,
		title = '',
		sx = null,
		style = null,
		onClick = null,
		className,
	}: TProps,
	ref,
) => {
	const [Component, setComponent] = useState(null);

	const {
		text,
		texthover,
		textfocus,
		textactive,
		bg,
		bghover,
		bgfocus,
		bgactive,
		border,
		borderhover,
		borderfocus,
		borderactive,
	} = (() => {
		if (typeof colors === 'string') {
			return namedColors[colors];
		}
		return colors || namedColors.primary;
	})();

	useEffect(() => {
		let isCancelled = false;

		// Нужно явно отмонтировать старый компонент
		setComponent(null);

		(async () => {
			// eslint-disable-next-line no-shadow
			const { ReactComponent: Component } = await import(
				// eslint-disable-next-line prefer-template
				'./icons/' + name + '-' + iconSize + '.svg'
			);
			if (!isCancelled) {
				setComponent(Component);
			}
		})();

		return () => {
			isCancelled = true;
		};
	}, [name, iconSize, setComponent]);

	const finalSize = size || iconSizeMap[iconSize];
	const iconContainerDynamicStyle = useMemo(
		() => ({
			minHeight: sizeMap[finalSize],
			minWidth: sizeMap[finalSize],
			borderWidth: variant === 'ghost' ? '2px' : 0,
			opacity: isDisabled ? '0.4' : null,
			cursor: onClick && !isDisabled ? 'pointer' : null,
			...style,
		}),
		[finalSize, variant, isDisabled, onClick, style],
	);

	const iconContainerThemedStyle = useMemo(() => ({
			...iconContainerStyle,
			backgroundColor:
				(variant !== 'flat' && (isActive ? bghover : bg)) || 'transparent',
			borderColor:
				(variant !== 'flat' && (isActive ? borderhover : border)) ||
				'transparent',
			...((!isActive &&
				variant !== 'flat' && {
					':hover': {
						backgroundColor: bghover,
						borderColor: borderhover,
					},
					':focus': {
						backgroundColor: bgfocus,
						borderColor: borderfocus,
					},
					':active': {
						backgroundColor: bgactive,
						borderColor: borderactive,
					},
				}) ||
				null),
			borderRadius: shape === 'normal' ? 'md.all' : 'rounded.all',
			...sx,
		}), [
		variant,
		isActive,
		bghover,
		bg,
		borderhover,
		border,
		bgfocus,
		borderfocus,
		bgactive,
		borderactive,
		shape,
		sx,
	]);

	const iconBoxDynamicStyle = useMemo(
		() => ({
			transform: `rotate(${rotation}deg)`,
			width: `${iconSize}px`,
			height: `${iconSize}px`,
		}),
		[rotation, iconSize],
	);

	const iconBoxThemedStyle = useMemo(
		() => ({
			...iconBoxStyle,
			svg: {
				...iconBoxStyle.svg,
				fill: isActive ? texthover : text,
				height: `${iconSize}px`,
			},
			...((!isActive && {
				':hover': {
					svg: {
						fill: texthover,
					},
				},
				':focus': {
					svg: {
						fill: textfocus,
					},
				},
				':active': {
					svg: {
						fill: textactive,
					},
				},
			}) ||
				null),
		}),
		[iconSize, isActive, text, textactive, textfocus, texthover],
	);

	return (
		<Flex
			ref={ref}
			sx={iconContainerThemedStyle}
			style={iconContainerDynamicStyle}
			title={title}
			onClick={onClick || undefined}
			className={className}
		>
			<Box sx={iconBoxThemedStyle} style={iconBoxDynamicStyle}>
				{Component}
			</Box>
		</Flex>
	);
};

export default memo<TProps>(forwardRef(Icon));
