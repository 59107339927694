// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Flex, Tooltip, Hotkey, Text } from '@graphite/uneon';
import { useDispatch } from '@graphite/use-redux';
import useHotkeys from '@graphite/use-hotkeys';
import { useTranslation } from 'react-i18next';
import { historyBack, historyForward } from 'Editor/ducks/history';
import FrameContext from 'Editor/Frame/Context';
import type { THistory } from '@graphite/types';

type TProps = $ReadOnly<{||}>;

const flexSx = {
	alignItems: 'center',
	marginRight: '6px',
};

const boxUndoSx = {
	marginRight: '6px',
};

const boxDisabledSx = {
	...boxUndoSx,
	opacity: 0.4,
};

function ButtonsHistory() {
	const constructorHistory: THistory = useSelector(state => state.constructorHistory);
	const { cursor, forward, history } = constructorHistory;
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { document: documentFrame, window: windowFrame } = React.useContext(
		FrameContext,
	);

	const historyLength = history.length;

	const { isHistoryUndoDisabled, isHistoryRedoDisabled } = React.useMemo(() => {
		const isHistoryUndoDisabled = cursor < 0 || (cursor === 0 && !forward);
		const isHistoryRedoDisabled =
			cursor < 0 ||
			cursor >= historyLength ||
			(cursor === historyLength - 1 && forward);
		return {
			isHistoryUndoDisabled,
			isHistoryRedoDisabled,
		};
	}, [cursor, forward, historyLength]);

	const boundBack = React.useCallback(() => {
		dispatch(historyBack());
	}, [dispatch]);
	const boundForward = React.useCallback(() => {
		dispatch(historyForward());
	}, [dispatch]);

	const hotkeyUndoHandler = React.useCallback(
		e => {
			e.preventDefault();
			boundBack();
		},
		[boundBack],
	);

	const hotkeyRedoHandler = React.useCallback(
		e => {
			e.preventDefault();
			boundForward();
		},
		[boundForward],
	);

	useHotkeys(['Control+z', 'Meta+z'], hotkeyUndoHandler, documentFrame, windowFrame);
	useHotkeys(
		['Control+Shift+z', 'Meta+Shift+z'],
		hotkeyRedoHandler,
		documentFrame,
		windowFrame,
	);

	const buttonHistoryUndo: React$ElementRef<any> = React.useRef(null);
	const buttonHistoryRedo: React$ElementRef<any> = React.useRef(null);

	return (
		<Flex sx={flexSx}>
			{/* wtf?! Почему бы здесь кнопку не заюзать? */}
			<Icon
				name="undo"
				iconSize={18}
				size="md"
				colors="secondary"
				sx={isHistoryUndoDisabled ? boxDisabledSx : boxUndoSx}
				onClick={boundBack}
				ref={buttonHistoryUndo}
			/>
			<Tooltip elRef={buttonHistoryUndo} delayShow={1000}>
				{t('Undo')}&nbsp;&nbsp;
				<Text as="span" color="spec.lightblue80">
					<Hotkey mac="Meta+z" win="Control+z" />
				</Text>
			</Tooltip>
			<Icon
				name="redo"
				iconSize={18}
				size="md"
				colors="secondary"
				sx={isHistoryRedoDisabled ? boxDisabledSx : null}
				onClick={boundForward}
				ref={buttonHistoryRedo}
			/>
			<Tooltip elRef={buttonHistoryRedo} delayShow={1000}>
				{t('Redo')}&nbsp;&nbsp;
				<Text as="span" color="spec.lightblue80">
					<Hotkey mac="Meta+Shift+z" win="Control+Shift+z" />
				</Text>
			</Tooltip>
		</Flex>
	);
}

export default React.memo<TProps>(ButtonsHistory);
