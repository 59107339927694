// @flow

import compose from 'libs/compose';
import _ from 'lodash/fp';
import cast from 'libs/types/widgets';
import type {
	TId,
	TWidgetComposed,
	TWidgetTraceContainersItem,
	TWidgetTraceContainersParams,
	TWidgetTraceContainersResult,
} from '@graphite/types';


export default ({
	widgets,
	start,
	finalId,
	finalInstanceId,
}: TWidgetTraceContainersParams): TWidgetTraceContainersResult =>
	(function _recurse(
		current: TWidgetTraceContainersItem,
		finalId: TId,
		finalInstanceId: ?TId,
	) {
		if (!widgets[current.targetId]) return [];

		if (
			current.targetId === finalId &&
			(finalInstanceId === undefined || current.instanceId === finalInstanceId)
		) {
			return [{ ...current }];
		}

		const composed: TWidgetComposed = compose(widgets, widgets[current.targetId]);

		if (!composed.children) {
			return [];
		}

		const composedInstance: ?TWidgetComposed = cast.TWidgetComposedInstance(composed);

		let result = [];
		(_.values(composed.children): $ReadOnlyArray<TId>)
			.filter(id => id)
			.some(id => {
				result = _recurse(
					{
						targetId: id,
						containerId: composed._id,
						instanceId: composedInstance ? composed._id : current.instanceId,
					},
					finalId,
					finalInstanceId,
				);
				return result.length > 0;
			});

		return result.length ? [...result, { ...current }] : [];
	})(start, finalId, finalInstanceId);
