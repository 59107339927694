// @flow
import every from 'lodash/every';
import compose from 'libs/compose';
import { defaultWidget } from '@graphite/constants';
import type { TWidget, TWidgets } from '@graphite/types';

const protosForCompose = {};
const cacheForCompose = {};

export const composeCached = (widgets: TWidgets, widget: TWidget = defaultWidget) => {
	// Складываем в массив все виджеты этой цепочки
	const protosWidget = [];
	const { _id } = widget;
	protosWidget.push(widget);

	let next = widget.protoId;
	while (next) {
		const proto = widgets[next];
		protosWidget.push(proto);
		next = proto.protoId;
	}

	// Сравниваем все элементы цепочки по отдельности
	const isEqual =
		protosForCompose[_id] &&
		every(protosForCompose[_id], (proto, index) => protosWidget[index] === proto);

	if (isEqual) {
		return cacheForCompose[_id];
	}

	protosForCompose[_id] = protosWidget;
	// ToDo здесь можно немного оптимизировать, научив compose принимать кешированный список родителей
	// ToDo Но пока не написаны тесты, рефакторить код нельзя
	const composeWidget = compose(widgets, widget);
	cacheForCompose[_id] = composeWidget;
	return cacheForCompose[_id];
};

export default {};
