// @flow
import _ from 'lodash/fp';
import type { TWidgets, TSpecs, TTransaction } from '@graphite/types';
import firebase from '../firebase';

export const db = firebase.firestore();
if (window.location.hostname === 'localhost') {
	db.useEmulator('localhost', 8080);
}

export const collection = db.collection.bind(db);

export const genId = (collectionName: string): string =>
	db.collection(collectionName).doc().id;

export const commitWidgetsToBd = async (widgets: TWidgets) => {
	const batch = db.batch();
	const count = _.size(widgets);
	const id = genId('widgets');
	const transaction: TTransaction = { id, count };

	for (const key in widgets) {
		// transaction нужен для склеивания изм
		// от firebase( в коллаборация приходят частями)
		const widget = { ...widgets[key], transaction };
		const widgetRef = collection('widgets').doc(key);
		batch.set(widgetRef, widget);
	}

	await batch.commit();
};

export const commitSpecsToBd = async (specs: TSpecs) => {
	const batch = db.batch();

	for (const key in specs) {
		const spec = specs[key];
		const specRef = collection('specs').doc(key);
		batch.set(specRef, spec);
	}

	await batch.commit();
};

export default db;
