// @flow
import React from 'react';
import { ButtonGroup, Text } from '@graphite/uneon';
import useNameParamProps from '@graphite/use-name-param-props';
import { ListItemParams } from '@graphite/lists';
import type { TDesignSelectProps } from '@graphite/types';

const groupStyle = {
	padding: '18px 0',
};

const tabList = [
	{ label: 'Style', name: 'style' },
	{ label: 'Color', name: 'color' },
];

const titleStyle = {
	marginBottom: '18px',
	cursor: 'default',
};

const DesignPanel = ({
	design,
	gridspec,
	colorspec,
	widgetspec,
	effectspec,
	device,
	onChange,
	Style,
	Color,
	t,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignSelectProps) => {
	const [activeTab, setActiveTab] = React.useState('style');

	const tab = React.useMemo(
		// eslint-disable-next-line no-shadow
		() => tabList.find(t => t.name === activeTab) || tabList[0],
		[activeTab],
	);

	const clickBound = React.useCallback((e, name) => {
		if (typeof name === 'string') {
			setActiveTab(name);
		}
	}, []);

	const nameParamProps = useNameParamProps(design, onChange, t);

	return (
		<>
			<Text variant="title4" color="text.primaryalt" sx={titleStyle}>
				{design.name}
			</Text>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<ListItemParams {...nameParamProps} />
			<ButtonGroup
				behavior="radio"
				active={tab.name}
				buttons={tabList}
				variant="normal"
				shape="rounded"
				size="sm"
				colors="accentghost"
				activeColors="accent"
				onClick={clickBound}
				sx={groupStyle}
			/>
			{(tab.name === 'style' && (
				<Style
					device={device}
					design={design}
					gridspec={gridspec}
					effectspec={effectspec}
					widgetspec={widgetspec}
					colorspec={colorspec}
					onChange={onChange}
					t={t}
					genId={genId}
				/>
			)) ||
				(tab.name === 'color' && (
					<Color
						device={device}
						design={design}
						gridspec={gridspec}
						effectspec={effectspec}
						widgetspec={widgetspec}
						colorspec={colorspec}
						onChange={onChange}
						t={t}
						genId={genId}
						insertImage={insertImage}
						removeImage={removeImage}
						resetImage={resetImage}
						images={images}
						uploads={uploads}
					/>
				))}
		</>
	);
};

export default React.memo<TDesignSelectProps>(DesignPanel);
