// @flow
import React, { memo, useState, useCallback, useRef } from 'react';
import type {
	TSx,
	TStyle,
	TListEmbed,
	TButtonEmbed,
	TPopupOnClick,
} from '@graphite/types';

import Box from '../Box';
import List from '../List';
import ButtonDeprecated from '../ButtonDeprecated';
import PopupSelect from '../PopupSelect';

type TProps = $ReadOnly<{|
	children?: ?React$Node,
	footer?: ?React$Node,
	button: TButtonEmbed,
	list: TListEmbed,
	sx?: ?TSx,
	style?: ?TStyle,
	onClick?: ?TPopupOnClick,
	onOpen?: (boolean) => void,
|}>;

function PopupMenu({
	children = null,
	footer = null,
	button,
	list,
	sx = null,
	style = null,
	onClick = null,
	onOpen = null,
}: TProps) {
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = useCallback(
		(e) => {
			if (e) e.stopPropagation();
			const newOpen = !isOpen;
			setIsOpen(newOpen);
			if (onOpen) {
				onOpen(newOpen);
			}
		},
		[isOpen, setIsOpen, onOpen],
	);

	const boundClick = useCallback(
		(e, itemName, buttons) => {
			const result = onClick ? onClick(e, itemName, buttons) : undefined;
			if (result !== false) {
				setIsOpen(false);
				if (onOpen) {
					onOpen(false);
				}
			}
		},
		[onClick, onOpen],
	);

	const anchorRef = useRef();

	return (
		<Box data-kind="uneon-popup-menu" sx={sx} style={style}>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<ButtonDeprecated {...button} onClick={toggleOpen} />
			<Box ref={anchorRef} />
			<PopupSelect
				isOpen={isOpen}
				anchorEl={anchorRef}
				offsetTop={0}
				offsetLeft={0}
				onClose={toggleOpen}
			>
				{children}
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<List {...list} onClick={boundClick} />
				{footer}
			</PopupSelect>
		</Box>
	);
}

export default memo<TProps>(PopupMenu);
