// @flow
import React from 'react';
import _ from 'lodash/fp';

import { Section } from '@graphite/uneon';
import { emptySelection } from '@graphite/constants';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '@graphite/use-breakpoint';
import { Params as ListParams } from '@graphite/lists';
import type { TDesignStyleProps, TParams } from '@graphite/types';

function Style({
	design,
	device,
	gridspec: { unit },
	effectspec,
	onChange = null,
}: TDesignStyleProps) {
	const { paramSource, changeParam } = useBreakpoint(onChange, design, device);
	const { t } = useTranslation();
	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Border'),
				key: 'border',
				kind: 'select',
				info: {
					label: 'None',
					list: {
						items: [],
					},
				},
			},
			{
				title: t('Radius'),
				key: 'radius',
				kind: 'select',
				info: {
					label: 'None',
					list: {
						items: [],
					},
				},
			},
		],
		[t],
	);

	const paramListFinal: TParams = React.useMemo(
		() =>
			_.set(
				[0, 'info', 'list', 'items'],
				[
					emptySelection,
					...effectspec.borders.map(border => ({
						name: border.id,
						label: border.name,
					})),
				],
				_.set(
					[1, 'info', 'list', 'items'],
					[
						emptySelection,
						...effectspec.radii.map(radius => ({
							name: radius.id,
							label: radius.name,
						})),
					],
					paramList,
				),
			),
		[effectspec.borders, effectspec.radii, paramList],
	);

	return (
		<>
			<Section label={t('Style')}>
				<ListParams
					listName={`${device}-c`}
					paramSource={paramSource}
					paramList={paramListFinal}
					unit={unit}
					onChange={changeParam}
				/>
			</Section>
		</>
	);
}

export default React.memo<TDesignStyleProps>(Style);
