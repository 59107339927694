// @flow
import React from 'react';
import styled from '@emotion/styled';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import getDisplayName from '@graphite/get-display-name';
import { transitions } from '@graphite/constants';
import { closestDeviceWithKey } from '@graphite/selectors';
import type { TSx, TDirection, TJustifyContent, TAlignItems } from '@graphite/types';
import Controls from './Controls';
import Resize from './Resize';
import type { TMinimalProps, TSymbioteProps, TRequiredControlsMargin } from './types';

const Concealer = styled.div`
	display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

type TPropsMixed = $ReadOnly<{
	...$Exact<TMinimalProps>,
	...$Exact<TSymbioteProps>,
	direction: TDirection,
	justifyContent?: TJustifyContent,
	alignItems?: TAlignItems,
}>;

const boxSx: TSx = {
	transitionDuration: transitions.widgetControls.showDuration,
	transitionTimingFunction: transitions.widgetControls.showTiming,
	transitionDelay: transitions.widgetControls.showDelay,
	transitionProperty: 'opacity, border, background-color',
	// пока не работают, потому что надо консилер переделать на опасити

	height: '100%',
	width: '100%',
	pointerEvents: 'none',
};

const withWidgetControls = (requiredControls: TRequiredControlsMargin) => <
	TProps: TPropsMixed,
>(
	Component: React$ComponentType<$Diff<TProps, TSymbioteProps>>,
): React$ComponentType<TProps> => {
	const WithWidgetControls = (props: TProps, ref) => {
		const {
			data,
			position,
			currentDevice,
			widgetMode,
			hoverAvailable,
			gridspec,
			direction,
			justifyContent,
			alignItems,
		} = props;

		const { symbioteRef, hovered, ...ownProps } = props;

		const isAbsolute = position === 'absolute' || position === 'absolute-container';
		const isEdit = widgetMode === 'widget-edit';
		const isResizeControls = widgetMode === 'widget-resize';

		const isControls = (hovered && hoverAvailable) || isResizeControls || isEdit;

		const initialBox = closestDeviceWithKey(data.box, {
			currentDevice,
			key: `initialBox-${data._id}`,
		});

		const requiredControlsWithAutoUnit = React.useMemo(() => {
			const isHeightEnabled = isAbsolute
				? !!initialBox.offset?.height
				: initialBox.heightUnit !== 'auto';
			const isWidthEnabled = isAbsolute
				? !!initialBox.offset?.width
				: initialBox.widthUnit !== 'auto';

			return {
				...requiredControls,
				width: isWidthEnabled,
				height: isHeightEnabled,
			};
		}, [initialBox, isAbsolute]);

		return (
			<Box data-kind="with-widget-controls" sx={boxSx}>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...ownProps} data={data} ref={ref} />
				<Concealer isActive={isControls}>
					<Controls
						title={_.capitalize(data.name || data.kind)}
						data={data}
						position={position}
						widgetMode={widgetMode}
					/>
				</Concealer>
				{isResizeControls && (
					<Resize
						isShown={isResizeControls}
						currentDevice={currentDevice}
						gridspec={gridspec}
						requiredControls={requiredControlsWithAutoUnit}
						direction={direction}
						justifyContent={justifyContent}
						alignItems={alignItems}
						isAbsolute={isAbsolute}
					/>
				)}
			</Box>
		);
	};

	WithWidgetControls.displayName = `withWidgetControls(${getDisplayName(Component)})`;
	return React.memo(React.forwardRef(WithWidgetControls));
};

export default withWidgetControls;
