// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Section, Box } from '@graphite/uneon';
import { emptySelection } from '@graphite/constants';
import useBreakpoint from '@graphite/use-breakpoint';
import { Params as ListParams } from '@graphite/lists';
import type {
	TActiveButtons,
	TUnit,
	TDesignStyleProps,
	TParams,
	TListParamsOnClick,
} from '@graphite/types';

const paramListContainer: TParams = [
	{
		title: 'Height',
		key: 'height',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'heightUnit',
			units: ['unit', 'px'],
		},
	},
	{
		title: 'Padding',
		key: 'padding',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'paddingUnit',
			units: ['unit', 'px'],
		},
	},
	{
		title: 'Radius',
		key: 'radius',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
	{
		title: 'Border',
		key: 'border',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
];

const paramListText: TParams = [
	{
		title: 'Style',
		key: 'textDesign',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
	{
		title: 'Margin Top',
		key: 'textMargin',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'textMarginUnit',
			units: ['px', 'unit'],
		},
	},
];

const paramListIcon: TParams = [
	{
		title: 'Size',
		key: 'btnIconSize',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'btnIconSizeUnit',
			units: ['px', 'unit'],
		},
	},
	{
		title: 'Margin',
		key: 'iconMargin',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'iconMarginUnit',
			units: ['unit', 'px'],
		},
	},
];

const paramListAll = [...paramListContainer, ...paramListText, ...paramListIcon];

function Style({
	design,
	gridspec: { unit },
	widgetspec,
	effectspec,
	device,
	onChange = null,
}: TDesignStyleProps) {
	const { paramSource, changeParam } = useBreakpoint(onChange, design, device);

	const paramListContainerFinal: TParams = React.useMemo(
		() =>
			_.set(
				[3, 'info', 'list', 'items'],
				[
					emptySelection,
					...effectspec.borders.map(border => ({
						name: border.id,
						label: border.name,
					})),
				],
				_.set(
					[2, 'info', 'list', 'items'],
					[
						emptySelection,
						...effectspec.radii.map(radius => ({
							name: radius.id,
							label: radius.name,
						})),
					],
					paramListContainer,
				),
			),
		[effectspec.borders, effectspec.radii],
	);

	const paramListTextFinal: TParams = React.useMemo(
		() =>
			_.set(
				[0, 'info', 'list', 'items'],
				_.filter(design => !design.removedAt, widgetspec.text).map(text => ({
					name: text._id,
					label: text.name,
				})),
				paramListText,
			),
		[widgetspec.text],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key: string, name: ?TActiveButtons) => {
			if (!onChange || (name !== 'px' && name !== 'unit')) {
				return;
			}
			const unitNext: TUnit = name;
			const param = paramListAll.find(p => p.key === key);
			if (!param || param.kind !== 'unit' || !param.info.unitKey) {
				return;
			}

			const unitPath = ['breakpoints', device, param.info.unitKey];
			const unitPrev: TUnit = _.get(unitPath, design);
			if (unitPrev === unitNext) {
				return;
			}

			let next = _.set(unitPath, unitNext, design);

			const valuePath = ['breakpoints', device, param.key];

			const valuePrev = _.get(valuePath, next);
			if (unitNext === 'px') {
				next = _.set(valuePath, valuePrev * unit, next);
			} else if (unitNext === 'unit') {
				next = _.set(valuePath, Math.round(valuePrev / unit), next);
			}

			onChange(next);
		},
		[onChange, device, design, unit],
	);

	return (
		<Box>
			<Section label="Container">
				<ListParams
					listName={`${device}-c`}
					paramSource={paramSource}
					paramList={paramListContainerFinal}
					unit={unit}
					onChange={changeParam}
					onClick={clickUnit}
				/>
			</Section>

			<Section label="Text">
				<ListParams
					listName={`${device}-t`}
					paramSource={paramSource}
					paramList={paramListTextFinal}
					unit={unit}
					onChange={changeParam}
					onClick={clickUnit}
				/>
			</Section>
			<Section label="Icon">
				<ListParams
					listName={`${device}-i`}
					paramSource={paramSource}
					paramList={paramListIcon}
					unit={unit}
					onChange={changeParam}
					onClick={clickUnit}
				/>
			</Section>
		</Box>
	);
}

export default React.memo<TDesignStyleProps>(Style);
