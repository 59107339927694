// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Params as List } from '@graphite/lists';
import type { TParams, TAnimation } from '@graphite/types';
import { easeFunctions, defaultValue } from '@graphite/animations';

type TProps = $ReadOnly<{|
	t: (string) => string,
	params: TAnimation,
	changeEffect: (TAnimation) => void,
|}>;

const EaseComponent = ({ t, params, changeEffect }: TProps) => {
	const easing = React.useMemo(
		() =>
			_.keys(easeFunctions).map((name) => ({
				name,
				label: t(easeFunctions[name]),
			})),
		[t],
	);

	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Duration'),
				key: 'duration',
				kind: 'unit',
				info: {
					domain: 'nonnegative',
					showUnits: true,
					unitKey: 'durationUnit',
					units: ['s'],
				},
			},
			{
				title: t('Delay'),
				key: 'delay',
				kind: 'unit',
				info: {
					domain: 'nonnegative',
					showUnits: true,
					unitKey: 'delayUnit',
					units: ['s'],
				},
			},
			{
				title: t('Timing'),
				key: 'ease',
				kind: 'select',
				info: {
					list: {
						items: easing,
					},
				},
			},
		],
		[t, easing],
	);

	const paramSource = React.useMemo(() => {
		const { duration, delay, ease } = _.assignAll([
			defaultValue.transition.repeat,
			defaultValue.transition.tween,
			params.transition,
		]);

		return {
			ease,
			duration: `${duration}`,
			delay: `${delay}`,

			durationUnit: 's',
			delayUnit: 's',
		};
	}, [params]);

	const changeParam = React.useCallback(
		(key, value) => {
			changeEffect(_.set(`transition.${key}`, value, params));
		},
		[changeEffect, params],
	);

	return (
		<List
			listName="animation-transition-ease"
			paramSource={paramSource}
			paramList={paramList}
			unit={0}
			onChange={changeParam}
		/>
	);
};

export default React.memo<TProps>(EaseComponent);
