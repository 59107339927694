// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDeprecated, Box, Tooltip } from '@graphite/uneon';

import { placeWidget } from 'Editor/ducks/widgets';

import type { TPropsBottomSide } from '../constants/types';

export const bottomSideSx = {
	pointerEvents: 'auto',
	position: 'absolute',
	bottom: '-16px',
	left: '50%',
	padding: '0 21px',
	marginLeft: '-36px',
};

const addBlockButtonSx = {
	boxShadow: 'sm',
	backgroundColor: '#fff',
	borderColor: '#fff',
};

const ControlsBottomSide = (props: TPropsBottomSide): React$Node => {
	const { t } = useTranslation();

	const { dispatch, data, originId } = props;
	const { _id } = data;

	const addBtnEl: React$ElementRef<any> = React.useRef(null);

	const onAdd = React.useCallback(() => {
		if (!originId) return;
		dispatch(
			placeWidget('block', originId, null, originId, {
				kind: 'grid',
				prevId: _id,
				nextId: null,
				destRect: null,
				dragRect: null,
				breakpoints: null,
			}),
		);
	}, [dispatch, _id, originId]);

	return (
		<Box sx={bottomSideSx}>
			<ButtonDeprecated
				colors="primary"
				size="sm"
				icons="plus"
				title="Add Block"
				shape="rounded"
				onClick={onAdd}
				sx={addBlockButtonSx}
				ref={addBtnEl}
			/>
			<Tooltip elRef={addBtnEl}>{t('Add section')}</Tooltip>
		</Box>
	);
};

export default React.memo<TPropsBottomSide>(ControlsBottomSide);
