// @flow
import React from 'react';
import _ from 'lodash/fp';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import useHotkeys from '@graphite/use-hotkeys';
import { Button, PopupWhite, themes, Flex, Tooltip, Hotkey, Text } from '@graphite/uneon';
import FrameContext from 'Editor/Frame/Context';
import useDefaultDevice from 'Editor/libs/use-default-device';
import type { TId, TEditorLang, TGridBreakpointName } from '@graphite/types';
import Design from './Design';
import Pages from './Pages';
import Layers from './Layers';
import ButtonsHistory from './ButtonsHistory';
import ButtonsDevices from './ButtonsDevices';
import ButtonPanel from './ButtonPanel';
import ButtonExtra from './ButtonExtra';
import ButtonPublish from '../ButtonPublish';
import Burger from './Burger';
import Domains from './Domains';

type TProps = $ReadOnly<{|
	goToPreview: () => void,
	showAddWidgetPanel: () => void,
	panelRef: {| current: ?React$ElementRef<any> |},
	goToDashboard: () => void,
	publishUrl: string,
	language: TEditorLang,
	currentGridHighlight: boolean,
	siteId: ?TId,
	currentDevice: TGridBreakpointName,
|}>;

const HEIGHT = 54;
const PANEL_DX = 12;
const PANEL_DY = 12;

const flexLeftSx = {
	flexBasis: '50%',
	alignItems: 'center',
};

const flexRightSx = {
	flexBasis: '50%',
	alignItems: 'center',
	justifyContent: 'flex-end',
};

const addWidgetButtonSx = {
	marginRight: '16px',
};

const WrapPreviewButton = styled.div`
	position: relative;
	margin-left: 12px;
`;

const SpanNowrap = styled.span`
	white-space: nowrap;
`;

const Builder = ({
	goToDashboard,
	goToPreview,
	panelRef,
	publishUrl,
	showAddWidgetPanel,
	language,
	currentGridHighlight,
	siteId,
	currentDevice,
}: TProps) => {
	const { document: documentFrame, window: windowFrame } = React.useContext(
		FrameContext,
	);

	const { t } = useTranslation();

	const [currentPanel, setCurrentPanel] = React.useState(null);
	const [isDomainPopup, setDomainPopup] = React.useState(false);
	const [isOpenPublish, setOpenPublishPopup] = React.useState(false);

	const domainRef = React.useRef(null);

	const togglePanel = React.useCallback(
		panel => setCurrentPanel(panel === currentPanel ? null : panel),
		[currentPanel],
	);

	const openDomainPopup = React.useCallback(ref => {
		domainRef.current = ref.current;
		setDomainPopup(true);
	}, []);

	const closeDomainPopup = React.useCallback(() => {
		setDomainPopup(false);
	}, []);

	const closePublishPopup = React.useCallback(() => {
		setOpenPublishPopup(true);
	}, []);

	const fullHeightPanel = ['layers', 'pages'].includes(currentPanel) ? 'full' : 'auto';

	const hidePanel = React.useCallback(() => setCurrentPanel(null), []);

	const buttonAddRef: React$ElementRef<any> = React.useRef(null);
	const buttonDesignRef: React$ElementRef<any> = React.useRef(null);
	const buttonPagesRef: React$ElementRef<any> = React.useRef(null);
	const buttonLayersRef: React$ElementRef<any> = React.useRef(null);
	const buttonPreviewRef: React$ElementRef<any> = React.useRef(null);

	const panels = React.useMemo(
		() => [
			{
				title: t('Design'),
				value: 'design',
				ref: buttonDesignRef,
				tips: 'alt+2',
			},
			{
				title: t('Pages'),
				value: 'pages',
				ref: buttonPagesRef,
				tips: 'alt+3',
			},
			{
				title: t('Layers'),
				value: 'layers',
				ref: buttonLayersRef,
				tips: 'alt+4',
			},
		],
		[t],
	);

	const getPanel = _.cond([
		[_.isEqual('design'), _.constant(<Design />)],
		[_.isEqual('design-grid'), _.constant(<Design targetTab="grid" />)],
		[_.isEqual('design-colors'), _.constant(<Design targetTab="colors" />)],
		[_.isEqual('design-fonts'), _.constant(<Design targetTab="fonts" />)],
		[_.isEqual('design-effects'), _.constant(<Design targetTab="effects" />)],
		[_.isEqual('pages'), _.constant(siteId ? <Pages siteId={siteId} /> : null)],
		[_.isEqual('layers'), _.constant(<Layers />)],
	]);

	const hotkeyDesignHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design');
		},
		[togglePanel],
	);

	const hotkeyDesignGridHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-grid');
		},
		[togglePanel],
	);

	const hotkeyDesignColorsHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-colors');
		},
		[togglePanel],
	);

	const hotkeyDesignFontsHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-fonts');
		},
		[togglePanel],
	);

	const hotkeyDesignEffectsHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('design-effects');
		},
		[togglePanel],
	);

	const hotkeyLayersHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('layers');
		},
		[togglePanel],
	);

	const hotkeyPagesHandler = React.useCallback(
		e => {
			e.preventDefault();
			togglePanel('pages');
		},
		[togglePanel],
	);

	const hotkeyPreviewHandler = React.useCallback(
		e => {
			e.preventDefault();
			goToPreview();
		},
		[goToPreview],
	);

	const hotkeyAddWidgetPanelHandler = React.useCallback(
		e => {
			e.preventDefault();
			showAddWidgetPanel();
		},
		[showAddWidgetPanel],
	);

	useHotkeys('alt+1', hotkeyAddWidgetPanelHandler, documentFrame, windowFrame);
	useHotkeys('alt+2', hotkeyDesignHandler, documentFrame, windowFrame);
	useHotkeys('alt+3', hotkeyPagesHandler, documentFrame, windowFrame);
	useHotkeys('alt+4', hotkeyLayersHandler, documentFrame, windowFrame);

	// design
	useHotkeys('alt+g', hotkeyDesignGridHandler, documentFrame, windowFrame);
	useHotkeys('alt+c', hotkeyDesignColorsHandler, documentFrame, windowFrame);
	useHotkeys('alt+f', hotkeyDesignFontsHandler, documentFrame, windowFrame);
	useHotkeys('alt+v', hotkeyDesignEffectsHandler, documentFrame, windowFrame);

	useHotkeys(['Control+p', 'Meta+p'], hotkeyPreviewHandler, documentFrame, windowFrame);

	return (
		<>
			<Flex sx={flexLeftSx} ref={domainRef}>
				<Burger goToDashboard={goToDashboard} openDomainPopup={openDomainPopup} />
				<Button
					variant="success.rounded.sm"
					onClick={showAddWidgetPanel}
					sx={addWidgetButtonSx}
					disabled={currentDevice !== useDefaultDevice()}
					ref={buttonAddRef}
				>
					{t('Add')}
				</Button>
				<Tooltip elRef={buttonAddRef} delayShow={1000}>
					{t('Add content')}&nbsp;&nbsp;
					<Text as="span" color="spec.lightblue80">
						<Hotkey mac="alt+1" win="alt+1" />
					</Text>
				</Tooltip>
				<Flex as="nav">
					{panels.map(panel => (
						<React.Fragment key={panel.value}>
							<ButtonPanel
								title={panel.title}
								value={panel.value}
								active={
									currentPanel
										? currentPanel.includes(panel.value)
										: false
								}
								onClick={togglePanel}
								ref={panel.ref}
							/>
							<Tooltip elRef={panel.ref} delayShow={1000}>
								<Hotkey mac={panel.tips} win={panel.tips} />
							</Tooltip>
						</React.Fragment>
					))}
				</Flex>
			</Flex>
			<ButtonsDevices />
			<Flex sx={flexRightSx}>
				<ButtonsHistory />
				<ButtonExtra
					currentGridHighlight={currentGridHighlight}
					language={language}
				/>
				<WrapPreviewButton>
					<Button
						variant="primary.rounded.sm"
						onClick={goToPreview}
						ref={buttonPreviewRef}
					>
						{t('Preview')}
					</Button>
					<Tooltip elRef={buttonPreviewRef} delayShow={1000}>
						<SpanNowrap>
							{t('Preview mode')}&nbsp;&nbsp;
							<Text as="span" color="spec.lightblue80">
								<Hotkey mac="Meta+p" win="Control+p" />
							</Text>
						</SpanNowrap>
					</Tooltip>
				</WrapPreviewButton>

				<ButtonPublish
					publishUrl={publishUrl}
					openDomainPopup={openDomainPopup}
					isOpenPublishLink={isOpenPublish}
					setStatePopupLink={setOpenPublishPopup}
				/>
			</Flex>

			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={!!currentPanel}
					anchorEl={panelRef}
					offsetTop={HEIGHT + PANEL_DY}
					offsetLeft={PANEL_DX}
					onClose={hidePanel}
					mutex="topleftcorner"
					height={fullHeightPanel}
					isFixed
				>
					{getPanel(currentPanel)}
				</PopupWhite>
			</ThemeProvider>
			{isDomainPopup && (
				<Domains
					isOpen={isDomainPopup}
					positionRef={domainRef}
					onClose={closeDomainPopup}
					openPublishPopup={closePublishPopup}
				/>
			)}
		</>
	);
};

export default React.memo<TProps>(Builder);
