// @flow
import emptyObject from 'empty/object';

import compose from 'libs/compose';
import cast from 'libs/types/widgets';
import repopulate from 'libs/repopulate';

import { getOrder } from '@graphite/selectors';
import type {
	TId,
	TWidget,
	TWidgetComposed,
	TWidgetEnterResult,
	TWidgetLeaveParams,
	TWidgetLeaveResult,
	TWidgetOpFeedbackEmpty,
	TWidgetOpFeedbackFull,
	TWidgetOpRemoveWidgetParams,
	TWidgetMethodReorderWidgets,
	TEntityChildren,
	TWidgetEnterParamsExtended,
} from '@graphite/types';


export {
	addWidgetHook,
	removeWidgetHook,
	reorderWidgetsHook,
} from 'Widget/libs/stack-hooks';
export { default as placeWidgetHook } from '../../../libs/place-widget-hook';

export const applyChildren = repopulate;
export const applyPosition: TWidgetMethodReorderWidgets = (
	widget,
	position,
	newId,
	currentDevice,
) =>
	getOrder({
		widget,
		position,
		newId,
		currentDevice,
	});

// Вынимаем из кола
export const leave = async (opts: TWidgetLeaveParams): Promise<TWidgetLeaveResult> => {
	const target: TWidget = opts.widgets[opts.targetId];
	const composed: TWidgetComposed = compose(opts.widgets, target);
	const children: TEntityChildren = composed.children || emptyObject;

	if (Object.values(children).filter(id => id).length > 0) {
		return emptyObject;
	}

	const params: TWidgetOpRemoveWidgetParams = {
		targetId: opts.targetId,
		containerId: opts.containerId,
		instanceId: opts.instanceId,
		originId: opts.originId,
		widgets: opts.widgets,
		ObjectId: opts.ObjectId,
		position: opts.position,
		currentDevice: opts.currentDevice,
	};

	return opts.operations.removeWidget(params);
};

// Кладём в кол
export const enter = async ({
	widgets,
	destContainerId,
	destInstanceId,
	destOriginId,
	position,
	operations,
	currentDevice,
}: TWidgetEnterParamsExtended): Promise<?TWidgetEnterResult> => {
	const protoStack: ?TWidget = widgets.stack__REPLACE_WITH_NEW_ID;
	if (!protoStack) {
		return;
	}
	const protoStackId = protoStack._id;
	const { side, prevId, nextId } =
		position.kind === 'grid' ? position : { side: null, prevId: null, nextId: null };

	// Если это НЕ БОКОВОЙ дроп то дополнительной логики НЕТ
	if (!['left', 'right'].includes(side)) {
		return null;
	}

	let updated = {};
	const feedbackStackEmpty: TWidgetOpFeedbackEmpty = {};

	// Создаем стек
	const newStack = await operations.placeWidget({
		protoId: protoStackId,
		destId: destContainerId,
		feedback: feedbackStackEmpty,
		destInstanceId,
		destOriginId,
		position,
		widgets,
		currentDevice,
		widget: emptyObject,
	});
	updated = {
		...newStack,
	};
	const feedbackStack: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
		feedbackStackEmpty,
	);

	if (!feedbackStack) {
		return null;
	}

	const neightbourId: ?TId = side === 'left' ? nextId : prevId;

	if (!neightbourId) {
		return null;
	}

	const updatedWidgets = { ...widgets, ...updated };

	// Переносим виджет, возле которого дропали, в стек
	const movedWidget = await operations.moveWidget({
		destInstanceId,
		destOriginId,
		currentDevice,
		widgets: updatedWidgets,
		srcId: neightbourId || '',
		srcContainerId: destContainerId,
		destContainerId: feedbackStack.targetId,
		position: {
			kind: 'grid',
			destRect: position.destRect || null,
			dragRect: position.dragRect || null,
			breakpoints: position.breakpoints || null,
			prevId: null,
			nextId: null,
		},
	});

	updated = { ...updated, ...movedWidget };

	// СТЕК (вместо колонки) возвращается как новая цель (destContainerId) дропа
	return {
		updated,
		destContainerId: feedbackStack.targetId,
		position: {
			kind: 'grid',
			prevId: side === 'right' ? neightbourId : null,
			nextId: side === 'left' ? neightbourId : null,
			destRect: position.destRect || null,
			dragRect: position.dragRect || null,
			breakpoints: position.breakpoints || null,
		},
	};
};
