// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';


import { symGt } from '@graphite/constants';

import compose from 'libs/compose';
import { genId } from 'libs/firebase';
import traceContainers from 'libs/trace-containers';
import cast from 'libs/types/widgets';
import widgetLibs from 'Widget/libs';
import type {
	TId,
	TWidgets,
	TWidget,
	TWidgetComposed,
	TWidgetOpFeedbackEmpty,
	TWidgetOpFeedbackFull,
	TWidgetOpDetachTreeParams,
	TWidgetOpCloneTreeParams,
	TWidgetOpRemoveTreeParams,
	TWidgetOpEditParams,
	TWidgetOpCloneWidgetParams,
	TWidgetOpPlaceWidgetParams,
	TWidgetOpSymbolizeWidgetParams,
	TWidgetOpRemoveWidgetParams,
	TWidgetOpMoveWidgetParams,
	TWidgetTraceContainersItem,
	TWidgetTraceContainersResult,
	TWidgetMethodApplyChildren,
	TWidgetMethodAddWidget,
	TWidgetMethodRemoveWidget,
	TWidgetMethodReorderWidgets,
	TWidgetOpCreatePresetParams,
	TWidgetMethodPlaceWidget,
} from '@graphite/types';

// -----------------------------------------
//
// В этом файле реализации всех операций с деревом виджетов ukit-pro
//
// -----------------------------------------

// ЭТОЖЕ ВИДЖЕТ В КВАДРАТЕ ЕБАААТЬ МЫ ВСЕ УМРЕМ
// function square(n: TWidget): TWidget {
// 	return n * n;
// }
//
// square({
// 	scope: 'page'
// });

type TWidgetDesc = {|
	id: TId,
	instanceId: ?TId,
	isFront: boolean,
|};

// Детачатся только инстансы целиком
// Подинстансы внутри текущего не детачатся
const _detachTree = async ({
	widgets,
	targetId,
	instanceId,
	containerId,
	ObjectId = genId,
}: TWidgetOpDetachTreeParams): Promise<TWidgets> => {
	const target: TWidget = widgets[targetId];
	if (!target.modified) {
		return emptyObject;
	}

	const instance: ?TWidget = instanceId
		? cast.TWidgetInstance(widgets[instanceId])
		: null;
	const modified = (instanceId && widgets[instanceId].modified) || [];

	let updInstance: ?TWidget = instance
		? {
				...instance,
				modified: [...modified],
		  }
		: null;

	let updated: TWidgets = {};

	if (updInstance && instanceId) {
		updated = {
			...updated,
			[instanceId]: updInstance,
		};
	}

	// Детач корня инстанса, удаляем модифаед
	const newTarget: TWidgetComposed = compose(widgets, target);
	const newTargetInstanceMud: ?TWidgetComposed = cast.TWidgetComposedInstance(
		newTarget,
	);

	if (!newTargetInstanceMud) {
		return emptyObject;
	}

	const { modified: __, ...newTargetInstance } = newTargetInstanceMud;

	updated = {
		...updated,
		[newTargetInstance._id]: newTargetInstance,
	};

	const _recurse = async (composed: TWidgetComposed): Promise<TWidget> => {
		// проходим по всем виджетам в этом скоупе
		// но не являющимя основным ребёнком
		for (const widgetKey in widgets) {
			const widget = widgets[widgetKey];
			if (
				composed.protoId &&
				widget.scopeId === composed.protoId &&
				!Object.values(composed.children || {}).includes(widget._id)
			) {
				const widgetNew = {
					...widget,
					_id: ObjectId('widgets'),
					scopeId: composed._id,
					userId: composed.userId,
				};
				updated = {
					...updated,
					[widgetNew._id]: { ...widgetNew },
				};
			}
		}

		const changed: { [key: TId]: TId } = {};

		const newChildren: { [key: TId]: TId } = {};
		await Promise.all(
			// https://github.com/facebook/flow/issues/2221
			// eslint-disable-next-line flowtype/no-weak-types
			(Object.entries(composed.children || emptyObject): any)
				.filter(([, v]): TWidget => v)
				.map(async ([refId, id]): Promise<void> => {
					let newChild: TWidgetComposed = compose(widgets, widgets[id]);
					const childAsInstance: ?TWidget = cast.TWidgetInstance(newChild);

					// Если этот виджет не модифицирован, то:
					//   надо дать новый ид
					//   надо прикрепить к скоупу ориджина
					//   надо добавить в модифаед инстанции
					if ((target.modified || []).indexOf(newChild._id) < 0) {
						newChild = {
							...newChild,
							_id: ObjectId('widgets'),
							updatedAt: new Date().toISOString(),
							display: 'normal',
						};

						if (updInstance) {
							updInstance = {
								...updInstance,
								modified: [...(updInstance.modified || []), newChild._id],
								updatedAt: new Date().toISOString(),
							};

							updated = {
								...updated,
								[updInstance._id]: { ...updInstance },
							};
						}

						updated = {
							...updated,
							[newChild._id]: { ...newChild },
						};
						const placeWidgetHook: ?TWidgetMethodPlaceWidget = await widgetLibs(
							'place-widget',
							newChild.kind,
						);
						if (placeWidgetHook) {
							const { updated: updatedAfterPlaceHook } = placeWidgetHook({
								widgets: { ...widgets, ...updated },
								id: newChild._id,
								containerId: composed._id,
							});
							updated = { ...updated, ...updatedAfterPlaceHook };
							newChild = { ...newChild, ...updated[newChild._id] };
						}
					}

					changed[refId] = newChild._id;
					newChildren[newChild._id] = newChild._id;

					// Если этот виджет - инстанция, то поезд дальше не идет
					if (childAsInstance) {
						return;
					}

					const updNewChild = await _recurse({
						...newChild,
						protoId: null,
						updatedAt: new Date().toISOString(),
					});

					updated = {
						...updated,
						[newChild._id]: updNewChild,
					};
				}),
		);

		const composedNew: TWidgetComposed = {
			...composed,
			children: newChildren,
		};

		const applyChildren: ?TWidgetMethodApplyChildren = await widgetLibs(
			'apply-children',
			composed.kind,
		);

		return applyChildren
			? // FixMe: тут какое-то наебалово, или типы не правильно прописаны для applyChildren
			  // или это вообще не работает '(
			  _.assign(composedNew, applyChildren(composedNew, changed))
			: composedNew;
	};

	const placeWidgetHook: ?TWidgetMethodPlaceWidget = await widgetLibs(
		'place-widget',
		newTargetInstance.kind,
	);

	if (placeWidgetHook) {
		const { updated: updatedAfterPlaceHook } = placeWidgetHook({
			widgets: { ...widgets, ...updated },
			id: newTargetInstance._id,
			containerId: containerId || targetId,
		});

		updated = { ...updated, ...updatedAfterPlaceHook };
	}

	const updTargetInst = await _recurse({
		...newTargetInstance,
		updatedAt: new Date().toISOString(),
	});

	updated = {
		...updated,
		[updTargetInst._id]: {
			...updTargetInst,
			protoId: null,
		},
	};

	return updated;
};

// Либо клонируется инстанция, либо нормал. Рекурсивно.
// Клонирование инстанции клонирует модифаеды, а нормал - чилдрен
const _cloneTree = async ({
	widgets,
	targetId,
	instanceId: _instanceId,
	feedback, // FixMe: говно мутирующее
	ObjectId = genId,
	containerId,
}: TWidgetOpCloneTreeParams): Promise<TWidgets> => {
	let instanceId = _instanceId;
	const target: TWidget = widgets[targetId];

	let instance: ?TWidget = instanceId
		? cast.TWidgetInstance(widgets[instanceId])
		: null;

	const modified = (instanceId && widgets[instanceId].modified) || [];

	// ------- DO NOTHING

	if (!target || (instance && !modified.includes(targetId))) {
		feedback.targetId = targetId;
		return emptyObject;
	}

	// ------- is outside instance or target is modified

	let newWidget: TWidget = {
		...target,
		_id: ObjectId('widgets'),
		updatedAt: new Date().toISOString(),
	};
	if (target.children) {
		newWidget = { ...newWidget, children: {} };
	}

	let updated: TWidgets = { [newWidget._id]: newWidget };
	let updatedWidgets: TWidgets = { ...widgets, ...updated };

	const newWidgetAsInstance: ?TWidget = cast.TWidgetInstance(newWidget);

	// FIXME: неуверен что так надо
	if (newWidgetAsInstance) {
		instanceId = newWidget._id;
		instance = {
			...newWidgetAsInstance,
			modified: [...(newWidgetAsInstance.modified || [])],
		};

		updated = {
			...updated,
			[instance._id]: instance,
		};
		updatedWidgets = { ...widgets, ...updated };
	}

	feedback.targetId = newWidget._id;

	const composed: TWidgetComposed = compose(updatedWidgets, target);

	const changed: { [key: TId]: TId } = {};

	if (newWidget.kind) {
		const placeWidgetHook: ?TWidgetMethodPlaceWidget = await widgetLibs(
			'place-widget',
			newWidget.kind,
		);
		if (placeWidgetHook) {
			const { updated: updatedAfterPlaceHook } = placeWidgetHook({
				widgets: { ...widgets, ...updated },
				id: newWidget._id,
				containerId: containerId || newWidget._id,
			});
			updated = { ...updated, ...updatedAfterPlaceHook };
			updatedWidgets = { ...widgets, ...updated };
		}
	}

	await Promise.all(
		// https://github.com/facebook/flow/issues/2221
		// eslint-disable-next-line flowtype/no-weak-types
		(Object.entries(target.children || emptyObject): any).map(
			([key, value]: [TId, ?TId]) =>
				(async (): Promise<void> => {
					if (!value) {
						return;
					}

					const myInstanceId: ?TId = newWidgetAsInstance
						? newWidget._id
						: instanceId;
					const feedbackEmpty: TWidgetOpFeedbackEmpty = {
						targetId: null,
					};
					const subtree = await _cloneTree({
						widgets: updatedWidgets,
						targetId: value,
						instanceId: myInstanceId,
						feedback: feedbackEmpty,
						ObjectId,
						containerId: newWidget._id,
					});

					updated = { ...updated, ...subtree };
					updatedWidgets = { ...widgets, ...updated };
					newWidget = updatedWidgets[newWidget._id];
					instance = instance ? updatedWidgets[instance._id] : null;

					// ой в пизду.. вы вчитайтесь, что здесь нахуй происходит
					const feedback: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
						feedbackEmpty,
					);

					if (!feedback) {
						return;
					}

					changed[value] = feedback.targetId;

					if (!instance) {
						if (newWidget.children) {
							const remChildren = { ...newWidget.children };
							delete remChildren[key];
							newWidget = {
								...newWidget,
								children: remChildren,
							};
						}

						newWidget = {
							...newWidget,
							children: {
								...newWidget.children,
								[feedback.targetId]: feedback.targetId,
							},
						};

						updated = { ...updated, [newWidget._id]: newWidget };
						updatedWidgets = { ...widgets, ...updated };
					} else {
						const { modified: arr }: { modified: $ReadOnlyArray<TId> } = {
							modified: [],
							...instance,
						};
						const at = arr.indexOf(value);

						const modified = [
							...arr.slice(0, at),
							feedback.targetId,
							...arr.slice(at + 1),
						];

						newWidget = {
							...newWidget,
							children: {
								...newWidget.children,
								[`${
									key === value ? feedback.targetId : key
								}`]: feedback.targetId,
							},
						};

						if (instance._id === newWidget._id) {
							newWidget = { ...newWidget, modified };
						} else {
							updated[instance._id] = { ...instance, modified };
						}

						updated[newWidget._id] = newWidget;
						updatedWidgets = { ...widgets, ...updated };
					}
				})(),
		),
	);

	const applyChildren: ?TWidgetMethodApplyChildren = await widgetLibs(
		'apply-children',
		composed.kind,
	);

	if (applyChildren)
		updated = {
			...updated,
			[newWidget._id]: _.assign(newWidget, applyChildren(newWidget, changed)),
		};

	return updated;
};

// Только помечает "удалено" для виджетов страницы, чтобы не грузились
const _removeTree = async ({
	widgets,
	targetId,
}: TWidgetOpRemoveTreeParams): Promise<TWidgets> => {
	const target: TWidget = widgets[targetId];

	let updated: TWidgets = {};

	(function _recurse(child: TWidget) {
		if (!child) {
			return;
		}

		updated = {
			...updated,
			[child._id]: {
				...child,
				removedAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
			},
		};

		const composed = compose(widgets, child);
		if (!composed.children) {
			return;
		}

		// https://github.com/facebook/flow/issues/2221
		// eslint-disable-next-line flowtype/no-weak-types
		(Object.entries(composed.children): any)
			.filter(([, id]) => id)
			.forEach(([, id]) => {
				if (target.protoId && !target.modified?.find(_id => _id === id)) return;
				const child = widgets[id];
				return _recurse(child);
			});
	})(target);

	return updated;
};

// Отредактировать виджет
// Допустимо ли равенство targetId === instanceId?
const _edit = async ({
	widgets,
	targetId,
	instanceId,
	originId,
	diff,
	deletes = [],
	ObjectId = genId,
	feedback,
}: TWidgetOpEditParams): Promise<TWidgets> => {
	let updated: TWidgets = {};

	const target: TWidget = widgets[targetId];
	const origin: TWidget = widgets[originId];
	const instance: ?TWidget =
		instanceId && targetId !== instanceId
			? // eslint-disable-next-line flowtype/no-weak-types
			  ((widgets[instanceId]: any): TWidget)
			: null;
	const modified: $ReadOnlyArray<TId> =
		(instanceId && widgets[instanceId].modified) || [];

	// SIMPLE CASE

	if (!instance || modified.indexOf(targetId) > -1) {
		const edited: TWidget = _.assignAll([
			target,
			diff,
			{
				updatedAt: new Date().toISOString(),
			},
		]);

		deletes.forEach(key => {
			delete edited[key];
		});
		updated = {
			...updated,
			[target._id]: edited,
		};

		if (feedback) {
			feedback.targetId = targetId;
		}

		return updated;
	}

	// ------------- IN INSTANCE

	// Если у цели есть protoId, но она не modified - форкаем и добавляем в modified
	let newWidget: TWidget = _.assign(
		{
			_id: ObjectId('widgets'),
			protoId: targetId,
			scope: origin.scope,
			scopeId: origin.scopeId,
			userId: origin.userId,
			updatedAt: new Date().toISOString(),
			display: 'normal',
		},
		diff,
	);

	const targetAsInstance: ?TWidget = cast.TWidgetInstance(target);

	if (targetAsInstance) {
		newWidget = {
			...newWidget,
			modified: targetAsInstance.modified,
		};
	}

	if (feedback) {
		feedback.targetId = newWidget._id;
	}

	updated = {
		...updated,
		[newWidget._id]: newWidget,
	};
	let updatedWidgets: TWidgets = { ...widgets, ...updated };

	let updInstance: TWidget = {
		...instance,
		modified: [...modified, newWidget._id],
		updatedAt: new Date().toISOString(),
	};
	updated = {
		...updated,
		[updInstance._id]: updInstance,
	};
	updatedWidgets = { ...updatedWidgets, ...updated };

	// Трейс собирает в массив все иды родителей в промежутке и порядке (bottomId; topId]
	const traced: TWidgetTraceContainersResult = traceContainers({
		widgets: updatedWidgets,
		start: {
			targetId: updInstance._id,
			instanceId: null,
			containerId: null,
		},
		finalId: targetId,
	}).slice(1);

	let prevId: TId = targetId;
	let newId: TId = newWidget._id;
	traced.some(({ targetId: id }: TWidgetTraceContainersItem): ?boolean => {
		// Если это инстанс, или уже модифаед, или без прото
		if (id === instanceId || modified.indexOf(id) > -1) {
			let current: TWidget = { ...updatedWidgets[id] };

			const composed: TWidgetComposed = compose(updatedWidgets, current);

			// Когда на глубокой вложенности инстанс лежит в children не под своим ключом
			if (composed.children && !composed.children[prevId]) {
				Object.entries(composed.children).forEach(([key, value]) => {
					if (prevId === value) {
						current = {
							...current,
							children: {
								...(current.children || null),
								[key]: newId,
							},
							updatedAt: new Date().toISOString(),
						};
						updated = {
							...updated,
							[current._id]: current,
						};
						updatedWidgets = { ...updatedWidgets, ...updated };
					}
				});

				return true;
			}

			current = {
				...current,
				children: { ...(current.children || null), [prevId]: newId },
				updatedAt: new Date().toISOString(),
			};

			updated = {
				...updated,
				[current._id]: current,
			};
			updatedWidgets = { ...updatedWidgets, ...updated };

			return true;
		}

		// Если этой дичи нужен форк, то делаем форк
		const newContainer: TWidget = {
			_id: ObjectId('widgets'),
			protoId: id,
			userId: origin.userId,
			scope: origin.scope,
			scopeId: origin.scopeId,
			display: 'normal',
			children: { [prevId]: newId },
			updatedAt: new Date().toISOString(),
		};

		updInstance = {
			...updInstance,
			modified: [...(updInstance.modified || []), newContainer._id],
			updatedAt: new Date().toISOString(),
		};

		updated = {
			...updated,
			[updInstance._id]: updInstance,
			[newContainer._id]: newContainer,
		};
		updatedWidgets = { ...updatedWidgets, ...updated };

		newId = newContainer._id;
		prevId = id;
	});

	return updated;
};

export const detachWidget = _detachTree;

// Нельзя клонировать оригин
export const cloneWidget = async ({
	widgets,
	targetId,
	containerId,
	instanceId,
	originId,
	// FixMe: Это костыль, надо его выпилить
	feedback = {},
	ObjectId = genId,
	diff,
}: TWidgetOpCloneWidgetParams): Promise<TWidgets> => {
	// А с хуяли тут ретёрн?
	// if (targetId === originId) {
	// 	return emptyObject;
	// }

	let updated: TWidgets = {};
	let updatedWidgets: TWidgets = widgets;

	const feedbackEmpty: TWidgetOpFeedbackEmpty = { targetId: null };

	let withCloned: TWidgets = await _cloneTree({
		widgets: updatedWidgets,
		targetId,
		instanceId,
		// FixMe: 🤦
		feedback: feedbackEmpty,
		ObjectId,
		containerId,
	});

	// FixMe: cast - это костыль, его надо выпилить
	const feedbackFull: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
		feedbackEmpty,
	);

	if (!feedbackFull) {
		return emptyObject;
	}
	const { targetId: newTargetId } = feedbackFull;
	if (!newTargetId) {
		return emptyObject;
	}
	// FixMe: Это костыль, надо его выпилить
	feedback.targetId = newTargetId;

	withCloned = ({
		...withCloned,
		[newTargetId]: _.merge(withCloned[newTargetId], diff),
	}: TWidgets);

	updated = { ...updated, ...withCloned };
	updatedWidgets = { ...widgets, ...withCloned };

	if (containerId) {
		const container: TWidget = updatedWidgets[containerId];
		const composedContainer: TWidgetComposed = compose(updatedWidgets, container);

		const withEdited: TWidgets = await _edit({
			widgets: updatedWidgets,
			targetId: containerId,
			instanceId,
			originId,
			diff: {
				children: {
					...(composedContainer.children || null),
					[newTargetId]: newTargetId,
				},
			},
			ObjectId,
		});

		updated = ({ ...updated, ...withEdited }: TWidgets);
		updatedWidgets = ({ ...widgets, ...withEdited }: TWidgets);
	}

	const instance: ?TWidget = instanceId
		? cast.TWidgetInstance(updatedWidgets[instanceId])
		: null;

	if (instance) {
		const { _id } = instance;
		const updInstance: TWidgets = await _edit({
			widgets: updatedWidgets,
			targetId: _id,
			instanceId: null,
			originId,
			diff: {
				modified: [...(instance.modified || []), feedbackFull.targetId],
			},
			ObjectId,
		});
		updated = { ...updated, [_id]: updInstance[_id] };
	}

	// Копируем все виджеты, которые принадлежат этому виджеиу, но не являются его прямыми детьми
	const children = Object.values(updatedWidgets[targetId]?.children || {});
	for (const widget of Object.values(updatedWidgets)) {
		if (
			widget &&
			// если sсope совпадает с типом копируемого виджета
			widget.scope === updatedWidgets[targetId].kind &&
			// если sсopeId совпадает с ИД копируемого виджета
			widget.scopeId === targetId &&
			widget._id &&
			// но его нет в прямых потомках
			!children.includes(widget._id)
		) {
			const newWidget = {
				...widget,
				_id: ObjectId('widgets'),
				scopeId: newTargetId,
			};

			updated = ({ ...updated, [newWidget._id]: newWidget }: TWidgets);
			updatedWidgets = ({ ...widgets, ...updated }: TWidgets);
		}
	}

	return updated;
};

export const placeWidget = async ({
	widgets,
	protoId,
	destId,
	destInstanceId,
	destOriginId,
	position,
	feedback,
	ObjectId = genId,
	currentDevice,
	widget,
}: TWidgetOpPlaceWidgetParams): Promise<TWidgets> => {
	const proto: TWidget = widgets[protoId];
	const dest: TWidget = widgets[destId];

	let newWidget: TWidget = _.assign(widget, {
		protoId,
		_id: ObjectId('widgets'),
		modified: [],
		display: 'normal',
		updatedAt: new Date().toISOString(),
		userId: dest.userId,
	});

	let updated: TWidgets = { [newWidget._id]: newWidget };
	let updatedWidgets: TWidgets = { ...widgets, ...updated };

	// FixMe: удалить эти ёбанные костыли от Луиса
	// какой, блять, feedback мутирующий в параметрах функции?
	if (feedback) {
		feedback.targetId = newWidget._id;
	}

	const composed: TWidgetComposed = compose(widgets, dest);

	const newDestChildren = {
		...composed.children,
		[newWidget._id]: newWidget._id,
	};

	updated = {
		...updated,
		[destId]: {
			...updatedWidgets[destId],
			children: newDestChildren,
		},
	};
	updatedWidgets = { ...updatedWidgets, ...updated };

	const addWidgetHook: ?TWidgetMethodAddWidget = await widgetLibs(
		'add-widget',
		composed.kind,
	);

	if (addWidgetHook) {
		const { children, ...placeExtra } = addWidgetHook(
			updatedWidgets,
			null,
			destId,
			position,
			newWidget._id,
			currentDevice,
		);

		const updatedDest = await _edit({
			widgets: updatedWidgets,
			targetId: destId,
			instanceId: destInstanceId,
			originId: destOriginId,
			diff: placeExtra,
			ObjectId,
		});
		updated = { ...updated, ...updatedDest };
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	const destAsInstance: ?TWidget = cast.TWidgetInstance(dest);
	const instanceId: ?TId = destAsInstance ? destId : destInstanceId;

	const instance: ?TWidget = instanceId
		? cast.TWidgetInstance(updatedWidgets[instanceId])
		: null;
	const modified: $ReadOnlyArray<TId> =
		(instanceId && updatedWidgets[instanceId].modified) || [];

	if (instance && instanceId) {
		const updInstance: TWidget = {
			...instance,
			modified: [...modified, newWidget._id],
		};
		updated = { ...updated, [instanceId]: updInstance };
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	if (proto.display === 'preset') {
		const detached = await _detachTree({
			widgets: updatedWidgets,
			targetId: newWidget._id,
			instanceId: destInstanceId,
			originId: destOriginId,
			containerId: composed._id,
			ObjectId,
		});

		updated = { ...updated, ...detached };
		updatedWidgets = { ...widgets, ...updated };
	} else {
		const proto = updatedWidgets[protoId];
		const childs = proto.children || emptyObject;
		const instanceId = newWidget._id;

		// TODO: test this behavior.
		// FIXME: bleat sto pudov tut huita napisana, mne bez testov vidno
		const _spawnRecurse = async function _spawnRecurse(childs, instanceId) {
			await Promise.all(
				_.values(childs).map((childId: TId) =>
					(async () => {
						const child = updatedWidgets[childId];
						const childAsInstance: ?TWidget = cast.TWidgetInstance(child);

						if (childAsInstance) {
							const spawned = await _edit({
								instanceId,
								widgets: updatedWidgets,
								targetId: child._id,
								originId: destOriginId,
								diff: { _id: child._id },
								ObjectId,
							});
							updated = { ...updated, ...spawned };
							updatedWidgets = { ...updatedWidgets, ...updated };
						}

						if (child.children) {
							await _spawnRecurse(
								child.children,
								childAsInstance ? child._id : instanceId,
							);
						}
					})(),
				),
			);
		};

		await _spawnRecurse(childs, instanceId);
	}

	newWidget = updatedWidgets[newWidget._id];

	const placeWidgetHook: ?TWidgetMethodPlaceWidget =
		newWidget.kind && (await widgetLibs('place-widget', newWidget.kind));

	if (placeWidgetHook) {
		const { updated: updatedAfterPlaceHook } = placeWidgetHook({
			widgets: updatedWidgets,
			id: newWidget._id,
			containerId: destId,
		});

		updated = { ...updated, ...updatedAfterPlaceHook };
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	return updated;
};

// Нельзя символизовать части инстансов.
// Надо сделать символ, а виджету дать protoId на символ.
// Вложенные виджеты уходят в scope символа.
// Если встречаем вложенный инстанс, то:
//    Передаем его и модифаеды в scope нового символа,
//    Расширяем scope символа вложенного инстанса.
export const symbolizeWidget = async ({
	widgets,
	targetId,
	originId,
	scopeObject,
	ObjectId = genId,
}: TWidgetOpSymbolizeWidgetParams): Promise<TWidgets> => {
	let updated: TWidgets = {};

	// 1. Собрать все ID виджетов в поддереве
	let treeStack: $ReadOnlyArray<TWidgetDesc> = [
		{ id: targetId, instanceId: null, isFront: true },
	];
	const touchedWidgets: { [TId]: TWidgetDesc } = {};
	while (treeStack.length) {
		// FIXME: флоу ебётся на [touchedWidgets[id]] = treeStack;
		const [first] = treeStack;
		const { id, instanceId, isFront }: TWidgetDesc = first;
		touchedWidgets[id] = first;
		[, ...treeStack] = treeStack;
		const { children, protoId, modified }: TWidget = compose(widgets, widgets[id]);
		const finalInstanceId = modified ? id : instanceId;
		const filtered: $ReadOnlyArray<TId> = (_.values(children).filter(
			id => id,
		): $ReadOnlyArray<TId>);
		treeStack = [
			...treeStack,
			...filtered.map(id => ({ id, instanceId: finalInstanceId, isFront })),
		];
		if (protoId) {
			treeStack = [
				...treeStack,
				{ id: protoId, instanceId: finalInstanceId, isFront: false },
			];
		}
	}
	const allIds: $ReadOnlyArray<TId> = Object.keys(touchedWidgets).sort((a, b) =>
		symGt(widgets[a].scope, widgets[b].scope) ? -1 : 1,
	);

	// 2. Обнаружить самый высокий уровень символа (используя symGt)
	const topScopeWidget = widgets[allIds[0]];
	const finalScope = symGt(topScopeWidget.scope, scopeObject.scope)
		? { scope: topScopeWidget.scope, _id: topScopeWidget.scopeId }
		: scopeObject;

	// 3. Всем поставить новый уровень символа
	allIds.forEach(id => {
		if (widgets[id].scopeId === finalScope._id) {
			return;
		}
		const current = {
			...widgets[id],
			scope: finalScope.scope,
			scopeId: finalScope._id,
			updatedAt: new Date().toISOString(),
		};
		updated = {
			...updated,
			[id]: current,
		};
	});

	let updatedWidgets: TWidgets = { ...widgets, ...updated };

	const target = updatedWidgets[targetId];
	const origin = updatedWidgets[originId];

	// 4. Ответвляем корень для нового инстанса
	const newId = ObjectId('widgets');
	updated = {
		...updated,
		[newId]: {
			...target,
			_id: newId,
			display: 'symbol',
			updatedAt: new Date().toISOString(),
		},
		[targetId]: {
			_id: targetId,
			protoId: newId,
			display: 'normal',
			modified: [],
			scope: origin.scope,
			scopeId: origin.scopeId,
			updatedAt: new Date().toISOString(),
		},
	};
	updatedWidgets = { ...widgets, ...updated };

	// 5. Все инстансы ответвить ПО ПОРЯДКУ сверху вниз по дереву
	const instanceList = allIds.filter(
		id => id !== targetId && updatedWidgets[id].modified,
	);
	const newInstanceMap: { [TId]: TId } = {};
	// FOR исользуется для УПОРЯДОЧЕНИЯ ответвления инстансов, Promise.all не подходит
	for (let i = 0; i < instanceList.length; i++) {
		const id = instanceList[i];
		if (!touchedWidgets[id].isFront) {
			continue;
		}
		const feedbackInstanceEmpty: TWidgetOpFeedbackEmpty = {};
		// eslint-disable-next-line no-await-in-loop
		const spawned = await _edit({
			widgets: updatedWidgets,
			targetId: id,
			// Инстанс: ближайший верхний ответвленный, или же инстанс этого символа
			instanceId:
				(touchedWidgets[id].instanceId &&
					newInstanceMap[touchedWidgets[id].instanceId]) ||
				touchedWidgets[id].instanceId ||
				targetId,
			originId,
			diff: {},
			feedback: feedbackInstanceEmpty,
			ObjectId,
		});

		const feedbackInstance: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(
			feedbackInstanceEmpty,
		);

		// Такое скорее всего не может произойти, можно даже кинуть ексепшен
		if (!feedbackInstance) {
			return emptyObject;
		}

		newInstanceMap[id] = feedbackInstance.targetId;

		updated = { ...updated, ...spawned };
		updatedWidgets = { ...updatedWidgets, ...spawned };
	}

	return updated;
};

// Виджеты страницы помечаем как удалённые (чтоб не грузились)
// В чилдрен контейнера вписать null.
// Операция _edit для контейнера обеспечит все нюансы работы с инстансом.
export const removeWidget = async ({
	widgets,
	targetId,
	containerId,
	instanceId,
	originId,
	position,
	ObjectId = genId,
	currentDevice,
}: TWidgetOpRemoveWidgetParams): Promise<TWidgets> => {
	let updated: TWidgets = {};
	let updatedWidgets: TWidgets = widgets;

	const container = widgets[containerId];
	const composed: TWidgetComposed = compose(widgets, container);

	let baseId: ?TId = null;
	_.entries(composed.children).some(([k, v]: [TId, TId]) => {
		if (typeof v === 'string' && v === targetId) {
			baseId = k;
			return true;
		}
	});

	if (!baseId) {
		return emptyObject; // wtf?
	}

	const srcId: TId = baseId;
	const removeWidgetHook: ?TWidgetMethodRemoveWidget = await widgetLibs(
		'remove-widget',
		composed.kind,
	);

	const { [targetId]: removedChildId, ...newChildren } =
		composed.children || emptyObject;

	updated = {
		...updated,
		[containerId]: {
			...updatedWidgets[containerId],
			children: newChildren,
		},
	};
	updatedWidgets = { ...updatedWidgets, ...updated };

	if (instanceId) {
		updated = {
			...updated,
			[containerId]: {
				...updatedWidgets[containerId],
				children: {
					...newChildren,
					[baseId]: null,
				},
			},
		};
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	if (removeWidgetHook) {
		const { children, ...removeExtra } = removeWidgetHook(
			composed,
			position,
			srcId,
			currentDevice,
		);

		const updatedSrc = await _edit({
			widgets: updatedWidgets,
			targetId: containerId,
			diff: removeExtra,
			originId,
			instanceId,
			ObjectId,
		});
		updated = { ...updated, ...updatedSrc };
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	const updatedTree = await _removeTree({
		widgets: updatedWidgets,
		targetId,
	});
	updated = { ...updated, ...updatedTree };
	updatedWidgets = { ...updatedWidgets, ...updated };

	return updated;
};

// Нельзя вытаскивать виджеты из инстанса, поэтому не передается srcInstanceId.
// Сделать вытаскивание можно, просто пока не просят, а без него легче.
export const moveWidget = async ({
	widgets,
	srcId,
	srcContainerId,
	// TODO? add src origin/instance
	destContainerId,
	destInstanceId,
	destOriginId,
	position,
	ObjectId = genId,
	currentDevice, // FIXME: брать это прямо из select editor
}: TWidgetOpMoveWidgetParams): Promise<TWidgets> => {
	const isSameElement = srcId === destContainerId;
	const isSameContainer = srcContainerId === destContainerId;

	if (isSameElement) {
		return emptyObject;
	}

	let updated: TWidgets = {};
	let updatedWidgets: TWidgets = widgets;

	const srcContainer: TWidget = updatedWidgets[srcContainerId];
	const srcContainerComposed: TWidgetComposed = compose(updatedWidgets, srcContainer);

	const destContainer: TWidget = updatedWidgets[destContainerId];
	const destContainerComposed: TWidgetComposed = compose(updatedWidgets, destContainer);

	const removeWidgetHook: ?TWidgetMethodRemoveWidget = await widgetLibs(
		'remove-widget',
		srcContainerComposed.kind,
	);
	const addWidgetHook: ?TWidgetMethodAddWidget = await widgetLibs(
		'add-widget',
		destContainerComposed.kind,
	);
	const reorderWidgetsHook: ?TWidgetMethodReorderWidgets = await widgetLibs(
		'reorder-widgets',
		srcContainerComposed.kind,
	);

	// reorder items in the container
	if (isSameContainer && reorderWidgetsHook) {
		const reorderExtra = reorderWidgetsHook(
			srcContainer,
			position,
			srcId,
			currentDevice,
		);
		const updated = await _edit({
			widgets: updatedWidgets,
			targetId: srcContainerId,
			instanceId: destInstanceId,
			originId: destOriginId,
			diff: reorderExtra,
			ObjectId,
		});

		return updated;
	}

	const { [srcId]: removedChildId, ...newChildren } =
		srcContainerComposed.children || emptyObject;
	updated = {
		...updated,
		[srcContainerId]: {
			...updatedWidgets[srcContainerId],
			children: newChildren,
		},
	};
	updatedWidgets = { ...updatedWidgets, ...updated };

	// remove item from the source container
	if (removeWidgetHook) {
		const { children, ...removeExtra } = removeWidgetHook(
			srcContainerComposed,
			position,
			srcId,
			currentDevice,
		);

		const updatedSrc = await _edit({
			widgets: updatedWidgets,
			targetId: srcContainerId,
			instanceId: null,
			originId: destOriginId,
			diff: removeExtra,
			ObjectId,
		});
		updated = { ...updated, ...updatedSrc };
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	const newDestChildren = {
		...destContainerComposed.children,
		[srcId]: srcId,
	};

	updated = {
		...updated,
		[destContainerId]: {
			...updatedWidgets[destContainerId],
			children: newDestChildren,
		},
	};
	updatedWidgets = { ...updatedWidgets, ...updated };

	// put item to the destination container
	if (addWidgetHook) {
		const { children: __, ...placeExtra } = addWidgetHook(
			updatedWidgets,
			srcContainerId,
			destContainerId,
			position,
			srcId,
			currentDevice,
		);

		const updatedDest = await _edit({
			widgets: updatedWidgets,
			targetId: destContainerId,
			instanceId: destInstanceId,
			originId: destOriginId,
			diff: placeExtra,
			ObjectId,
		});
		updated = { ...updated, ...updatedDest };
		updatedWidgets = { ...updatedWidgets, ...updated };
	}

	// TODO: Это понадобится если разрешим дроп в инстансы (лучше не надо!)
	// const destInstance: ?TWidget = cast.TWidgetInstance(destContainer);
	// if (destInstance) {
	// 	const updatedInstance = await _edit({
	// 		widgets    : updatedWidgets,
	// 		targetId   : destContainerId,
	// 		instanceId : null,
	// 		originId   : destOriginId,
	// 		diff       : {
	// 			modified: [...(destInstance.modified), srcId],
	// 		},
	// 		ObjectId,
	// 	});
	// 	updated = { ...updated, ...updatedInstance };
	// }

	return updated;
};

export const createPreset = async ({
	widgets,
	id,
	ObjectId = genId,
}: TWidgetOpCreatePresetParams): Promise<TWidgets> => {
	const feedback = {};
	const widget = widgets[id];
	const { instanceId = null } = widget;
	let updated: TWidgets = {};

	updated = await _cloneTree({
		widgets,
		targetId: id,
		instanceId,
		feedback,
		ObjectId,
		containerId: null,
	});

	updated = {
		...updated,
		[feedback.targetId]: {
			...updated[feedback.targetId],
			scope: null,
			scopeId: null,
		},
	};

	return updated;
};

export const editWidget = _edit;
