// @flow
import React from 'react';
import _ from 'lodash/fp';
import type { TSx } from '@graphite/types';
import Button from '../Button';
import { IconClear } from '../Icon';

type TToolbarBtnProps = $ReadOnly<{|
	children?: React$Node,
	iconName?: string,
	isActive?: boolean,
	variant?: 'sm' | 'md',
	onClick?: (MouseEvent, ?string) => void,
	color?: string,
	sx?: TSx,
|}>;

const toolbarBtnSx = {
	sm: { width: 30 },
	md: { width: 36 },
};

const ToolbarBtn = (
	{
		iconName,
		variant = 'md',
		onClick,
		isActive,
		children,
		color = 'primaryflat',
		sx,
	}: TToolbarBtnProps,
	ref,
) => {
	const style = React.useMemo(() => _.assign(toolbarBtnSx[variant], sx), [sx, variant]);

	return (
		<Button
			ref={ref}
			variant={`${isActive ? 'accentflat' : color}.flat.${variant}`}
			onClick={onClick}
			sx={style}
			data-kind={`toolbar-btn-${iconName || ''}`}
		>
			{iconName && <IconClear name={iconName} />}
			{children}
		</Button>
	);
};

export default React.memo<TToolbarBtnProps>(React.forwardRef(ToolbarBtn));
