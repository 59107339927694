// @flow
import React from 'react';
import _ from 'lodash/fp';
import {
	Box,
	Text,
	PopupWhite,
	Section,
	PopupSelect,
	List,
	Switch,
} from '@graphite/uneon';
import type { TAnimation } from '@graphite/types';
import { effects } from '@graphite/animations';
import PropItem from './PropItem';
import Empty from '../Empty';
import PlayButton from '../PlayButton';

type TProps = $ReadOnly<{|
	t: (string) => string,
	params: TAnimation,
	trigger: string,
	handleClose: () => void,
	anchorEl: {| current: ?React$ElementRef<any> |},
	insertEffect: (string) => void,
	removeEffect: (string) => void,
	changeEffect: (TAnimation) => void,
|}>;

const wrapBoxSx = {
	display: 'flex',
	flexDirection: 'column',
};

const headerSx = {
	cursor: 'default',
	textTransform: 'capitalize',
	marginBottom: '16px',
};

const separatorSx = {
	margin: '0 -24px',
	height: '1px',
	backgroundColor: 'bg.primaryalt',
};

const spaceSx = {
	marginTop: '12px',
	marginBottom: '20px',
};

const EffectPanelComponent = ({
	t,
	params,
	trigger,
	handleClose,
	anchorEl,
	insertEffect,
	changeEffect,
	removeEffect,
}: TProps) => {
	const [isOpenEffects, setOpenEffects] = React.useState(false);
	const anchorRef = React.useRef();

	const toggleOpenEffect = React.useCallback(() => {
		setOpenEffects(!isOpenEffects);
	}, [isOpenEffects]);

	const handleInsertEffect = React.useCallback(
		(e, value) => {
			if (typeof value !== 'string' || value === null) {
				return;
			}
			insertEffect(value);
			toggleOpenEffect();
		},
		[toggleOpenEffect, insertEffect],
	);

	const btnInsertEffect = React.useMemo(
		() => ({
			buttons: [
				{
					name: 'insert',
					icons: [
						{
							title: t('Insert effect'),
							name: 'plus',
							iconSize: 18,
						},
					],
					colors: 'primary',
					ref: anchorRef,
				},
			],
		}),
		[t, anchorRef],
	);

	const is3d = React.useMemo(() => {
		const { from, to } = params;
		return !!from?.transformPerspective && !!to?.transformPerspective;
	}, [params]);

	const currentEffects = React.useMemo(() => effects[is3d ? '3d' : '2d'], [is3d]);

	const itemsTrigger = React.useMemo(() => {
		const { from, to } = params;

		return _.map(
			(name) => ({
				name,
				label: t(name),
				isDisabled: !!from[`${name}`] || !!to[`${name}`],
			}),
			currentEffects,
		);
	}, [t, params, currentEffects]);

	const paramKeys = React.useMemo(() => {
		const { from, to } = params;
		return _.filter((prop) => {
			if (from?.[prop] || from?.[prop] === 0 || to?.[prop] || to?.[prop] === 0)
				return true;
			return false;
		}, currentEffects);
	}, [params, currentEffects]);

	const noEffect = _.isEmpty(paramKeys);

	const handleSwitch3D = React.useCallback(() => {
		if (is3d) {
			removeEffect('transformPerspective');
			return;
		}
		insertEffect('transformPerspective');
	}, [is3d, removeEffect, insertEffect]);

	return (
		<PopupWhite
			isOpen
			onClose={handleClose}
			mutex="animate"
			width={288}
			offsetLeft={64}
			anchorEl={anchorEl}
		>
			<Box sx={wrapBoxSx}>
				<Text variant="title4" color="text.primaryalt" sx={headerSx}>
					{t(trigger)}
				</Text>
				<Box sx={spaceSx}>
					<Switch
						checked={is3d}
						onClick={handleSwitch3D}
						label={t('3D effects')}
					/>
				</Box>
				{is3d && (
					<Box>
						<PropItem
							t={t}
							prop="transformPerspective"
							params={params}
							changeEffect={changeEffect}
						/>
						<Box sx={separatorSx} />
					</Box>
				)}

				<Section
					size="sm"
					label={t('Effects')}
					buttonGroup={btnInsertEffect}
					onClick={toggleOpenEffect}
				>
					{paramKeys.map((prop, i) => (
						<Box key={`animate-prop-${prop}`}>
							<PropItem
								t={t}
								prop={prop}
								params={params}
								changeEffect={changeEffect}
								removeEffect={removeEffect}
							/>
							{i < paramKeys.length - 1 && <Box sx={separatorSx} />}
						</Box>
					))}
					{noEffect && (
						<Empty
							t={t}
							title={is3d ? t('Add 3D effects') : t('Add 2D effects')}
						/>
					)}
				</Section>
				<PlayButton t={t} params={params} />
				<PopupSelect
					isOpen={isOpenEffects}
					anchorEl={anchorRef}
					offsetTop={0}
					offsetLeft={0}
					onClose={toggleOpenEffect}
				>
					<List
						items={itemsTrigger}
						colors="primaryflat"
						activeColors="primaryflat"
						behavior="button"
						onClick={handleInsertEffect}
					/>
				</PopupSelect>
			</Box>
		</PopupWhite>
	);
};

export default React.memo<TProps>(EffectPanelComponent);
