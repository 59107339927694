// @flow
import React from 'react';
import { Text, Box, InputInplace, useInput } from '@graphite/uneon';
import type { TStringParam } from '@graphite/types';

type TProps = $ReadOnly<{|
	sign?: ?string,
	value: string,
	param: TStringParam,
	onChange: string => void,
|}>;

const signSx = {
	textAlign: 'right',
	position: 'absolute',
	right: '100%',
	marginRight: '15px',
};

const paramRightStyle = {
	flexGrow: 1,
	maxWidth: '50%',
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
};

const inputSx = {
	margin: '0 -9px',
	flexGrow: 1,
};

function ListItemStringParams({ value, param, onChange, sign }: TProps) {
	const { info } = param;

	const [currentValue, changeValue] = useInput(value, onChange);

	return (
		<Box sx={paramRightStyle}>
			{info.signKey && (
				<Text variant="bodysm" color="text.tertiary" sx={signSx}>
					{sign || ''}
				</Text>
			)}
			<InputInplace
				value={currentValue}
				max={param.info.maxLength || 0}
				placeholder={param.info.placeholder || ''}
				sx={inputSx}
				onChange={changeValue}
				isDisabled={info.isDisabled}
				onValidate={info.validate}
			/>
		</Box>
	);
}

ListItemStringParams.defaultProps = {
	onChange: null,
};

export default React.memo<TProps>(ListItemStringParams);
