// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import type { TConnectProps, TConnectPropsEdit } from '../constants/types';

export type THOCFilterProps = $ReadOnly<{|
	...$Exact<TConnectPropsEdit>,
|}>;

export type THOCFilterComponentInput = React$ComponentType<TConnectPropsEdit>;

export type THOCFilterComponentOutput = React$ComponentType<TConnectProps>;

export type THOCFilter = THOCFilterComponentOutput => THOCFilterComponentInput;

export type THOCFilterFunc = TConnectPropsEdit => TConnectProps;

const filterProps: THOCFilterFunc = props => ({
	id: props.id,
	originId: props.originId,
	widgetspec: props.widgetspec,
	colorspec: props.colorspec,
	gridspec: props.gridspec,
	effectspec: props.effectspec,
	rowId: props.rowId,
	containerId: props.containerId,
	instanceId: props.instanceId,
	widgetChain: props.widgetChain,
	children: props.children,
	onClick: props.onClick,
	data: props.data,
	babies: props.babies,
	dispatch: props.dispatch,
});

/**
	Фильтрует пропсы, оставляет только те пропсы, которые нужны
	обычному компоненту блока. Чтобы служебные пропсы (нужные только для конструктора)
	не долетели до компонента, используемого на опубликованном сайте.
 */
const withFilter: THOCFilter = Component => {
	const Filter = (props, ref) => {
		const propsNew: TConnectProps = filterProps(props);
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...propsNew} ref={ref} />;
	};

	Filter.displayName = `withFilter(${getDisplayName(Component)})`;

	return React.memo<THOCFilterProps>(React.forwardRef(Filter));
};

export default withFilter;
