// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useSelector, useDispatch } from '@graphite/use-redux';
import styled from '@emotion/styled';
import emptyArray from 'empty/array';
import { css, CacheProvider } from '@emotion/core';
import { PopupWhite, Portal, Box } from '@graphite/uneon';

import {
	getPresets,
	getCurrentSiteBreakpoints,
	getWidgets,
	getWidget,
	getCurrentPageId,
	getTrueIds,
} from '@graphite/selectors';


import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

import {
	getShownAddWidget,
	getCurrentDevice,
	getCurrentWidget,
} from 'Editor/selectors/editor';
import { updateEditor } from 'Editor/ducks/editor';
import { placeWidget } from 'Editor/ducks/widgets';
import useDefaultDevice from 'Editor/libs/use-default-device';
import type { TId, TSpecsGridBreakpoints, TWidget, TWidgets } from '@graphite/types';

import WidgetPanel from './WidgetPanel';

type TProps = $ReadOnly<{||}>;

const excludeList = ['project', 'site', 'page', 'block', 'col', 'icon'];
const DEFAULT_PX_ABS_WIDGET_ADD = { top: 30, left: 30 };

const PopupWhiteWrapped = styled(PopupWhite)`
	${({ isHidden }) =>
		isHidden
			? css`
					transition: left 0.1s ease-out 0.15s, opacity 0.15s ease-out 0s !important;
					opacity: 0 !important;
					left: -100% !important;
			  `
			: ''}
`;

const addWidgetPanelSx = {
	position: 'fixed',
	top: 0,
	left: 0,
};

const memoizedCreateCacheWithContainer = weakMemoize(
	container => container && createCache({ container }),
);

function WidgetAdd() {
	const dispatch = useDispatch();
	const breakpoints: TSpecsGridBreakpoints = useSelector(getCurrentSiteBreakpoints);
	const widgets: TWidgets = useSelector(getWidgets);
	const currentPageId: ?TId = useSelector(getCurrentPageId);
	const isShowAddWidget = useSelector(getShownAddWidget);
	const currentDevice = useSelector(getCurrentDevice);
	const currentWidget = useSelector(getCurrentWidget);
	const currentWidgetClickAdd = React.useRef(null);
	const currentPage: ?TWidget = useSelector(state =>
		getWidget(state, { id: currentPageId || '' }),
	);

	const ids = React.useMemo(() => {
		if (!currentPage) return emptyArray;
		return getTrueIds({ ...currentPage, currentDevice });
	}, [currentDevice, currentPage]);

	const idFirstBlock: ?TId = ids?.[0];

	const currentWidgetContener = React.useMemo(
		() =>
			currentWidget &&
			_.find(
				widget => !!widget.children?.[currentWidget.id] && !widget.removedAt,
				widgets,
			),
		[widgets, currentWidget],
	);

	const isDefaultDevice = useDefaultDevice() === currentDevice;

	const widgetPresets = getPresets({ widgets });

	// if before added widget through the click
	// srcolling for this widget
	React.useEffect(() => {
		if (currentWidgetClickAdd.current) {
			if (
				currentWidget &&
				window.document.querySelector(`[data-id='${currentWidget.id}']`)
			)
				window.document
					.querySelector(`[data-id='${currentWidget.id}']`)
					.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
			currentWidgetClickAdd.current = null;
		}
	}, [currentWidget]);

	const addWidgetOnClick = React.useCallback(
		(protoId: TId, type: string) => {
			if (!currentPageId) return;
			const containerId =
				currentWidgetContener?._id || idFirstBlock || currentPageId;

			// position for grid(default)
			let position = {
				kind: 'grid',
				destRect: null,
				dragRect: null,
				breakpoints,
				prevId: currentWidget?.id || null,
				nextId: null,
				row: currentWidget ? null : 'new',
			};
			// position for absalute
			if (type !== 'grid') {
				const { width, height } = widgetPresets[protoId].box?.[currentDevice]
					.offset || { width: 0, height: 0 };
				position = {
					kind: 'absolute',
					destY: DEFAULT_PX_ABS_WIDGET_ADD.top,
					destX: DEFAULT_PX_ABS_WIDGET_ADD.left,
					destWidth: width,
					destHeight: height,
					srcWidth: width,
					srcHeight: height,
					prevId: currentWidget?.id || null,
					nextId: null,
				};
			}
			// if before selected any widget
			// need adding widget after currentWidget
			// else in first block
			dispatch(
				placeWidget(
					protoId,
					containerId,
					currentWidget?.instanceId,
					currentPageId,
					position,
				),
			);

			// for scrolling
			if (!currentWidget && containerId) currentWidgetClickAdd.current = protoId;
		},
		[
			breakpoints,
			currentDevice,
			currentPageId,
			currentWidget,
			currentWidgetContener?._id,
			dispatch,
			idFirstBlock,
			widgetPresets,
		],
	);

	const availableWidgetPresets = _.filter(
		({ kind, scope }) => !excludeList.includes(kind) && scope === 'system',
		widgetPresets,
	);

	const hidePanel = React.useCallback(
		() => dispatch(updateEditor({ isShowAddWidget: false })),
		[dispatch],
	);

	const panelRef = React.useRef();

	return (
		<CacheProvider value={memoizedCreateCacheWithContainer(document.head)}>
			<Portal>
				<Box sx={addWidgetPanelSx} ref={panelRef} />
			</Portal>
			<PopupWhiteWrapped
				anchorEl={panelRef}
				isOpen={isShowAddWidget && isDefaultDevice}
				onClose={hidePanel}
				offsetTop={54}
				mutex="topleftcorner"
				isFixed
			>
				{isShowAddWidget && isDefaultDevice && (
					<WidgetPanel
						widgetPresets={availableWidgetPresets}
						addWidgetOnClick={addWidgetOnClick}
					/>
				)}
			</PopupWhiteWrapped>
		</CacheProvider>
	);
}

export default React.memo<TProps>(WidgetAdd);
