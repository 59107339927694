// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';

import type { TWidget } from '@graphite/types';

type TProps = $ReadOnly<{|
	data: TWidget,
	save: (string, string | Object) => void,
	t: string => string,
|}>;

const Settings = ({ save, data, t }: TProps) => {
	const paramLabel = React.useMemo(
		() => [
			{
				title: t('Type'),
				key: 'type',
				kind: 'select',
				info: {
					label: t('Text'),
					list: {
						items: [
							{ label: t('Email'), name: 'email' },
							{ label: t('Text'), name: 'text' },
							{ label: t('Number'), name: 'number' },
						],
					},
				},
			},
			{
				title: t('Name'),
				key: 'label',
				kind: 'string',
				info: { maxLength: 140 },
			},
			{
				title: t('Placeholder'),
				key: 'placeholder',
				kind: 'string',
				info: { maxLength: 140 },
				hideSeparator: true,
				gap: 15,
			},
			{
				title: t('Required'),
				key: 'isRequired',
				kind: 'switch',
				info: {},
			},
			{
				title: t('Max length'),
				key: 'maxlength',
				kind: 'string',
				info: {
					validate: val => val === '' || !!String(val).match(/^\d+$/),
					placeholder: 'None',
					maxLength: 3,
				},
			},
		],
		[t],
	);

	/**
		Text
	 */
	const paramSourceText = React.useMemo(() => {
		const {
			input = {
				label: '',
				placeholder: '',
				type: 'text',
				maxlength: null,
				isRequired: false,
			},
		} = data;

		return {
			label: input.label,
			placeholder: input.placeholder,
			isRequired: input.isRequired ? 'true' : '',
			maxlength: `${input.maxlength || ''}`,
			type: input.type,
		};
	}, [data]);

	const changeParamInput = React.useCallback(
		(key, value) => {
			save('input', {
				...data.input,
				[key]: value,
			});
		},
		[save, data.input],
	);

	return (
		<>
			<Section label={t('Input')}>
				<ListParams
					listName="text"
					paramSource={paramSourceText}
					paramList={paramLabel}
					unit={1}
					onChange={changeParamInput}
				/>
			</Section>
		</>
	);
};

export default React.memo<TProps>(Settings);
