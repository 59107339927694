// @flow
import React from 'react';
import { Box, PopupWhite, ToolbarBtn } from '@graphite/uneon';
import { AlignSelfPanel } from '@graphite/align-self';
import type {
	TPositionValue,
	TGridBreakpointName,
	TWidget,
	TWidgetDiff,
	TDirection,
	TAlignItems,
} from '@graphite/types';

import Header from './Header';
import Position from './Position';
import Layout from './Layout';
import Props from './Props';

const DEFAULT_OPT = {
	noActive: false,
	padding: {
		isShow: true,
	},
	margin: {
		isShow: true,
	},
	layout: {
		isHeightShow: true,
		isWidthShow: true,
	},
	props: {
		isIdShow: true,
		isClassNameShow: true,
	},
};

type TChildrenProps = $ReadOnly<{|
	activeTab: 'layout' | 'style',
|}>;

type TProps = $ReadOnly<{|
	t: (string) => string,
	children?: (TChildrenProps) => React$Node,
	data: TWidget,
	onSave: (TWidgetDiff) => void,
	unit: number,
	position: TPositionValue,
	currentDevice: TGridBreakpointName,
	currentRef?: {| current: ?HTMLDivElement |},
	options?: $ReadOnly<{
		noActive?: boolean,
		padding: $ReadOnly<{
			isShow: boolean,
		}>,
		margin: $ReadOnly<{
			isShow: boolean,
		}>,
		layout: $ReadOnly<{
			isHeightShow: boolean,
			isWidthShow: boolean,
		}>,
		props: $ReadOnly<{
			isIdShow: boolean,
			isClassNameShow: boolean,
		}>,
	}>,
	direction?: TDirection,
	alignItems?: TAlignItems,
|}>;

const wrapBoxSx = {
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	paddingTop: '24px',
	paddingBottom: '12px',
};

const scrollSx = {
	overflowY: 'overlay',
	overflowX: 'hidden',
	position: 'relative',
	flex: 1,

	'::-webkit-scrollbar': {
		width: '6px',
		backgroundColor: 'transparent',
	},
	'::-webkit-scrollbar-button': {
		display: 'none',
	},
	'::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
		borderRadius: 'md.all',
	},
	'::-webkit-scrollbar-track:hover': {
		backgroundColor: 'bg.primaryaltplus',
	},
	'::-webkit-scrollbar-thumb': {
		backgroundColor: 'transparent',
		borderRadius: 'md.all',
	},
	':hover::-webkit-scrollbar-thumb': {
		backgroundColor: 'bg.secondaryminus',
	},
	'::-webkit-scrollbar-thumb:hover': {
		backgroundColor: 'text.tertiary',
	},
};

const scrollContainerSx = {
	padding: '24px',
};

const WidgetSettings = ({
	t,
	children,
	data,
	onSave,
	unit,
	position,
	currentDevice,
	currentRef,
	options = DEFAULT_OPT,
	direction,
	alignItems = 'normal',
}: TProps) => {
	const [activeTab, setActiveTab] = React.useState('layout');
	const anchorRef = React.useRef(document.body);
	const [isOpenedPanel, setOpenedPanel] = React.useState(false);

	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		(e) => {
			e.preventDefault();
			setOpenedPanel(!isOpenedPanel);
		},
		[isOpenedPanel],
	);
	const handleClose = React.useCallback(
		(e) => {
			e.preventDefault();
			setOpenedPanel(false);
		},
		[setOpenedPanel],
	);

	return (
		<>
			{options?.noActive ? (
				<ToolbarBtn
					iconName="gear-18"
					variant="sm"
					color="whiteflat"
					onClick={handleToogleOpen}
					ref={btnEl}
				/>
			) : (
				<ToolbarBtn
					iconName="gear-18"
					variant="sm"
					onClick={handleToogleOpen}
					ref={btnEl}
					isActive={isOpenedPanel}
				/>
			)}

			{isOpenedPanel && (
				<PopupWhite
					isOpen
					anchorEl={anchorRef}
					offsetTop={54}
					onClose={handleClose}
					mutex="widget-settings"
					width={318}
					isFixed={false}
					height="full"
					placement="left" // когда к body откроется справа)
				>
					<Box sx={wrapBoxSx}>
						<Header
							t={t}
							setActiveTab={setActiveTab}
							activeTab={activeTab}
							widgetKind={data.name}
						/>
						<Box sx={scrollSx}>
							<Box sx={scrollContainerSx}>
								{activeTab === 'layout' && (
									<>
										{direction && (
											<AlignSelfPanel
												t={t}
												onChange={onSave}
												direction={direction}
												data={data}
												alignItems={alignItems}
												currentDevice={currentDevice}
												position={position}
											/>
										)}
										{options.margin.isShow && (
											<Position
												t={t}
												data={data}
												position={position}
												save={onSave}
												unit={unit}
												currentRef={currentRef}
												currentDevice={currentDevice}
											/>
										)}

										{options.padding.isShow && (
											<Position
												t={t}
												data={data}
												position="grid"
												save={onSave}
												unit={unit}
												currentDevice={currentDevice}
												typePosition="padding"
											/>
										)}

										<Layout
											t={t}
											onChange={onSave}
											data={data}
											unit={unit}
											currentRef={currentRef}
											currentDevice={currentDevice}
											position={position}
											options={options.layout}
										/>

										{children && children({ activeTab })}

										<Props
											t={t}
											data={data}
											options={options.props}
											onChange={onSave}
										/>
									</>
								)}
							</Box>
						</Box>
					</Box>
				</PopupWhite>
			)}
		</>
	);
};

export default React.memo<TProps>(WidgetSettings);
