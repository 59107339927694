// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex, IconClear, Box } from '@graphite/uneon';
import type { TAnimation } from '@graphite/types';
import { convertUnit } from '@graphite/animations';

import WrapInput from './UnitInput';

type TProps = $ReadOnly<{|
	prop: string,
	params: TAnimation,
	changeEffect: (TAnimation) => void,
|}>;

const iconSx = {
	padding: '6px 18px',
	svg: {
		transform: 'rotate(90deg)',
		fill: 'text.tertiary',
	},
};

const UnitInputsComponent = ({ prop, params, changeEffect }: TProps) => {
	const { from, to } = React.useMemo(() => {
		const fromValue = params?.from[prop];
		const toValue = params?.to[prop];

		// начало и конец обязательно должны быть одного типа
		// в случае строк имеем юнит, который нужно парсить
		if (typeof fromValue === 'string' && typeof toValue === 'string') {
			return {
				from: {
					value: parseFloat(fromValue),
					unit: convertUnit(fromValue),
				},
				to: {
					value: parseFloat(toValue),
					unit: convertUnit(toValue),
				},
			};
		}

		// Пока что только scale будет иметь 0 -> 1 значения
		// но в теории такие свойства могут быть
		// конвертируем в %
		if (
			typeof fromValue === 'number' &&
			typeof toValue === 'number' &&
			(fromValue >= 0 || fromValue <= 1) &&
			(toValue >= 0 || toValue <= 1)
		)
			return {
				from: {
					value: parseInt(fromValue * 100, 10),
					unit: '%',
				},
				to: {
					value: parseInt(toValue * 100, 10),
					unit: '%',
				},
			};

		// тут будет логика шагов в будущем

		return {
			from: {
				value: 0,
				unit: null,
			},
			to: {
				value: 0,
				unit: null,
			},
		};
	}, [params, prop]);

	const changeValue = React.useCallback(
		(value, point, unit) => {
			let concatValue = `${value}${unit || 'px'}`;
			if (prop.includes('scale')) concatValue = +value / 100;

			changeEffect(_.set(`${point}.${prop}`, concatValue, params));
		},
		[prop, changeEffect, params],
	);

	const changeUnit = React.useCallback(
		(value) => {
			const concatValueFrom = `${from.value}${value}`;
			const concatValueTo = `${to.value}${value}`;

			changeEffect(
				_.flow(
					_.set(`from.${prop}`, concatValueFrom),
					_.set(`to.${prop}`, concatValueTo),
				)(params),
			);
		},
		[from, to, prop, changeEffect, params],
	);

	return (
		<Flex>
			<WrapInput
				prop={prop}
				value={from.value}
				unit={from.unit}
				point="from"
				changeUnit={changeUnit}
				changeValue={changeValue}
			/>
			<Box sx={iconSx}>
				<IconClear name="expand-small-top-18" />
			</Box>
			<WrapInput
				prop={prop}
				value={to.value}
				unit={to.unit}
				point="to"
				changeUnit={changeUnit}
				changeValue={changeValue}
			/>
		</Flex>
	);
};

export default React.memo<TProps>(UnitInputsComponent);
