// @flow
import { select } from 'redux-saga/effects';

import { collection, genId } from 'libs/firebase';
import { cloneWidget } from 'libs/operations/widgets';
import { getSpecsFromPreset } from 'libs/operations/specs';
import { getWidget, getTrueIds, getWidgets } from '@graphite/selectors';
import type { TId, TWidget, TWidgets, TSpecs } from '@graphite/types';

export function* uploadDataSite(siteId: TId): any {
	const stateWidgets: TWidgets = yield select(getWidgets);
	let widgets: TWidgets = {};
	let specs: TSpecs = {};
	// Получаем сайт
	const site: ?TWidget = yield select(getWidget, { id: siteId });

	if (!site) {
		throw new Error('Site not find.');
	}

	widgets = {
		...widgets,
		[site._id]: site,
	};

	// Получаем все виджеты, которые принадлежат сайту
	// на сегодняшний день — страницы и файлы
	const widgetsSiteSnapshot: any = yield collection('widgets')
		.where('userId', '==', site.userId)
		.where('scope', '==', 'site')
		.where('scopeId', '==', site._id)
		.get();
	for (const widgetsSiteDoc of widgetsSiteSnapshot.docs) {
		const widget = widgetsSiteDoc.data();
		widgets = {
			...widgets,
			[widget._id]: widget,
		};
	}

	// Получаем все страницы сайта
	const pageIds = getTrueIds({
		...site,
		currentDevice: 'desktop',
	});
	for (const pageId of pageIds) {
		// Получаем виджеты всех страниц
		const widgetsSnapshot: any = yield collection('widgets')
			.where('userId', '==', (widgets[pageId] || stateWidgets[pageId]).userId)
			.where('scope', '==', 'page')
			.where('scopeId', '==', pageId)
			.get();

		for (const doc of widgetsSnapshot.docs) {
			const widget = doc.data();
			widgets = {
				...widgets,
				[widget._id]: widget,
			};
		}
	}

	// Получаем виджеты всех страниц
	const specsSnapshot: any = yield collection('specs')
		.where('userId', '==', site.userId)
		.where('scope', '==', 'site')
		.where('scopeId', '==', site._id)
		.get();

	// Получаем спеки
	for (const specDoc of specsSnapshot.docs) {
		const spec = specDoc.data();
		specs = {
			...specs,
			[spec._id]: spec,
		};
	}

	return { widgets, specs };
}

export function* getSiteData(siteId: TId): any {
	const { widgets, specs } = yield uploadDataSite(siteId);

	const site: ?TWidget = yield select(getWidget, { id: siteId });
	if (!site) {
		throw new Error('Site not find.');
	}

	// Копируем сайт
	const feedback = {};
	let newWidgets = yield cloneWidget({
		widgets,
		targetId: site._id,
		containerId: null,
		instanceId: null,
		originId: site._id,
		feedback,
		diff: {},
	});

	const { targetId = null } = feedback;
	if (!targetId) {
		throw new Error('Feedback is bad.');
	}
	newWidgets = {
		...newWidgets,
		[targetId]: {
			...(newWidgets ? newWidgets[targetId] : {}),
			scope: 'site',
			scopeId: targetId,
		},
	};

	// Копируем спеки
	let dictionaryIds = {};
	for (const specId of Object.keys(specs)) {
		dictionaryIds = {
			...dictionaryIds,
			[specId]: genId('specs'),
		};
	}

	newWidgets = {
		...newWidgets,
		[feedback.targetId]: {
			...newWidgets[feedback.targetId],
			gridspecId: site.gridspecId ? dictionaryIds[site.gridspecId] : null,
			effectspecId: site.effectspecId ? dictionaryIds[site.effectspecId] : null,
			widgetspecId: site.widgetspecId ? dictionaryIds[site.widgetspecId] : null,
			colorspecId: site.colorspecId ? dictionaryIds[site.colorspecId] : null,
		},
	};

	const newSpecs = getSpecsFromPreset({
		userId: site.userId,
		dictionaryIds,
		sitespec: specs,
		display: 'specs',
		scope: 'site',
		scopeId: feedback.targetId,
	});

	const res: any = {
		widgets: newWidgets,
		specs: newSpecs,
		targetId: feedback.targetId,
	};

	return res;
}

export default { uploadDataSite, getSiteData };
