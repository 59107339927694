// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Section } from '@graphite/uneon';
import { Params as List } from '@graphite/lists';
import type { TParams, TAnimation } from '@graphite/types';
import { defaultValue } from '@graphite/animations';

type TProps = $ReadOnly<{|
	t: (string) => string,
	trigger: string,
	params: TAnimation,
	changeEffect: (TAnimation) => void,
|}>;

const sectionSx = {
	marginTop: '12px',
};

const RepeatComponent = ({ t, trigger, params, changeEffect }: TProps) => {
	const isLoop = params.transition?.loop;

	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Trigger once'),
				key: 'triggerOnce',
				kind: 'switch',
				hidden: trigger !== 'appear',
				info: {},
			},
			{
				title: t('Loop'),
				key: 'loop',
				kind: 'switch',
				info: {},
				hideSeparator: !isLoop,
			},
			{
				title: t('Type'),
				key: 'repeatType',
				kind: 'select',
				hidden: !isLoop,
				info: {
					list: {
						items: ['mirror', 'loop', 'reverse'].map((name) => ({
							name,
							label: t(name),
						})),
					},
				},
			},
		],
		[t, isLoop, trigger],
	);

	const paramSource = React.useMemo(() => {
		const { triggerOnce, loop, repeatType } = _.assign(
			defaultValue.transition.repeat,
			params.transition,
		);

		return {
			triggerOnce: triggerOnce ? 'true' : '', // ... ToDo надо избавиться от этого
			loop: loop ? 'true' : '', // какой же это бред, плакать хочется(
			repeatType,
		};
	}, [params]);

	const changeParam = React.useCallback(
		(key, value) => {
			changeEffect(_.set(`transition.${key}`, value, params));
		},
		[changeEffect, params],
	);

	return (
		<Section size="sm" label={t('Repeat')} sx={sectionSx}>
			<List
				listName="animation-transition-repeat"
				paramSource={paramSource}
				paramList={paramList}
				unit={0}
				onChange={changeParam}
			/>
		</Section>
	);
};

export default React.memo<TProps>(RepeatComponent);
