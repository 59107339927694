// @flow

import React from 'react';
import { Flex, Text, Box, IconClear, Button } from '@graphite/uneon';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';

import { closestDeviceWithKey } from '@graphite/selectors';
import { ListItemUnitParams } from '@graphite/lists';
import type {
	TWidget,
	TWidgetDiff,
	TPositionValue,
	TWidgetBox,
	TWidgetBoxBreakpoint,
	TOffsetDevice,
	TMarginDevice,
	TPaddingDevice,
	TGridBreakpointName,
} from '@graphite/types';

import LockOn from './LockOn';

type TProps = $ReadOnly<{|
	t: string => string,
	data: TWidget,
	unit: number,
	position: TPositionValue,
	currentDevice: TGridBreakpointName,
	save: TWidgetDiff => void,
	currentRef?: {| current: ?HTMLDivElement |},
	typePosition?: 'padding' | 'margin',
|}>;

const columnSx = {
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: '-12px',
	marginBottom: '12px',
};

const rowSx = {
	marginTop: '6px',
	marginBottom: '6px',
	alignItems: 'center',
	justifyContent: 'space-around',
	width: '100%',
};

const rowBoxSx = {
	width: '90px',
};

const rowBoxLeftSx = {
	...rowBoxSx,
	justifyContent: 'flex-end',
};

const headerWrapSx = {
	position: 'relative',
	alignItems: 'center',
	height: '42px',
	textAlign: 'left',
};

const headerSx = {
	textTransform: 'capitalize',
	width: '50%',
};

const separatorOuterSx = {
	position: 'relative',
};

const separatorSx = {
	position: 'absolute',
	left: '-24px',
	right: '-24px',
	bottom: '1px',
	height: '1px',
	backgroundColor: 'bg.primaryalt',
};

const rightSideWrapSx = {
	width: '50%',
	justifyContent: 'space-between',
};

const wrapInputSx = {
	width: 'auto',
	maxWidth: '100%',
	flexGrow: 0,
	marginLeft: '9px',
	marginRight: '-6px',
};

const param = {
	title: 'Left',
	key: 'left',
	kind: 'unit',
	info: {
		showUnits: true,
		unitKey: 'leftUnit',
		domain: 'any',
	},
};

const sideValGetters = {
	left: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.left || offsets.centerx : margins.left;
		return `${value || 0}`;
	},
	right: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.right : margins.right;
		return `${value || 0}`;
	},
	top: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.top || offsets.centery : margins.top;
		return `${value || 0}`;
	},
	bottom: (isAbsolute, offsets, margins) => {
		const value = isAbsolute ? offsets.bottom : margins.bottom;
		return `${value || 0}`;
	},
	all: ({ top, left, right, bottom }) => {
		if (![top, left, right, bottom].every(v => v === top)) return 'mixed';
		return `${top || 0}`;
	},
};

const sideNames = {
	left: 'Left',
	right: 'Right',
	top: 'Top',
	bottom: 'Bottom',
};

const getIsActive = (isAbsolute, offsets, margins, side) => {
	if (isAbsolute) {
		if (side === 'left' && offsets.centerx !== undefined) {
			return true;
		}
		if (side === 'top' && offsets.centery !== undefined) {
			return true;
		}
		return offsets[side] !== undefined;
	}
	return true;
};

function Position({
	data,
	position,
	save,
	unit,
	currentDevice,
	currentRef,
	typePosition = 'margin',
	t,
}: TProps) {
	const [isShowPositionCross, setShowPositionCross] = React.useState(
		typePosition !== 'padding',
	);
	const isAbsolute = position && position.includes('absolute');

	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		// eslint-disable-next-line
		key: `box-${data._id}`,
	});
	const margins: TMarginDevice | TPaddingDevice = box[typePosition] || emptyObject;
	const offsets: TOffsetDevice = box.offset || emptyObject;

	const sideProps = React.useMemo(
		() =>
			['top', 'right', 'bottom', 'left'].map(side => {
				const isActive = getIsActive(isAbsolute, offsets, margins, side);
				const sideDomain =
					isAbsolute ||
					(typePosition === 'margin' && (side === 'right' || side === 'left'))
						? 'any'
						: 'nonnegative';

				return {
					value: sideValGetters[side](isAbsolute, offsets, margins),
					valueUnit: isActive ? 'px' : 'auto',
					param: _.flow(
						_.set('title', sideNames[side]),
						_.set('key', side),
						_.set('info.domain', sideDomain),
						_.set('info.unitKey', `${side}Unit`),
					)(param),
					change: value => {
						if (isAbsolute) {
							let nextData: TOffsetDevice = offsets;
							if (side === 'left' && nextData.centerx !== undefined) {
								nextData = (_.set(
									'centerx',
									value,
									nextData,
								): TOffsetDevice);
							} else if (side === 'top' && nextData.centery !== undefined) {
								nextData = (_.set(
									'centery',
									value,
									nextData,
								): TOffsetDevice);
							} else {
								nextData = (_.set(side, value, nextData): TOffsetDevice);
							}
							save({
								box: (_.set(
									currentDevice,
									(_.set(
										'offset',
										nextData,
										box,
									): TWidgetBoxBreakpoint),
									data.box,
								): TWidgetBox),
							});
						} else {
							const nextData: TMarginDevice = _.assign(margins, {
								[`${side}`]: value,
							});
							save({
								box: (_.set(
									currentDevice,
									(_.set(
										typePosition,
										nextData,
										box,
									): TWidgetBoxBreakpoint),
									data.box,
								): TWidgetBox),
							});
						}
					},
				};
			}),
		[box, currentDevice, data.box, isAbsolute, margins, offsets, save, typePosition],
	);

	const allSideProps = React.useMemo(() => {
		const initialValue = sideValGetters.all(margins);

		return {
			placeholder: initialValue === 'mixed' ? 'Mixed' : '',
			value: initialValue === 'mixed' ? '' : initialValue,
			valueUnit: initialValue === 'mixed' ? '' : 'px',
			param: {
				title: t('All'),
				key: 'all',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'allUnit',
					domain: 'nonnegative',
				},
			},
			change: value => {
				const nextData: TMarginDevice = _.assign(margins, {
					top: value,
					left: value,
					right: value,
					bottom: value,
				});
				save({
					box: (_.set(
						currentDevice,
						(_.set(typePosition, nextData, box): TWidgetBoxBreakpoint),
						data.box,
					): TWidgetBox),
				});
			},
		};
	}, [t, box, currentDevice, data.box, margins, save, typePosition]);

	const handleSetShowPositionCross = React.useCallback(() => {
		setShowPositionCross(!isShowPositionCross);
	}, [isShowPositionCross]);

	const allUnitSx = React.useMemo(
		() => ({
			transition: 'opacity 0.1s ease-in',
			...(isShowPositionCross
				? {
						opacity: 0,
						pointerEvents: 'none',
				  }
				: {}),
		}),
		[isShowPositionCross],
	);

	return (
		<>
			<Flex sx={headerWrapSx}>
				<Text variant="bodysm" color="text.primary" sx={headerSx}>
					{t(
						position === 'absolute' && typePosition === 'margin'
							? 'position'
							: typePosition,
					)}
				</Text>
				<Flex sx={rightSideWrapSx}>
					<Flex sx={allUnitSx}>
						<ListItemUnitParams
							param={allSideProps.param}
							value={allSideProps.value}
							placeholder={allSideProps.placeholder}
							valueUnit={allSideProps.valueUnit}
							unit={unit}
							onChange={allSideProps.change}
							maxWidth="48px"
						/>
					</Flex>
					<Button
						variant={`${
							isShowPositionCross ? 'accentflat' : 'tertiaryflat'
						}.flat.sm`}
						onClick={handleSetShowPositionCross}
					>
						<IconClear name="square-side-18" />
					</Button>
				</Flex>
			</Flex>
			{!isShowPositionCross && (
				<Box sx={separatorOuterSx}>
					<Box sx={separatorSx} />
				</Box>
			)}
			{isShowPositionCross && (
				<Flex sx={columnSx}>
					<ListItemUnitParams
						value={sideProps[0].value}
						param={sideProps[0].param}
						valueUnit={sideProps[0].valueUnit}
						unit={unit}
						onChange={sideProps[0].change}
						maxWidth="48px"
						sx={wrapInputSx}
					/>
					<Flex sx={rowSx}>
						<Flex sx={rowBoxLeftSx}>
							<ListItemUnitParams
								value={sideProps[3].value}
								param={sideProps[3].param}
								valueUnit={sideProps[3].valueUnit}
								unit={unit}
								onChange={sideProps[3].change}
								maxWidth="48px"
								sx={wrapInputSx}
							/>
						</Flex>
						<LockOn
							data={data}
							position={position}
							currentDevice={currentDevice}
							currentRef={currentRef}
							save={save}
							typePosition={typePosition}
						/>
						<Flex sx={rowBoxSx}>
							<ListItemUnitParams
								value={sideProps[1].value}
								param={sideProps[1].param}
								valueUnit={sideProps[1].valueUnit}
								unit={unit}
								onChange={sideProps[1].change}
								maxWidth="48px"
								sx={wrapInputSx}
							/>
						</Flex>
					</Flex>
					<ListItemUnitParams
						value={sideProps[2].value}
						param={sideProps[2].param}
						valueUnit={sideProps[2].valueUnit}
						unit={unit}
						onChange={sideProps[2].change}
						maxWidth="48px"
						sx={wrapInputSx}
					/>
				</Flex>
			)}
		</>
	);
}

export default React.memo<TProps>(Position);
