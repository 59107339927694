// @flow
import React from 'react';
import type { TId } from '@graphite/types';
import { TriggerContext } from './triggerReducer';
import type { TRegAction } from './types';

const useActions = () => {
	const [, dispatch] = React.useContext(TriggerContext);

	const onReg = React.useCallback(
		(action: TRegAction): void => {
			dispatch({
				type: 'reg',
				payload: { action },
			});
		},
		[dispatch],
	);

	const onClick = React.useCallback(
		(triggerId: TId): void => {
			dispatch({
				type: 'click',
				payload: { triggerId },
			});
		},
		[dispatch],
	);

	const onMouseEnter = React.useCallback(
		(triggerId: TId): void => {
			dispatch({
				type: 'hover',
				payload: { triggerId },
			});
		},
		[dispatch],
	);

	const onMouseLeave = React.useCallback(
		(triggerId: TId): void => {
			dispatch({
				type: 'hover',
				payload: { triggerId },
			});
		},
		[dispatch],
	);

	return React.useMemo(() => ({ onReg, onClick, onMouseEnter, onMouseLeave }), [
		onReg,
		onClick,
		onMouseEnter,
		onMouseLeave,
	]);
};

export default useActions;
