// @flow
import React from 'react';
import _ from 'lodash/fp';

import { closestDeviceWithKey } from '@graphite/selectors';
import type {
	TDesign,
	TDesignBreakpoint,
	TGridBreakpointName,
	TParamSource,
} from '@graphite/types';

type TUseBreakpointData = $ReadOnly<{|
	paramSource: TParamSource,
	breakpoint: TDesignBreakpoint,
	changeParam: (string, ?(string | number | $ReadOnlyArray<number> | boolean)) => void,
	changeBreakpoint: TDesignBreakpoint => void,
|}>;

const paramConv = {
	string: v => v,
	number: v => `${v}`,
	boolean: v => (v ? 'true' : ''),
	array: v => v,
};

export const useBreakpoint = (
	onChange: ?(TDesign) => void,
	design: TDesign,
	device: TGridBreakpointName,
): TUseBreakpointData => {
	const breakpoint: TDesignBreakpoint = closestDeviceWithKey(design.breakpoints, {
		currentDevice: device,
		// eslint-disable-next-line no-underscore-dangle
		key: `breakpoint-${design._id}-${device}`,
	});

	// Фильтр свойств
	const paramSource: TParamSource = React.useMemo(
		() =>
			_.mapValues(
				v => {
					// Проверка каждого свойства из дизайна на допустимый тип
					// По логике любой объект не должен попасть в инпуты панели
					// ToDo На самом деле ненужная херня которая ничего полезного не делает
					if (Array.isArray(v)) return paramConv.array(v);
					if (paramConv[typeof v]) return paramConv[typeof v](v);

					return '';
				},
				_.pickBy(v => !!paramConv[typeof v] || Array.isArray(v), {
					...breakpoint,
				}),
			),
		[breakpoint],
	);

	const changeParam = React.useCallback(
		(key, value) => {
			if (onChange) {
				const newBreakpoint = _.set(key, value, breakpoint);
				onChange(_.set(['breakpoints', device], newBreakpoint, design));
			}
		},
		[onChange, breakpoint, device, design],
	);

	const changeBreakpoint = React.useCallback(
		(newBreakpoint: TDesignBreakpoint) => {
			if (onChange) {
				onChange(_.set(['breakpoints', device], newBreakpoint, design));
			}
		},
		[onChange, device, design],
	);

	return { paramSource, changeParam, breakpoint, changeBreakpoint };
};

export default useBreakpoint;
