// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type EditorState as TEditorState } from 'draft-js';
import { Toolbar, ToolbarBtn, Popper, Tooltip } from '@graphite/uneon';
import { getAlign, toggleAlign } from '@graphite/widget-text';

const ICONS = {
	left: 'text-align-left-18',
	center: 'text-align-center-18',
	right: 'text-align-right-18',
	justify: 'text-align-justify-18',
};

type TProps = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
|}>;

const TextAlign = ({
	editorState,
	setEditorState,
	setOpenedPanel,
	openedPanel,
}: TProps) => {
	const { t } = useTranslation();

	const textAlignBtnEl: React$ElementRef<any> = React.useRef(null);
	const textAlignBtnLeftEl: React$ElementRef<any> = React.useRef(null);
	const textAlignBtnCenterEl: React$ElementRef<any> = React.useRef(null);
	const textAlignBtnRightEl: React$ElementRef<any> = React.useRef(null);
	const textAlignBtnJustifyEl: React$ElementRef<any> = React.useRef(null);

	const handleTextAlign = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === 'textAlign' ? null : 'textAlign',
			);
		},
		[setOpenedPanel],
	);

	const textAlignStyle = React.useMemo(() => getAlign(editorState), [editorState]);

	const changeTextAlignStyle = React.useMemo(() => toggleAlign(editorState), [
		editorState,
	]);

	const handleSetLeft = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('left'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const handleSetCenter = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('center'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const handleSetRight = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('right'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const handleSetJustify = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('justify'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const isActive = openedPanel === 'textAlign';

	return (
		<>
			<ToolbarBtn
				onClick={handleTextAlign}
				iconName={ICONS[textAlignStyle || 'left']}
				ref={textAlignBtnEl}
				isActive={openedPanel === 'textAlign'}
			/>
			<Tooltip elRef={textAlignBtnEl} placement={isActive ? 'bottom' : 'top'}>
				{t('Text align')}
			</Tooltip>

			<Popper
				isOpen={openedPanel === 'textAlign'}
				anchorEl={textAlignBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<ToolbarBtn
						onClick={handleSetLeft}
						iconName={ICONS.left}
						isActive={textAlignStyle === 'left'}
						ref={textAlignBtnLeftEl}
					/>
					<Tooltip elRef={textAlignBtnLeftEl} placement="top">
						{t('Left')}
					</Tooltip>

					<ToolbarBtn
						onClick={handleSetCenter}
						iconName={ICONS.center}
						isActive={textAlignStyle === 'center'}
						ref={textAlignBtnCenterEl}
					/>
					<Tooltip elRef={textAlignBtnCenterEl} placement="top">
						{t('Center')}
					</Tooltip>

					<ToolbarBtn
						onClick={handleSetRight}
						iconName={ICONS.right}
						isActive={textAlignStyle === 'right'}
						ref={textAlignBtnRightEl}
					/>
					<Tooltip elRef={textAlignBtnRightEl} placement="top">
						{t('Right')}
					</Tooltip>

					<ToolbarBtn
						onClick={handleSetJustify}
						iconName={ICONS.justify}
						isActive={textAlignStyle === 'justify'}
						ref={textAlignBtnJustifyEl}
					/>
					<Tooltip elRef={textAlignBtnJustifyEl} placement="top">
						{t('Justify')}
					</Tooltip>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(TextAlign);
