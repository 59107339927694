// @flow
import _ from 'lodash/fp';
import {
	takeEvery,
	put,
	fork,
	select,
	call,
	all,
	take,
	cancel,
} from 'redux-saga/effects';
import { eventChannel, type EventChannel } from 'redux-saga';
import { handleActions } from 'redux-actions';
import { specsPreset } from '@graphite/constants';

import { commitSpecsToBd, collection } from 'libs/firebase';
import logger from '@graphite/logger';
import { getSpecs } from '@graphite/selectors';
import type {
	TId,
	TAction,
	TQueryConstraints,
	TQueryConstraint,
	TSpecs,
	TSpec,
} from '@graphite/types';
import type { Saga } from 'redux-saga';

import { historyNewAction } from './history';
import { APPLY } from './editor';

const LOAD_AND_WATCH_SPECS = 'SPECS/LOAD_AND_WATCH_SPECS';
const SAVE_SPECS = 'SPECS/SAVE_SPECS';
const UPDATE_SPECS = 'SPECS/UPDATE_SPECS';
const SYNC_SCOPED_SPECS = 'SPECS/SYNC_SCOPED_SPECS';
const UNSYNC_SCOPED_SPECS = 'SPECS/UNSYNC_SCOPED_SPECS';

const initialState: TSpecs = specsPreset;

export const loadAndWatchSpecs = (projectId: TId, siteId: TId): TAction => ({
	type: LOAD_AND_WATCH_SPECS,
	payload: {
		projectId,
		siteId,
	},
});

export const saveSpecs = (specs: TSpecs): TAction => ({
	type: SAVE_SPECS,
	payload: {
		specs,
	},
});

export const updateSpecs = (specs: TSpecs): TAction => ({
	type: UPDATE_SPECS,
	payload: { specs },
});

export const syncScopedSpecs = (
	scope: string,
	scopeId: ?TId,
	userId: ?TId,
	key: ?string,
): TAction => ({
	type: SYNC_SCOPED_SPECS,
	payload: {
		scope,
		scopeId,
		userId,
		key,
	},
});

export const unsyncScopedSpecs = (
	scope: string,
	scopeId: ?TId,
	userId: ?TId,
	key: ?string,
): TAction => ({
	type: UNSYNC_SCOPED_SPECS,
	payload: {
		scope,
		scopeId,
		userId,
		key,
	},
});

type TWithSpecs = { specs: TSpecs };
type TDbChannel = EventChannel<TWithSpecs>;

type TSyncParams = $ReadOnly<{|
	dbEventChannel: TDbChannel,
|}>;

const buildQuery = (
	scope: string,
	scopeId: ?TId,
	minDate: string,
): $ReadOnlyArray<TQueryConstraints> => [
	{ scope: ['==', scope], scopeId: ['==', scopeId], removedAt: ['==', null] },
	{ scope: ['==', scope], scopeId: ['==', scopeId], removedAt: ['>', minDate] },
];

const syncTasks = {};

const subscribe = (
	constraints: TQueryConstraints,
	emit: ({ specs: TSpecs }) => void,
	userId: ?TId,
): (() => void) => {
	let widgets = collection('specs').where('userId', '==', userId);
	_.forEach(([k, v]: [string, TQueryConstraint]) => {
		widgets = widgets.where(k, v[0], v[1]);
	}, _.entries(constraints));

	return widgets.onSnapshot((snapshot: any) => {
		const specs = {};
		// Если изменения локальные
		if (snapshot.metadata.hasPendingWrites) return;
		snapshot.docChanges().forEach((change: any) => {
			const spec = change.doc.data();
			specs[spec._id] = spec;
		});
		if (_.size(specs)) {
			emit({ specs });
		}
	});
};

/**
	Коллаборация
 */
const createDbChannel = (
	scope: string,
	scopeId: ?TId,
	userId: ?TId,
): EventChannel<{ specs: TSpecs }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ specs: TSpecs }>(
		(emit: ({ specs: TSpecs }) => void): (() => void) => {
			const query = buildQuery(scope, scopeId, minDate);
			const unsubscriptors: $ReadOnlyArray<
				() => void,
			> = query.map((constraints: TQueryConstraints): (() => void) =>
				subscribe(constraints, emit, userId),
			);
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

export function* watchDbSaga({ dbEventChannel }: TSyncParams): Saga<void> {
	try {
		while (!0) {
			const { specs } = yield take(dbEventChannel);
			const specAll: TSpecs = yield select(getSpecs);

			const updated: TSpecs = _.pickBy(
				(spec: TSpec): boolean =>
					!specAll[spec._id] || specAll[spec._id].updatedAt !== spec.updatedAt,
				specs,
			);
			if (_.size(updated)) {
				yield put(historyNewAction({ specs: updated }));
			}
		}
	} catch (e) {
		logger.error(e);
	} finally {
		if (dbEventChannel) {
			dbEventChannel.close();
		}
	}
}

export function* syncScopedSpecsSaga(): Saga<void> {
	yield takeEvery(SYNC_SCOPED_SPECS, function*({
		payload: { scope, scopeId, userId, key },
	}: {
		payload: { scope: string, scopeId: ?TId, userId: ?TId, key: ?string },
	}): Saga<void> {
		const taskId = `${scope}-${scopeId || ''}-${userId || ''}-${key || ''}`;
		// Cancel the previous task
		if (syncTasks[taskId]) {
			yield cancel(syncTasks[taskId]);
		}

		const dbEventChannel = createDbChannel(scope, scopeId, userId);
		if (!dbEventChannel) {
			return;
		}
		syncTasks[taskId] = yield fork(watchDbSaga, { dbEventChannel });
	});
}

export function* unsyncScopedSpecsSaga(): Saga<void> {
	yield takeEvery(UNSYNC_SCOPED_SPECS, function*({
		payload: { scope, scopeId, userId, key },
	}: {
		payload: { scope: string, scopeId: ?TId, userId: ?TId, key: ?string },
	}): Saga<void> {
		const taskId = `${scope}-${scopeId || ''}-${userId || ''}-${key || ''}`;
		// Cancel the previous task
		if (syncTasks[taskId]) {
			yield cancel(syncTasks[taskId]);
		}
		syncTasks[taskId] = null;
	});
}

export function* saveSpecsSaga(): Saga<void> {
	yield takeEvery(SAVE_SPECS, function*({
		payload: { specs },
	}: {
		payload: {
			specs: TSpecs,
		},
	}): Saga<void> {
		try {
			// if change specs need update modificate date
			// for right working collaboration and etc.
			const update: TSpecs = _.reduce(
				(update: TSpecs, spec: TSpec): TSpecs => {
					update[spec._id] = {
						...spec,
						updatedAt: new Date().toISOString(),
					};
					return update;
				},
				{},
				specs,
			);
			yield all([
				call(commitSpecsToBd, update),
				// FixMe: переделать на call
				// чтобы можно было ловить ошибки внизу
				put(historyNewAction({ specs: update })),
			]);
		} catch (e) {
			// FixMe: тут по хорошему нужно обработать ошибку
			// если мы сюда попали, значит
			// или упало сохранение на сервер, или упало сохранение в стейт
			// оба варианта плохи.
			// Поэтому в идеале нужно с сервера подтянуть актуальный стейт
			// и полностью его обновить на клиенте.
			logger.error(e);
		}
	});
}

export function* updateSpecsSaga(): Saga<void> {
	yield takeEvery(UPDATE_SPECS, function*({
		payload: { specs },
	}: {
		payload: { specs: TSpecs },
	}): Saga<void> {
		yield put(saveSpecs(specs));
	});
}

export function* saga(): Saga<void> {
	yield fork(updateSpecsSaga);
	yield fork(saveSpecsSaga);
	yield fork(syncScopedSpecsSaga);
	yield fork(unsyncScopedSpecsSaga);
}

export default handleActions<$ReadOnly<TSpecs>, TAction>(
	{
		[APPLY](
			state: $ReadOnly<TSpecs>,
			{ payload: { specs } }: { +payload: { +specs: TSpecs } },
		): $ReadOnly<TSpecs> {
			return _.pickBy(_.identity, _.assign(state, specs));
		},
	},
	initialState,
);
