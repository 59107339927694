// @flow

import React from 'react';

import { useAnimatePlayContext, AnimatePlayContext } from './libs/animatePlayReducer';

type TProps = $ReadOnly<{|
	children: React$Node,
|}>;

function PlayProvider({ children }: TProps) {
	const data = useAnimatePlayContext();
	return (
		<AnimatePlayContext.Provider value={data}>{children}</AnimatePlayContext.Provider>
	);
}

export default React.memo<TProps>(PlayProvider);
