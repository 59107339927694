// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useInjectReducer } from 'libs/inject-reducer';

import { themes } from '@graphite/uneon';
import { Helmet } from 'react-helmet';
import { config } from '@graphite/constants';

import { useDispatch, useSelector } from '@graphite/use-redux';
import {
	getCurrentUser,
	getAuthError,
	getIsAuth,
	getCurrentUserId,
	getCurrentSiteId,
	getCurrentProjectId,
	getCurrentPageId,
} from '@graphite/selectors';
import ComponentContext from 'ComponentContext';
import Widget from 'Widget';
import CursorProvider from 'CursorProvider';
import { anyKind } from 'Widget/libs';
import type { TId } from '@graphite/types';
import TopBar from './TopBar';
import Login from '../Login';
import LoginToken from '../LoginToken';
import SignUp from '../SignUp';
import Loader from '../Loader';
import Notices from './Notices';
import Render from '../Render';
import Page from '../Widget/Widgets/Page/Edit';

import { saga as sagaEditor, removeNotice } from './ducks/editor';
import {
	saga as sagaUsers,
	signInWithToken,
	signInWithGoogle,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
} from './ducks/users';
import { saga as sagaSpecs, syncScopedSpecs, unsyncScopedSpecs } from './ducks/specs';
import { saga as sagaQueue  } from './ducks/queue';

import imageLibraryReducer, { saga as sagaImageLibrary } from './ducks/imageLibrary';
import {
	saga as sagaWidgets,
	syncScopedWidgets,
	unsyncScopedWidgets,
} from './ducks/widgets';
import { saga as sagaHistory } from './ducks/history';

import { useInjectSaga } from '../libs/inject-saga';

import PtRootUiMediumEot from './fonts/pt_root_ui_medium.eot';
import PtRootUiMediumWoff2 from './fonts/pt_root_ui_medium.woff2';
import PtRootUiMediumWoff from './fonts/pt_root_ui_medium.woff';
import PtRootUiMediumTtf from './fonts/pt_root_ui_medium.ttf';

import PtRootUiBoldEot from './fonts/pt_root_ui_bold.eot';
import PtRootUiBoldWoff2 from './fonts/pt_root_ui_bold.woff2';
import PtRootUiBoldWoff from './fonts/pt_root_ui_bold.woff';
import PtRootUiBoldTtf from './fonts/pt_root_ui_bold.ttf';

type TProps = $ReadOnly<{||}>;

const PublishedBox = styled.div`
	margin-top: 54px;
`;

const globalStyle = css`
	@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@800;900&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

	@font-face {
		font-family: 'PTRootUIWeb';
		src: url('${PtRootUiMediumEot}');
		src: url('${PtRootUiMediumEot}?#iefix') format('embedded-opentype'),
			url('${PtRootUiMediumWoff2}') format('woff2'),
			url('${PtRootUiMediumWoff}') format('woff'),
			url('${PtRootUiMediumTtf}') format('truetype');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'PTRootUIWeb';
		src: url('${PtRootUiBoldEot}');
		src: url('${PtRootUiBoldEot}?#iefix') format('embedded-opentype'),
			url('${PtRootUiBoldWoff2}') format('woff2'),
			url('${PtRootUiBoldWoff}') format('woff'),
			url('${PtRootUiBoldTtf}') format('truetype');
		font-weight: 700;
		font-style: normal;
	}
`;

const Editor = () => {
	useInjectSaga({ key: 'editor', saga: sagaEditor });
	useInjectSaga({ key: 'users', saga: sagaUsers });
	useInjectSaga({ key: 'specs', saga: sagaSpecs });
	useInjectSaga({ key: 'queue', saga: sagaQueue });
	useInjectSaga({ key: 'widgets', saga: sagaWidgets });
	useInjectSaga({ key: 'constructorHistory', saga: sagaHistory });
	useInjectSaga({ key: 'imageLibrary', saga: sagaImageLibrary });

	useInjectReducer({ key: 'imageLibrary', reducer: imageLibraryReducer });

	const dispatch = useDispatch();

	const signInWithTokenHandler = React.useCallback(() => {
		dispatch(signInWithToken());
	}, [dispatch]);
	const signInWithGoogleHandler = React.useCallback(
		(e) => {
			e.preventDefault();
			dispatch(signInWithGoogle());
		},
		[dispatch],
	);
	const signInWithEmailAndPasswordHandler = React.useCallback(
		({ email, password }) => {
			dispatch(signInWithEmailAndPassword(email, password));
		},
		[dispatch],
	);
	const createUserWithEmailAndPasswordHandler = React.useCallback(
		({ email, password, name }) => {
			dispatch(createUserWithEmailAndPassword(email, password, name));
		},
		[dispatch],
	);

	const authError = useSelector(getAuthError);
	const isAuth = useSelector(getIsAuth);
	const userId = useSelector(getCurrentUserId);
	const user = useSelector(getCurrentUser);
	const isFetching = useSelector((state) => state.users.isFetching);

	const notices = useSelector((state) => state.editor.notices);
	const delNotice = React.useCallback(
		(id) => {
			dispatch(removeNotice(id));
		},
		[dispatch],
	);

	React.useEffect(() => {
		if (userId) {
			dispatch(syncScopedWidgets('user', userId, userId));
		}

		return () => {
			if (userId) {
				dispatch(unsyncScopedWidgets('user', userId, userId));
			}
		};
	}, [dispatch, userId]);

	const projectId: ?TId = useSelector(getCurrentProjectId);
	const siteId: ?TId = useSelector(getCurrentSiteId);
	const pageId: ?TId = useSelector(getCurrentPageId);

	React.useEffect(() => {
		if (!userId) return;

		if (projectId) {
			dispatch(syncScopedWidgets('project', projectId, userId, 'editor'));
		}
		if (siteId) {
			dispatch(syncScopedWidgets('site', siteId, userId, 'editor'));
			dispatch(syncScopedSpecs('site', siteId, userId, 'editor'));
		}
		if (pageId) {
			dispatch(syncScopedWidgets('page', pageId, userId, 'editor'));
			dispatch(syncScopedSpecs('page', pageId, userId, 'editor'));
		}

		return () => {
			if (!userId) return;

			if (projectId) {
				dispatch(unsyncScopedWidgets('project', projectId, userId, 'editor'));
			}
			if (siteId) {
				dispatch(unsyncScopedWidgets('site', siteId, userId, 'editor'));
				dispatch(unsyncScopedSpecs('site', siteId, userId, 'editor'));
			}
			if (pageId) {
				dispatch(unsyncScopedWidgets('page', pageId, userId, 'editor'));
				dispatch(unsyncScopedSpecs('page', pageId, userId, 'editor'));
			}
		};
	}, [dispatch, pageId, projectId, siteId, userId]);

	if (isFetching || (isAuth && !user))
		return (
			<ThemeProvider theme={themes.light}>
				<Loader />
			</ThemeProvider>
		);

	return (
		<ThemeProvider theme={themes.light}>
			<Global styles={globalStyle} />
			{config.robots && (
				<Helmet>
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
			)}
			<ComponentContext.Provider value={anyKind('get-edit-component')}>
				<Switch>
					<Route exact path="/login-token">
						<LoginToken signInWithToken={signInWithTokenHandler} />
					</Route>
					<Route exact path="/login">
						{!user ? (
							<Login
								signInWithGoogle={signInWithGoogleHandler}
								signInWithEmailAndPassword={
									signInWithEmailAndPasswordHandler
								}
								error={authError}
								isAuth={isAuth}
							/>
						) : (
							<Redirect to="/" />
						)}
					</Route>
					<Route exact path="/signup">
						{!user ? (
							<SignUp
								signInWithGoogle={signInWithGoogleHandler}
								createUserWithEmailAndPassword={
									createUserWithEmailAndPasswordHandler
								}
								error={authError}
								isAuth={isAuth}
							/>
						) : (
							<Redirect to="/" />
						)}
					</Route>
					<Route path="/project/:projectId/site/:siteId">
						{user ? (
							<ThemeProvider theme={themes.dark}>
								<CursorProvider>
									<TopBar />
									<Switch>
										<Route
											exact
											path="/project/:projectId/site/:siteId/page/:pageId"
											component={Page}
										/>
										<Route path="/project/:projectId/site/:siteId/page/:pageId/preview">
											<PublishedBox>
												<Render />
											</PublishedBox>
										</Route>
										<Route path="" component={Loader} />
									</Switch>
								</CursorProvider>
							</ThemeProvider>
						) : (
							<Redirect to="/login" />
						)}
					</Route>
					<Route path="/">
						{user ? (
							<Widget
								containerId={null}
								instanceId={null}
								originId={user._id}
								id={user._id}
							/>
						) : (
							<Redirect to="/login" />
						)}
					</Route>
				</Switch>
			</ComponentContext.Provider>
			<Notices data={notices} close={delNotice} />
		</ThemeProvider>
	);
};

export default React.memo<TProps>(Editor);
