// @flow
import { useEffect, useMemo } from 'react';
import { listForFontsLib } from '@graphite/constants';
import type { TFonts } from '@graphite/types';

type TOptions = $ReadOnly<{|
	ref: React$ElementRef<any>,
|}>;

const useGoogleFonts = (fonts: ?TFonts, { ref }: TOptions) => {
	const fontsGoogle = useMemo(
		() =>
			fonts &&
			fonts.filter(
				font =>
					font.family &&
					!listForFontsLib.find(
						conf => conf.name === font.family || conf.family === font.family,
					),
			),
		[fonts],
	);

	useEffect(() => {
		if (typeof ref.current === 'undefined') {
			throw new Error('Ref is required');
		}

		// eslint-disable-next-line global-require
		const { loadFonts } = require('../GoogleFonts/driver');

		const documentCurrent = ref.current?.ownerDocument;
		if (fontsGoogle && document)
			loadFonts({ fonts: fontsGoogle, document: documentCurrent });
	}, [fontsGoogle, ref]);
};

export default useGoogleFonts;
