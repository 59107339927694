// @flow
import React from 'react';
import emptyArray from 'empty/array';
import { useTranslation } from 'react-i18next';
import { Section } from '@graphite/uneon';
import logger from '@graphite/logger';
import { defaultCommonEffect, defaultShadow } from '@graphite/constants';
import useBreakpoint from '@graphite/use-breakpoint';

import {
	Color as SectionColor,
	Background as SectionBackground,
	Shadow as SectionShadow,
} from '@graphite/sections';
import type {
	TDesignCommonEffect,
	TBackgroundValues,
	TColorValue,
	TDesignColorProps,
	TShadowValue,
	TShadowValues,
} from '@graphite/types';


const defaultBorderItem: TColorValue = {
	kind: 'color',
	value: 'bg.accent',
};

const defaultBgItem: TColorValue = {
	kind: 'color',
	value: 'bg.primary',
};

const defaultBgShadowItem: TShadowValue = {
	kind: 'shadow',
	value: defaultShadow,
};

function Color({
	design,
	device,
	colorspec,
	gridspec,
	onChange = null,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignColorProps) {
	const { t } = useTranslation();
	const { breakpoint, changeBreakpoint } = useBreakpoint(onChange, design, device);

	const [stateName] = React.useState('normal');
	const stateData: TDesignCommonEffect =
		breakpoint[stateName || 'normal'] || defaultCommonEffect;

	const bgSource: TBackgroundValues = stateData.container.background || emptyArray;
	const bgShadowSource: TShadowValues = stateData.container.shadow || emptyArray;

	const borderSource: ?TColorValue = React.useMemo(
		() =>
			stateData.container.border
				? { kind: 'color', value: stateData.container.border }
				: null,
		[stateData.container.border],
	);

	const bgChange = React.useCallback(
		(background: TBackgroundValues) => {
			if (!stateName) {
				return;
			}
			changeBreakpoint({
				...breakpoint,
				[stateName]: {
					...stateData,
					container: {
						...stateData.container,
						background,
					},
				},
			});
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	const bgShadowChange = React.useCallback(
		(shadow: TShadowValues) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							shadow,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	const borderChange = React.useCallback(
		(color: ?TColorValue) => {
			if (!stateName) {
				return;
			}
			changeBreakpoint({
				...breakpoint,
				[stateName]: {
					...stateData,
					container: {
						...stateData.container,
						border: color ? color.value : null,
					},
				},
			});
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	return (
		<Section label={t('Сolor')}>
			<SectionBackground
				t={t}
				label={t('Background')}
				source={bgSource}
				defaultItem={defaultBgItem}
				colorspec={colorspec}
				gridspec={gridspec}
				onChange={bgChange}
				isSeparated
				genId={genId}
				images={images}
				insertImage={insertImage}
				resetImage={resetImage}
				removeImage={removeImage}
				uploads={uploads}
			/>
			<SectionColor
				t={t}
				label={t('Border Color')}
				source={borderSource}
				defaultItem={defaultBorderItem}
				colorspec={colorspec}
				gridspec={gridspec}
				onChange={borderChange}
				genId={genId}
				isSeparated
			/>
			<SectionShadow
				t={t}
				genId={genId}
				gridspec={gridspec}
				label={t('Shadow')}
				source={bgShadowSource}
				defaultItem={defaultBgShadowItem}
				colorspec={colorspec}
				onChange={bgShadowChange}
			/>
		</Section>
	);
}

export default React.memo<TDesignColorProps>(Color);
