// @flow
import React from 'react';
import styled from '@emotion/styled';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { CacheProvider, Global, css } from '@emotion/core';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';
import logger from '@graphite/logger';

import Widget from 'Widget';
import type { TWidget, TId } from '@graphite/types';

import previewImage from '../img/site-preview.inline.svg';

const memoizedCreateCacheWithContainer = weakMemoize((container) =>
	createCache({ container }),
);

type TProps = $ReadOnly<{|
	containerId: TId,
	site: TWidget,
|}>;

const FrameStyled = styled(Frame)`
	width: 100%;
	height: calc(100% - 70px);
	margin-top: 70px;
	overflow: hidden;
	background-image: url(${previewImage});
`;

const IFrameStyled = styled.iframe`
	width: 100%;
	height: calc(100% - 70px);
	margin-top: 70px;
	overflow: hidden;
`;

const Preset = ({ site, containerId }: TProps) => {
	React.useEffect(() => {
		logger.info('previewTemplate', { template: site?.name });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onLoadHandler = React.useCallback(
		(e) => {
			function doSomething() {
				const sheet = document.createElement('style');
				sheet.innerHTML = 'html,body{min-height:100vh;background:#fff;}';
				document.body?.appendChild(sheet);
			}

			e.target.contentWindow.postMessage(
				encodeURI(doSomething.toString()),
				site.url,
			);
		},
		[site.url],
	);

	return site.url ? (
		<IFrameStyled
			data-kind="preview-preset-iframe-published"
			src={site.url}
			onLoad={onLoadHandler}
		/>
	) : (
		<FrameStyled data-kind="preview-preset-iframe-render">
			<FrameContextConsumer>
				{({ document }) => (
					<CacheProvider
						value={memoizedCreateCacheWithContainer(document.head)}
					>
						<Global
							styles={css`
								html,
								body {
									background: #fff;
								}
							`}
						/>
						<Widget
							id={site._id}
							containerId={containerId}
							instanceId={null}
							originId={containerId}
						/>
					</CacheProvider>
				)}
			</FrameContextConsumer>
		</FrameStyled>
	);
};

export default React.memo<TProps>(Preset);
