// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box, Text, PopupWhite, ButtonGroup, Section } from '@graphite/uneon';
import type { TAnimation } from '@graphite/types';
import Ease from './Ease';
import Spring from './Spring';
import Repeat from './Repeat';
import Sensitivity from './Sensitivity';
import PlayButton from '../PlayButton';

type TProps = $ReadOnly<{|
	t: (string) => string,
	trigger: string,
	params: TAnimation,
	handleClose: () => void,
	anchorEl: {| current: ?React$ElementRef<any> |},
	changeEffect: (TAnimation) => void,
	changeTransitionType: (string) => void,
|}>;

const wrapBoxSx = {
	display: 'flex',
	flexDirection: 'column',
};

const wrapTabSx = {
	marginTop: '22px',
};

const wrapContentSx = {
	marginBottom: '12px',
};

const tabSx = {
	marginTop: '6px',
};

const TransitionPanelComponent = ({
	t,
	params,
	trigger,
	handleClose,
	anchorEl,
	changeEffect,
	changeTransitionType,
}: TProps) => {
	const tabList = React.useMemo(
		() => [
			{ label: t('Easing'), name: 'tween' },
			{ label: t('Spring'), name: 'spring' },
		],
		[t],
	);

	const clickBound = React.useCallback(
		(e, name) => {
			if (typeof name === 'string') {
				const tab = tabList.find((item) => item.name === name) || tabList[0];

				if (tab.name !== params.transition?.type) {
					changeTransitionType(tab.name);
					return;
				}

				changeEffect(_.set('transition.type', tab.name, params));
			}
		},
		[tabList, changeEffect, changeTransitionType, params],
	);

	return (
		<PopupWhite
			isOpen
			onClose={handleClose}
			mutex="animate"
			width={288}
			offsetLeft={64}
			anchorEl={anchorEl}
		>
			<Box sx={wrapBoxSx}>
				<Text variant="title5" color="text.primaryalt">
					{t('Playback')}
				</Text>
				<Section size="sm" label={t('Transition')} sx={wrapTabSx}>
					<Box sx={tabSx}>
						<ButtonGroup
							behavior="radio"
							active={params.transition?.type || 'tween'}
							buttons={tabList}
							variant="normal"
							shape="rounded"
							size="sm"
							colors="accentghost"
							activeColors="accent"
							onClick={clickBound}
						/>
					</Box>
				</Section>
				<Box sx={wrapContentSx}>
					{params.transition?.type === 'tween' && (
						<Ease t={t} changeEffect={changeEffect} params={params} />
					)}
					{params.transition?.type === 'spring' && (
						<Spring t={t} changeEffect={changeEffect} params={params} />
					)}
					{trigger === 'appear' && (
						<Sensitivity t={t} params={params} changeEffect={changeEffect} />
					)}
					{trigger !== 'click' && (
						<Repeat
							trigger={trigger}
							t={t}
							params={params}
							changeEffect={changeEffect}
						/>
					)}
				</Box>
				<PlayButton t={t} params={params} />
			</Box>
		</PopupWhite>
	);
};

export default React.memo<TProps>(TransitionPanelComponent);
