// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Button, Flex, Box, Text, ModalDialog } from '@graphite/uneon';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { Transition } from 'react-transition-group';
import logger from '@graphite/logger';
import type { TId, TWidgets, TWidget, TWidgetDiff } from '@graphite/types';

import Presets from './Presets';
import EmptySite from './EmptySite';
import Site from './Site';

type TProps = $ReadOnly<{|
	id: TId,
	containerId: TId,
	project: TWidget,
	sites: TWidgets,
	presets: TWidgets,
	t: (string) => string,
	onRemove: (TId, ?TId) => void,
	onCreateSite: (TId, ?TId) => void,
	onUpdate: (TId, TWidgetDiff) => void,
	onSelect: (TId) => void,
	onCreatePreset: (TId) => void,
|}>;

const headerSx = {
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	marginBottom: '30px',
	paddingBottom: '16px',
	borderBottomWidth: '2px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'text.primaryalt',
};

const headersButtonSx = {
	marginLeft: '18px',
};

const sitesSx = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
};

const transitionStyles = {
	entering: 1,
	entered: 1,
	exiting: 0,
	exited: 0,
};
const StyledPresets = styled(Presets)`
	transition: opacity 250ms ease-in-out;
	opacity: ${({ state }) => transitionStyles[state]};
`;

const Project = ({
	id,
	containerId,
	project,
	sites,
	presets,
	t,
	onRemove,
	onCreateSite,
	onCreatePreset,
	onUpdate,
	onSelect,
}: TProps) => {
	const [isShowPresets, setIsShowPresets] = React.useState<boolean>(false);
	const [isShowModalDialog, setIsShowModalDialog] = React.useState<boolean>(false);

	const toggleModalDialog = React.useCallback(() => {
		setIsShowModalDialog(!isShowModalDialog);
	}, [isShowModalDialog]);

	const onRemoveHandler = React.useCallback(() => {
		onRemove(id, containerId);
	}, [containerId, id, onRemove]);

	const onRemoveSiteHandler = React.useCallback(
		(id) => {
			onRemove(id, containerId);
		},
		[containerId, onRemove],
	);

	const onShowPresetsHandler = React.useCallback(() => {
		setIsShowPresets((isShowPresets) => {
			if (!isShowPresets) logger.info('openTemplates');
			return !isShowPresets;
		});
	}, []);

	return (
		<Box data-kind="project-sites">
			<Flex data-kind="project-control" sx={headerSx}>
				<Text as="h4" variant="title4" color="text.primaryalt">
					{project.name}
				</Text>
				<Flex>
					<Button
						data-kind="project-control-remove"
						variant="primaryaltflat.flat.sm"
						onClick={toggleModalDialog}
						sx={headersButtonSx}
					>
						{t('dashboard.project.remove')}
					</Button>
					{isShowModalDialog && (
						<ModalDialog
							onClose={toggleModalDialog}
							headingText={t('Remove project')}
							withActionBtn
							actionBtnText={t('Remove')}
							actionBtnKind="negative"
							withCancelBtn
							onActionHandler={onRemoveHandler}
						>
							<Box>
								<Text as="span" variant="bodymd" color="text.primaryalt">
									<Trans i18nKey="All project websites will be: – deleted – unpublished – disconnected from domains This action cannot be undone.">
										All project websites will be:
										<br />
										– deleted
										<br />
										– unpublished
										<br />
										– disconnected from domains
										<br />
										<br />
										This action cannot be undone.
									</Trans>
								</Text>
							</Box>
						</ModalDialog>
					)}
				</Flex>
			</Flex>

			<Box data-kind="project-sites" variant="grid" sx={sitesSx}>
				{_.map(
					(site) => (
						<Site
							key={site._id}
							site={site}
							containerId={id}
							onRemove={onRemoveSiteHandler}
							onSelect={onSelect}
							onUpdate={onUpdate}
							createPreset={onCreatePreset}
						/>
					),
					sites,
				)}
				<EmptySite
					insertSiteHandler={onShowPresetsHandler}
					isEmptySite={_.isEmpty(sites)}
				/>
			</Box>

			<Transition in={isShowPresets} timeout={250} unmountOnExit>
				{(state) => (
					<StyledPresets
						state={state}
						t={t}
						id={id}
						presets={presets}
						onCreateSite={onCreateSite}
						onShowPresetsHandler={onShowPresetsHandler}
					/>
				)}
			</Transition>
		</Box>
	);
};

export default React.memo<TProps>(Project);
