// @flow
import React from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import findLast from 'lodash/fp/findLast';
import { getActiveBreakpointNames, closestDeviceWithKey } from '@graphite/selectors';
import { defaultDevice } from '@graphite/constants';
import type {
	Dispatch,
	TAction,
	TWidgetComposed,
	TSpecsGrid,
	TWidgetMode,
	TGridBreakpointName,
	TGridBreakpointNames,
	TAnimations,
} from '@graphite/types';

import { filterBox } from './libs/selectors';

import Scroll from './Scroll';
import Appear from './Appear';
import Mouse from './Mouse';
import Play from './Play';

type TMinimalProps = $ReadOnly<{|
	dispatch: Dispatch<TAction>,
	gridspec: ?TSpecsGrid,
	data: TWidgetComposed,
	widgetMode?: TWidgetMode,
|}>;

const getMediaQueryDevice = (gridspec: ?TSpecsGrid): TGridBreakpointName => {
	// есть вероятность, что gridspec может не быть
	// если у нас нет window, то вернем дефолтный девайс
	if (typeof window === 'undefined' || !gridspec) return defaultDevice;
	const { breakpoints } = gridspec;
	const activeBreakpoints: TGridBreakpointNames = getActiveBreakpointNames({
		gridspec,
	});

	return (
		findLast((breakpoint: TGridBreakpointName) => {
			const width = breakpoints?.[breakpoint]?.breakpoint;
			if (width && window?.matchMedia(`(max-width: ${width}px)`).matches)
				return true;

			return false;
		}, activeBreakpoints) || defaultDevice
	);
};

const withAnimation = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{| ...$Exact<T> |}>> => {
	const WidgetWrapper = (props: T, ref) => {
		const { data, gridspec, widgetMode } = props;
		const [device, setDevice] = React.useState(() => getMediaQueryDevice(gridspec));
		const isConstructor = !!widgetMode;

		const handleDevice = React.useCallback(() => {
			if (isConstructor) return;
			setDevice(getMediaQueryDevice(gridspec));
		}, [gridspec, isConstructor]);

		React.useEffect(() => {
			window.addEventListener('resize', handleDevice);

			return () => {
				window.removeEventListener('resize', handleDevice);
			};
		}, [isConstructor, handleDevice]);

		const animate = React.useMemo(() => {
			if (isConstructor) return {};
			const animateBox: TAnimations = closestDeviceWithKey(data.animation, {
				isDeep: true,
				currentDevice: device,
				key: `animation-${data._id}`,
			});

			return filterBox(animateBox);
		}, [isConstructor, device, data.animation, data._id]);

		if (isConstructor)
			return (
				<LazyMotion features={domAnimation}>
					<Play>
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<Component {...props} ref={ref} />
					</Play>
				</LazyMotion>
			);

		return (
			<LazyMotion features={domAnimation}>
				<Scroll data={animate}>
					<Appear data={animate}>
						<Mouse type="hover" data={animate} widgetId={data._id}>
							<Mouse type="click" data={animate} widgetId={data._id}>
								{/* eslint-disable-next-line react/jsx-props-no-spreading */}
								<Component {...props} ref={ref} />
							</Mouse>
						</Mouse>
					</Appear>
				</Scroll>
			</LazyMotion>
		);
	};

	return React.memo(React.forwardRef(WidgetWrapper));
};

export default withAnimation;
