// @flow
import React from 'react';
import styled from '@emotion/styled';
import emptyArray from 'empty/array';
import _ from 'lodash/fp';
import logger from '@graphite/logger';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Flex, Box, Button, Text, IconClear } from '@graphite/uneon';
import { useDispatch, useSelector } from '@graphite/use-redux';
import {
	getTrueIds,
	getCurrentPageId,
	getWidget,
	getCurrentProjectId,
} from '@graphite/selectors';
import { getCurrentDevice } from 'Editor/selectors/editor';
import {
	moveWidget,
	placeWidget,
	removeWidget,
	cloneWidget,
	editWidget,
} from 'Editor/ducks/widgets';
import type { TStateEditor, TId, TWidget } from '@graphite/types';

import Item from './Item';

const BoxItems = styled(Box)`
	margin: 20px -24px 30px;
`;

type TProps = $ReadOnly<{|
	siteId: TId,
|}>;

const preventDefault = (e: DragEvent) => e.preventDefault();

const placeholder = new Image();
// eslint-disable-next-line max-len
placeholder.src = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`;

const Pages = ({ siteId }: TProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const currentDevice = useSelector(getCurrentDevice);
	const currentPageId = useSelector(getCurrentPageId);
	const currentProjectId = useSelector(getCurrentProjectId);
	const { t } = useTranslation();
	const site = useSelector((state: TStateEditor) => getWidget(state, { id: siteId }));

	const ids = React.useMemo(() => {
		if (!site) return emptyArray;
		return getTrueIds({ ...site, currentDevice });
	}, [currentDevice, site]);

	const pages = useSelector(({ widgets }) => _.pick(ids, widgets));

	React.useEffect(() => {
		logger.info('openPagesPanel');
	}, []);

	const onSelect = React.useCallback(
		id => {
			if (!currentProjectId) return;
			history.push(`/project/${currentProjectId}/site/${siteId}/page/${id}`);
			logger.info('pickPage');
		},
		[currentProjectId, history, siteId],
	);

	const addPage = React.useCallback(() => {
		// generate Page Name
		let pageNewNumber = 1;
		// find empty page number
		while (_.find(_.matchesProperty('name', `Page ${pageNewNumber}`), pages)) {
			++pageNewNumber;
		}
		const pageName = `Page ${pageNewNumber}`;

		// добавляем в начало
		const nextId = ids[0];

		dispatch(
			placeWidget(
				// ToDo: protoId вычислять из пресетов
				'page',
				siteId,
				// ToDo: instanceId надо докинуть?
				null,
				siteId,
				{
					kind: 'grid',
					prevId: null,
					nextId,
					destRect: null,
					dragRect: null,
					breakpoints: null,
				},
				{
					name: pageName,
				},
			),
		);
	}, [pages, ids, dispatch, siteId]);

	const removePage = React.useCallback(
		(id: string) => {
			const nextId = ids[ids.findIndex(page => page === id) + 1];

			if (nextId && id === currentPageId) {
				onSelect(nextId);
			} else {
				const prewId = ids[ids.findIndex(page => page === id) - 1];
				if (prewId && id === currentPageId) onSelect(prewId);
			}

			dispatch(
				removeWidget(
					id,
					siteId,
					// ToDo: instanceId надо докинуть?
					null,
					siteId,
				),
			);
		},
		[dispatch, siteId, ids, onSelect, currentPageId],
	);

	const clonePage = React.useCallback(
		(id: string) => {
			// ТЗ: Дублирование При дублировании страницы, она добавляется снизу от цели,
			// а к названию добавляется цифра 2. Если в конце уже есть цифра, то ей делаем +1.
			// Домашня страница при дублировании перестает быть домашней.

			// Берём текущую страницу
			let { name } = pages[id];
			let matches = name.match(/\d+$/);
			let number = 1;
			if (!matches) {
				name = `${name} ${number}`;
				matches = name.match(/\d+$/);
			}
			if (matches) {
				const numberStr = matches[0];
				const { index } = matches;
				number = parseInt(numberStr, 10) + 1;
				name = name.substring(0, index).trim();
				while (_.find(_.matchesProperty('name', `${name} ${number}`), pages)) {
					++number;
				}
			}

			const diff = { name: `${name} ${number}`, url: '' };

			dispatch(
				cloneWidget(
					id,
					siteId,
					// ToDo: instanceId надо докинуть?
					null,
					siteId,
					diff,
				),
			);
		},
		[dispatch, pages, siteId],
	);

	const updatePage = React.useCallback(
		(id: string, diff) => {
			dispatch(editWidget(id, null, siteId, diff));
		},
		[dispatch, siteId],
	);

	const makeHomePage = React.useCallback(
		(id: string) => {
			const prevHome: ?TWidget = _.find(_.matchesProperty('url')('index'), pages);

			// текущую домашную страницу нужно сделать
			if (prevHome)
				updatePage(prevHome._id, {
					url: '',
				});

			// выбранную страницу делаем домашней
			updatePage(id, {
				url: 'index',
			});
		},
		[updatePage, pages],
	);

	const [dragId, setDragId] = React.useState<?string>(null);
	const boxItemsRef = React.useRef<?HTMLDivElement>(null);
	const items = React.useRef<{ [string]: ?HTMLDivElement }>({});
	const [drop, setDrop] = React.useState({ targetId: null, side: null });

	const regItem = React.useCallback((id, ref) => {
		items.current[id] = ref;
	}, []);

	const unregItem = React.useCallback(id => {
		items.current[id] = null;
	}, []);

	const onDragEnterHandler = React.useCallback((e: DragEvent) => {
		e.preventDefault();

		// get target node
		const targetId: ?string = _.findKey(
			item =>
				item === e.target ||
				(e.target instanceof Node && item?.contains(e.target)),
			items.current,
		);
		if (!targetId) return;

		const target: ?HTMLDivElement = items.current[targetId];

		if (!target) return;

		// get position target node
		const domRect = target.getBoundingClientRect();

		// fetch the closest side of the target node
		const side = e.pageY - domRect.top < domRect.bottom - e.pageY ? 'top' : 'bottom';

		setDrop({
			targetId,
			side,
		});
	}, []);

	const onDragStartHandler = React.useCallback(
		(e: DragEvent) => {
			const { target, dataTransfer } = e;
			if (!dataTransfer || !(target instanceof HTMLElement)) return;

			const widgetId = target.getAttribute('data-id');
			const boxItemsRefCurrent = boxItemsRef.current;
			if (!boxItemsRefCurrent || !widgetId) return;

			dataTransfer.effectAllowed = 'copyMove';
			dataTransfer.setDragImage(placeholder, 1, 1);

			boxItemsRefCurrent.addEventListener('dragenter', onDragEnterHandler, false);
			boxItemsRefCurrent.addEventListener('dragover', preventDefault, false);

			setDragId(widgetId);
		},
		[onDragEnterHandler],
	);

	const onDragEndHandler = React.useCallback(
		e => {
			e.preventDefault();

			const currentDragId = dragId;
			const boxItemsRefCurrent = boxItemsRef.current;
			if (!boxItemsRefCurrent || !currentDragId) return;
			boxItemsRefCurrent.removeEventListener('dragenter', onDragEnterHandler);
			boxItemsRefCurrent.removeEventListener('dragover', preventDefault);

			setDragId(null);
			setDrop({ targetId: null, side: null });

			let nextId = null;
			let prevId = null;

			if (drop.side === 'top') {
				nextId = drop.targetId;
			} else {
				prevId = drop.targetId;
			}

			if (drop.targetId === currentDragId) return;

			dispatch(
				moveWidget(
					currentDragId, // srcId
					siteId, // srcContainerId
					siteId, // destContainerId
					null, // destInstanceId
					siteId, // destOriginId
					{
						kind: 'grid',
						nextId,
						prevId,
						destRect: null,
						dragRect: null,
						breakpoints: null,
					},
					currentDevice,
				),
			);
		},
		[
			currentDevice,
			dispatch,
			dragId,
			drop.side,
			drop.targetId,
			onDragEnterHandler,
			siteId,
		],
	);

	return (
		<>
			<Text as="h2" variant="title4" color="text.primaryalt">
				{t('Site')}
			</Text>
			<Flex justifyContent="space-between" mt="39px">
				<Text as="h3" variant="title6" color="text.primaryalt">
					{t('Pages')}
				</Text>
				<Button variant="primaryflat.icon.sm" onClick={addPage} mr="-6px">
					<IconClear name="plus-18" />
				</Button>
			</Flex>

			<BoxItems
				ref={boxItemsRef}
				onDragStart={onDragStartHandler}
				onDragEnd={onDragEndHandler}
			>
				{_.map(page => (
					<Item
						key={page._id}
						isDrag={!!dragId}
						isActive={currentPageId === page._id}
						dropSide={
							dragId &&
							dragId !== page._id &&
							drop.targetId === page._id &&
							drop.side
								? drop.side
								: null
						}
						page={page}
						regItem={regItem}
						unregItem={unregItem}
						remove={removePage}
						clone={clonePage}
						update={updatePage}
						onSelect={onSelect}
						makeHomePage={makeHomePage}
					/>
				))(pages)}
			</BoxItems>
		</>
	);
};

export default React.memo<TProps>(Pages);
