// @flow

import _ from 'lodash/fp';
import emptyObject from 'empty/object';

import refineOrder from '@graphite/refine-order';

import { defaultDevice } from '@graphite/constants';
import type {
	TId,
	TGridBreakpointName,
	TOrder,
	TWidgetDiff,
	TOrderDevice,
	TEntityChildren,
	TRowInfo,
	TWidgets,
	TWidget,
} from '@graphite/types';
import { preferDevice, closestDeviceWithKey } from './grid';
import reSelect from './libs/re-select';

export type TReorderParams = $ReadOnly<{|
	deviceOrder: TOrderDevice,
	deviceKey: TGridBreakpointName,
	prevId: ?TId,
	nextId: ?TId,
	newId: TId,
|}>;
export type TReorderFn = (TReorderParams) => TOrderDevice;

export type TGetOrderListFn = (
	widget: TWidget,
	currentDevice: TGridBreakpointName,
	widgets: TWidgets,
	orderList?: $ReadOnlyArray<TId>,
) => $ReadOnlyArray<TId>;

export type TGetOrderIndexParams = $ReadOnly<{|
	id: TId,
	currentPage: TId,
	widgets: TWidgets,
	currentDevice: TGridBreakpointName,
|}>;
export type TGetOrderIndexFn = (TGetOrderIndexParams) => number;

export const reorder: TReorderFn = reSelect<
	TReorderParams,
	TOrderDevice,
	TGridBreakpointName,
	?TId,
	?TId,
	TId,
	TOrderDevice,
>(
	({ deviceOrder }): TOrderDevice => deviceOrder,
	({ deviceKey }): TGridBreakpointName => deviceKey,
	({ prevId }): ?TId => prevId,
	({ nextId }): ?TId => nextId,
	({ newId }): TId => newId,
	(
		deviceOrder: TOrderDevice,
		deviceKey: TGridBreakpointName,
		prevId: ?TId,
		nextId: ?TId,
		newId: TId,
	) => {
		if (!_.size(deviceOrder)) {
			return { [newId]: 0 };
		}

		let counter: number = 0;
		const entriesOrders: $ReadOnlyArray<[TId, number]> = _.entries(deviceOrder);
		const sortedOrders: $ReadOnlyArray<[TId, number]> = _.sortBy(
			(item) => item[1],
			entriesOrders,
		).filter((item) => item[0] !== newId);

		let newOrder: TOrderDevice = sortedOrders.reduce(
			(result: TOrderDevice, item): TOrderDevice => {
				const [itemId] = item;

				if (prevId === itemId) {
					return {
						...result,
						// eslint-disable-next-line no-plusplus
						[itemId]: counter++,
						// eslint-disable-next-line no-plusplus
						[newId]: counter++,
					};
				}

				if (!prevId && nextId === itemId) {
					return {
						...result,
						// eslint-disable-next-line no-plusplus
						[newId]: counter++,
						// eslint-disable-next-line no-plusplus
						[itemId]: counter++,
					};
				}

				return ({
					...result,
					// eslint-disable-next-line no-plusplus
					[itemId]: counter++,
				}: TOrderDevice);
			},
			({}: TOrderDevice),
		);

		if (newId && newOrder[newId] === undefined) {
			// eslint-disable-next-line no-plusplus
			newOrder = ({ ...newOrder, [newId]: counter++ }: TOrderDevice);
		}

		return newOrder;
	},
)(({ deviceOrder, deviceKey, prevId, nextId, newId }) =>
	[
		'widget@reorder',
		deviceOrder,
		deviceKey,
		prevId || 'null',
		nextId || 'null',
		newId,
	].join('-'),
);

export type TGetOrderParams = $ReadOnly<{|
	widget: $ReadOnly<TWidgetDiff>,
	position: $ReadOnly<{
		kind: 'grid' | 'absolute',
		row?: TRowInfo,
		prevId?: ?TId,
		nextId?: ?TId,
	}>,
	newId: TId,
	currentDevice?: TGridBreakpointName,
|}>;
export type TGetOrderFn = (TGetOrderParams) => TWidgetDiff;

export const getOrder: TGetOrderFn = reSelect<
	TGetOrderParams,
	TOrder,
	TEntityChildren,
	$ReadOnly<{
		kind: 'grid' | 'absolute',
		row?: TRowInfo,
		prevId?: ?TId,
		nextId?: ?TId,
	}>,
	TId,
	?TGridBreakpointName,
	TWidgetDiff,
>(
	({ widget }) => widget.order || emptyObject,
	({ widget }) => widget.children || emptyObject,
	({ position }) => position,
	({ newId }) => newId,
	({ currentDevice }) => currentDevice,
	(order, children, position, newId, currentDevice) => {
		const { prevId = null, nextId = null } = position;

		const refinedOrder: TOrder = refineOrder(order, children, currentDevice);

		const finalDevice: TGridBreakpointName = preferDevice(
			Object.keys(refinedOrder),
			currentDevice,
		);

		const reordered: TOrderDevice = reorder({
			deviceOrder: refinedOrder[finalDevice],
			deviceKey: finalDevice,
			prevId,
			nextId,
			newId,
		});

		if (currentDevice === defaultDevice) {
			return ({ order: { [`${defaultDevice}`]: reordered } }: TWidgetDiff);
		}

		const newOrder = {
			...refinedOrder,
			[`${finalDevice}`]: reordered,
		};
		return ({ order: newOrder }: TWidgetDiff);
	},
)(({ position, newId, currentDevice }) =>
	[
		'widget@getOrder',
		position.kind || 'grid',
		position.kind && position.kind.includes('absolute')
			? `${position.prevId || 'null'}-${position.nextId || 'null'}`
			: 'null-null',
		currentDevice,
		newId,
	].join('-'),
);

const getOrderList: TGetOrderListFn = (
	{ children: _children, order: _order, _id },
	currentDevice,
	widgets,
	orderList = [],
) => {
	if (!_order || !_children) return orderList;

	const orderBox = closestDeviceWithKey(_order, {
		currentDevice,
		key: `order-${_id}`,
	});

	const order = _.map(
		(id) => ({
			id,
			widget: widgets[id],
			kind: widgets[id].kind,
			children: widgets[id].children,
			order: orderBox[id] || 0,
		}),
		_children,
	);

	const sortOrder = _.sortBy((o) => o.order, order);

	return [
		...orderList,
		..._.flatten(
			sortOrder.map(({ id, kind, children, widget }) => [
				...(kind !== 'stack' ? [id] : []),
				...(children
					? getOrderList(widget, currentDevice, widgets, orderList)
					: []),
				...(kind === 'stack' ? [id] : []),
			]),
		),
	];
};

export const getOrderIndex: TGetOrderIndexFn = reSelect<
	TGetOrderIndexParams,
	TId,
	TId,
	TWidgets,
	TGridBreakpointName,
	number,
>(
	({ id }) => id,
	({ currentPage }) => currentPage,
	// FixMe: этот селектор не работает
	({ widgets }) => widgets,
	({ currentDevice }) => currentDevice,
	(
		id: TId,
		currentPage: TId,
		widgets: TWidgets,
		currentDevice: TGridBreakpointName,
	) => {
		if (!widgets[currentPage]) return 0;

		const orderList = getOrderList(widgets[currentPage], currentDevice, widgets);
		const index = _.findIndex((_id) => id === _id, orderList);

		return parseInt(index, 10);
	},
)(({ id, currentPage, currentDevice }) =>
	['widget@getOrderIndex', id, currentPage, currentDevice].join('-'),
);

export default getOrder;
