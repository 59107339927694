// @flow

import find from 'lodash/fp/find';
import type { TId, TWidgetChain, TWidget, TWidgets } from '@graphite/types';

/**
 * Recursive find all parents
 * and return chain of parents id
 *
 * @param {*} id
 * @param {*} chains
 * @param {*} widgets
 */
const findWidgetChain = (
	id: TId,
	chains: TWidgetChain,
	widgets: TWidgets,
): TWidgetChain => {
	const finded: ?TWidget = find(
		(item: TWidget): boolean => !!item.children?.[id],
		widgets,
	);
	if (!finded || !finded.kind) return chains;

	const tmpChains: TWidgetChain = [finded._id].concat(chains);

	if (!['user', 'site'].includes(finded.kind))
		return findWidgetChain(finded._id, tmpChains, widgets);

	return tmpChains;
};

export default findWidgetChain;
