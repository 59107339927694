// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { Section, PopupWhite, Box } from '@graphite/uneon';
import { useDispatch } from '@graphite/use-redux';
import { Spec as SelectSpec } from '@graphite/selects';
import DesignPanel from '@graphite/design-panel';
import { useGoogleFonts } from '@graphite/fonts-lib';

import {
	getCurrentSiteId,
	getSpecs,
	getWidgetsByKind,
	getCurrentSiteGridSpec,
	getImageLibraryListUploads,
	getCurrentSiteWidgetSpec,
	getCurrentSiteColorSpec,
	getCurrentSiteEffectSpec,
} from '@graphite/selectors';
import {
	listForFontsLib,
	defaultDesignBreakpointText,
	defaultDesign,
	listMatchWeight,
} from '@graphite/constants';
import Color from '@graphite/design-color-text';
import Style from '@graphite/design-style-text';
import { genId } from 'libs/firebase';
import logger from '@graphite/logger';
import { getCurrentDevice } from 'Editor/selectors/editor';
import { editWidget } from 'Editor/ducks/widgets';
import { updateSpecs as updateSpecsOriginal } from 'Editor/ducks/specs';
import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';
import type {
	TId,
	TSpecs,
	TSpecsWidget,
	TSpecsGrid,
	TSpecsColor,
	TSpecsEffect,
	TDesign,
	TDesignSection,
	TGridBreakpointName,
	TSite,
	TWidgets,
	TImageLibraryListUploads,
} from '@graphite/types';

import ListFonts from './ListFonts';

type TProps = $ReadOnly<{|
	specs: TSpecs,
	widgetspec: TSpecsWidget,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
	colorspec: TSpecsColor,
	device: TGridBreakpointName,
	updateSpecs: TSpecs => void,
	updateSite: ($ReadOnly<$Shape<TSite>>) => void,
|}>;

const anchorBoxOuterStyle = {
	position: 'relative',
	margin: '0 -24px 0 -24px',
	height: 0,
};

const anchorBoxInnerStyle = {
	position: 'absolute',
	width: 0,
	height: 0,
	right: 0,
	top: 0,
};

const filterBySection = (
	designs: $ReadOnlyArray<TDesign>,
	section: TDesignSection,
): $ReadOnlyArray<TDesign> =>
	_.filter(design => !design.removedAt && design.section === section, designs);

function SettingsFont() {
	const { t } = useTranslation();
	const siteId: ?TId = useSelector(getCurrentSiteId);
	const dispatch = useDispatch();
	const specs = useSelector(getSpecs);

	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const widgetspec: ?TSpecsWidget = useSelector(getCurrentSiteWidgetSpec);
	const colorspec: ?TSpecsColor = useSelector(getCurrentSiteColorSpec);
	const effectspec: ?TSpecsEffect = useSelector(getCurrentSiteEffectSpec);

	const device = useSelector(getCurrentDevice);
	const images: ?TWidgets = useSelector(state =>
		getWidgetsByKind(state, { kind: 'file', scopeId: siteId, removedAt: null }),
	);

	const uploads: TImageLibraryListUploads = useSelector(getImageLibraryListUploads);

	const [editedFontId, setEditedFont] = React.useState<?TId>(null);
	const editedFont = React.useMemo(() => widgetspec?.text.find(p => p._id === editedFontId && !p.removedAt), [widgetspec, editedFontId]);

	const updateSpecs = React.useCallback(
		// eslint-disable-next-line no-void
		(...args) => {
			logger.info('editWidgetSpec');
			dispatch(updateSpecsOriginal(...args));
		},
		[dispatch],
	);

	const insertFont = React.useCallback(
		section => {
			try {
				if (!widgetspec) return;
				updateSpecs({
					[widgetspec._id]: {
						...widgetspec,
						text: [
							{
								...defaultDesign,
								breakpoints: {
									[`${device}`]: defaultDesignBreakpointText,
								},
								section,
								_id: genId('specs'),
								target: 'text',
							},
							...widgetspec.text,
						],
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[updateSpecs, widgetspec, device],
	);

	const insertHeader = React.useCallback(() => insertFont('header'), [insertFont]);
	const insertBody = React.useCallback(() => insertFont('body'), [insertFont]);

	const removeFont = React.useCallback(
		id => {
			try {
				if (!widgetspec) return;
				const itemAt = widgetspec.text.findIndex(
					p => p._id === id && !p.removedAt,
				);
				if (itemAt < 0) {
					return;
				}
				if (editedFont?._id === id) {
					setEditedFont(null);
				}
				updateSpecs({
					[widgetspec._id]: _.set(
						`text.${itemAt}.removedAt`,
						new Date().toISOString(),
						widgetspec,
					),
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[widgetspec, updateSpecs, editedFont],
	);

	const clickFont = React.useCallback(
		id => {
			try {
				const target = widgetspec?.text.find(p => p._id === id && !p.removedAt);
				if (!target) {
					return;
				}
				setEditedFont(id);
			} catch (e) {
				logger.error(e);
			}
		},
		[widgetspec],
	);

	const uneditFont = React.useCallback(() => setEditedFont(null), []);

	const changeFont = React.useCallback(
		changed => {
			try {
				if (!widgetspec || !editedFont) return;
				const editAt = widgetspec.text.findIndex(p => p._id === changed._id);
				if (editAt < 0) return;
				updateSpecs({
					[widgetspec._id]: {
						...widgetspec,
						text: [
							...widgetspec.text.slice(0, editAt),
							changed,
							...widgetspec.text.slice(editAt + 1),
						],
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[editedFont, widgetspec, updateSpecs],
	);

	const anchorRef = React.useRef();

	const headerDesigns = React.useMemo(() => filterBySection(widgetspec?.text || [], 'header'), [widgetspec?.text]);

	const bodyDesigns = React.useMemo(() => filterBySection(widgetspec?.text || [], 'body'), [widgetspec?.text]);

	const updateSiteHandler = React.useCallback(
		(siteId, site) => {
			// не ебу какой тут нужен instanceId и originId
			if (siteId) dispatch(editWidget(siteId, null, siteId, site));
		},
		[dispatch],
	);

	const handleUpload = React.useCallback(
		files => {
			dispatch(insertImages(files));
		},
		[dispatch],
	);

	const handleRemove = React.useCallback(
		imageId => {
			dispatch(removeImage(imageId));
		},
		[dispatch],
	);
	const handleReset = React.useCallback(() => {
		dispatch(reset());
	}, [dispatch]);

	const activeFonts = React.useMemo(() => {
		let fonts = [];

		_.forEach(({ breakpoints }) => {
			_.forEach(deviceDesign => {
				const font = {};

				const { family, weight } = deviceDesign;

				const webSafeFont = listForFontsLib.find(
					({ name, family: fontFamily }) =>
						family === fontFamily || name === family,
				);

				if (webSafeFont) return;

				if (typeof family === 'string') {
					font.family = family;
					font.id = family.replace(/\s+/g, '-').toLowerCase();
					const currentWeight = listMatchWeight.find(
						({ name }) => name === weight,
					);

					if (currentWeight) font.variants = [currentWeight.value];

					fonts = [...fonts, font];
				}
			}, breakpoints);
		}, widgetspec?.text || []);

		return fonts;
	}, [widgetspec]);

	const fontButtons = React.useMemo(
		() => ({
			buttons: [
				{
					name: 'insert',
					icons: [
						{
							title: t('Add a font'),
							name: 'plus',
							iconSize: 18,
						},
					],
					colors: 'primary',
				},
			],
		}),
		[t],
	);

	useGoogleFonts([{ family: '', variants: [], id: '' }].concat(activeFonts), {
		ref: anchorRef,
	});

	if (
		!siteId ||
		!widgetspec ||
		!gridspec ||
		!headerDesigns ||
		!bodyDesigns ||
		!effectspec ||
		!colorspec
	)
		return null;

	return (
		<>
			<SelectSpec
				siteId={siteId}
				spec={widgetspec}
				specs={specs}
				t={t}
				genCustomId={genId}
				updateSite={updateSiteHandler}
				updateSpecs={updateSpecs}
			/>
			<Box sx={anchorBoxOuterStyle}>
				<Box ref={anchorRef} sx={anchorBoxInnerStyle} />
			</Box>
			<Section label="Headers" buttonGroup={fontButtons} onClick={insertHeader}>
				<ListFonts
					device={device}
					items={headerDesigns}
					gridspec={gridspec}
					active={
						(editedFont && editedFont.kind === 'header' && editedFont._id) ||
						null
					}
					onRemove={removeFont}
					onClick={clickFont}
				/>
			</Section>
			<Section label="Body" buttonGroup={fontButtons} onClick={insertBody}>
				<ListFonts
					device={device}
					items={bodyDesigns}
					gridspec={gridspec}
					active={
						(editedFont && editedFont.kind === 'body' && editedFont._id) ||
						null
					}
					onRemove={removeFont}
					onClick={clickFont}
				/>
			</Section>
			{editedFont && (
				<PopupWhite
					key={editedFont._id}
					isOpen={!!editedFont}
					anchorEl={anchorRef}
					offsetLeft={12}
					offsetTop={-100}
					onClose={uneditFont}
					mutex="design"
					isFixed
				>
					<DesignPanel
						device={device}
						design={editedFont}
						gridspec={gridspec}
						effectspec={effectspec}
						widgetspec={widgetspec}
						colorspec={colorspec}
						onChange={changeFont}
						t={t}
						Color={Color}
						Style={Style}
						genId={genId}
						images={images}
						uploads={uploads}
						insertImage={handleUpload}
						removeImage={handleRemove}
						resetImage={handleReset}
					/>
				</PopupWhite>
			)}
		</>
	);
}

export default React.memo<TProps>(SettingsFont);
