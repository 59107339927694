// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import { Params } from '@graphite/lists';
import { closestDeviceWithKey } from '@graphite/selectors';
import { getRect } from '@graphite/use-rect';
import type {
	TParams,
	TWidgetBoxBreakpoint,
	TWidget,
	TGridBreakpointName,
	TWidgetDiff,
	TPositionValue,
	TUnit,
	TWidgetBox,
	TListParamsOnClick,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	t: string => string,
	data: TWidget,
	unit: number,
	currentDevice: TGridBreakpointName,
	onChange: TWidgetDiff => void,
	currentRef?: {| current: ?HTMLDivElement |},
	position: TPositionValue,
	options?: $ReadOnly<{
		isHeightShow: boolean,
		isWidthShow: boolean,
	}>,
|}>;

const DEFAULT_OPT = {
	isHeightShow: true,
	isWidthShow: true,
};

const listStyle = {
	marginBottom: '12px',
};

const Layout = ({
	t,
	unit,
	currentRef,
	currentDevice,
	onChange,
	data,
	position,
	options = DEFAULT_OPT,
}: TProps) => {
	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		// eslint-disable-next-line
		key: `box-${data._id}`,
	});

	const paramList: TParams = React.useMemo(
		() => [
			{
				title: t('Width'),
				key: 'width',
				kind: 'unit',
				info: {
					domain: 'nonnegative',
					showUnits: true,
					unitKey: 'widthUnit',
					units: position === 'absolute' ? ['px', 'auto'] : ['px', '%', 'auto'],
				},
				hidden: !options.isWidthShow,
			},
			{
				title: t('Height'),
				key: 'height',
				kind: 'unit',
				info: {
					domain: 'nonnegative',
					showUnits: true,
					unitKey: 'heightUnit',
					units: ['px', 'auto'],
				},
				hidden: !options.isHeightShow,
			},
		],
		[t, position, options],
	);

	const paramSource = React.useMemo(() => {
		if (position === 'absolute')
			return {
				width: `${box.offset?.width || ''}`,
				widthUnit: `${box.offset?.width ? 'px' : 'auto'}`,
				height: `${box.offset?.height || ''}`,
				heightUnit: `${box.offset?.height ? 'px' : 'auto'}`,
			};

		return {
			width: `${box.width || ''}`,
			widthUnit: box.widthUnit || 'auto',
			height: `${box.height || ''}`,
			heightUnit: box.heightUnit || 'auto',
		};
	}, [position, box]);

	const changeParam = React.useCallback(
		(key, value) => {
			if (position === 'absolute') {
				onChange({
					box: _.set(`${currentDevice}.offset.${key}`, value, data.box),
				});
			} else {
				onChange({ box: _.set(`${currentDevice}.${key}`, value, data.box) });
			}
		},
		[onChange, currentDevice, data.box, position],
	);

	const changeUnitGrid = React.useCallback(
		(key, unitNext) => {
			const unitKey = `${key}Unit`;

			let unitPrev: TUnit = box[unitKey] || 'auto';
			if (unitPrev === unitNext) {
				return;
			}

			// Convert (%/px/unit/auto <-> %/px/unit/auto)
			// по необходимости получаем ректы виджета для % расчетов
			// или когда не хватает данных, что бы получить px

			let calcValue: ?number = box[key];

			if (!calcValue) {
				const rect = getRect(currentRef?.current, {
					margin: false,
					padding: true,
				});
				calcValue = rect[key];
				unitPrev = 'px';
			}

			if (unitNext === 'px') {
				if (unitPrev === 'unit') {
					calcValue *= unit;
				} else {
					const rect = getRect(currentRef?.current, {
						margin: false,
						padding: true,
					});
					calcValue = rect[key];
				}
			}

			if (unitNext === 'unit') {
				if (unitPrev === 'px') {
					calcValue /= unit;
				} else {
					const rect = getRect(currentRef?.current, {
						margin: false,
						padding: true,
					});
					calcValue = rect[key] / unit;
				}
			}

			if (unitNext === '%') {
				if (unitPrev === 'px') {
					const parrentRect = getRect(currentRef?.current?.parentElement, {
						margin: false,
						padding: true,
					});
					calcValue = (calcValue / parrentRect[key]) * 100;
				} else if (unitPrev === 'unit') {
					const parrentRect = getRect(currentRef?.current?.parentElement, {
						margin: false,
						padding: true,
					});
					calcValue = ((calcValue * unit) / parrentRect[key]) * 100;
				} else {
					const parrentRect = getRect(currentRef?.current?.parentElement, {
						margin: false,
						padding: true,
					});
					calcValue = parseInt((calcValue / parrentRect[key]) * 100, 10);
				}
			}

			let newBox: TWidgetBox = _.flow(
				_.set(`${currentDevice}.${key}`, calcValue),
				_.set(`${currentDevice}.${unitKey}`, unitNext),
			)(data.box);

			if (unitNext === 'auto') {
				newBox = _.unset(`${currentDevice}.${key}`, newBox);
			}

			onChange({ box: newBox });
		},
		[currentDevice, box, currentRef, unit, data.box, onChange],
	);

	const changeUnitAbs = React.useCallback(
		(key, unitNext) => {
			if (key === 'height' || key === 'width') {
				// ToDo по умолчанию тут нет unitKey
				let unitPrev: TUnit = box.offset?.[key] ? 'px' : 'auto';

				if (unitPrev === unitNext) {
					return;
				}

				// Convert (px/auto <-> px/auto)
				let calcValue: ?number = box.offset?.[key];

				if (!calcValue) {
					const rect = getRect(currentRef?.current);
					calcValue = rect[key];
					unitPrev = 'px';
				}

				let newBox = _.set(`${currentDevice}.offset.${key}`, calcValue, data.box);

				if (unitNext === 'auto') {
					newBox = _.unset(`${currentDevice}.offset.${key}`, data.box);
				}

				onChange({ box: newBox });
			}
		},
		[currentDevice, box, currentRef, data.box, onChange],
	);

	const clickUnit: TListParamsOnClick = React.useCallback(
		(e, key, unitNext) => {
			if (position === 'absolute') {
				changeUnitAbs(key, unitNext);
			} else {
				changeUnitGrid(key, unitNext);
			}
		},
		[changeUnitGrid, changeUnitAbs, position],
	);

	return (
		<Box sx={listStyle}>
			<Params
				listName="widget-settings-layout"
				paramSource={paramSource}
				paramList={paramList}
				unit={unit}
				onChange={changeParam}
				onClick={clickUnit}
			/>
		</Box>
	);
};

export default React.memo<TProps>(Layout);
