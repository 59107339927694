// @flow
import React from 'react';
import { ToolbarBtn, Tooltip } from '@graphite/uneon';

import type { TPropsButtonPosition } from './types';

const ButtonPosition = ({
	name,
	isActive,
	onChange,
	iconName,
	children,
	color,
}: TPropsButtonPosition) => {
	const tooltipRef: React$ElementRef<any> = React.useRef(null);

	const handleChange = React.useCallback(() => {
		onChange(name);
	}, [name, onChange]);

	return (
		<>
			<ToolbarBtn
				onClick={handleChange}
				iconName={iconName}
				ref={tooltipRef}
				isActive={isActive}
				color={color}
			/>
			<Tooltip elRef={tooltipRef} placement="top">
				{children}
			</Tooltip>
		</>
	);
};

export default React.memo<TPropsButtonPosition>(ButtonPosition);
