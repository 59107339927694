// @flow
import { connect } from 'react-redux';
import type { TWidgetOwnProps } from '@graphite/types';

import type { TConnectProps } from './constants/types';
import mapStateToProps from './libs/mapStateToProps';
import withMeta from './libs/with-meta';
import Page from './Page';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(mapStateToProps)(
	withMeta(Page),
);
