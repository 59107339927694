// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';
import { closestDeviceWithKey } from '@graphite/selectors';
import { useTranslation } from 'react-i18next';
import type { TWidget, TWidgetDiff, TGridBreakpointName } from '@graphite/types';

type TProps = $ReadOnly<{|
	data: TWidget,
	saveDiff: (diff: TWidgetDiff) => void,
	currentDevice: TGridBreakpointName,
|}>;

const Settings = ({ data, saveDiff, currentDevice }: TProps) => {
	const { t } = useTranslation();

	/**
		Image
	 */
	const paramListImage = React.useMemo(() => {
		const params = [
			{
				title: t('Link'),
				key: 'src',
				kind: 'string',
				info: { maxLength: 0 },
			},
			{
				title: t('Position X'),
				key: 'objectPositionX',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'objectPositionXUnit',
					units: ['px', '%'],
				},
			},
			{
				title: t('Position Y'),
				key: 'objectPositionY',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'objectPositionYUnit',
					units: ['px', '%'],
				},
			},
		];

		return params;
	}, [t]);

	const paramSourceImage = React.useMemo(() => {
		const box = closestDeviceWithKey(data.box, {
			currentDevice,
			key: `box-${data._id}`,
		});

		return {
			src: data.src || '',
			objectPositionX:
				typeof box.objectPositionX === 'number' ? `${box.objectPositionX}` : '50',
			objectPositionXUnit: box.objectPositionXUnit || '%',
			objectPositionY:
				typeof box.objectPositionY === 'number' ? `${box.objectPositionY}` : '50',
			objectPositionYUnit: box.objectPositionYUnit || '%',
		};
	}, [data, currentDevice]);

	/**
		Adjust
	 */
	const paramListAdjust = React.useMemo(() => {
		const params = [
			{
				title: t('Brightness'),
				key: 'brightness',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Contrast'),
				key: 'contrast',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Saturation'),
				key: 'saturation',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Grayscale'),
				key: 'grayscale',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'onehundred',
					unitKey: 'unitPercent',
				},
			},
			{
				title: t('Hue'),
				key: 'hue',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'unitDegree',
				},
			},
			{
				title: t('Blur'),
				key: 'blur',
				kind: 'unit',
				info: {
					showUnits: true,
					domain: 'nonnegative',
				},
			},
			{
				title: t('Rotation'),
				key: 'rotation',
				kind: 'unit',
				info: {
					showUnits: true,
					unitKey: 'unitDegree',
				},
			},
		];

		return params;
	}, [t]);

	const paramSourceAdjust = React.useMemo(
		() => ({
			brightness: `${
				typeof data.brightness === 'number' && data.brightness >= 0
					? data.brightness
					: 100
			}`,
			contrast: `${
				typeof data.contrast === 'number' && data.contrast >= 0
					? data.contrast
					: 100
			}`,
			saturation: `${
				typeof data.saturation === 'number' && data.saturation >= 0
					? data.saturation
					: 100
			}`,
			grayscale: `${data.grayscale || 0}`,
			hue: `${data.hue || 0}`,
			blur: `${data.blur || 0}`,
			rotation: `${data.rotation || 0}`,
			unitDegree: '°',
			unitPercent: '%',
		}),
		[
			data.rotation,
			data.blur,
			data.brightness,
			data.contrast,
			data.grayscale,
			data.hue,
			data.saturation,
		],
	);

	/**
		SEO
	 */
	const paramListSeo = React.useMemo(() => {
		const params = [
			{
				title: t('Alt'),
				key: 'alt',
				kind: 'string',
				info: { maxLength: 0, placeholder: 'Description' },
			},
			{
				title: t('Title'),
				key: 'title',
				kind: 'string',
				info: { maxLength: 0, placeholder: 'Title' },
			},
		];

		return params;
	}, [t]);

	const paramSourceSeo = React.useMemo(
		() => ({
			alt: data.alt || '',
			title: data.title || '',
		}),
		[data.alt, data.title],
	);

	const handleSave = React.useCallback(
		(name, value) => {
			saveDiff({ [`${name}`]: value });
		},
		[saveDiff],
	);

	const handleSaveBox = React.useCallback(
		(name, value) => {
			// Только это свойство из этого списка хранится не в box
			if (name === 'src') {
				saveDiff({ [`${name}`]: value });
				return;
			}
			// Остальные из этого списка, хранятся box
			saveDiff({ box: _.set(`${currentDevice}.${name}`, value, data.box) });
		},
		[saveDiff, currentDevice, data.box],
	);

	const handleChangeUnit = React.useCallback(
		(e, name, nextUnit) => {
			const newBox = _.flow(
				_.set(`${currentDevice}.${name}Unit`, nextUnit),
				box => {
					if (nextUnit === 'px')
						return _.set(`${currentDevice}.${name}`, '0', box);
					if (nextUnit === '%')
						return _.set(`${currentDevice}.${name}`, '50', box);
					return box;
				},
			)(data.box);

			saveDiff({ box: newBox });
		},
		[data.box, currentDevice, saveDiff],
	);

	return (
		<>
			<Section label={t('Image')}>
				<ListParams
					listName="image"
					paramSource={paramSourceImage}
					paramList={paramListImage}
					unit={1}
					onChange={handleSaveBox}
					onClick={handleChangeUnit}
				/>
			</Section>
			<Section label={t('Adjust')}>
				<ListParams
					listName="adjust"
					paramSource={paramSourceAdjust}
					paramList={paramListAdjust}
					unit={1}
					onChange={handleSave}
				/>
			</Section>
			<Section label={t('SEO')}>
				<ListParams
					listName="props"
					paramSource={paramSourceSeo}
					paramList={paramListSeo}
					unit={1}
					onChange={handleSave}
				/>
			</Section>
		</>
	);
};

export default React.memo<TProps>(Settings);
