// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Spec as SelectSpec } from '@graphite/selects';
import { useTranslation } from 'react-i18next';
import logger from '@graphite/logger';

import { editWidget } from 'Editor/ducks/widgets';
import {
	getCurrentSiteId,
	getSpecs,
	getCurrentSiteEffectSpec,
} from '@graphite/selectors';
import { updateSpecs as updateSpecsOriginal } from 'Editor/ducks/specs';
import { useDispatch } from '@graphite/use-redux';
import { genId } from 'libs/firebase';

import Radii from './Radii';
import Borders from './Borders';

type TProps = $ReadOnly<{||}>;

const ColorSettings = () => {
	const { t } = useTranslation();
	const siteId = useSelector(getCurrentSiteId);
	const dispatch = useDispatch();
	const specs = useSelector(getSpecs);
	const effectspec = useSelector(getCurrentSiteEffectSpec);

	const updateSpecs = React.useCallback(
		// eslint-disable-next-line no-void
		(...args) => {
			logger.info('editEffectsSpec');
			dispatch(updateSpecsOriginal(...args));
		},
		[dispatch],
	);

	const updateSiteHandler = React.useCallback(
		(siteId, site) => {
			// не ебу какой тут нужен instanceId и originId
			if (siteId) dispatch(editWidget(siteId, null, siteId, site));
		},
		[dispatch],
	);

	if (!siteId) return null;

	return (
		<>
			<SelectSpec
				siteId={siteId}
				spec={effectspec}
				specs={specs}
				t={t}
				genCustomId={genId}
				updateSite={updateSiteHandler}
				updateSpecs={updateSpecs}
			/>
			<Radii effectspec={effectspec} updateSpecs={updateSpecs} />
			<Borders effectspec={effectspec} updateSpecs={updateSpecs} />
		</>
	);
};

export default React.memo<TProps>(ColorSettings);
