// @flow
import React from 'react';

type TProps = $ReadOnly<{|
	signInWithToken: () => void,
|}>;

const LoginToken = ({ signInWithToken }: TProps) => {
	React.useEffect(() => {
		signInWithToken();
	}, [signInWithToken]);

	return null;
};

export default React.memo<TProps>(LoginToken);
