// @flow
import _ from 'lodash/fp';
import { defaultColor } from '@graphite/constants';
import type { TSpecsColor } from '@graphite/types';

import type { TFillParams } from '../types';

const bindColor = (colors, id) => ({ entryId: id, shadeId: null, snapshot: colors[id] });
const backgroundColor = {
	entryId: 'background',
	shadeId: null,
	snapshot: defaultColor,
};

export default ({ colors, _id, userId, siteId, name }: TFillParams): TSpecsColor => {
	const theme = {
		id: 'theme1',
		name: 'Theme 1',
		text: {
			primary: bindColor(colors, 'primary'),
			primaryalt: bindColor(colors, 'primary'),
			secondary: bindColor(colors, 'secondary'),
			tertiary: bindColor(colors, 'secondary'),
			accent: bindColor(colors, 'success'),
			accentalt: bindColor(colors, 'success'),
			success: bindColor(colors, 'success'),
			error: bindColor(colors, 'error'),
			warning: bindColor(colors, 'warning'),
			oncolor: backgroundColor,
			accentplus: bindColor(colors, 'success'),
			accentaltplus: bindColor(colors, 'success'),
			successplus: bindColor(colors, 'success'),
			errorplus: bindColor(colors, 'error'),
			warningplus: bindColor(colors, 'warning'),
			accentminus: bindColor(colors, 'success'),
			accentaltminus: bindColor(colors, 'success'),
			successminus: bindColor(colors, 'success'),
			errorminus: bindColor(colors, 'error'),
			warningminus: bindColor(colors, 'warning'),
		},
		bg: {
			primary: backgroundColor,
			primaryalt: backgroundColor,
			secondary: backgroundColor,
			tertiary: backgroundColor,
			accent: bindColor(colors, 'success'),
			accentalt: bindColor(colors, 'success'),
			success: bindColor(colors, 'success'),
			error: bindColor(colors, 'error'),
			warning: bindColor(colors, 'warning'),
			primaryplus: backgroundColor,
			primaryaltplus: backgroundColor,
			secondaryplus: backgroundColor,
			tertiaryplus: backgroundColor,
			accentplus: bindColor(colors, 'success'),
			accentaltplus: bindColor(colors, 'success'),
			successplus: bindColor(colors, 'success'),
			errorplus: bindColor(colors, 'error'),
			warningplus: bindColor(colors, 'warning'),
			primaryminus: backgroundColor,
			primaryaltminus: backgroundColor,
			secondaryminus: backgroundColor,
			tertiaryminus: backgroundColor,
			accentminus: bindColor(colors, 'success'),
			accentaltminus: bindColor(colors, 'success'),
			successminus: bindColor(colors, 'success'),
			errorminus: bindColor(colors, 'error'),
			warningminus: bindColor(colors, 'warning'),
		},
	};
	return {
		userId,
		_id,
		kind: 'colorspec',
		name: _.capitalize(name),
		display: 'normal',
		protoId: null,
		removedAt: null,
		updatedAt: null,
		scope: 'site',
		scopeId: siteId,

		activeTheme: 'theme1',

		generator: {
			isEnabled: false,
			baseColor: null,
		},

		palette: [
			{ id: 'background', name: 'Background', color: defaultColor, shades: [] },
			..._.map(
				id => ({
					id,
					name: _.capitalize(id),
					color: colors[id],
					shades: [],
				}),
				_.keys(colors),
			),
		],
		themes: [theme],
	};
};
