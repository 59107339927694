// @flow
import _ from 'lodash/fp';
import type { TWidgetMethodPlaceWidget } from '@graphite/types';

export { addWidgetHook } from '../../../libs/stack-hooks';

export const placeWidgetHook: TWidgetMethodPlaceWidget = ({
	widgets,
	id,
	containerId,
}) => {
	const site = widgets[id];
	const project = widgets[containerId];
	const { _id, userId } = project;

	return {
		updated: {
			[site._id]: _.flow(
				_.set('scope', 'project'),
				_.set('scopeId', _id),
				_.set('userId', userId),
			)(site),
		},
	};
};

export default {};
