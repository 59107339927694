// @flow
// Needed for redux-saga es6 generator support
// FixMe оно скорее всего не надо уже
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Global, css } from '@emotion/core';
import emotionReset from 'emotion-reset';
import logger from '@graphite/logger';
import TagManager from 'react-gtm-module';

import Editor from './Editor';
import './Editor/libs/i18n';
import configureStore from './libs/configure-store';
import history from './libs/history';
import * as serviceWorker from './serviceWorker';
import { getConfig, logEvent } from './libs/firebase';

const globalStyle = css`
	${emotionReset}

	html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
`;

const MOUNT_NODE = document.getElementById('app');

let store = null;

const render = async (isStoreChanged: boolean = false) => {
	if (!store || isStoreChanged) {
		const initialState = {};
		// Create redux store with history
		store = configureStore(initialState, history);
	}

	if (MOUNT_NODE) {
		ReactDOM.render(
			<Provider store={store}>
				<Global styles={globalStyle} />
				<ConnectedRouter history={history}>
					<Editor />
				</ConnectedRouter>
			</Provider>,
			MOUNT_NODE,
		);
	}
};

/* $FlowFixMe(>=0.102.0) описать тип module
 * */
if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	/* $FlowFixMe(>=0.102.0) описать тип module
	 * */
	module.hot.accept(['./Editor'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		render();
	});
}

(async () => {
	const {
		gtmId,
		apiKeyAmplitudeKey,
		logRocketId,
		mixpanelId,
		yandexMetrikaId,
		sentryDNS,
	} = await getConfig();

	if (gtmId)
		TagManager.initialize({
			gtmId,
		});

	const loggerConfig =
		process.env.NODE_ENV !== 'production' || process.env.ENV_MODE === 'server'
			? { browser: true }
			: {
					sentry: {
						DNS: sentryDNS,
					},
					analytics: {
						logEvent,
					},
					fb: true,
					amplitude: {
						key: apiKeyAmplitudeKey,
					},
					mixpanel: {
						key: mixpanelId,
					},
					yandex: {
						key: yandexMetrikaId,
					},
					logRocket: {
						key: logRocketId,
					},
			  };

	logger.init(loggerConfig);

	render();
})();

serviceWorker.unregister();
