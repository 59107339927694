// @flow
import React from 'react';
import { Provider as DragAbsoluteProvider } from '@graphite/abs-grid';

import { moveWidget, placeWidget } from 'Editor/ducks/widgets';
import FrameContext from 'Editor/Frame/Context';
import { ContextSetActiveLevel } from 'Editor/libs/with-symbiote/Provider';
import Widget from 'Widget';
import type {
	TGridBreakpointName,
	Dispatch,
	TAction,
	TWidgetChain,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	children: React$Node,
	currentDevice: TGridBreakpointName,
	dispatch: Dispatch<TAction>,
	widgetChain: TWidgetChain,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
|}>;

const AbsoluteProvider = (props: TProps) => {
	const {
		children,
		currentDevice,
		dispatch,
		widgetChain,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
	} = props;

	const frameContext = React.useContext(FrameContext);
	const frameBox = frameContext.elBox.current;
	const frameDocumnet = frameContext.document;

	const setActiveLevel = React.useContext(ContextSetActiveLevel);
	const onStart = React.useCallback(() => {
		setActiveLevel('abs-drag-place');
	}, [setActiveLevel]);

	const onStop = React.useCallback(() => {
		setActiveLevel(null);
	}, [setActiveLevel]);

	return (
		<DragAbsoluteProvider
			currentDevice={currentDevice}
			dispatch={dispatch}
			widgetChain={widgetChain}
			widgetspec={widgetspec}
			colorspec={colorspec}
			gridspec={gridspec}
			effectspec={effectspec}
			Widget={Widget}
			moveWidget={moveWidget}
			placeWidget={placeWidget}
			onStart={onStart}
			onStop={onStop}
			frameBox={frameBox}
			frameDocumnet={frameDocumnet}
		>
			{children}
		</DragAbsoluteProvider>
	);
};

export default React.memo<TProps>(AbsoluteProvider);
