// @flow
import {
	useDispatch as useDispatchOriginal,
	useSelector as useSelectorOriginal,
} from 'react-redux';
import type { Dispatch } from 'redux';
import type { TAction } from '@graphite/types';

export const useDispatch = (): Dispatch<TAction> => useDispatchOriginal<Dispatch<TAction>>();

export const useSelector = useSelectorOriginal;
