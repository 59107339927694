// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import logger from '@graphite/logger';
import reSelect from 'libs/re-select';
import {
	closestDeviceWithKey,
	getActiveBreakpointNames,
	maxCombineSx,
} from '@graphite/selectors';
import type {
	TSx,
	TId,
	TWidget,
	TSpecsGrid,
	TSpecsGridBreakpoint,
	TWidgetBox,
	TWidgetBoxBreakpoint,
} from '@graphite/types';

export type TGetContainerBreakpointSxParams = $ReadOnly<{|
	breakpoint: TSpecsGridBreakpoint,
	box: TWidgetBoxBreakpoint,
	unit: number,
|}>;

export type TGetContainerBreakpointSxFn = TGetContainerBreakpointSxParams => TSx;

// Для стилизации текста по спеке
export const getContainerBreakpointSx: TGetContainerBreakpointSxFn = reSelect<
	TGetContainerBreakpointSxParams,
	TSpecsGridBreakpoint,
	TWidgetBoxBreakpoint,
	number,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	({ box }) => box,
	({ unit }) => unit,
	(breakpoint, box, unit) => {
		try {
			const { height, heightUnit } = box;
			const { paddingUnit, padding, containerUnit, container } = breakpoint;
			const customSx = {
				height: 'auto',
				width: '100%',
				marginRight: '0',
				marginLeft: '0',
			};

			if (height && heightUnit === 'unit') {
				customSx.height = `${height * unit}px`;
			} else if (height && heightUnit !== 'auto') {
				customSx.height = `${height}px`;
			}

			let width = '100%';
			if (container && containerUnit === 'px') {
				width = `${container}px`;
			}
			if (container && containerUnit === 'unit') {
				width = `${container * unit}px`;
			}
			if (container && containerUnit === '%') {
				width = `${container}%`;
			}
			customSx.width = width;

			let px = '0';
			if (padding && paddingUnit === 'px') {
				px = `${padding}px`;
			}
			if (padding && paddingUnit === 'unit') {
				px = `${padding * unit}px`;
			}
			if (padding && paddingUnit === '%') {
				px = `${padding}%`;
			}
			customSx.marginLeft = px;
			customSx.marginRight = px;

			if (box.fluid) {
				customSx.width = '100%';
			}

			return customSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint, box, unit }) =>
	['widget@getContainerBreakpointSx', _.size(breakpoint), _.size(box), unit].join('-'),
);

type TGetContainerSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
type TGetContainerSx = TGetContainerSxParams => TSx;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getContainerSx: TGetContainerSx = reSelect<
	TGetContainerSxParams,
	?TWidgetBox,
	TId,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ gridspec }) => gridspec,
	(box, _id, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const breakpoint = closestDeviceWithKey(gridspec.breakpoints, {
						currentDevice,
						key: `breakpoint-${gridspec._id}`,
					});

					const boxBreakpoint = closestDeviceWithKey(box, {
						currentDevice,
						key: `box-${_id}`,
					});

					const breakpointSx = getContainerBreakpointSx({
						breakpoint,
						box: boxBreakpoint,
						unit: gridspec.unit,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ data, gridspec }) =>
	['widget@getContainerSx', _.size(data.box), data._id, gridspec._id].join('-'),
);
