// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { Button, Flex, Box, Logo, Text, PopupSelect, List } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	name: string,
	photoURL: ?string,
	logOut: () => void,
	t: (string) => string,
|}>;

const topBarSx = {
	justifyContent: 'space-between',
	alignItems: 'center',
	flexDirection: ['column', 'row'],
	paddingTop: '15px',
	paddingBottom: '14px',
	borderBottomWidth: '2px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
};

const logoSx = {
	width: '120px',
};

const RightButtons = styled(Flex)`
	gap: 24px;
	max-width: 100%;
	white-space: nowrap;
`;

const AvatarBox = styled(Flex)`
	width: 30px;
	height: 30px;
	min-width: 30px;
	background: ${({ photoUrl, theme }) =>
		photoUrl ? `url(${photoUrl})` : theme.colors.bg.accent};
	background-size: contain;
	background-position: center;
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
`;

const Topbar = ({ name, photoURL, logOut, t }: TProps) => {
	const history = useHistory();

	const buttonRef = React.useRef(null);
	const [isOpenMenu, setStateMenu] = React.useState(false);
	const toggleOpenMenu = React.useCallback(
		(e) => {
			e?.stopPropagation();
			return setStateMenu(!isOpenMenu);
		},
		[isOpenMenu],
	);
	const list = React.useMemo(
		() => ({
			items: [
				{
					name: 'settings',
					label: t('Settings'),
				},
				{
					name: 'logout',
					label: t('Log out'),
				},
			],
			colors: 'primaryflat',
			activeColors: 'primaryflat',
			behavior: 'button',
		}),
		[t],
	);
	const boundClick = React.useCallback(
		(e, itemName) => {
			e.stopPropagation();
			switch (itemName) {
				case 'settings':
					history.push('/profile');
					break;
				case 'logout':
					logOut();
					break;
				default:
					break;
			}

			setStateMenu(false);
		},
		[history, logOut],
	);

	return (
		<Box variant="container.negative">
			<Flex sx={topBarSx} variant="container.fluid">
				<Link data-kind="user-header-logo" to="/">
					<Logo sx={logoSx} />
				</Link>
				<RightButtons>
					<Button
						data-kind="user-header-help"
						as="a"
						variant="primaryaltflat.flat.sm"
						href="//feedback.graphite.space"
						target="_blank"
						rel="noreferrer"
						alt={t('Help')}
					>
						{t('Help')}
					</Button>
					<Button
						data-kind="user-header-email"
						onClick={toggleOpenMenu}
						variant="primaryaltflat.flat.sm"
						alt={name}
					>
						<AvatarBox photoUrl={photoURL}>
							{!photoURL && (
								<Text as="span" variant="headlinesm" color="text.oncolor">
									{name[0]}
								</Text>
							)}
						</AvatarBox>
						{name}
						<Box ref={buttonRef} />
					</Button>
				</RightButtons>
			</Flex>
			<PopupSelect
				isOpen={isOpenMenu}
				anchorEl={buttonRef}
				offsetTop={0}
				offsetLeft={0}
				onClose={toggleOpenMenu}
				width="auto"
			>
				<Box data-kind="site-control-menu">
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<List {...list} onClick={boundClick} />
				</Box>
			</PopupSelect>
		</Box>
	);
};

export default React.memo<TProps>(Topbar);
