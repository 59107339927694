// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box } from '@graphite/uneon';
import { Input as InputField } from '@rebass/forms';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import { getBoxSx, getDesignSx } from '@graphite/selectors';
import type { TSx, TDesign } from '@graphite/types';

import type { TConnectProps } from './constants/types';

const baseInputSx: TSx = {
	borderWidth: 0,
	borderRadius: 0,
	borderColor: 'transparent',
	outline: 'none',
	background: 'none',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	textDecoration: 'none',
	padding: 0,
	flexGrow: 1,
	opacity: 1,
};

const Input = (props: TConnectProps, ref) => {
	const {
		data,
		position,
		colorspec,
		effectspec,
		gridspec,
		widgetspec,
		dragContainer,
		dragFlip,
	} = props;

	const boxSx = gridspec ? getBoxSx({ data, position, gridspec }) : emptyObject;

	const InputSx: TSx = React.useMemo(() => {
		if (!gridspec || !widgetspec || !colorspec || !effectspec) return emptyObject;

		if (!data.designId) return baseInputSx;

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.input.find(d => d._id === data.designId);

		if (!design) return baseInputSx;

		const designSx = getDesignSx({
			design,
			widgetspec,
			colorspec,
			effectspec,
			gridspec,
		});
		return _.mergeAll([baseInputSx, designSx]);
	}, [data, colorspec, widgetspec, effectspec, gridspec]);

	const {
		input = {
			label: '',
			placeholder: '',
			type: 'text',
			maxlength: null,
			isRequired: false,
		},
	} = data;

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const otherProps = _.assign(dragContainer, dragFlip);
	return (
		<>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Box sx={boxSx} style={dynamicStyle} ref={ref} {...otherProps}>
				<InputField
					name={input.label}
					type={input.type}
					maxLength={input.maxlength}
					placeholder={input.placeholder}
					required={input.isRequired}
					sx={InputSx}
				/>
			</Box>
		</>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Input));
