// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import useGoogleFonts from '@graphite/fonts-lib/lib/libs/use-fonts';
import type {
	TFonts,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
} from '@graphite/types';

import type { TWidgetText } from '../constants/types';
import getFonts from './getFonts';

type TMinimalProps = $ReadOnly<{
	fonts: TFonts,
	data: TWidgetText,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
}>;

const withFontsEdit = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const Fonts = (props: T, ref) => {
		const { data, widgetspec } = props;

		const fonts = getFonts({
			id: data._id,
			raw: data.raw,
			designs: data.designs || {},
			widgetspec,
		});

		useGoogleFonts(fonts, { ref });

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} ref={ref} />;
	};

	Fonts.displayName = `withFontsEdit(${getDisplayName(Component)})`;

	return React.memo<T>(React.forwardRef(Fonts));
};

export default withFontsEdit;
