// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import styled from '@emotion/styled';
import { Flex, Box, Button, Icon, Tooltip, themes } from '@graphite/uneon';
import getDisplayName from '@graphite/get-display-name';
import withSymbiote from 'Editor/libs/with-symbiote';
import useDefaultDevice from 'Editor/libs/use-default-device';
import type { TGridBreakpointName } from '@graphite/types';

import type { TConnectPropsEdit } from '../constants/types';

export type TPropsEmptyContent = $ReadOnly<{|
	onAddClick: () => void,
	currentDevice: TGridBreakpointName,
|}>;

const Wrapper = styled(Flex)`
	background-color: ${({ theme }) => theme.colors.bg.primaryplus};
	width: 100%;
	height: 300px;
	justify-content: center;
	align-items: center;
	pointer-events: auto;
`;

const Content = styled(Flex)`
	min-width: 180px;
	min-height: 120px;
	flex-direction: column;
	justify-content: center;
	align-content: stretch;
	align-items: center;
	flex-wrap: nowrap;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.15s ease-out;
	
	&:hover: {
		opacity: 0.8
	},
`;

const withAddBlockButton = (Component: React$ComponentType<TConnectPropsEdit>) => {
	const AddBlockButton = (props: TConnectPropsEdit) => {
		const { t } = useTranslation();

		const { babies, currentDevice, placeWidget } = props;
		const btnEl: React$ElementRef<any> = React.useRef(null);

		const isDefaultDevice = currentDevice === useDefaultDevice();

		const addBlock = React.useCallback(() => {
			placeWidget('block');
		}, [placeWidget]);

		if (_.size(babies)) return null;

		return (
			<ThemeProvider theme={themes.dark}>
				<Wrapper>
					{isDefaultDevice && (
						<Content onClick={addBlock}>
							<Button variant="white.iconrounded.md" ref={btnEl}>
								<Icon name="plus" colors="baseflat" />
							</Button>
							<Tooltip elRef={btnEl} placement="top">
								{t('Add first section')}
							</Tooltip>
						</Content>
					)}
				</Wrapper>
			</ThemeProvider>
		);
	};

	AddBlockButton.displayName = `withAddBlockButton(${getDisplayName(Component)})`;

	return React.memo<TConnectPropsEdit>(AddBlockButton);
};

const withEmptyContent = (Component: React$ComponentType<TConnectPropsEdit>) => {
	const WithEmptyContent = (props: TConnectPropsEdit, ref) => {
		const { babies } = props;

		const babyCond = [
			[_.size, _.constant<React$Node>(null)],
			[_.stubTrue, _.constant<React$Node>(<Box height="300px" />)],
		];

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Component {...props} ref={ref}>
				{(_.cond(babyCond)(babies): React$Node)}
			</Component>
		);
	};

	WithEmptyContent.displayName = `withEmptyContent(${getDisplayName(Component)})`;

	return withSymbiote(withAddBlockButton)(
		React.memo<TConnectPropsEdit>(React.forwardRef(WithEmptyContent)),
	);
};

export default withEmptyContent;
