// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from '@graphite/uneon';
import useBreakpoint from '@graphite/use-breakpoint';
import logger from '@graphite/logger';
import { defaultCommonEffect, defaultShadow } from '@graphite/constants';
import {
	Color as SectionColor,
	Background as SectionBackground,
	Shadow as SectionShadow,
} from '@graphite/sections';
import type {
	TDesignCommonEffect,
	TBackgroundValues,
	TColorValue,
	TShadowValue,
	TShadowValues,
	TDesignColorProps,
} from '@graphite/types';

const defaultBorderItem: TColorValue = {
	kind: 'color',
	value: 'bg.accent',
};

const defaultBgItem: TColorValue = {
	kind: 'color',
	value: 'bg.primary',
};

const defaultBgShadowItem: TShadowValue = {
	kind: 'shadow',
	value: defaultShadow,
};

function Color({
	design,
	device,
	colorspec,
	gridspec,
	onChange = null,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignColorProps) {
	const { t } = useTranslation();

	const { breakpoint, changeBreakpoint } = useBreakpoint(onChange, design, device);

	const stateName = 'normal';

	const stateData: TDesignCommonEffect =
		breakpoint[stateName || 'normal'] || defaultCommonEffect;

	const bgSource: TBackgroundValues = React.useMemo(
		() => stateData.container.background || [],
		[stateData.container.background],
	);

	const bgShadowSource: TShadowValues = React.useMemo(
		() => stateData.container.shadow || [],
		[stateData.container.shadow],
	);

	const borderSource: ?TColorValue = React.useMemo(
		() =>
			stateData.container.border
				? { kind: 'color', value: stateData.container.border }
				: null,
		[stateData.container.border],
	);

	const bgChange = React.useCallback(
		(background: TBackgroundValues) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							background,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData],
	);

	const bgShadowChange = React.useCallback(
		(shadow: TShadowValues) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							shadow,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData],
	);

	const borderChange = React.useCallback(
		(color: ?TColorValue) => {
			try {
				if (!stateName) {
					return;
				}
				changeBreakpoint({
					...breakpoint,
					[stateName]: {
						...stateData,
						container: {
							...stateData.container,
							border: color ? color.value : null,
						},
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[breakpoint, changeBreakpoint, stateData],
	);

	return (
		<Section label={t('Color')}>
			<SectionBackground
				t={t}
				label={t('Background')}
				source={bgSource}
				defaultItem={defaultBgItem}
				colorspec={colorspec}
				gridspec={gridspec}
				onChange={bgChange}
				isSeparated
				genId={genId}
				insertImage={insertImage}
				removeImage={removeImage}
				resetImage={resetImage}
				images={images}
				uploads={uploads}
			/>
			<SectionColor
				t={t}
				label={t('Border Color')}
				source={borderSource}
				defaultItem={defaultBorderItem}
				colorspec={colorspec}
				gridspec={gridspec}
				onChange={borderChange}
				isSeparated
				genId={genId}
			/>
			<SectionShadow
				t={t}
				label={t('Shadow')}
				source={bgShadowSource}
				defaultItem={defaultBgShadowItem}
				colorspec={colorspec}
				gridspec={gridspec}
				onChange={bgShadowChange}
				genId={genId}
			/>
		</Section>
	);
}

export default React.memo<TDesignColorProps>(Color);
