// @flow
import React from 'react';
import { getTrueIds, getWidget, getSpec, getCurrentUserId } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import ComponentContext from 'ComponentContext';
import { anyKind } from 'Widget/libs';
import { syncScopedWidgets, unsyncScopedWidgets } from 'Editor/ducks/widgets';
import { syncScopedSpecs, unsyncScopedSpecs } from 'Editor/ducks/specs';
import type {
	TWidgetOwnProps,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
} from '@graphite/types';

import Site from './Site';

const SiteEdit = ({ id }: TWidgetOwnProps) => {
	const dispatch = useDispatch();
	const userId = useSelector(getCurrentUserId);

	const siteId = id;
	const site = useSelector(state => getWidget(state, { id }));
	const babies = site
		? getTrueIds({
				...site,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
		  })
		: [];
	const pageId = babies[0];

	React.useEffect(() => {
		if (siteId) {
			dispatch(syncScopedWidgets('site', siteId, userId, 'dasboard'));
			dispatch(syncScopedWidgets('site', siteId, null, 'dasboard'));
			dispatch(syncScopedSpecs('site', siteId, userId, 'dasboard'));
			dispatch(syncScopedSpecs('site', siteId, null, 'dasboard'));
		}
		if (pageId) {
			dispatch(syncScopedWidgets('page', pageId, userId, 'dasboard'));
			dispatch(syncScopedWidgets('page', pageId, null, 'dasboard'));
		}

		return () => {
			if (siteId) {
				dispatch(unsyncScopedWidgets('site', siteId, userId, 'dasboard'));
				dispatch(unsyncScopedWidgets('site', siteId, null, 'dasboard'));
				dispatch(unsyncScopedSpecs('site', siteId, userId, 'dasboard'));
				dispatch(unsyncScopedSpecs('site', siteId, null, 'dasboard'));
			}
			if (pageId) {
				dispatch(unsyncScopedWidgets('page', pageId, userId, 'dasboard'));
				dispatch(unsyncScopedWidgets('page', pageId, null, 'dasboard'));
			}
		};
	}, [dispatch, pageId, siteId, userId]);

	const gridspec: ?TSpecsGrid = useSelector(state =>
		getSpec(state, { id: site?.gridspecId }),
	);
	const widgetspec: ?TSpecsWidget = useSelector(state =>
		getSpec(state, { id: site?.widgetspecId }),
	);
	const colorspec: ?TSpecsColor = useSelector(state =>
		getSpec(state, { id: site?.colorspecId }),
	);
	const effectspec: ?TSpecsEffect = useSelector(state =>
		getSpec(state, { id: site?.effectspecId }),
	);

	const widgetChain = React.useMemo(() => [pageId], [pageId]);
	if (!gridspec || !widgetspec || !colorspec || !effectspec) return null;

	return (
		<ComponentContext.Provider value={anyKind('get-view-component')}>
			<Site
				childId={pageId}
				widgetChain={widgetChain}
				gridspec={gridspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				effectspec={effectspec}
			/>
		</ComponentContext.Provider>
	);
};

export default React.memo<TWidgetOwnProps>(SiteEdit);
