// @flow
import _ from 'lodash/fp';
import React from 'react';
import { Trans } from 'react-i18next';
import { Icon, ListItem, Box, Text, Tooltip } from '@graphite/uneon';

import type { TWidgetProps } from './constants/types';

const wrapperSx = {
	zIndex: 1,
	cursor: 'grab',
	position: 'relative',
	transition: 'background-color 0.15s ease-in',
	':after': {
		content: '""',
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		borderBottomColor: 'bg.primaryalt',
	},
	':hover': {
		backgroundColor: 'bg.primaryplus',
		borderRadus: 'md.all',
	},
	':active:after': {
		opacity: 0,
	},
};

const itemSx = {
	justifyContent: 'left',
	height: '48px',
	marginLeft: '0px',
	marginRight: '0px',
	paddingLeft: '24px',
	paddingRight: '24px',
	position: 'relative',
	width: '100%',
};

const widgetIconSx = {
	marginTop: '-2px',
	marginRight: '18px',
};

const grabIconSx = {
	position: 'absolute',
	right: '24px',
	zIndex: 1,
};

const Widget = (props: TWidgetProps, ref: React$ElementRef<any>) => {
	const { title, icon, dragHandler, dragContainer } = props;

	const uneonProps = _.assign(dragHandler, dragContainer);

	const iconEl: React$ElementRef<any> = React.useRef(null);

	return (
		<>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Box ref={ref} sx={wrapperSx} {...uneonProps}>
				<ListItem sx={itemSx}>
					<Icon name={icon} sx={widgetIconSx} />
					<Text variant="bodysm">{title}</Text>
					<Icon
						name="dots-horizontal-thin-6"
						colors="secondary"
						sx={grabIconSx}
						ref={iconEl}
					/>

					<Tooltip elRef={iconEl} placement="right">
						<Trans i18nKey="Drag element on canvas to add">
							Drag element
							<br />
							on canvas to add
						</Trans>
					</Tooltip>
				</ListItem>
			</Box>
		</>
	);
};

export default React.memo<TWidgetProps>(React.forwardRef(Widget));
