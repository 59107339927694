// @flow
import React from 'react';
import { ToolbarSelect, Tooltip } from '@graphite/uneon';
import { useTranslation } from 'react-i18next';
import type { TWidget } from '@graphite/types';

type TProps = $ReadOnly<{|
	// setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	// openedPanel: ?string,
	data: TWidget,
	save: (string, string | Object) => void,
|}>;

const selectSx = {
	width: 'auto',
	minWidth: '72px',
};

const Settings = ({ save, data }: TProps) => {
	const { t } = useTranslation();

	const btnEl: React$ElementRef<any> = React.useRef(null);

	const [activeFormat, setActiveFormat] = React.useState(data.cropMode || 'fit');

	const buttonGroup = React.useMemo(
		() => ({
			items: [
				{ label: t('Fill'), name: 'fill' },
				{ label: t('Fit'), name: 'fit' },
				// { label: t('Crop'), name: 'crop' },
			],
			active: activeFormat,
		}),
		[t, activeFormat],
	);

	const modeClickBound = React.useCallback(
		(e, value) => {
			if (typeof value === 'string') {
				setActiveFormat(value);
				save('cropMode', value);
			}
		},
		[save],
	);

	return (
		<>
			<ToolbarSelect
				list={buttonGroup}
				onClick={modeClickBound}
				sx={selectSx}
				ref={btnEl}
			/>
			<Tooltip elRef={btnEl} placement="top">
				{t('Crop mode')}
			</Tooltip>
		</>
	);
};

export default React.memo<TProps>(Settings);
