// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@graphite/uneon';
import { useDispatch } from '@graphite/use-redux';
import { getCurrentSiteId, getCurrentSiteGridSpec, getSpecs } from '@graphite/selectors';
import { Spec as SelectSpec } from '@graphite/selects';
import { updateSpecs as updateSpecsOriginal } from 'Editor/ducks/specs';
import { editWidget } from 'Editor/ducks/widgets';
import { genId } from 'libs/firebase';
import logger from '@graphite/logger';
import type { TSpecsGrid } from '@graphite/types';

import Columns from './Columns';
import Spacing from './Spacing';

type TProps = $ReadOnly<{||}>;

const groupStyle = {
	padding: '18px 0',
	marginBottom: '21px',
};

const tabList = [
	{ label: 'Columns', name: 'columns' },
	{ label: 'Spacing', name: 'spacing' },
];

const SettingsGrid = () => {
	const { t } = useTranslation();
	const siteId = useSelector(getCurrentSiteId);
	const dispatch = useDispatch();
	const specs = useSelector(getSpecs);

	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const [activeTab, setActiveTab] = React.useState('columns');

	const updateSpecs = React.useCallback(
		// eslint-disable-next-line no-void
		(...args) => {
			logger.info('editGridSpec');
			dispatch(updateSpecsOriginal(...args));
		},
		[dispatch],
	);

	const tab = React.useMemo(
		() => tabList.find(t => t.name === activeTab) || tabList[0],
		[activeTab],
	);

	const clickBound = React.useCallback((e, name) => {
		if (typeof name === 'string') {
			setActiveTab(name);
		}
	}, []);

	const updateSiteHandler = React.useCallback(
		(siteId, site) => {
			// не ебу какой тут нужен instanceId и originId
			if (siteId) dispatch(editWidget(siteId, null, siteId, site));
		},
		[dispatch],
	);

	if (!siteId || !gridspec) return null;

	return (
		<>
			<SelectSpec
				siteId={siteId}
				spec={gridspec}
				specs={specs}
				t={t}
				genCustomId={genId}
				updateSite={updateSiteHandler}
				updateSpecs={updateSpecs}
			/>
			<ButtonGroup
				behavior="radio"
				active={tab.name}
				buttons={tabList}
				variant="normal"
				shape="rounded"
				size="sm"
				colors="accentghost"
				activeColors="accent"
				onClick={clickBound}
				sx={groupStyle}
			/>
			{(tab.name === 'spacing' && (
				<Spacing gridspec={gridspec} updateSpecs={updateSpecs} />
			)) ||
				(tab.name === 'columns' && (
					<Columns gridspec={gridspec} updateSpecs={updateSpecs} />
				))}
		</>
	);
};

export default React.memo<TProps>(SettingsGrid);
