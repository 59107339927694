// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { TWidget, TWidgetDiff } from '@graphite/types';
import {
	Flex,
	Box,
	Text,
	Input,
	IconClear as Icon,
	Button,
	Switch,
} from '@graphite/uneon';
import { debounce } from 'lodash/fp';
import {
	auth,
	storage,
	EmailAuthProvider,
	googleAuthProvider,
} from '../../../libs/firebase';

import Topbar from './Topbar';
import Footer from './Footer';

type TProps = $ReadOnly<{
	user: TWidget,
	fallbackName: string,
	onUpdate: (TWidgetDiff) => void,
	logOut: () => void,
	showNotice: (title: string, description: string) => void,
}>;

const bodySx = {
	minHeight: '100vh',
	flexDirection: 'column',
	backgroundColor: 'bg.primary',
};

const UploadInp = styled(Input)`
	display: none;
`;

const Block = styled(Box)`
	background: #fff;
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	width: 100%;
	max-width: 564px;
	margin-top: 33px;
	margin-bottom: 48px;
	padding: 24px 30px;
`;

const EmailStatusText = styled(Text)`
	margin-left: 15px;

	svg {
		vertical-align: sub;
		margin-right: 6px;
	}
`;

const EmailStatusErrorText = styled(EmailStatusText)`
	color: ${({ theme }) => theme.colors.text.error};

	svg {
		fill: ${({ theme }) => theme.colors.text.error};
	}
`;

const EmailStatusSuccesText = styled(EmailStatusText)`
	color: ${({ theme }) => theme.colors.text.success};

	svg {
		fill: ${({ theme }) => theme.colors.text.success};
	}
`;

const AvatarBox = styled(Flex)`
	width: 60px;
	height: 60px;
	background: ${({ photoUrl, theme }) =>
		photoUrl ? `url(${photoUrl})` : theme.colors.bg.accent};
	background-size: contain;
	background-position: center;
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	margin-right: 35px;
	min-width: 60px;
`;

const SwitchWrapper = styled(Flex)`
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	justify-content: space-between;

	label {
		margin: 0;
	}
`;

const BoxWithBorder = styled(Box)`
	border-bottom: 1px solid ${({ theme }) => theme.colors.bg.primaryalt};
`;

const Alert = styled(Flex)`
	background: ${({ theme }) => theme.colors.bg.primaryalt};
	padding: 12px 18px;
	margin-top: 18px;
	align-items: center;
	border-radius: 6px;
`;

const ExclamationBox = styled(Flex)`
	width: 36px;
	height: 36px;
	min-width: 36px;
	background: ${({ theme }) => theme.colors.bg.warning};
	border-radius: 100%;
	align-items: center;
	justify-content: center;
`;

const InputHidden = styled(Input)`
	height: 0;
	width: 0;
	border: 0;
	outline: 0;
	margin: 0;
	padding: 0;
`;

const initialNotification = {
	paymentInformation: false,
	reminders: false,
	tutorials: false,
	news: false,
	promotion: false,
};

const emailRegexp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UserEditor = ({ logOut, user, fallbackName, onUpdate, showNotice }: TProps) => {
	const { t } = useTranslation();
	const [userUid] = React.useState(auth.currentUser.uid);
	const [photoURL, setPhotoURL] = React.useState(auth.currentUser.photoURL);
	const [userName, setUserName] = React.useState(
		auth.currentUser.displayName || fallbackName,
	);
	const [userEmail, setUserEmail] = React.useState(auth.currentUser.email);
	const [isDisabledEmail, setDisabledEmail] = React.useState(true);
	const [emailVerified, setEmailVerified] = React.useState(
		auth.currentUser.emailVerified,
	);
	const uploadRef = React.useRef<?HTMLInputElement>();
	const [isSetPasswordProvider, changeSetPasswordProvider] = React.useState(
		auth.currentUser.providerData.some(({ providerId }) => providerId === 'password'),
	);
	const [isSetGoogleProvider, changeSetGoogleProvider] = React.useState(
		auth.currentUser.providerData.some(
			({ providerId }) => providerId === 'google.com',
		),
	);

	const emailRef = React.useRef();

	const saveName = React.useRef(
		debounce(
			1000,
			(nextValue) =>
				nextValue &&
				auth.currentUser.updateProfile({
					displayName: nextValue,
				}),
		),
	).current;

	const updateName = React.useCallback(
		(e) => {
			const { target } = e;

			if (!(target instanceof window.HTMLInputElement)) {
				return;
			}

			setUserName(target.value);

			saveName(target.value);
		},
		[saveName],
	);

	const updateEmail = React.useCallback(async (e) => {
		const { target } = e;

		if (!(target instanceof window.HTMLInputElement)) {
			return;
		}

		setUserEmail(target.value);
	}, []);

	const changeEmail = React.useCallback(() => {
		setDisabledEmail(false);
		setTimeout(() => {
			emailRef.current?.focus();
		}, 0);
	}, []);

	const saveEmail = React.useCallback(() => {
		if (userEmail && emailRegexp.test(userEmail)) {
			auth.currentUser.updateEmail(userEmail);
		} else {
			showNotice(t('Error'), t("Don't looks like email"));
			setUserEmail(auth.currentUser.email);
		}
		setDisabledEmail(true);
		setEmailVerified(false);
	}, [showNotice, t, userEmail]);

	const uploadPhoto = React.useCallback(() => {
		uploadRef.current?.click();
	}, []);

	const handlerUpload = React.useCallback(async () => {
		if (!uploadRef.current || !(uploadRef.current instanceof HTMLInputElement))
			return;

		const file = uploadRef.current?.files[0];
		const { type, name } = file;

		if (!type.includes('image')) {
			showNotice(t('Bad format'), t('The image is not in the correct format'));
			return;
		}
		/* $FlowFixMe(>=0.102.0) не видит at у массива
		 * */
		const ext = name.split('.').at(-1);

		const storageRef = storage.ref(`user/${userUid}/pictures/avatar.${ext}`);
		await storageRef.put(file);
		const photoURL = await storageRef.getDownloadURL();

		await auth.currentUser.updateProfile({
			photoURL,
		});

		setPhotoURL(photoURL);
	}, [showNotice, t, userUid]);

	const removePhoto = React.useCallback(async () => {
		const photoURL = null;

		await auth.currentUser.updateProfile({
			photoURL,
		});

		setPhotoURL(photoURL);
	}, []);

	const [isEditingPassword, setEditingPassword] = React.useState(false);
	const changePassword = React.useCallback(() => {
		setEditingPassword(true);
	}, []);
	const currentPassword = React.useRef();
	const updateCurrentPassword = React.useCallback((e) => {
		const { target } = e;

		if (!(target instanceof window.HTMLInputElement)) {
			return;
		}

		currentPassword.current = target.value;
	}, []);
	const newPassword = React.useRef();
	const updateNewPassword = React.useCallback((e) => {
		const { target } = e;

		if (!(target instanceof window.HTMLInputElement)) {
			return;
		}

		newPassword.current = target.value;
	}, []);
	const retypePassword = React.useRef();
	const updateRetypePassword = React.useCallback((e) => {
		const { target } = e;

		if (!(target instanceof window.HTMLInputElement)) {
			return;
		}

		retypePassword.current = target.value;
	}, []);

	const savePassword = React.useCallback(async () => {
		if (!currentPassword.current || !newPassword.current) {
			showNotice(t('Error'), t('You need input password'));
			return;
		}

		const credential = EmailAuthProvider.credential(
			auth.currentUser.email,
			currentPassword.current,
		);

		try {
			await auth.currentUser.reauthenticateWithCredential(credential);
		} catch (e) {
			showNotice(t('Error'), t('Current password incorect'));
			return;
		}

		if (!currentPassword.current) {
			showNotice(t('Error'), t('You need input password'));
			return;
		}

		if (newPassword.current !== retypePassword.current) {
			showNotice(t('Error'), t('Passwords is not equals'));
			return;
		}
		try {
			await auth.currentUser.updatePassword(newPassword.current);
		} catch (e) {
			showNotice(t('Error'), t(e.message));
			return;
		}

		setEditingPassword(false);
	}, [showNotice, t]);

	const forgotPassword = React.useCallback(() => {}, []);
	const resendVerificationEmail = React.useCallback(() => {}, []);

	const [isSettingPassword, setSettingPassword] = React.useState(false);
	const openSetPassword = React.useCallback(() => setSettingPassword(true), []);
	const setPassword = React.useCallback(async () => {
		if (newPassword.current !== retypePassword.current) {
			showNotice(t('Error'), t('Passwords is not equals'));
			return;
		}

		const credential = await EmailAuthProvider.credential(
			auth.currentUser.email,
			newPassword.current,
		);

		try {
			await auth.currentUser.linkWithCredential(credential);
		} catch (e) {
			showNotice(t('Error'), t(e.message));
			return;
		}

		changeSetPasswordProvider(true);
		setSettingPassword(false);
	}, [showNotice, t]);

	const disconnectGoogleAccount = React.useCallback(async () => {
		await auth.currentUser.unlink('google.com');
		changeSetGoogleProvider(false);
	}, []);

	const connectGoogleAccount = React.useCallback(async () => {
		await auth.currentUser.linkWithPopup(googleAuthProvider);
		changeSetGoogleProvider(true);
	}, []);

	const switchNotification = React.useCallback(
		(
			name: 'paymentInformation' | 'reminders' | 'tutorials' | 'news' | 'promotion',
		) => {
			onUpdate({
				notification: {
					...(user.notification || initialNotification),
					[`${name}`]: !user.notification?.[name],
				},
			});
		},
		[onUpdate, user.notification],
	);

	const switchPaymentInformation = React.useCallback(
		() => switchNotification('paymentInformation'),
		[switchNotification],
	);

	const switchReminders = React.useCallback(
		() => switchNotification('reminders'),
		[switchNotification],
	);

	const switchTutorials = React.useCallback(
		() => switchNotification('tutorials'),
		[switchNotification],
	);

	const switchNews = React.useCallback(
		() => switchNotification('news'),
		[switchNotification],
	);

	const switchPromotion = React.useCallback(
		() => switchNotification('promotion'),
		[switchNotification],
	);

	return (
		<Flex sx={bodySx}>
			<Box data-kind="user-header" variant="container.fluid">
				<Topbar logOut={logOut} name={userName} photoURL={photoURL} t={t} />
				<Flex>
					<Box width="150px">&nbsp;</Box>
					<Box data-kind="user-profile" width="100%" mt="30px" mb="60px">
						<Text as="h2" variant="title1" color="text.primaryalt">
							{t('Profile')}
						</Text>
						<Block>
							<Text as="h2" variant="title4" color="text.primaryalt">
								{t('General')}
							</Text>
							<Text
								as="h3"
								mt="30px"
								mb="12px"
								variant="headlinemd"
								color="text.primaryalt"
							>
								{t('Photo')}
							</Text>
							<Flex>
								<AvatarBox photoUrl={photoURL}>
									{!photoURL && (
										<Text
											as="span"
											variant="headlinesm"
											color="text.oncolor"
										>
											{userName[0]}
										</Text>
									)}
								</AvatarBox>
								<Box>
									<UploadInp
										type="file"
										ref={uploadRef}
										name="fileInput"
										onChange={handlerUpload}
									/>
									{(photoURL && (
										<>
											<Button
												variant="accentflat.flat.sm"
												display="inline-flex"
												onClick={uploadPhoto}
											>
												{t('Change photo')}
											</Button>

											<Button
												variant="secondaryflat.flat.sm"
												display="inline-flex"
												ml="20px"
												onClick={removePhoto}
											>
												{t('Remove photo')}
											</Button>
										</>
									)) || (
										<Button
											variant="accentflat.flat.sm"
											onClick={uploadPhoto}
										>
											{t('Upload photo')}
										</Button>
									)}
									<Text variant="bodysm" color="text.secondary">
										{t(
											'We recommend a minimum size of 300x300px and a .png or .jpg format',
										)}
									</Text>
								</Box>
							</Flex>
							<Text
								as="h3"
								mt="30px"
								mb="12px"
								variant="headlinemd"
								color="text.primaryalt"
							>
								{t('Name')}
							</Text>
							<Input
								width="100%"
								onChange={updateName}
								value={userName}
								required
							/>
							<Text
								as="h3"
								mt="36px"
								mb="12px"
								variant="headlinemd"
								color="text.primaryalt"
							>
								{t('Email')}
							</Text>
							{isDisabledEmail ? (
								<>
									<Text as="span" variant="headlinesm">
										{userEmail}
									</Text>
									<InputHidden
										width="100%"
										value={userEmail}
										required
										type="email"
										name="username"
										autoComplete="off"
									/>
									{emailVerified ? (
										<EmailStatusSuccesText
											as="span"
											ml="15px"
											variant="headlinesm"
											color="text.success"
										>
											<Icon name="check-18" />
											Verified
										</EmailStatusSuccesText>
									) : (
										<EmailStatusErrorText
											as="span"
											variant="headlinesm"
										>
											<Icon name="cross-18" />
											Not verified
										</EmailStatusErrorText>
									)}
								</>
							) : (
								<Input
									width="100%"
									onChange={updateEmail}
									value={userEmail}
									ref={emailRef}
									required
									type="email"
									name="username"
									autoComplete="off"
								/>
							)}
							<Text mt="12px" variant="bodysm" color="text.secondary">
								{t(
									'If you change your email, you will need to confirm it again.',
								)}
							</Text>
							{(isDisabledEmail && (
								<Flex mt="18px">
									<Button
										variant="accentflat.flat.sm"
										onClick={changeEmail}
									>
										{t('Change email')}
									</Button>
									<Button
										variant="accentflat.flat.sm"
										onClick={resendVerificationEmail}
										ml="25px"
										disabled
									>
										{t('Resend verification email')}
									</Button>
								</Flex>
							)) || (
								<Button
									variant="accent.rounded"
									mt="10px"
									onClick={saveEmail}
								>
									<Text variant="headlinemd">{t('Save email')}</Text>
								</Button>
							)}
							<Text
								as="h3"
								mt="36px"
								mb="12px"
								variant="headlinemd"
								color="text.primaryalt"
							>
								{t('Password')}
							</Text>
							{/* eslint-disable-next-line no-nested-ternary */}
							{isSetPasswordProvider ? (
								isEditingPassword ? (
									<>
										<Input
											width="100%"
											type="password"
											onChange={updateCurrentPassword}
											placeholder={t('Current password')}
											autoComplete="off"
										/>
										<Input
											width="100%"
											type="password"
											mt="15px"
											onChange={updateNewPassword}
											placeholder={t('New password')}
											autoComplete="off"
										/>
										<Input
											width="100%"
											type="password"
											mt="15px"
											onChange={updateRetypePassword}
											placeholder={t('Retype password')}
											autoComplete="off"
										/>
										<Flex mt="15px" alignItems="center">
											<Button
												variant="accent.rounded"
												onClick={savePassword}
											>
												<Text variant="headlinemd">
													{t('Save password')}
												</Text>
											</Button>
											<Button
												variant="accentflat.flat.sm"
												ml="15px"
												onClick={forgotPassword}
												disabled
											>
												{t('Forgot password?')}
											</Button>
										</Flex>
									</>
								) : (
									<Button
										variant="accentflat.flat.sm"
										mt="18px"
										onClick={changePassword}
									>
										{t('Change password')}
									</Button>
								)
							) : isSettingPassword ? (
								<>
									<Input
										width="100%"
										type="password"
										mt="15px"
										onChange={updateNewPassword}
										placeholder={t('New password')}
									/>
									<Input
										width="100%"
										type="password"
										mt="15px"
										onChange={updateRetypePassword}
										placeholder={t('Retype password')}
									/>
									<Button
										variant="accent.rounded"
										onClick={setPassword}
										mt="15px"
									>
										<Text variant="headlinemd">
											{t('Set password')}
										</Text>
									</Button>
								</>
							) : (
								<Button
									variant="accentflat.flat.sm"
									mt="18px"
									onClick={openSetPassword}
								>
									{t('Set password')}
								</Button>
							)}
						</Block>

						<Block>
							<Text as="h2" variant="title4" color="text.primaryalt">
								{t('Log in with social')}
							</Text>

							{isSetGoogleProvider ? (
								<>
									<Button
										variant="primaryghost.rounded"
										onClick={disconnectGoogleAccount}
										mt="30px"
										disabled={!isSetPasswordProvider}
									>
										<Icon name="social-google-18" />
										<Text variant="headlinesm" ml="5px">
											{t('Disconnect Google account')}
										</Text>
									</Button>
									{isSetPasswordProvider ? (
										<Text
											variant="bodysm"
											color="text.secondary"
											mt="18px"
										>
											{t(
												'The Google account is now connected for quick login.',
											)}
										</Text>
									) : (
										<>
											<Text
												variant="bodysm"
												color="text.secondary"
												mt="18px"
											>
												{t(
													'Google Account is now connected for quick login and is the only way to sign in. ',
												)}
											</Text>
											<Text
												variant="bodysm"
												color="text.secondary"
												mt="18px"
											>
												{t(
													'Set a password so that you can disable the Google Account. ',
												)}
											</Text>
										</>
									)}
								</>
							) : (
								<>
									<Button
										variant="accent.rounded"
										onClick={connectGoogleAccount}
										mt="30px"
									>
										<Icon name="social-google-18" />
										<Text variant="headlinesm" ml="5px">
											{t('Connect Google account')}
										</Text>
									</Button>
									<Text
										variant="bodysm"
										color="text.secondary"
										mt="18px"
									>
										{t(
											'Connect your Google account for quick login.',
										)}
									</Text>
								</>
							)}
						</Block>

						<Block>
							<Text as="h2" variant="title4" color="text.primaryalt">
								{t('Notification')}
							</Text>
							<Text variant="headlinemd" color="text.primaryalt" mt="30px">
								{t('Important')}
							</Text>
							{(!user.notification?.paymentInformation ||
								!user.notification?.reminders) && (
								<Alert>
									<ExclamationBox>
										<Icon name="exclamation-point-18" />
									</ExclamationBox>
									<Box ml="18px">
										<Text
											variant="headlinesm"
											color="text.primaryalt"
										>
											{t('Important notifications are disabled')}
										</Text>
										<Text
											variant="bodysm"
											color="text.primaryalt"
											mt="5px"
										>
											{t(
												'You may miss the end of the payment period and your sites may be disabled',
											)}
										</Text>
									</Box>
								</Alert>
							)}
							<BoxWithBorder mt="20px" pb="20px">
								<SwitchWrapper>
									<Switch
										label={t('Payment information')}
										checked={!!user.notification?.paymentInformation}
										onClick={switchPaymentInformation}
									/>
								</SwitchWrapper>
								<Text variant="bodysm" color="text.secondary">
									{t(
										'of connected domains, site or account deactivation',
									)}
								</Text>
							</BoxWithBorder>
							<Box mt="15px">
								<SwitchWrapper>
									<Switch
										label={t('Reminders')}
										checked={!!user.notification?.reminders}
										onClick={switchReminders}
									/>
								</SwitchWrapper>
								<Text variant="bodysm" color="text.secondary">
									{t(
										'about trial expirations, plan or domain payments',
									)}
								</Text>
							</Box>
							<Text variant="headlinemd" color="text.primaryalt" mt="40px">
								{t('Useful')}
							</Text>
							<BoxWithBorder mt="20px" pb="20px">
								<SwitchWrapper>
									<Switch
										label={t('Tutorials')}
										checked={!!user.notification?.tutorials}
										onClick={switchTutorials}
									/>
								</SwitchWrapper>
								<Text variant="bodysm" color="text.secondary">
									{t('educational content to help you build a website')}
								</Text>
							</BoxWithBorder>
							<Box mt="15px">
								<SwitchWrapper>
									<Switch
										label={t('News')}
										checked={!!user.notification?.news}
										onClick={switchNews}
									/>
								</SwitchWrapper>
								<Text variant="bodysm" color="text.secondary">
									{t('about our company and product updates')}
								</Text>
							</Box>
							<Text variant="headlinemd" color="text.primaryalt" mt="40px">
								{t('Promo')}
							</Text>
							<Box mt="20px" pb="20px">
								<SwitchWrapper>
									<Switch
										label={t('Promotion and special offers')}
										checked={!!user.notification?.promotion}
										onClick={switchPromotion}
									/>
								</SwitchWrapper>
								<Text variant="bodysm" color="text.secondary">
									{t('from Graphite and our partners')}
								</Text>
							</Box>
						</Block>
					</Box>
				</Flex>
			</Box>

			<Box mt="auto">
				<Footer />
			</Box>
		</Flex>
	);
};

export default React.memo<TProps>(UserEditor);
