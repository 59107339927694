// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { CacheProvider, Global, css } from '@emotion/core';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';
import { zIndices } from '@graphite/constants';

import Context from './Context';
import { INITIAL_POSITION } from './constants';
import type {
	TPropsDragAbsoluteProvider,
	TDropPlaces,
	TDropData,
	TDropRect,
	TDragPosition,
	TPageOffset,
} from './constants/types';
import findDropId from './libs/find-drop-id';
import Duplicate from './Duplicate';

const memoizedCreateCacheWithContainer = weakMemoize(container =>
	createCache({ container }),
);

const DragAbsoluteProvider = (props: TPropsDragAbsoluteProvider) => {
	const {
		children,
		dispatch,
		currentDevice,
		widgetChain,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
		Widget,
		moveWidget,
		placeWidget,
		onStart: onStartCallback,
		onStop: onStopCallback,
		frameBox,
		frameDocumnet,
	} = props;

	const dropPlaces = React.useRef<TDropPlaces>({});
	// кеши ректов - микрооптимизация
	const dropRects = React.useRef<{ [string]: TDropRect }>({});
	const dropData = React.useRef<?TDropData>(null);
	const initialPageOffset = React.useRef<TPageOffset>({ x: 0, y: 0 });
	const dropId = React.useRef<?string>(null);
	const positionCache = React.useRef<TDragPosition>(INITIAL_POSITION);
	const [, forceRender] = React.useState(0);

	const elRef = React.useRef((frameDocumnet || document).createElement('div'));
	React.useEffect(() => {
		const root = elRef.current;
		if (frameDocumnet) frameDocumnet.body.appendChild(root);
		return () => {
			if (frameDocumnet) frameDocumnet.body.removeChild(root);
		};
	}, [frameDocumnet]);

	const regDropPlace = React.useCallback(
		data => {
			dropPlaces.current[data.dropId] = data;
		},
		[dropPlaces],
	);

	const unregDropPlace = React.useCallback(
		data => {
			delete dropPlaces.current[data.id];
		},
		[dropPlaces],
	);

	const hideControls = React.useCallback(onStartCallback, [onStartCallback]);

	const showControls = React.useCallback(onStopCallback, [onStopCallback]);

	const onStart = React.useCallback(
		({ id, ref, rect, containerId, instanceId, originId, position }) => {
			// Сохраняем изначальные позиции скрола
			initialPageOffset.current = {
				x: frameBox ? frameBox.getBoundingClientRect().left : 0,
				y: frameBox ? frameBox.getBoundingClientRect().top : 0,
			};

			dropData.current = {
				id,
				ref,
				rect,
				containerId,
				instanceId,
				originId,
			};

			hideControls();
			positionCache.current = position;
			forceRender(Math.random());
		},
		[frameBox, hideControls],
	);

	const onDrag = React.useCallback((e, data) => {
		dropId.current = findDropId({
			dropPlaces,
			dropRects,
			position: { x: e.x, y: e.y },
			dropData,
		});

		// записываем новую позицию виджета
		positionCache.current = { x: data.deltaX, y: data.deltaY };
		forceRender(Math.random());
	}, []);

	const onStop = React.useCallback(() => {
		const drop = dropData.current;
		dropData.current = null;
		const _dropId = dropId.current;
		dropId.current = null;
		if (!_dropId || !drop?.rect) {
			showControls();
			forceRender(Math.random());
			return;
		}

		// вычисляем переменные, необходимые для изменения стейта
		const dropRect = dropRects.current[_dropId];
		const dest = dropPlaces.current[_dropId];
		const destWidth = dropRect?.width;
		const destHeight = dropRect?.height;
		const dragRect = drop.rect;

		const destX =
			dragRect.left +
			positionCache.current.x -
			dropRect.left +
			(drop.containerId === 'panel' ? 0 : initialPageOffset.current.x);

		const destY =
			dragRect.top +
			positionCache.current.y -
			dropRect.top +
			(drop.containerId === 'panel' ? 0 : initialPageOffset.current.y);

		const srcWidth = dragRect.width;
		const srcHeight = dragRect.height;

		// после того, как вычислили зависимости, обнуляем всё
		dropRects.current = {};

		const isPlace = drop.containerId === 'panel';
		const dropPosition = {
			kind: dest.kind,
			destX,
			destY,
			destWidth,
			destHeight,
			srcWidth,
			srcHeight,
		};

		dispatch(
			isPlace
				? placeWidget(
						drop.id,
						dest.id,
						dest.instanceId,
						dest.originId,
						dropPosition,
				  )
				: moveWidget(
						drop.id,
						drop.containerId,
						dest.id,
						dest.instanceId,
						dest.originId,
						dropPosition,
						currentDevice,
				  ),
		);

		showControls();
	}, [dispatch, placeWidget, moveWidget, currentDevice, showControls]);

	const dragId = dropData.current?.id;
	const value = React.useMemo(
		() => ({
			dragId,
			dropId: dropId.current,
			regDropPlace,
			unregDropPlace,
			onStart,
			onStop,
			onDrag,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dragId, dropId.current, regDropPlace, unregDropPlace, onStart, onStop, onDrag],
	);

	return (
		<>
			<Context.Provider value={value}>{children}</Context.Provider>

			{dropData.current &&
				dropData.current.rect &&
				ReactDOM.createPortal(
					<Duplicate
						position={positionCache.current}
						rect={dropData.current.rect}
						offset={
							dropData.current.containerId === 'panel'
								? initialPageOffset.current
								: { x: 0, y: 0 }
						}
					>
						<Widget
							id={dropData.current.id}
							containerId={dropData.current.containerId}
							instanceId={dropData.current.instanceId}
							originId={dropData.current.originId}
							widgetChain={widgetChain}
							widgetspec={widgetspec}
							colorspec={colorspec}
							gridspec={gridspec}
							effectspec={effectspec}
						/>
					</Duplicate>,
					elRef.current,
				)}

			{document.head && (
				<CacheProvider value={memoizedCreateCacheWithContainer(document.head)}>
					{dropData.current && dropData.current.rect && (
						<Global
							styles={css`
								body:after {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									z-index: ${zIndices.absControlsFactor -
										1 -
										widgetChain.length};
								}
							`}
						/>
					)}
				</CacheProvider>
			)}
		</>
	);
};

export default React.memo<TPropsDragAbsoluteProvider>(DragAbsoluteProvider);
