// @flow

import React, { memo, useCallback } from 'react';
import { Box, Text, Flex, Tooltip, Hotkey } from '@graphite/uneon';
import emptyFunction from 'empty/function';
import type { ComponentType } from 'react';

const DEFAULT_PADDING_X = 24;

export type TTabInfo = $ReadOnly<{|
	id: string,
	title: string,
	desc: string,
	Icon: ComponentType<any> | string,
	Panel: ComponentType<any>,
	hotkeyMac: string,
	hotkeyWin: string,
|}>;

type TProps = $ReadOnly<{|
	info: TTabInfo,
	click?: string => void,
|}>;

const iconSx = {
	marginRight: '21px',
};

const descSx = {
	marginTop: '6px',
};

const itemFlexSx = {
	alignItems: 'center',
	minHeight: '78px',
	marginLeft: `${-DEFAULT_PADDING_X}px`,
	marginRight: `${-DEFAULT_PADDING_X}px`,
	paddingTop: '6px',
	paddingRight: `${DEFAULT_PADDING_X}px`,
	paddingBottom: '6px',
	paddingLeft: `${DEFAULT_PADDING_X}px`,
	cursor: 'pointer',
	':hover': {
		backgroundColor: 'bg.primaryplus',
	},
};

function Item({ info, click = emptyFunction }: TProps) {
	const clickBound = useCallback(() => click(info.id), [info, click]);
	const { id, title, desc, Icon, hotkeyWin, hotkeyMac } = info;

	const elRef: React$ElementRef<any> = React.useRef(null);

	return (
		<Flex key={id} onClick={clickBound} sx={itemFlexSx} ref={elRef}>
			<Box sx={iconSx}>
				<Icon />
			</Box>
			<Box>
				<Text variant="headlinesm" color="text.primary">
					{title}
				</Text>
				<Text variant="bodysm" color="text.secondary" sx={descSx}>
					{desc}
				</Text>
			</Box>
			<Tooltip elRef={elRef} placement="right">
				<Hotkey win={hotkeyWin} mac={hotkeyMac} />
			</Tooltip>
		</Flex>
	);
}

Item.defaultProps = {
	click: emptyFunction,
};

export default memo<TProps>(Item);
