// @flow
import mapValues from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import { sizeRules } from '@graphite/constants';
import type { TId, TSize, TSizes, TEntityChildren } from '@graphite/types';

export default (sizes: ?TSizes, children: ?$ReadOnly<TEntityChildren>): TSizes => {
	const validSizes: TSizes = { ...(sizes || null) };
	const validChildren: $ReadOnly<TEntityChildren> = { ...(children || null) };

	const defaultSizes = (mapValues(
		() =>
			(mapValues(
				() => ({ width: 1, margin: { left: 0, right: 0 } }),
				sizeRules,
			): TSize),
		validChildren,
	): TSizes);

	return (pickBy((v: TSize, k: TId): boolean => !!validChildren[k], {
		...defaultSizes,
		...validSizes,
	}): TSizes);
};
