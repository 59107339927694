// @flow
import React from 'react';
import styled from '@emotion/styled';
import CodeEditor from '@graphite/code-editor';
import { zIndices } from '@graphite/constants';
import { editWidget } from 'Editor/ducks/widgets';
import { resetEdit } from 'Editor/ducks/editor';
import type { Dispatch, TAction } from '@graphite/types';

const CodeEditorStyled = styled(CodeEditor)`
	position: fixed;
	top: 54px;
	right: 0;
	height: calc(100vh - 54px);
	pointer-events: auto;
	z-index: ${zIndices.codeEditor};
`;

type TMinimlProps = $ReadOnly<{
	dispatch: Dispatch<TAction>,
	instanceId: string,
	originId: string,
	data: $ReadOnly<{ _id: string, code: string }>,
	widgetMode: 'none' | 'widget-resize' | 'widget-edit',
}>;

const withCodeEditor = <T: TMinimlProps>(Component: React$ComponentType<T>) => {
	const WidgetWrapper = (props: T) => {
		const {
			dispatch,
			instanceId,
			originId,
			data: { _id, code },
			widgetMode,
		} = props;

		const handleClose = React.useCallback(
			e => {
				e.preventDefault();
				dispatch(resetEdit());
			},
			[dispatch],
		);

		const handleChange = React.useCallback(
			value => {
				dispatch(editWidget(_id, instanceId, originId, { code: value }));
			},
			[_id, dispatch, instanceId, originId],
		);

		const isShow = widgetMode === 'widget-edit';

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
				{isShow && (
					<CodeEditorStyled
						code={code}
						onClose={handleClose}
						onChange={handleChange}
					/>
				)}
			</>
		);
	};

	return React.memo<T>(WidgetWrapper);
};

export default withCodeEditor;
