// @flow
import React from 'react';
import styled from '@emotion/styled';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { CacheProvider, Global, css } from '@emotion/core';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

import { Flex, Button, Box, Text } from '@graphite/uneon';

import Widget from 'Widget';
import type { TWidget, TId } from '@graphite/types';

import previewImage from '../img/site-preview.inline.svg';
import useWatchResize from '../libs/use-watch-resize';

const memoizedCreateCacheWithContainer = weakMemoize((container) =>
	createCache({ container }),
);

type TProps = $ReadOnly<{|
	t: (string) => string,
	containerId: TId,
	site: TWidget,
	onCreate: (TId) => void,
	onPreview: (TId) => void,
|}>;

const siteSx = {
	marginBottom: '30px',
};

const siteNameSx = {
	color: 'text.primaryalt',
	a: {
		textDecoration: 'none',
		color: 'text.primary',
	},
	marginBottom: '6px',
};

const buttonPreviewSx = {
	position: 'absolute',
	bottom: '18px',
};

const Controls = styled(Box)`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 2;
`;

const wrapperActiveStyle = css`
	&:after {
		opacity: 0.7;
	}
	${Controls} {
		visibility: visible;
	}
`;
const WrapperFrame = styled(Box)`
	background: #fff;
	/* Расятгивает прямоугольник на разрешение 16:9 */
	padding-top: 56.25%;
	position: relative;
	border: 1px solid;
	border-color: ${({ theme }) => theme.colors.bg.secondary};
	background-image: url(${previewImage});
	background-size: cover;
	background-position: center;
	overflow: hidden;
	margin-bottom: 15px;
	cursor: pointer;
	transition: opacity 250ms ease-in-out;

	&:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background-color: ${({ theme }) => theme.colors.spec.darkblue70};
		transition: opacity 250ms ease-in-out;
	}

	${Controls} {
		visibility: hidden;
	}

	&:hover {
		${wrapperActiveStyle}
	}
`;

const FrameStyled = styled(Frame)`
	position: absolute;
	top: 0;
	left: 0;
	width: 1200px;
	transform-origin: left top;
`;

const IFrameStyled = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 1200px;
	transform-origin: left top;
`;

const Preset = ({ t, site, containerId, onCreate, onPreview }: TProps) => {
	const { frameStyle, innerElRef } = useWatchResize();

	const onCreateHandler = React.useCallback(
		(e) => {
			e.stopPropagation();
			onCreate(site._id);
		},
		[onCreate, site._id],
	);

	const onPreviewHandler = React.useCallback(
		(e) => {
			e.stopPropagation();
			onPreview(site._id);
		},
		[onPreview, site._id],
	);

	const onLoadHandler = React.useCallback(
		(e) => {
			function doSomething() {
				const sheet = document.createElement('style');
				sheet.innerHTML =
					'html,body{overflow:hidden;min-height:100vh;background:#fff;}';
				document.body?.appendChild(sheet);
			}

			e.target.contentWindow.postMessage(
				encodeURI(doSomething.toString()),
				site.url,
			);
		},
		[site.url],
	);

	return (
		<Box variant="grid.column" mb="27px" sx={siteSx}>
			<WrapperFrame ref={innerElRef}>
				{site.url ? (
					<IFrameStyled
						data-kind="preview-preset-iframe-published"
						style={frameStyle}
						src={site.url}
						onLoad={onLoadHandler}
					/>
				) : (
					<FrameStyled
						data-kind="preview-preset-iframe-render"
						style={frameStyle}
					>
						<FrameContextConsumer>
							{({ document }) => (
								<CacheProvider
									value={memoizedCreateCacheWithContainer(
										document.head,
									)}
								>
									<Global
										styles={css`
											html,
											body {
												overflow: hidden;
												min-height: 100vh;
												background: #fff;
											}
										`}
									/>
									<Widget
										id={site._id}
										containerId={containerId}
										instanceId={null}
										originId={containerId}
									/>
								</CacheProvider>
							)}
						</FrameContextConsumer>
					</FrameStyled>
				)}

				<Controls data-kind="preview-preset-controls" onClick={onPreviewHandler}>
					<Flex>
						<Button
							data-kind="preview-preset-controls-create"
							variant="accent.rounded"
							onClick={onCreateHandler}
						>
							<Text variant="headlinemd">{t('Create site')}</Text>
						</Button>
					</Flex>
					<Flex sx={buttonPreviewSx}>
						<Button
							data-kind="preview-preset-controls-preview"
							variant="whiteflat.rounded"
						>
							<Text variant="headlinesm" color="white">
								{t('Preview')}
							</Text>
						</Button>
					</Flex>
				</Controls>
			</WrapperFrame>

			<Text data-kind="preview-preset-name" variant="bodysm" sx={siteNameSx}>
				{site.name}
			</Text>
		</Box>
	);
};

export default React.memo<TProps>(Preset);
