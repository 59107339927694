// @flow
import type { TId, TCurrentWidget, TWidgets, TWidgetChain } from '@graphite/types';

const getHoverAvailablity = (
	currentWidget: ?TCurrentWidget,
	widgets: TWidgets,
	widgetChain: TWidgetChain,
	myId: TId,
) => {
	const isParentEdited: boolean = !!widgetChain && widgetChain[0] === currentWidget?.id;
	const parentKind = widgetChain[0] && widgets[widgetChain[0]].kind;
	const excludesParentTypes = ['col', 'block'];
	const isSiblingsEditing =
		currentWidget &&
		currentWidget.widgetChain.includes(widgetChain[0]) &&
		!currentWidget.widgetChain.includes(myId);
	/* Мой ховер активен в случаях:
        1. я нахожусь непосредственно в блоке/коле или редактируется мой прямой родитель
        2. если редактируется кто-то из других детей нашего общего родителя
	*/
	return !!(
		excludesParentTypes.includes(parentKind) ||
		isParentEdited ||
		isSiblingsEditing
	);
};

export default getHoverAvailablity;
