// @flow
import React from 'react';
import emptyObject from 'empty/object';
import { Flex, Text as RebassText } from 'rebass';
import styled from '@emotion/styled';
import _ from 'lodash/fp';

import type { TButtonEmbed, TIconEmbed } from '@graphite/types';
import Icon from '../Icon';
import { namedColors } from '../constants';

const Text = styled(RebassText)`
	white-space: nowrap;
`;

type TProps = $ReadOnly<{|
	...$Exact<TButtonEmbed>,
	children?: ?React$Node,
	onClick?: (MouseEvent, ?string) => void,
	onMouseDown?: (MouseEvent, ?string) => void,
|}>;

const buttonStyle = {
	padding: 0,
	alignItems: 'center',
	borderStyle: 'solid',
	justifyContent: 'center',
	transitionTimingFunction: 'cubic-bezier(.25, .46, .45, .94)',
	transitionDuration: '0.25s',
	transitionProperty: 'color backgroundColor',
	textDecoration: 'none',
};

const textSxMap = {
	other: {
		sm: { margin: '0 13px', fontSize: '14px' },
		md: { margin: '0 16px' },
		lg: { margin: '0 19px' },
	},
	rounded: {
		sm: { margin: '0 16px', fontSize: '14px' },
		md: { margin: '0 19px' },
		lg: { margin: '0 23px' },
	},
	flat: {
		sm: { margin: '0', fontSize: '14px' },
		md: { margin: '0' },
		lg: { margin: '0' },
	},
};

const heightMap = {
	sm: '30px',
	md: '36px',
	lg: '42px',
};

const getAction = (onClick, onMouseDown, clickBound) => {
	if (onClick) return { onClick: clickBound };
	if (onMouseDown) return { onMouseDown: clickBound };

	return emptyObject;
};

function Button(
	{
		children = null,
		name = null,
		label = '',
		title = '',
		icons = null,
		colors = 'primary',
		size = 'md',
		isDisabled = false,
		isWide = false,
		isActive = false,
		as = 'button',
		href = null,
		sx = null,
		style = null,
		variant = 'normal',
		shape = 'normal',
		onClick = null,
		onMouseDown = null,
		type = null,
		...props
	}: TProps,
	ref,
) {
	const onAction = onClick || onMouseDown;

	const clickBound = React.useCallback(
		(e) => {
			if (onAction) {
				onAction(e, name);
			}
		},
		[onAction, name],
	);

	const {
		text,
		texthover,
		textactive,
		bg,
		bghover,
		bgactive,
		border,
		borderhover,
		borderactive,
	} =
		(colors !== null && typeof colors === 'object' && colors) ||
		(typeof colors === 'string' && namedColors[colors]) ||
		namedColors.primary;

	const iconsAsArray: ?$ReadOnlyArray<?TIconEmbed> = React.useMemo(
		() =>
			// eslint-disable-next-line no-shadow
			(typeof icons === 'string' && icons.split(',').map((name) => ({ name }))) ||
			(typeof icons === 'object' && icons) ||
			null,
		[icons],
	);

	const [iconLeftProps, iconRightProps] = React.useMemo(
		() =>
			[0, 1].map(
				(i) =>
					(iconsAsArray &&
						iconsAsArray[i] && {
							...iconsAsArray[i],
							isActive: iconsAsArray[i].isActive || isActive,
							variant: 'flat',
							colors: iconsAsArray[i].colors || colors,
							size: iconsAsArray[i].size || size,
							sx: _.assign(
								{ margin: variant === 'flat' ? null : '-2px' },
								iconsAsArray[i].sx,
							),
						}) ||
					null,
			),
		[iconsAsArray, isActive, colors, size, variant],
	);

	const [iconLeftColors, iconRightColors] = React.useMemo(
		() =>
			[0, 1].map((i) =>
				// eslint-disable-next-line no-shadow
				((colors) => {
					if (typeof colors === 'string') {
						return namedColors[colors];
					}
					return colors || namedColors.primary;
				})((iconsAsArray && iconsAsArray[i] && iconsAsArray[i].colors) || colors),
			),
		[iconsAsArray, colors],
	);

	// Infer border rules
	const borderRadius = React.useMemo(() => {
		if (shape === 'underlined' || variant === 'flat' || shape === 'none') {
			return null;
		}
		if (!shape.includes('.')) {
			return `${shape === 'normal' ? 'md' : 'rounded'}.all`;
		}
		const [kind, side] = shape.split('.');
		return `${kind === 'normal' ? size : 'rounded'}.${side}`;
	}, [variant, shape, size]);

	const buttonThemedStyle = React.useMemo(() => {
		const hasBorder = variant === 'normal' || shape === 'underlined';
		const hasBg = variant === 'normal';
		return {
			...buttonStyle,
			backgroundColor: (hasBg && (isActive ? bghover : bg)) || 'transparent',
			borderColor:
				(hasBorder && (isActive ? borderhover : border)) || 'transparent',
			color: isActive ? texthover : text,
			'>div:nth-of-type(1) svg': {
				fill: isActive ? iconLeftColors.texthover : iconLeftColors.text,
			},
			outline: 'none', // FIXME, он может быть нужен
			svg: {
				fill: isActive ? iconRightColors.texthover : iconRightColors.text,
			},
			cursor: 'pointer',
			...((!isActive && {
				':hover': {
					backgroundColor: hasBg ? bghover : 'transparent',
					borderColor: hasBorder ? borderhover : 'transparent',
					color: texthover,
					'>div:nth-of-type(1) svg': {
						fill: iconLeftColors.texthover,
					},
					svg: {
						fill: iconRightColors.texthover,
					},
				},
				':active': {
					backgroundColor: hasBg ? bgactive : 'transparent',
					borderColor: hasBorder ? borderactive : 'transparent',
					color: textactive,
					'>div:nth-of-type(1) svg': {
						fill: iconLeftColors.textactive,
					},
					svg: {
						fill: iconRightColors.textactive,
					},
				},
			}) ||
				null),
			borderRadius,
			...sx,
		};
	}, [
		variant,
		shape,
		isActive,
		bghover,
		bg,
		borderhover,
		border,
		texthover,
		text,
		iconLeftColors.texthover,
		iconLeftColors.text,
		iconLeftColors.textactive,
		iconRightColors.texthover,
		iconRightColors.text,
		iconRightColors.textactive,
		bgactive,
		borderactive,
		textactive,
		borderRadius,
		sx,
	]);

	const buttonDynamicStyle = React.useMemo(
		() => ({
			width: isWide ? '100%' : null,
			opacity: isDisabled ? '0.4' : null,
			height: heightMap[size],
			borderLeftWidth: variant === 'normal' ? '2px' : 0,
			borderRightWidth: variant === 'normal' ? '2px' : 0,
			borderTopWidth: variant === 'normal' || shape === 'none' ? '2px' : 0,
			borderBottomWidth: variant === 'normal' || shape === 'underlined' ? '2px' : 0,
			cursor: onAction && !isDisabled ? 'pointer' : null,
			...style,
		}),
		[isWide, isDisabled, size, variant, onAction, style, shape],
	);

	const textSx =
		(variant === 'flat' && textSxMap.flat[size]) ||
		(shape === 'rounded' && textSxMap.rounded[size]) ||
		textSxMap.other[size];

	return (
		<Flex
			as={as}
			href={href}
			name={name}
			sx={buttonThemedStyle}
			style={buttonDynamicStyle}
			disabled={isDisabled}
			title={title}
			type={type}
			ref={ref}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...getAction(onClick, onMouseDown, clickBound)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			{iconLeftProps && <Icon {...iconLeftProps} />}
			{label && (
				<Text variant="headlinemd" sx={textSx}>
					{label}
				</Text>
			)}
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			{iconRightProps && <Icon {...iconRightProps} />}
			{children}
		</Flex>
	);
}

export default React.memo<TProps>(React.forwardRef(Button));
