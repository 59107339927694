// @flow
import React from 'react';
import { withAbsoluteDrag as withAbsoluteDragOriginal } from '@graphite/abs-grid';

import type {
	TWidgetAbsoluteProps,
	TWithAbsoluteDragComponentProps,
} from '../constants/types';

const withAbsoluteDrag = (Component: React$ComponentType<TWidgetAbsoluteProps>) => {
	const ComponentWithDrag = withAbsoluteDragOriginal(Component);

	const WithAbsoluteDrag = (props: TWithAbsoluteDragComponentProps) => {
		const { kind, protoId, addWidgetOnClick, ...otherProps } = props;
		const currentRef = React.useRef<?HTMLElement>(null);

		const data = React.useMemo(() => ({ kind }), [kind]);

		const handleMouseUp = React.useCallback(
			event => {
				event.stopPropagation();
				if (addWidgetOnClick) addWidgetOnClick(protoId, 'abs');
			},
			[addWidgetOnClick, protoId],
		);

		return (
			<div ref={currentRef} onMouseUp={handleMouseUp}>
				<ComponentWithDrag
					rowId={null}
					instanceId={null}
					originId={null}
					id={protoId}
					containerId="panel"
					widgetMode="none"
					position="absolute"
					currentRef={currentRef}
					data={data}
					hovered
					dragAvailable
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...otherProps}
				/>
			</div>
		);
	};

	return React.memo<TWithAbsoluteDragComponentProps>(WithAbsoluteDrag);
};

export default withAbsoluteDrag;
