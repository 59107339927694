// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex, Box, Text, Section } from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';
import { defaultDevice } from '@graphite/constants';
import type { TGridBreakpointName, TWidget, TWidgetDiff } from '@graphite/types';
import { defaultValue, effects } from '@graphite/animations';
import EffectPanel from './Effects/EffectPanel';
import TransitionPanel from './Transition/TransitionPanel';

import effectIcon from './img/effect.inline.svg';
import transitionIcon from './img/transition.inline.svg';

type TProps = $ReadOnly<{|
	t: (string) => string,
	data: TWidget,
	onSave: (TWidgetDiff) => void,
	currentDevice: TGridBreakpointName,
	trigger: string,
	editedTrigger: ?string,
	setEditedTrigger: (?string) => void,
|}>;

const hoverSx = {
	cursor: 'pointer',
	'&:hover': {
		background: 'rgba(41, 155, 255, 0.1)',
	},
};

const animateSx = {
	margin: '0 -24px',
	padding: '9px 24px',
	...hoverSx,
};

const animateActiveSx = {
	...animateSx,
	background: 'rgba(41, 155, 255, 0.1)',
};

const animateNameSx = {
	flexGrow: 1,
	marginLeft: '12px',
};

const iconWrapSx = {
	position: 'relative',
	width: '18px',
	height: '18px',
	justifyContent: 'center',
	alignItems: 'center',
};

const iconSx = {
	position: 'absolute',
};

const effectIconSx = {
	...iconSx,
	width: '18px',
	height: '18px',
	backgroundColor: 'bg.accent',
	mask: `url(${effectIcon}) no-repeat center`,
};

const disableEffectIconSx = {
	...effectIconSx,
	backgroundColor: 'text.secondary',
};

const AnimateItemComponent = ({
	t,
	data,
	onSave,
	currentDevice,
	trigger,
	editedTrigger,
	setEditedTrigger,
}: TProps) => {
	const { animation } = data;

	const anchorRef = React.useRef();

	const animate = closestDeviceWithKey(animation, {
		isDeep: true,
		currentDevice,
		key: `animation-${data._id}`,
	});

	const triggerParams = React.useMemo(() => animate[trigger], [animate, trigger]);

	const insertEffect = React.useCallback(
		(prop) => {
			onSave({
				animation: _.flow(
					_.set(
						`${currentDevice}.${trigger}.from.${prop}`,
						defaultValue.from[prop],
					),
					_.set(
						`${currentDevice}.${trigger}.to.${prop}`,
						defaultValue.to[prop],
					),
				)(data.animation),
			});
		},
		[onSave, data.animation, trigger, currentDevice],
	);

	const removeEffect = React.useCallback(
		(prop) => {
			if (currentDevice !== defaultDevice) {
				onSave({
					animation: _.flow(
						_.unset(`${currentDevice}.${trigger}.from.${prop}`),
						_.unset(`${currentDevice}.${trigger}.to.${prop}`),
					)(data.animation),
				});
			}

			onSave({
				animation: _.flow(
					_.set(`${currentDevice}.${trigger}.from.${prop}`, null),
					_.set(`${currentDevice}.${trigger}.to.${prop}`, null),
				)(data.animation),
			});
		},
		[onSave, data.animation, trigger, currentDevice],
	);

	const removeTrigger = React.useCallback(() => {
		if (currentDevice !== defaultDevice) {
			onSave({
				animation: _.unset(`${currentDevice}.${trigger}`, data.animation),
			});
		}

		onSave({
			animation: _.set(`${currentDevice}.${trigger}`, null, data.animation),
		});
	}, [onSave, data.animation, trigger, currentDevice]);

	const changeEffect = React.useCallback(
		(diff) => {
			const fullPathDiff = _.set(
				`${currentDevice}.${trigger}`,
				diff,
				data.animation,
			);

			onSave({
				animation: _.merge(data.animation, fullPathDiff),
			});
		},
		[onSave, data.animation, trigger, currentDevice],
	);

	const changeTransitionType = React.useCallback(
		(transitionType) => {
			const allDefaultValues = {
				...defaultValue.transition[transitionType],
				...defaultValue.transition.sensitivity,
				...defaultValue.transition.repeat,
			};

			onSave({
				animation: _.set(
					`${currentDevice}.${trigger}.transition`,
					allDefaultValues,
					data.animation,
				),
			});
		},
		[onSave, data.animation, trigger, currentDevice],
	);

	const handleSetAnimatePanel = React.useCallback(() => {
		setEditedTrigger(`${trigger}-animate`);
	}, [trigger, setEditedTrigger]);

	const handleSetTransitionPanel = React.useCallback(() => {
		setEditedTrigger(`${trigger}-transition`);
	}, [trigger, setEditedTrigger]);

	const handleClose = React.useCallback(() => {
		setEditedTrigger(null);
	}, [setEditedTrigger]);

	const btnRemoveTrigger = React.useMemo(
		() => ({
			buttons: [
				{
					name: 'remove',
					icons: [
						{
							title: t('Remove trigger'),
							name: 'minus',
							iconSize: 18,
						},
					],
					colors: 'primary',
					ref: anchorRef,
				},
			],
		}),
		[t],
	);

	const transitionName = React.useMemo(() => {
		if (triggerParams && triggerParams?.transition?.type) {
			const duration =
				triggerParams?.transition?.duration >= 0
					? `${triggerParams?.transition?.duration}s `
					: '0s ';

			if (triggerParams.transition.type === 'tween')
				return `${duration}${t(triggerParams.transition.ease)}`;
			if (triggerParams.transition.type === 'spring') return t('Spring');
		}

		return t('Transition');
	}, [t, triggerParams]);

	const is3d = React.useMemo(() => {
		const { from, to } = triggerParams;
		return !!from?.transformPerspective && !!to?.transformPerspective;
	}, [triggerParams]);

	const currentEffects = React.useMemo(() => effects[is3d ? '3d' : '2d'], [is3d]);

	const effectProp = React.useMemo(
		() =>
			_.flow(
				_.filter(
					(prop) =>
						triggerParams?.to?.[prop] === 0 || !!triggerParams?.to?.[prop],
				),
				_.map((prop) => t(prop)),
			)(currentEffects),
		[t, triggerParams, currentEffects],
	);

	const isNoEffect = _.isEmpty(effectProp);

	const effectName = React.useMemo(
		() => (isNoEffect ? t('No effects') : `${_.capitalize(effectProp.join(', '))}`),
		[t, isNoEffect, effectProp],
	);

	const isAnimateButtonShown = editedTrigger === `${trigger}-animate`;
	const isTransitionButtonShown = editedTrigger === `${trigger}-transition`;

	return (
		triggerParams && (
			<Box>
				<Section
					size="sm"
					label={trigger}
					buttonGroup={btnRemoveTrigger}
					onClick={removeTrigger}
				>
					<Flex
						sx={isAnimateButtonShown ? animateActiveSx : animateSx}
						onClick={handleSetAnimatePanel}
					>
						<Flex sx={iconWrapSx}>
							<Box sx={isNoEffect ? disableEffectIconSx : effectIconSx} />
						</Flex>
						<Text
							variant="bodysm"
							color={isNoEffect ? 'text.secondary' : 'text.primaryalt'}
							sx={animateNameSx}
						>
							{effectName}
						</Text>
					</Flex>
					{trigger !== 'scroll' && (
						<Flex
							sx={isTransitionButtonShown ? animateActiveSx : animateSx}
							onClick={handleSetTransitionPanel}
						>
							<Flex sx={iconWrapSx}>
								<Box as="img" src={transitionIcon} sx={iconSx} />
							</Flex>
							<Text
								variant="bodysm"
								color="text.primaryalt"
								sx={animateNameSx}
							>
								{transitionName}
							</Text>
						</Flex>
					)}
				</Section>
				{isAnimateButtonShown && (
					<EffectPanel
						t={t}
						trigger={trigger}
						params={triggerParams}
						handleClose={handleClose}
						anchorEl={anchorRef}
						insertEffect={insertEffect}
						changeEffect={changeEffect}
						removeEffect={removeEffect}
					/>
				)}
				{isTransitionButtonShown && (
					<TransitionPanel
						t={t}
						trigger={trigger}
						params={triggerParams}
						handleClose={handleClose}
						anchorEl={anchorRef}
						changeEffect={changeEffect}
						changeTransitionType={changeTransitionType}
					/>
				)}
			</Box>
		)
	);
};

export default React.memo<TProps>(AnimateItemComponent);
