// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute } from '@graphite/abs-grid';
import withRef from '@graphite/with-ref';

import type { TWidgetOwnProps } from '@graphite/types';

import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import Input from './Input';
import type { TConnectProps } from './constants/types';
import withFonts from './libs/with-fonts';

const WidgetComponent = compose(
	connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(getMapStateToProps),
	withRef,
	withAbsolute<TConnectProps>(),
	withFonts,
)(Input);

export default WidgetComponent;
