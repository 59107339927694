// @flow
import { getTrueIds, adjustColorspec } from '@graphite/selectors';

import { composeCached } from 'Widget/selectors';
import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';

import type { TWidgetStackComposed } from '../constants/types';

const mapStateToProps = (
	{ widgets }: TStateEditor,
	{ id, containerId, widgetspec, colorspec, gridspec, effectspec }: TWidgetOwnProps,
) => {
	const parent =
		containerId && widgets[containerId]
			? composeCached(widgets, widgets[containerId])
			: null;
	const positions = parent ? parent.positions : { [id]: 'absolute' };

	const data: TWidgetStackComposed = composeCached(widgets, widgets[id]);

	const babies = getTrueIds({
		_id: data._id,
		children: data.children,
		order: data.order,
		currentDevice: 'desktop',
	});

	return {
		data,
		gridspec,
		colorspec:
			colorspec &&
			widgetspec &&
			adjustColorspec({ data, colorspec, widgetspec, target: 'stack' }),
		widgetspec,
		effectspec,
		babies,
		position: positions[data._id],
	};
};

export default mapStateToProps;
