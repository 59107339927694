// @flow

import React from 'react';
import type { PlayData } from './types';

const contextDefaults = {
	isPlay: false,
	animation: {
		from: {},
		to: {},
		transition: {},
	},
	dispatch: () => ({ payload: '', type: '' }),
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'animation':
			return {
				...state,
				animation: action.payload.animation,
				isPlay: false,
			};
		case 'play':
			return {
				...state,
				isPlay: true,
			};
		case 'stop':
			return {
				...state,
				isPlay: false,
			};
		default:
			return state;
	}
};

export const useAnimatePlayContext = (): PlayData => {
	const trigger = React.useReducer(reducer, contextDefaults);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return React.useMemo(() => trigger, [trigger[0]]);
};

export const AnimatePlayContext = React.createContext<PlayData>([
	contextDefaults,
	() => {},
]);
