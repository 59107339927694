// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute, withAbsoluteDrag } from '@graphite/abs-grid';
import withRef from '@graphite/with-ref';

import withSymbiote from 'Editor/libs/with-symbiote';
import withControls from 'Editor/libs/with-controls';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import withDrag from 'Widget/libs/dnd/drag';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';
import withWidgetEdit from 'Widget/libs/with-widget-edit';
import withFilter from 'Widget/libs/with-filter';
import Code from '@graphite/widget-code';
import { withPlay } from '@graphite/animation-edit';
import type { TWidgetOwnProps } from '@graphite/types';

import type {
	TConnectPropsEdit,
	TConnectPropsDrag,
	TConnectProps,
} from './constants/types';
import Controls from './Controls';
import withCodeEditor from './libs/with-code-editor';
import mapDispatchToPropsEdit from './libs/mapDispatchToPropsEdit';
import mapStateToPropsEdit from './libs/mapStateToPropsEdit';

import withHydrate from '../../libs/with-hydrate';

const filter = (props: TConnectPropsDrag): TConnectProps => ({
	id: props.id,
	containerId: props.id,
	instanceId: props.instanceId,
	originId: props.originId,
	data: props.data,
	widgetspec: props.widgetspec,
	colorspec: props.colorspec,
	effectspec: props.effectspec,
	gridspec: props.gridspec,
	position: props.position,
	dispatch: props.dispatch,
	dragContainer: props.dragContainer,
	dragFlip: props.dragFlip,
	rowId: props.rowId,
	children: props.children,
	widgetMode: props.widgetMode,
});

const WidgetComponentEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		mapStateToPropsEdit,
		mapDispatchToPropsEdit,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	(Component) => withRef<TConnectPropsEdit>(Component),
	withDrag,
	withWidgetResizer,
	// Выносим ХОКи на служебный слой
	withSymbiote(
		// Добавляет возможность таскать абсолютные виджеты
		withAbsoluteDrag,
		{
			level: 'abs-drag-place',
		},
	),
	// Выносим ХОКи на служебный слой
	withPlay,
	withSymbiote(
		compose(withWidgetEdit, withWidgetControls({ margin: true }), (Component) =>
			withControls(checkActiveWidget, Controls, Component, {
				isAnimateAvailable: false,
			}),
		),
	),
	// Добавляем виджету возможность быть абсолютным
	withAbsolute(),
	// Выносим ХОКи на служебный слой
	withSymbiote(withCodeEditor),
	(WidgetComponent) =>
		withFilter<TConnectPropsDrag, TConnectProps>(filter, WidgetComponent),
	(WidgetComponent) =>
		withHydrate(WidgetComponent, {
			baseSx: {
				flexDirection: 'column',
			},
		}),
)(Code);

export default WidgetComponentEdit;
