// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';

import type { TWidget } from '@graphite/types';

type TProps = $ReadOnly<{|
	data: TWidget,
	save: (string, string | Object) => void,
|}>;

const paramLabel = [
	{
		title: 'Name',
		key: 'label',
		kind: 'string',
		info: { maxLength: 140 },
	},
	{
		title: 'Placeholder',
		key: 'placeholder',
		kind: 'string',
		info: { maxLength: 140 },
		hideSeparator: true,
		gap: 15,
	},
	{
		title: 'Required',
		key: 'isRequired',
		kind: 'switch',
		info: {},
	},
	{
		title: 'Max length',
		key: 'maxlength',
		kind: 'string',
		info: {
			validate: val => val === '' || !!String(val).match(/^\d+$/),
			placeholder: 'None',
			maxLength: 3,
		},
	},
	{
		title: 'Resize',
		key: 'resize',
		kind: 'select',
		info: {
			label: 'Both',
			list: {
				items: [
					{ label: 'Both', name: 'both' },
					{ label: 'Horizontal', name: 'horizontal' },
					{ label: 'Vertical', name: 'vertical' },
					{ label: 'None', name: 'none' },
				],
			},
		},
	},
];

const Settings = ({ save, data }: TProps) => {
	/**
		Text
	 */
	const paramSourceText = React.useMemo(() => {
		const {
			textarea = {
				label: '',
				placeholder: '',
				resize: 'both',
				maxlength: null,
				isRequired: false,
			},
		} = data;

		return {
			label: textarea.label,
			placeholder: textarea.placeholder,
			isRequired: textarea.isRequired ? 'true' : '',
			maxlength: `${textarea.maxlength || ''}`,
			resize: textarea.resize,
		};
	}, [data]);

	const changeParamText = React.useCallback(
		(key, value) => {
			save('textarea', {
				...data.textarea,
				[key]: value,
			});
		},
		[save, data.textarea],
	);

	return (
		<>
			<Section label="Textarea">
				<ListParams
					listName="text"
					paramSource={paramSourceText}
					paramList={paramLabel}
					unit={1}
					onChange={changeParamText}
				/>
			</Section>
		</>
	);
};

export default React.memo<TProps>(Settings);
